import React from "react";
import styled, { css } from "styled-components";
import {
  colors,
  durations,
  fontSizes,
  preset,
  space,
  timingFunctions,
} from "~/theme";
import { InputWithLabel } from "./input-with-label";

type RadioProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  onClick?: () => void;
  disabled?: boolean;
  name?: string;
  testingName?: string;
};

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    { checked, onChange, onClick, disabled, name, testingName }: RadioProps,
    ref
  ) => {
    const handleChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) =>
        onChange(evt.target.checked),
      [onChange]
    );

    return (
      <RadioContainer data-t={testingName} disabled={disabled}>
        <HiddenRadio
          checked={checked}
          onChange={handleChange}
          onClick={() => onClick && onClick()}
          disabled={disabled}
          name={name}
          ref={ref}
        />
        <VisibleRadio checked={checked} disabled={disabled} />
      </RadioContainer>
    );
  }
);

Radio.displayName = "Radio";

const RadioContainer = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  cursor: ${(x) => !x.disabled && "pointer"};
`;

const HiddenRadio = styled.input.attrs(() => ({ type: "radio" }))`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    background: red;
  }
`;

const VisibleRadio = styled.div<{ checked?: boolean; disabled?: boolean }>`
  position: relative;
  border: 1px solid ${colors.ghostGray};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  color: white;

  transition-property: background-color, border-color, color, box-shadow;
  transition-duration: ${durations.sm};
  transition-timing-function: ${timingFunctions.out};

  opacity: ${(x) => (x.disabled ? 0.5 : 1)};

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    transform: scale(0);
    transition-property: transform, opacity;
    transition-duration: ${durations.md};
    transition-timing-function: ${timingFunctions.out};
    background-color: ${colors.indigo};
    border-radius: 50%;
    opacity: 0;
  }

  ${(x) =>
    !x.disabled &&
    css`
      &:hover {
        color: ${colors.ghostGray};
        border-color: ${colors.indigo};
      }
    `}

  ${HiddenRadio}:hover:not(:disabled) + & {
    border-color: ${colors.slateGray};
  }
  ${HiddenRadio}:hover:not(:disabled):focus + &,
  ${HiddenRadio}:hover:not(:disabled):checked + & {
    border-color: ${colors.indigo};
  }

  ${HiddenRadio}:focus + & {
    border-color: ${colors.indigo};
    box-shadow: 0 0 0px 1px ${colors.indigo};
  }

  ${HiddenRadio}:checked + & {
    border-color: ${colors.indigo};

    &::after {
      transform: scale(1);
      transition-duration: ${durations.lg};
      opacity: 1;
    }
  }
`;

export function RadioWithLabel({
  label,
  ...radioProps
}: RadioProps & { label?: React.ReactNode }) {
  return (
    <InputWithLabel
      input={<Radio {...radioProps} />}
      disabled={radioProps.disabled}
    >
      {label}
    </InputWithLabel>
  );
}

export function RadioWithLabelAndDescription({
  label,
  description,
  noMargin,
  ...radioProps
}: RadioProps & {
  label?: React.ReactNode;
  description?: React.ReactNode;
  noMargin?: boolean;
}) {
  return (
    <StyledLabel noMargin={noMargin}>
      <StyledInput>
        <Radio {...radioProps} />
      </StyledInput>
      <span>
        <span css={preset.typography.h5}>{label}</span>
        <Description>{description}</Description>
      </span>
    </StyledLabel>
  );
}

const Description = styled.span`
  display: block;
  font-size: ${fontSizes.sm};
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: center;
  margin-right: ${space.md};
`;

const StyledLabel = styled.label<{ noMargin?: boolean }>`
  padding: ${space.xs} 0;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  margin-bottom: ${(x) => (x.noMargin ? undefined : space.sm)};
`;
