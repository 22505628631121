export type ImpactLabel = (typeof impactLabels)[number];

const impactLabels = [
  "Harmful",
  "Disruptive",
  "Interruption",
  "Acceptable",
  "Positive",
  "Perfect",
] as const;

export function sortImpacts<T extends { name: string }>(a: T, b: T) {
  return (
    impactLabels.indexOf(b.name as ImpactLabel) -
    impactLabels.indexOf(a.name as ImpactLabel)
  );
}

export function isImpact(x: unknown): x is ImpactLabel {
  return impactLabels.includes(x as ImpactLabel);
}

export function getImpactLabel(impact: number): ImpactLabel {
  if (impact <= -2.6) {
    return "Harmful";
  } else if (impact <= -1.3) {
    return "Disruptive";
  } else if (impact < 0) {
    return "Interruption";
  } else if (impact === 0) {
    return "Acceptable";
  } else if (impact <= 1.8) {
    return "Positive";
  } else {
    return "Perfect";
  }
}
