import React from "react";

export function RenderOnceInViewport({
  children,
  placeholder,
}: {
  children: React.ReactNode;
  placeholder?: React.ReactNode;
}) {
  const [isInViewport, setIsInViewport] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isInViewport) return;

    const observer = new IntersectionObserver(([entry]) => {
      setIsInViewport((x) => x || entry.isIntersecting);
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref, isInViewport]);

  return <div ref={ref}>{isInViewport ? children : placeholder}</div>;
}
