import type { ChangeEvent } from "react";
import React from "react";
import styled, { css } from "styled-components";
import { inputStyle } from "./input";
import { Icon } from "./icon";

export type SelectProps<T> = {
  options: Array<{ label: string; value: T | undefined }>;
  value: T | undefined;
  onChange: (value: T | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  testingName?: string;
};

export function Select<T extends string | undefined>({
  options,
  value,
  onChange,
  placeholder,
  disabled,
  required,
  testingName,
}: SelectProps<T>) {
  function handleChange(evt: ChangeEvent<HTMLSelectElement>) {
    const index = options.findIndex((x) => x.value === evt.target.value);
    onChange(index > -1 ? options[index].value : undefined);
  }

  return (
    <div css={{ position: "relative" }}>
      <StyledSelect
        value={value}
        onChange={handleChange}
        disabled={disabled}
        required={required}
        data-t={testingName}
      >
        {placeholder && <option value="">{placeholder}</option>}

        {options.map((option, i) => (
          <option value={option.value} key={i}>
            {option.label}
          </option>
        ))}
      </StyledSelect>

      <div
        css={css`
          pointer-events: none;
          position: absolute;
          right: 12px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
        `}
      >
        <Icon size={24} name="chevronDown" />
      </div>
    </div>
  );
}

const StyledSelect = styled.select`
  ${inputStyle};
`;
