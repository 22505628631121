/**
 * When a parent gets deselected, any selected children of that parent should
 * also get deselected. Previously we handled this by firing a change handler
 * for the children when un-mounting (without branch preview) or disabled (with
 * branch preview).
 *
 * That strategy worked for normal user interaction where the selection of
 * parent and children is chronological, but it somehow made it difficult to
 * apply the selection directly via a list of ids of nested scenarios (like when
 * applying a pre-assess rule).
 *
 * Therefore we have chosen to keep the responsibility for clearing the child
 * selections in the business logic here. It makes the ScenarioBranch component
 * more understandable and predictable.
 *
 * The updateSelection function is always called per level. So ids apply only to
 * the direct children of that parent, called siblings below.
 */

import { difference } from "lodash-es";
import type { ScenarioIdsByParent } from "..";

export function updateScenarioSelectionInPlace(
  selectedIdsMap: ScenarioIdsByParent,
  parentId: string,
  values?: string[]
): void {
  const oldValues = selectedIdsMap[parentId];
  const removedSiblingIds = difference(oldValues, values || []);

  if (values) {
    /**
     * Clear any selection concerning children from the siblings that got
     * removed or disabled.
     */
    deselectScenariosAndChildren(selectedIdsMap, removedSiblingIds);

    selectedIdsMap[parentId] = values;
  } else {
    delete selectedIdsMap[parentId];
  }
}

/**
 * Recursively clears any selection related to the given scenario ids. Mutates
 * the map in-place.
 */
function deselectScenariosAndChildren(
  selectedIdsMap: Record<string, string[]>,
  deselectScenarioIds: string[]
) {
  for (const scenarioId of deselectScenarioIds) {
    const children = selectedIdsMap[scenarioId];

    if (children) {
      /** Recurse to remove all levels of children */
      deselectScenariosAndChildren(selectedIdsMap, children);
    }

    delete selectedIdsMap[scenarioId];
  }
}
