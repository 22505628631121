import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import type { Condition, ConditionalText } from "@gemini/common";
import { cleanObject } from "@gemini/common";
import { Button, Input, Tabs } from "antd";
import { toJS } from "mobx";
import React from "react";
import { ConditionEditor } from "~/features/admin/components";
import {
  Comment,
  SectionTitle,
} from "~/features/admin/guideline/edit/components";

type ConditionalTextEditorProps = {
  conditionalText: ConditionalText;
  onChange: (value?: ConditionalText) => void;
};

export function ConditionalTextEditor({
  conditionalText,
  onChange,
}: ConditionalTextEditorProps) {
  const { conditionals } = conditionalText;

  function generateKey(condition: Condition, index: number): string {
    const { industry_ids = [], tag_ids = [] } = condition;
    return [index.toString()].concat(industry_ids, tag_ids).join();
  }

  return (
    <div>
      <Tabs
        animated={false}
        items={[
          {
            label: "Default",
            key: "default",
            children: (
              <>
                <SectionTitle small noTopMargin>
                  Text
                </SectionTitle>
                <Input.TextArea
                  style={{
                    marginBottom: "0.5em",
                  }}
                  autoSize
                  placeholder="No default text"
                  value={conditionalText.default}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    onChange(
                      cleanObject<ConditionalText>({
                        default: value ? value : undefined,
                        conditionals,
                      })
                    );
                  }}
                />
                <Comment>
                  The default text is displayed as a fallback in case none of
                  the conditions of the conditional texts are met.
                </Comment>
              </>
            ),
          },
          ...conditionals.map(({ condition, text }, index) => ({
            label: `Conditional ${index + 1}`,
            key: generateKey(condition, index),
            children: (
              <>
                <SectionTitle small noTopMargin>
                  Text
                </SectionTitle>
                <Input.TextArea
                  style={{
                    marginBottom: "0.5em",
                  }}
                  autoSize
                  autoFocus
                  value={text}
                  onChange={(evt) => {
                    const value = evt.target.value || "";
                    const updatedConditionals = toJS(conditionals);
                    updatedConditionals[index].text = value;
                    onChange(
                      cleanObject<ConditionalText>({
                        default: conditionalText.default,
                        conditionals: updatedConditionals,
                      })
                    );
                  }}
                />
                <SectionTitle small>Condition</SectionTitle>
                <div
                  css={`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <ConditionEditor
                    condition={condition}
                    onChange={(condition) => {
                      const updatedConditionals = toJS(conditionals);
                      updatedConditionals[index].condition = condition;
                      onChange(
                        cleanObject<ConditionalText>({
                          default: conditionalText.default,
                          conditionals: updatedConditionals,
                        })
                      );
                    }}
                  />

                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    size="small"
                    ghost
                    onClick={() => {
                      const updatedConditionals = toJS(conditionals).splice(
                        index,
                        1
                      );

                      if (updatedConditionals.length === 0) {
                        // If last clear out everything including default text
                        onChange();
                      } else {
                        onChange(
                          cleanObject<ConditionalText>({
                            default: conditionalText.default,
                            conditionals: updatedConditionals,
                          })
                        );
                      }
                    }}
                  >
                    Remove Conditional {index + 1}
                  </Button>
                </div>
              </>
            ),
          })),
        ]}
      />

      <Button
        type="primary"
        icon={<PlusOutlined />}
        size="small"
        onClick={() => {
          const updatedConditionals = toJS(conditionals);
          updatedConditionals.push({
            condition: { tag_ids: [] },
            text: `Conditional text ${updatedConditionals.length + 1}`,
          });
          onChange({
            default: conditionalText.default,
            conditionals: updatedConditionals,
          });
        }}
      >
        Add Conditional
      </Button>
    </div>
  );
}
