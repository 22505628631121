import React from "react";
import { colors } from "~/theme";
import { useLocalStorageNumber } from "./use-local-storage-number";

const DEBUG_MODE_KEY = "gemini-debug-mode";
let hasLogged = false;

const log = (msg: string, ...args: unknown[]) =>
  console.log(`%c[gemini]: ${msg}`, `color: ${colors.indigo}`, ...args);

export function useDebug() {
  const [isDebugMode] = useLocalStorageNumber(DEBUG_MODE_KEY);

  React.useEffect(() => {
    if (hasLogged) return;
    hasLogged = true;

    if (isDebugMode) {
      log("gemini-debug-mode enabled");
    }

    if (process.env.NODE_ENV !== "production" && !isDebugMode) {
      log(
        `run \`localStorage.setItem("${DEBUG_MODE_KEY}", "1")\` to enable debug mode`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isDebugMode: !!isDebugMode,
  };
}
