import { assert } from "@gemini/common";
import { signInWithCustomToken } from "firebase/auth";
import { signInWithBaymard } from "../api";
import { auth } from "../firebase";

export async function signInWithTokenFromBaymard(
  email: string,
  password: string
) {
  const result = await signInWithBaymard({ email, password });

  assert(result.data.token, "Failed to get token");

  await signInWithCustomToken(auth, result.data.token);
}

export function getCurrentUserId() {
  assert(auth.currentUser, "There is no current user");

  return auth.currentUser.uid;
}

export function loadScript(src: string) {
  const script = document.createElement("script");
  script.setAttribute("src", src);
  document.body.appendChild(script);
}

export async function getBaymardUserToken() {
  const request = await fetchWithCredentials(
    process.env.PHASE === "prd"
      ? `https://baymard.com/gemini-api/firebase-auth-token?env=prd`
      : `/gemini-api/firebase-auth-token?env=${process.env.PHASE}`
  );

  const { token } = await request.json();

  return token;
}

export function redirectToSignIn() {
  const returnUrl = encodeURIComponent(window.location.href);
  window.location.href =
    process.env.PHASE === "prd"
      ? `https://baymard.com/premium/sign-in?return-url=${returnUrl}`
      : `/premium/sign-in?return-url=${returnUrl}`;
}

function fetchWithCredentials(url: string) {
  return fetch(url, {
    mode: "cors",
    credentials: "include",
    cache: "no-cache",
    redirect: "follow",
  });
}
