import React from "react";
import { colors } from "~/theme";
import { Title } from "..";
import { SidebarAnchor } from "./anchor";
import { Part } from "./part";

export type TextPartProps = {
  area: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  link?: {
    label: string;
    href: string;
  };
};

export function TextPart({ area, title, link, children }: TextPartProps) {
  return (
    <Part area={area}>
      {title && (
        <Title level={5} as="h3">
          {title}
        </Title>
      )}
      <div css={{ color: colors.ghostGray }}>{children}</div>

      {link && <SidebarAnchor href={link.href}>{link.label}</SidebarAnchor>}
    </Part>
  );
}
