import { observer } from "mobx-react-lite";
import type { ReactNode } from "react";
import React from "react";
import { FileInput, SpacedChildren, Text } from "~/components";
import { fontSizes, space } from "~/theme";

type FileFieldProps = {
  disabled?: boolean;
  onAdd: (files: File[]) => void;
  label: string;
  children: ReactNode;
  extraLabel?: string;
};

export const FileField = observer(
  ({ disabled, label, extraLabel, onAdd, children }: FileFieldProps) => {
    return (
      <SpacedChildren size="sm">
        <div css={disabled ? { cursur: "default", opacity: 0.75 } : undefined}>
          <FileInput disabled={disabled} onChange={onAdd} label={label} />
          {extraLabel && <Text size="small">{extraLabel}</Text>}
        </div>

        <div
          css={{
            display: "flex",
            flexWrap: "wrap",
            margin: `-${space.xs}`,
            fontSize: fontSizes.sm,
          }}
        >
          {children}
        </div>
      </SpacedChildren>
    );
  }
);
