import { getErrorMessage } from "@gemini/common";
import { Button, Space, Tabs, Tag, Typography } from "antd";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useState } from "react";
import {
  CheckboxWithLabel,
  LoadingIndicator,
  SpacedChildren,
} from "~/components";
import { callPublishVersion, callUnPublishVersion } from "~/modules/callable";
import { useBeforeunload, useIsMountedRef } from "~/modules/hooks";
import { notify } from "~/modules/notifications";
import { validateTree } from "../logic/validate-tree";
import { useEditStore } from "../store";
import { CloneGuidelineForm } from "./clone-guideline-form";
import { Comment } from "./comment";
import { PanelTitle } from "./panel-title";
import { RevisionHistory } from "./revision-history";

const { Text } = Typography;

export const GuidelineStatusView = observer(() => {
  const store = useEditStore();

  const [isCalling, setIsCalling] = useState(false);
  useBeforeunload((event) => isDirty && event.preventDefault());

  const isMountedRef = useIsMountedRef();

  const { comparisonGuideline, guidelineState, isDirty, isSubmitting } = store;

  if (!comparisonGuideline) {
    return null;
  }

  function handleSubmit() {
    /**
     * First run some validation here. We could use a hook and have the result
     * disable the button, but that would trigger the validation on every
     * edit/text change and that seems like overkill.
     */

    const validation = validateTree("__root", store.scenarios);

    if (validation.isValid) {
      store
        .submitChanges()
        .then(() => notify.success("Saved changes successfully"))
        .catch((err) => {
          notify.error(`Failed to save changes. ${getErrorMessage(err)}`);
        });
    } else {
      notify.error(
        `Unable to submit changes because the tree is not currently valid: ${validation.validationError}`
      );
    }
  }

  if (!guidelineState.data) {
    return <LoadingIndicator />;
  }

  const { reference } = comparisonGuideline.data;

  const isLastEdited =
    comparisonGuideline.id === guidelineState.data.last_edited_id;
  const isLastPublished =
    comparisonGuideline.id === guidelineState.data.last_published_id;

  return (
    <>
      <Space align="baseline">
        <PanelTitle>Status</PanelTitle>
        {isLastPublished ? (
          <Tag color="purple">Published</Tag>
        ) : (
          <Tag color="blue">Draft</Tag>
        )}
        {isDirty && <Tag color="orange">Has Changes</Tag>}
        {!isLastEdited && !isSubmitting && (
          <Tag color="red">!! Not Editing Latest</Tag>
        )}
      </Space>

      <Tabs
        items={[
          {
            label: "Submit & Publish",
            key: "submit",
            children: (
              <SpacedChildren>
                {!isLastEdited && (
                  <Comment>
                    You are not currently editing the latest version! This
                    probably means that someone has submitted changes to this
                    guideline while you were editing. You can choose to
                    overwrite those changes by submitting, or loose your edits
                    and{" "}
                    <Link
                      // Not using href+as here because we want the page to reload.
                      href={`/admin/guidelines/${reference}/edit`}
                    >
                      load latest version.
                    </Link>
                  </Comment>
                )}
                {store.hasTextualChanges && (
                  <>
                    <Comment>
                      You have made some changes to textual elements. If these
                      changes are meant to invalidate scenarios of older
                      versions, tick the box below. Users of existing reviews
                      will then be prompted to re-assess this guideline if the
                      edited scenario was part of their selection.
                    </Comment>
                    <CheckboxWithLabel
                      onChange={(v) => (store.shouldUseTextualChanges = v)}
                      checked={store.shouldUseTextualChanges}
                    >
                      <Text>Use textual changes to invalidate scenarios. </Text>
                      <Text type="danger">Read warning above.</Text>
                    </CheckboxWithLabel>
                  </>
                )}

                <Button
                  type={isLastEdited ? "primary" : "default"}
                  danger={!isLastEdited}
                  size="small"
                  disabled={!isDirty}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {isDirty
                    ? isLastEdited
                      ? "Submit Changes"
                      : "Submit Changes Anyway"
                    : "No Changes to Submit"}
                </Button>

                {isLastEdited && (
                  <>
                    {isLastPublished ? (
                      <>
                        {isDirty ? (
                          <Comment>
                            Un-publishing is disabled when there are
                            un-submitted changes to the document.
                          </Comment>
                        ) : (
                          <Comment>
                            Un-publishing a guideline means that it will no
                            longer be used in newly created reviews or
                            considered for updating older reviews. It will take
                            the status back to draft.
                          </Comment>
                        )}
                        <Button
                          size="small"
                          danger
                          ghost
                          loading={isCalling}
                          onClick={() => {
                            setIsCalling(true);
                            callUnPublishVersion(comparisonGuideline.id)
                              .catch((err) => notify.error(err))
                              .finally(
                                () =>
                                  isMountedRef.current && setIsCalling(false)
                              );
                          }}
                          disabled={isDirty}
                        >
                          Un-Publish This Version
                        </Button>
                      </>
                    ) : (
                      <>
                        <Comment>
                          Publishing a guideline means that it will be set as
                          the current version which will be included in any
                          newly created reviews from that point on. Also it will
                          be considered for applying updates to existing
                          reviews.
                        </Comment>
                        <Comment>
                          Publishing is disabled if the guideline is already
                          published.
                        </Comment>
                        <Button
                          size="small"
                          danger
                          ghost
                          loading={isCalling}
                          onClick={() => {
                            setIsCalling(true);
                            callPublishVersion(comparisonGuideline.id)
                              .then(() =>
                                notify.info("Published current version")
                              )
                              .catch((err) => notify.error(err))
                              .finally(() => setIsCalling(false)); // @TODO use isMounted
                          }}
                          disabled={isDirty}
                        >
                          Publish This Version
                        </Button>
                      </>
                    )}
                  </>
                )}
              </SpacedChildren>
            ),
          },
          {
            label: "History",
            key: "history",
            children: (
              <RevisionHistory
                guidelineReference={reference}
                allowRevert={!isLastPublished && !isDirty}
                onNewDocument={(documentId) => {
                  store
                    .injectNewGuidelineData(documentId)
                    .catch((err) => notify.error(err));
                }}
              />
            ),
          },
          {
            label: "Clone",
            key: "clone",
            children: (
              <CloneGuidelineForm
                srcGuidelineId={
                  store.comparisonGuideline?.id ?? "__no_guideline_id"
                }
                counterpartReferences={store.counterpartReferences ?? {}}
              />
            ),
          },
        ]}
      />
    </>
  );
});
