import React from "react";
import { usePrevious } from "~/modules/hooks";
import { notify } from "~/modules/notifications";
import { Button } from "./button";

export function NotifyNewVersion({ enabled }: { enabled: boolean }) {
  const [buildId, updateBuildId] = useBuildId();
  const prevBuildId = usePrevious(buildId);

  React.useEffect(() => {
    if (enabled) {
      updateBuildId();
      const id = setInterval(
        updateBuildId,
        1000 * 60 * 5 // five minutes
      );
      return () => clearInterval(Number(id));
    }
  }, [buildId, updateBuildId, enabled]);

  React.useEffect(() => {
    if (prevBuildId && prevBuildId !== buildId) {
      showReloadNotification();
    }
  }, [buildId, prevBuildId]);

  return null;
}

function useBuildId(): [string | undefined, () => void] {
  const [buildId, setBuildId] = React.useState<string>();
  const updateBuildId = React.useCallback(async () => {
    try {
      const request = await fetch(`/_next/static/build.json`);
      const { build_id } = await request.json();

      setBuildId(build_id);
    } catch (err) {
      console.error("Error while reading build.json", err);
    }
  }, []);

  return [buildId, updateBuildId];
}

function showReloadNotification() {
  notify.info({
    message: "Please refresh the page",
    description: "A new version of the Review Tool is available",
    duration: null,
    btn: <ReloadButton />,
    key: "refresh-page",
  });
}

function ReloadButton() {
  const [clicked, setIsClicked] = React.useState(false);

  return (
    <Button
      size="small"
      variant="tertiary"
      loading={clicked}
      onClick={() => {
        setIsClicked(true);
        window.location.reload();
      }}
    >
      Refresh
    </Button>
  );
}
