import { css } from "styled-components";
import { fontSizes, fontWeights, lineHeights, mediaQueries } from "../theme";

export type Preset = typeof preset;

export const preset = {
  typography: {
    h1: css`
      font-size: ${fontSizes.xxl};
      line-height: ${lineHeights.xs};
      font-weight: ${fontWeights.bold};

      @media ${mediaQueries.md} {
        font-size: ${fontSizes.xxxl};
      }
    `,
    h2: css`
      font-size: ${fontSizes.xxl};
      line-height: ${lineHeights.md};
      font-weight: ${fontWeights.bold};
    `,
    h3: css`
      font-size: ${fontSizes.xl};
      line-height: ${lineHeights.sm};
      font-weight: ${fontWeights.bold};
    `,
    h4: css`
      font-size: ${fontSizes.lg};
      line-height: ${lineHeights.xs};
      font-weight: ${fontWeights.bold};
    `,
    h5: css`
      font-size: ${fontSizes.md};
      line-height: ${lineHeights.sm};
      font-weight: ${fontWeights.semibold};
    `,
    h6: css`
      font-size: ${fontSizes.md};
      line-height: ${lineHeights.sm};
      font-weight: ${fontWeights.semibold};
    `,
    subtitle1: css`
      font-size: ${fontSizes.mdLg};
      line-height: ${lineHeights.md};
    `,
    subtitle2: css`
      font-size: ${fontSizes.sm};
      line-height: ${lineHeights.lg};
      font-weight: ${fontWeights.semibold};
    `,
    body1: css``,
    body2: css`
      font-size: ${fontSizes.sm};
      line-height: ${lineHeights.lg};
    `,
    body3: css`
      font-size: ${fontSizes.mdLg};
      line-height: ${lineHeights.lg};
    `,
    button: css`
      font-size: ${fontSizes.xs};
      line-height: ${lineHeights.md};
      font-weight: ${fontWeights.bold};
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    `,
    buttonXs: css`
      font-size: ${fontSizes.xxs};
      line-height: ${lineHeights.md};
      font-weight: ${fontWeights.bold};
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    `,
    caption: css`
      font-size: ${fontSizes.xs};
      line-height: ${lineHeights.sm};
    `,
    overline: css`
      font-size: ${fontSizes.xs};
      line-height: ${lineHeights.xl};
      font-weight: ${fontWeights.bold};
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    `,
  },
} as const;
