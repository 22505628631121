import React from "react";
import { FatLink } from "./fat-link";
import { IconInline } from "./icon";

export function ForwardLink({
  href,
  children,
  disabled,
  onClick,
  hideIcon,
}: {
  children: React.ReactNode;
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
  hideIcon?: boolean;
}) {
  return (
    <FatLink href={href} disabled={disabled} onClick={onClick}>
      {children}{" "}
      {!hideIcon && <IconInline name="chevronRight" cx={{ marginRight: -6 }} />}{" "}
    </FatLink>
  );
}
