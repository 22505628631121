import { Card } from "antd";
import React from "react";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import styled, { createGlobalStyle } from "styled-components";
import { useLocalStorageNumber } from "~/modules/hooks";

type ViewportGridProps = {
  topLeft: React.ReactNode;
  topRight: React.ReactNode;
  bottomLeft: React.ReactNode;
  bottomRight: React.ReactNode;
};

export function ViewportGrid({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
}: ViewportGridProps) {
  const [columnWidth, setColumnWidth] = useLocalStorageNumber(
    "viewport-grid-column"
  );

  const [leftRowHeight, setLeftRowHeight] = useLocalStorageNumber(
    "viewport-grid-left-row"
  );

  const [rightRowHeight, setRightRowHeight] = useLocalStorageNumber(
    "viewport-grid-right-row"
  );

  return (
    <div>
      <ReflexStyles />
      <ReflexContainer
        orientation="vertical"
        style={{ height: "calc(100vh - 110px)" }}
      >
        <ReflexElement
          minSize={100}
          flex={columnWidth}
          onStopResize={(x) => setColumnWidth(x.component.props.flex)}
        >
          <ReflexContainer orientation="horizontal">
            <ReflexElement
              minSize={100}
              flex={leftRowHeight}
              onStopResize={(x) => setLeftRowHeight(x.component.props.flex)}
            >
              <Pane>{topLeft}</Pane>
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement minSize={100}>
              <Pane>{bottomLeft}</Pane>
            </ReflexElement>
          </ReflexContainer>
        </ReflexElement>

        <ReflexSplitter />

        <ReflexElement minSize={100}>
          <ReflexContainer orientation="horizontal">
            <ReflexElement
              minSize={100}
              flex={rightRowHeight}
              onStopResize={(x) => setRightRowHeight(x.component.props.flex)}
            >
              <Pane>{topRight}</Pane>
            </ReflexElement>

            <ReflexSplitter />

            <ReflexElement minSize={100}>
              <Pane>{bottomRight}</Pane>
            </ReflexElement>
          </ReflexContainer>
        </ReflexElement>
      </ReflexContainer>
    </div>
  );
}

const Pane = styled(Card)`
  display: block;
  overflow: scroll;
  width: 100%;
  height: 100%;
`;

const ReflexStyles = createGlobalStyle`
  /* The following styles are copy-paste from the library */

  body.col-resize {
    cursor: col-resize;
  }

  body.row-resize {
    cursor: row-resize;
  }

  .reflex-container {
    justify-content: flex-start;
    /* align items in Main Axis */
    align-items: stretch;
    /* align items in Cross Axis */
    align-content: stretch;
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .reflex-container.horizontal {
    flex-direction: column;
    min-height: 1px;
  }

  .reflex-container.vertical {
    flex-direction: row;
    min-width: 1px;
  }

  .reflex-container > .reflex-element {
    position: relative;
    overflow: auto;
    height: 100%;
    width: 100%;
  }

  .reflex-container.reflex-resizing > .reflex-element {
    pointer-events: none;
    user-select: none;
  }

  .reflex-container > .reflex-element > .reflex-size-aware {
    height: 100%;
    width: 100%;
  }

  .reflex-container > .reflex-splitter {
    background-color: #f5f7fb;
    z-index: 100;
  }

  .reflex-container > .reflex-splitter.active,
  .reflex-container > .reflex-splitter:hover {
    background-color: #f5f7fb;
    transition: all 1s ease;
  }

  .horizontal > .reflex-splitter {
    border-bottom: 1px solid #f5f7fb;
    border-top: 1px solid #f5f7fb;
    cursor: row-resize;
    width: 100%;
    height: 5px;
  }

  .reflex-element.horizontal .reflex-handle {
    cursor: row-resize;
    user-select: none;
  }

  .reflex-container.horizontal > .reflex-splitter:hover,
  .reflex-container.horizontal > .reflex-splitter.active {
    border-bottom: 1px solid #f5f7fb;
    border-top: 1px solid #f5f7fb;
  }

  .reflex-container.vertical > .reflex-splitter {
    border-right: 1px solid #f5f7fb;
    border-left: 1px solid #f5f7fb;
    cursor: col-resize;
    height: 100%;
    width: 5px;
  }

  .reflex-element.vertical .reflex-handle {
    cursor: col-resize;
    user-select: none;
  }

  .reflex-container.vertical > .reflex-splitter:hover,
  .reflex-container.vertical > .reflex-splitter.active {
    border-right: 1px solid #f5f7fb;
    border-left: 1px solid #f5f7fb;
  }

  .reflex-container > .reflex-splitter.reflex-thin {
    box-sizing: border-box;
    background-clip: padding-box;
    opacity: 0.2;
    z-index: 100;
  }

  .reflex-container > .reflex-splitter.reflex-thin.active
  .reflex-container > .reflex-splitter.reflex-thin:hover {
    transition: all 1.5s ease;
    opacity: 0.5;
  }

  .reflex-container.horizontal > .reflex-splitter.reflex-thin {
    border-bottom: 8px solid rgba(255, 255, 255, 0);
    border-top: 8px solid rgba(255, 255, 255, 0);
    height: 17px !important;
    cursor: row-resize;
    margin: -8px 0;
    width: 100%;
  }

  .reflex-container.horizontal > .reflex-splitter.reflex-thin.active,
  .reflex-container.horizontal > .reflex-splitter.reflex-thin:hover {
    border-bottom: 8px solid #e4e4e4;
    border-top: 8px solid #e4e4e4;
  }

  .reflex-container.vertical > .reflex-splitter.reflex-thin {
    border-right: 8px solid rgba(255, 255, 255, 0);
    border-left: 8px solid rgba(255, 255, 255, 0);
    width: 17px !important;
    cursor: col-resize;
    margin: 0 -8px;
    height: 100%;
  }

  .reflex-container.vertical > .reflex-splitter.reflex-thin.active,
  .reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
    border-right: 8px solid #e4e4e4;
    border-left: 8px solid #e4e4e4;
  }
`;
