import type { ValidationError } from "@gemini/common";
import { assert } from "@gemini/common";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { colors, fontSizes, space } from "~/theme";
import { useBranchContext } from "../context";

const messages: { [T in ValidationError["errorType"]]: string } = {
  SELECTION_REQUIRED: "Please select a further option",
  OPTION_WITHOUT_VALUE: "The selected option has no value",
  OPTION_WITHOUT_POINT_SCORE: "The selected option has no point score",
  /** This is not really possible in the UI, but we still need to handle it */
  INCOMPATIBLE_SELECTION: "The selected options are incompatible",
};

export function ValidationMessage({ scenarioId }: { scenarioId: string }) {
  const message = useValidationMessage(scenarioId);

  return (
    <AnimatePresence initial={false}>
      {message ? (
        <motion.div
          style={{ overflow: "hidden" }}
          exit={{ height: 0, opacity: 0 }}
        >
          <Message>{message}</Message>
        </motion.div>
      ) : undefined}
    </AnimatePresence>
  );
}

function useValidationMessage(scenarioId: string) {
  const { validationErrors = [] } = useBranchContext();
  const error = validationErrors.find((x) => x.scenarioId === scenarioId);

  assert(
    !(error && !messages[error.errorType]),
    `Cannot find message for validation error type ${error?.errorType}`
  );

  return error ? messages[error.errorType] : undefined;
}

const Message = styled.em`
  display: block;
  font-size: ${fontSizes.sm};
  color: ${colors.grayDark};
  margin-bottom: ${space.sm};
`;
