import type { ReactNode } from "react";
import { useEffect, useRef } from "react";
import { delegate } from "tippy.js";
import "tippy.js/animations/shift-toward-subtle.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";

export function SelectorObserverTooltip({
  selector,
  children,
}: {
  selector: string;
  children: ReactNode;
}) {
  const rootElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!rootElementRef.current) return;

    const instance = delegate(rootElementRef.current, {
      target: selector,
      theme: "light",
      placement: "top",
      animation: "shift-toward-subtle",
      content: (el) => el.getAttribute("title") || "",
    });

    return () => instance.destroy();
  }, [selector]);

  return <div ref={rootElementRef}>{children}</div>;
}
