import type {
  DeliverableMasterTexts,
  Polarity,
  Scenario,
} from "@gemini/common";
import {
  NEGATIVE_INTRO_HEADER_TEXT,
  NEGATIVE_RECOMMENDATION_HEADER_TEXT,
  POSITIVE_INTRO_HEADER_TEXT,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import { Comment } from "./comment";
import { OptionalTextEditor } from "./optional-text-editor";

export type SetFieldValueFunction = (
  polarity: Polarity,
  field: keyof DeliverableMasterTexts,
  value?: string
) => void;

export const MasterTextsEditorFields = observer(
  ({
    scenario,
    polarity,
    setFieldValue,
  }: {
    scenario: Scenario;
    polarity: Polarity;
    setFieldValue: SetFieldValueFunction;
  }) => {
    const { master_texts, has_children, logic } = scenario;

    if (!master_texts) {
      return null;
    }

    /**
     * We do not use master texts from parents with OR logic children. Existing
     * texts are shown so they can be removed, but they will not be used in the
     * master text output.
     */
    const hasChildrenWithOrLogic = has_children && logic === "or";

    const texts = master_texts[polarity];

    if (!texts) {
      return null;
    }

    const isPositive = polarity === "positive";

    const {
      custom_guideline_title,
      intro,
      recommendation,
      company_text_template,
      presenter_notes,
    } = texts;

    return (
      <>
        <OptionalTextEditor
          title="Custom Guideline Title"
          value={custom_guideline_title}
          setValue={(v) => setFieldValue(polarity, "custom_guideline_title", v)}
          showOnlyToRemove={has_children}
          noTopMargin
          smallHeader
        />

        <OptionalTextEditor
          title={`Intro / ${
            isPositive ? POSITIVE_INTRO_HEADER_TEXT : NEGATIVE_INTRO_HEADER_TEXT
          }`}
          value={intro}
          setValue={(v) => setFieldValue(polarity, "intro", v)}
          showOnlyToRemove={hasChildrenWithOrLogic}
          smallHeader
        />

        <OptionalTextEditor
          title="Company Text Template"
          value={company_text_template}
          setValue={(v) => setFieldValue(polarity, "company_text_template", v)}
          showOnlyToRemove={has_children}
          smallHeader
        />

        <OptionalTextEditor
          title={`Recommendation / ${
            isPositive ? "!! UNUSED" : NEGATIVE_RECOMMENDATION_HEADER_TEXT
          }`}
          value={recommendation}
          setValue={(v) => setFieldValue(polarity, "recommendation", v)}
          showOnlyToRemove={hasChildrenWithOrLogic || isPositive}
          smallHeader
        />

        {has_children && (
          <Comment>
            Deliverable texts can only be defined on scenarios that have no
            children.
          </Comment>
        )}

        <OptionalTextEditor
          title="Presenter Notes"
          value={presenter_notes}
          setValue={(v) => setFieldValue(polarity, "presenter_notes", v)}
          showOnlyToRemove={has_children}
          smallHeader
        />
      </>
    );
  }
);
