import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { Icon, Sidebar } from "~/components";
import { durations, space, timingFunctions } from "~/theme";

type GuidelineNavigationProps = {
  nextLink?: string;
  prevLink?: string;
  position: number;
  total: number;
};

export const GuidelineNavigation = observer(
  (props: GuidelineNavigationProps) => {
    const { nextLink, prevLink, position, total } = props;

    return (
      <Sidebar.Metric name="Guideline">
        <span
          css={{
            width: "100%",
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            {position + 1} / {total}
          </span>

          {prevLink && nextLink && (
            <span>
              <NavIconLink href={prevLink}>
                <Icon name="chevronLeft" />
              </NavIconLink>
              <NavIconLink href={nextLink}>
                <Icon name="chevronRight" />
              </NavIconLink>
            </span>
          )}
        </span>
      </Sidebar.Metric>
    );
  }
);

export const NavIconLink = styled(Link)`
  color: white;
  display: inline-block;
  width: 28px;
  height: 28px;
  border: 1px solid rgba(235, 236, 243, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: ${space.sm};
  transition-property: border;
  transition-duration: ${durations.sm};
  transition-timing-function: ${timingFunctions.out};

  &:hover {
    border-color: rgba(235, 236, 243, 1);
    transition-duration: ${durations.lg};
    transition-timing-function: ${timingFunctions.out};
  }

  & > * {
    width: 16px;
    height: 16px;
  }
`;
