import { get, isPlainObject, keys, mapValues, pickBy, set } from "lodash-es";
import { assert } from "./assert";

/**
 * Clean all keys which have undefined values.
 *
 * This function is handy because Firestore does not allow you to pass in an
 * object with undefined keys, and often times in code you create an data
 * structure to store with values which are possibly undefined.
 */

export function cleanObject<T>(obj: T): T {
  assert(
    obj && typeof obj === "object",
    `Type ${typeof obj} can not be used with cleanObject`
  );

  return mapValues(
    pickBy(obj as Record<string, unknown>, (x) => x !== undefined),
    (x) =>
      isPlainObject(x)
        ? cleanObject(x as unknown as Record<string, unknown>)
        : x
  ) as T;
}

/**
 * Flatten a plain objects nested structure to a flat object using field paths
 * as key string. The result can be used for firestore update.
 *
 * @param obj Input object
 * @param acc Accumulated output, only used for recursion
 * @param paths Paths stack, only used for recursion
 * @todo Write a simple test for this function
 */

export function flattenObject(
  obj: PlainObject,
  acc: PlainObject = {},
  paths: string[] = []
): PlainObject {
  return keys(obj).reduce((acc, key) => {
    paths.push(key);
    if (isPlainObject(obj[key])) {
      flattenObject(obj[key] as PlainObject, acc, paths);
    } else {
      acc[paths.join(".")] = obj[key];
    }
    paths.pop();
    return acc;
  }, acc);
}

/** There's possibly a built-in Typescript type for this */
type PlainObject = {
  [key: string]: number | boolean | string | PlainObject | null;
};

/**
 * Identical to lodash-es.pick, but with support for nested keys like `x.y.z`.
 *
 * @example
 *   pickDeep(obj, ["foo", "bar.baz"]); // => { foo, bar: { baz } }
 *
 * @param obj Input object
 * @param paths Array of paths to pick
 * @param mapValue Optional value mapper
 */
export function pickDeep<T>(
  obj: T,
  paths: string[],
  mapValue: (value: unknown) => unknown = (x) => x
) {
  return paths.reduce<Partial<T>>(
    (result, path) => set(result, path, mapValue(get(obj, path))),
    {}
  );
}
