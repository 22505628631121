import type { LinkProps } from "next/link";
import Link from "next/link";
import type { ComponentProps, ReactNode } from "react";
import styled, { css } from "styled-components";
import { colors, fontWeights, space } from "~/theme";

export const TabLayout = styled.div`
  border: 1px solid ${colors.gray};
`;

export const TabBar = styled.div`
  display: flex;

  & > * {
    flex: 1;
  }
`;

export const TabContent = styled.div`
  padding: ${space.lg};
`;

export const TabButton = styled(ButtonOrLink)<{ $isActive?: boolean }>`
  text-decoration: none;
  font-weight: ${fontWeights.semibold};
  display: block;
  text-align: center;
  margin: 0;
  padding: calc(${space.md} + 0.2rem) ${space.md};
  border: 0;
  position: relative;
  cursor: pointer;

  color: ${colors.slateGray};
  background: white;
  transition-property: color, background;
  transition-duration: 80ms;
  transition-timing-function: linear;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: ${colors.gray};
    will-change: height background;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: height 80ms ease-out;
  }

  & + & {
    border-left: 1px solid ${colors.gray};
  }

  ${(x) =>
    x.$isActive &&
    css`
      color: ${colors.indigo};
      background: rgba(245, 247, 251, 0.35);
      &::after {
        height: 4px;
        background: ${colors.indigo};
    `}
`;

function ButtonOrLink(
  props:
    | (LinkProps & { children: ReactNode })
    | (ComponentProps<"button"> & { children: ReactNode; href?: undefined })
) {
  const { href, ...rest } = props;
  if (href) {
    return <Link {...props} />;
  }

  return <button {...(rest as ComponentProps<"button">)} />;
}
