import type { GuidelineHit } from "./logic";

export const itemsJsOptions = {
  searchableFields: [],
  query: "",
  sortings: {
    impact_asc: { field: ["impactSort", "positionSort"], order: "asc" },
    impact_desc: { field: ["impactSort", "positionSort"], order: "desc" },
  },
  aggregations: {
    breadcrumb: { title: "Topic", conjunction: false, size: 999 },
    platform: { title: "Platform", conjunction: false },
    impact: { title: "Impact", conjunction: false },
    causeOfChange: { title: "Observation", conjunction: false },
    judgement: { title: "Judgement", conjunction: false },
    isLowCost: { title: "Is Low Cost", conjunction: true },
    isMissedOpportunity: { title: "Is Missed Opportunity", conjunction: true },
    includesNotes: { title: "Includes Notes", conjunction: true },
    isSuggestion: { title: "Is Suggestion", conjunction: true },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as Record<keyof GuidelineHit, any>,
};
