import { getErrorMessage } from "@gemini/common";
import * as Sentry from "@sentry/nextjs";
import { notification } from "antd";

type Notification = {
  description: React.ReactNode;
  message?: React.ReactNode;
  btn?: React.ReactNode;
  key?: string;
  onClose?: () => void;
  duration?: number | null;
  icon?: React.ReactNode;
  getContainer?: () => HTMLElement;
};

function createNotificationOptions(
  defaultMessage: string,
  x: Partial<Notification> | Error | string
) {
  const defaults = {
    message: defaultMessage,
  };

  if (typeof x === "string") {
    return {
      ...defaults,
      description: x,
    };
  }

  return {
    ...defaults,
    ...x,
  };
}

export function error(err: Error | string | unknown) {
  try {
    Sentry.captureException(err);
  } catch (err) {}

  notification.error({
    message: "Error",
    description: getErrorMessage(err),
  });

  if (err instanceof Error) {
    console.error(err.stack); // extra persist in console
  } else {
    console.error(err);
  }
}

export function warning(x: Notification | string) {
  notification.warning(createNotificationOptions("Warning", x));
}

export function info(x: Notification | string) {
  notification.info(createNotificationOptions("Info", x));
}

export function success(x: Notification | string) {
  notification.success(createNotificationOptions("Success", x));
}

export const notify = {
  error,
  warning,
  info,
  success,
};
