import { causeOfChangeLabels, parseCitationCode } from "@gemini/common";
import { observer } from "mobx-react-lite";
import {
  BoxWithBorder,
  Button,
  JudgementPill,
  Paragraph,
  SpacedChildren,
  Table,
  Text,
  Title,
} from "~/components";
import { plural } from "~/modules/string";
import { colors } from "~/theme";
import { WithCitationCodePrefix } from "../../components";
import { getAssessmentByReferenceMap } from "../../helpers";
import { useReviewStore } from "../../store";
import { useUpdateStore } from "../store";
import { StyledGuidelineTitle } from "./guideline-title";
import { UpdateComplete } from "./update-complete";

export const AssessTypeUpdates = observer(() => {
  const { review, guidelineObservations } = useReviewStore();
  const {
    assessTypeUpdates,
    entryGuidelineLink,
    assessTypeGuidelines,
    assessTypeAssessments,
  } = useUpdateStore();

  const assessCount = assessTypeUpdates.length;
  const title = (
    <Title level={3} as="h2">
      Re-assessments
    </Title>
  );

  if (assessCount === 0) {
    return (
      <BoxWithBorder>
        <SpacedChildren>
          {title}
          <UpdateComplete message="All re-assessments have been applied" />
        </SpacedChildren>
      </BoxWithBorder>
    );
  }

  const assessmentByReference = getAssessmentByReferenceMap(
    assessTypeAssessments
  );

  const sortedGuidelines = assessTypeGuidelines.sort(
    (a, b) =>
      parseCitationCode(a.data.citation_code)[0] -
      parseCitationCode(b.data.citation_code)[0]
  );

  return (
    <BoxWithBorder>
      <SpacedChildren>
        {title}
        <Paragraph>
          There {assessCount === 1 ? "is" : "are"}{" "}
          <strong>{assessCount}</strong> {plural("guideline", assessCount)}{" "}
          where the selected scenarios have changed substantially, so you will
          need to reassess these guidelines to upgrade them. (Note that it is
          not required to update guidelines for the scorecards to work, but it
          does improve accuracy.)
        </Paragraph>

        {entryGuidelineLink && (
          <Button href={entryGuidelineLink} variant="secondary">
            Start Reviewing
          </Button>
        )}

        <Table
          getKey={(x) => x.id}
          data={sortedGuidelines}
          columns={[
            {
              name: "Guideline",
              size: "50%",
              render(guideline) {
                const title = guideline.data.scenarios.__root.title;

                return (
                  <WithCitationCodePrefix
                    citationCode={guideline.data.citation_code}
                  >
                    <StyledGuidelineTitle
                      href={`/reviews/${review.id}/updates/assessments/${guideline.data.reference}/assess`}
                    >
                      {title}
                    </StyledGuidelineTitle>
                  </WithCitationCodePrefix>
                );
              },
            },
            {
              name: "Implementation",
              size: "50%",
              render(guideline) {
                const assessment =
                  assessmentByReference[guideline.data.reference];
                const implementation = assessment?.selected_scenario_ids
                  ?.map((id) => guideline.data.scenarios[id]?.title)
                  .join(" • ");

                return <Text size="small">{implementation}</Text>;
              },
            },

            /**
             * Only show the Observation column if the guideline has a
             * comparison document attached.
             */
            review.data?.review_comparison_id
              ? {
                  name: "Observation",
                  render(guideline) {
                    const observation = guidelineObservations.documents.find(
                      (x) => x.id === guideline.data.reference
                    );

                    if (!observation) {
                      return (
                        <Text size="small" cx={{ color: colors.slateGray }}>
                          —
                        </Text>
                      );
                    }

                    return (
                      <Text size="small">
                        {causeOfChangeLabels[observation.data.cause_of_change]}
                      </Text>
                    );
                  },
                }
              : undefined,
            {
              name: "Judgement",
              render(guideline) {
                const assessment =
                  assessmentByReference[guideline.data.reference];
                return (
                  <JudgementPill
                    value={assessment?.judgement}
                    isIssueResolved={assessment?.is_issue_resolved}
                  />
                );
              },
            },
          ]}
        />
      </SpacedChildren>
    </BoxWithBorder>
  );
});
