import type { Except } from "type-fest";
import type { Scenario } from "~/schemas";

/**
 * These constants determine what will be seen as a textual change and what not.
 *
 * We need to explicitly define both textual and none-textual fields, because
 * the keys in changesets contain besides these strings also the ids and
 * positions of scenarios
 */

export const versionedTextFields = [
  "title",
  "description",
  "instruction_text",
  "conditional_instruction_text",
  "instruction_video_url",
] as const;

export const nonVersionedTextFields = [
  "master_text",
  "master_texts",
  "id",
] as const;

export type VersionedScenarioTextField = (typeof versionedTextFields)[number];

export type ScenarioTextField =
  | VersionedScenarioTextField
  | (typeof nonVersionedTextFields)[number];

export type ScenarioNonTextField = keyof Except<Scenario, ScenarioTextField>;

export const versionedNonTextFields: ScenarioNonTextField[] = [
  "logic_and_mode",
  "logic",
  "parent_id",
  "promoted_solution_scenario_ids",
  "rule",
  "translation_value",
  "point_score",
  "points_mapping",
  "use_master_text_if_unchecked",
];
