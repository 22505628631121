import { parseCitationCode } from "@gemini/common";
import React from "react";
import styled from "styled-components";
import { GuidelineLink, Title } from "~/components";
import { useAuth } from "~/modules/auth";
import { mediaQueries, space } from "~/theme";

type AssessmentHeaderProps = {
  title: React.ReactNode;
  citationCode: string;
  sqlId?: string;
};

export function AssessmentHeader({
  title,
  citationCode,
  sqlId,
}: AssessmentHeaderProps) {
  const { claims } = useAuth();

  const [universalCode, platform] = parseCitationCode(citationCode);

  return (
    <StyledAssessmentHeader>
      <Title level={4} as="h2" noMargin>
        {claims.isAdmin ? (
          <>
            <GuidelineLink sqlId={sqlId}>{citationCode}</GuidelineLink>: {title}
          </>
        ) : (
          <>
            {title} (
            <GuidelineLink
              sqlId={sqlId}
            >{`#${universalCode} ${platform}`}</GuidelineLink>
            )
          </>
        )}
      </Title>
    </StyledAssessmentHeader>
  );
}

const StyledAssessmentHeader = styled.div`
  box-shadow: 0 2px 8px 0 rgba(11, 35, 60, 0.15);

  padding: ${space.lg} ${space.md};

  @media ${mediaQueries.md} {
    padding: ${space.lg};
  }
`;
