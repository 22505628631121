import type {
  Dictionary,
  ReviewStructureFmtAdmin,
  ReviewStructureGroup,
  ReviewStructurePart,
} from "~/schemas";
import { assert } from "~/utils";
import { derivedSectionId } from "./from-admin-format";
import {
  isReviewStructureGroupFmtAdmin,
  isReviewStructurePartFmtAdmin,
} from "./identity";

export function convertSectionsFromAdminFormat(
  referenceByCitationCode: Dictionary<string>,
  sections: ReviewStructureFmtAdmin["sections"],
  state: {
    sequentialPositionCounter: number;
    unresolvedCitationCodes: string[];
  },
  parentGroupId = "__root"
): (ReviewStructureGroup | ReviewStructurePart)[] {
  return sections.flatMap((section, index) => {
    const sectionId = derivedSectionId(section);

    if (isReviewStructureGroupFmtAdmin(section)) {
      assert(
        section.sections.length > 0,
        `A group should contain at least one section, but "${section.title}" has none`
      );
    }

    return isReviewStructurePartFmtAdmin(section)
      ? [
          {
            id: sectionId,
            parent_group_id: parentGroupId,
            title: section.title,
            semantic_level: section.semantic_level,
            sentence_reference_key: section.sentence_reference_key,
            position: index,
            sequential_position: state.sequentialPositionCounter++,
            guideline_references: section.guideline_citation_codes.map((x) => {
              const reference = referenceByCitationCode[x];

              if (!reference) {
                state.unresolvedCitationCodes.push(x);
              }

              /**
               * It could be that the citation code was already replaced with
               * __no_reference_for and then re-submitted again, so in that case
               * we do not want to append the string again.
               */
              return reference
                ? reference
                : x.startsWith("__no_reference_for_")
                  ? x
                  : `__no_reference_for_${x}`;
            }),
          },
        ]
      : [
          {
            id: sectionId,
            parent_group_id: parentGroupId,
            title: section.title,
            semantic_level: section.semantic_level,
            sentence_reference_key: section.sentence_reference_key,
            position: index,
          },
          ...convertSectionsFromAdminFormat(
            referenceByCitationCode,
            section.sections,
            state,
            sectionId
          ),
        ];
  });
}
