import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import { colors, mediaQueries, preset, space } from "~/theme";
import { Icon } from "../icon";
import { SpacedChildren } from "../spaced-children";
import { Title } from "../typography";
import { SidebarAnchor, SidebarAnchorButton } from "./anchor";
import { Metric } from "./metric";
import { Part } from "./part";
import { Separator } from "./separator";
import { TextPart } from "./text-part";

export type SidebarProps = {
  breadcrumbs?: Array<{ name: string; href?: string }>;
  variant: "primary" | "secondary";
  children?: React.ReactNode;
};

Sidebar.Part = Part;
Sidebar.TextPart = TextPart;
Sidebar.Metric = Metric;
Sidebar.Separator = Separator;
Sidebar.Anchor = SidebarAnchor;
Sidebar.AnchorButton = SidebarAnchorButton;

export function Sidebar({ breadcrumbs = [], variant, children }: SidebarProps) {
  const [title, ...breadcrumbsWithoutTitle] = [...breadcrumbs].reverse();
  breadcrumbsWithoutTitle.reverse();

  return (
    <StyledSidebar variant={variant}>
      <SpacedChildren>
        {breadcrumbsWithoutTitle.length > 0 && (
          <div
            css={css`
              ${preset.typography.body2};
              display: flex;
              align-items: baseline;
              color: ${colors.ghostGray};
              padding-bottom: ${space.sm};

              & > a {
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
              }

              & > svg {
                opacity: 0.5;
                position: relative;
                top: 1px;
              }
            `}
          >
            {breadcrumbsWithoutTitle.map((x, i) => (
              <React.Fragment key={x.href}>
                {x.href ? <Link href={x.href}>{x.name}</Link> : x.name}
                {i < breadcrumbsWithoutTitle.length - 1 && (
                  <div>
                    <Icon
                      size={12}
                      name="chevronRight"
                      cx={{ margin: "0 2px", transform: "translateY(2px)" }}
                    />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}

        {title && (
          <Title level={3} as="h2">
            {title.href ? (
              <Link
                href={title.href}
                css={{
                  textDecoration: "none",
                  ":hover": { textDecoration: "underline" },
                }}
              >
                {title.name}
              </Link>
            ) : (
              title.name
            )}
          </Title>
        )}

        {children}
      </SpacedChildren>
    </StyledSidebar>
  );
}

const variants: Record<SidebarProps["variant"], string> = {
  primary: "#09294a",
  secondary: "#01424B",
};

const StyledSidebar = styled.div<{ variant: "primary" | "secondary" }>`
  background-color: ${(x) => variants[x.variant]};
  color: white;

  padding: ${space.md};

  @media ${mediaQueries.md} {
    padding: ${space.lg};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
`;
