import React from "react";

export function useKeyIsPressed(key: string | string[]) {
  const keys = Array.isArray(key) ? key : [key];
  const keysStr = JSON.stringify(keys);

  const [keyPressedMap, setKeyPressedMap] = React.useState<
    Record<string, boolean>
  >(
    keys.reduce(
      (map, key) => {
        map[key] = false;
        return map;
      },
      {} as Record<string, boolean>
    )
  );

  React.useEffect(() => {
    const keys = JSON.parse(keysStr);
    const shift = "shift";

    if (!keys.includes(shift)) return;

    /**
     * Taking a screenshot on MacOS with command+shift+4 won't fire a shift
     * keyup event. Therefore we'll listen to mousemove events which include a
     * `shiftKey` property in order to overwrite the shift status.
     */

    const updateShiftKey = (e: MouseEvent | KeyboardEvent) => {
      setKeyPressedMap((map) => {
        if (e.shiftKey !== map[shift]) {
          return { ...map, shift: e.shiftKey };
        }
        return map;
      });
    };

    window.addEventListener("mousemove", updateShiftKey);
    window.addEventListener("keydown", updateShiftKey);

    return () => {
      window.removeEventListener("mousemove", updateShiftKey);
      window.removeEventListener("keydown", updateShiftKey);
    };
  }, [keysStr]);

  React.useEffect(() => {
    const handleKeydown = (evt: KeyboardEvent) => {
      const key = evt.key.toLowerCase();
      setKeyPressedMap((map) => {
        if (key in map) {
          evt.preventDefault();
          return { ...map, [key]: true };
        }
        return map;
      });
    };

    const handleKeyup = (e: KeyboardEvent) => {
      const key = e.key.toLowerCase();
      setKeyPressedMap((map) => (key in map ? { ...map, [key]: false } : map));
    };

    document.addEventListener("keydown", handleKeydown, false);
    document.addEventListener("keyup", handleKeyup, false);

    return () => {
      document.removeEventListener("keydown", handleKeydown, false);
      document.removeEventListener("keyup", handleKeyup, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, keys);

  return Object.values(keyPressedMap).filter((x) => x === false).length === 0;
}
