import React from "react";
import { css } from "styled-components";
import { isDefined } from "ts-is-present";
import { AdaptiveCarouselContextProvider, MarkdownText } from "~/components";
import { fontSizes, space } from "~/theme";

export function MarkdownWrapper({
  noMargin,
  sources,
  fontSize = fontSizes.md,
}: {
  noMargin?: boolean;
  sources: Array<string | undefined>;
  fontSize?: string;
}) {
  const filteredSources = sources.filter(isDefined);

  if (filteredSources.length === 0) return null;

  return (
    <AdaptiveCarouselContextProvider>
      <div
        css={css`
          font-size: ${fontSize};
          margin-bottom: ${noMargin ? undefined : space.md};
          &:last-child {
            margin-bottom: 0;
          }
        `}
      >
        {filteredSources.map((text, index) => (
          <MarkdownText key={index}>{text}</MarkdownText>
        ))}
      </div>
    </AdaptiveCarouselContextProvider>
  );
}
