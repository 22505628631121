export function sendEvent(category: string, action: string, label?: string) {
  try {
    /**
     * For now we'll send all originally GA events to PostHog as well, but we'll
     * add a prefix to the event name so we can distinguish them.
     */
    window.posthog?.capture(action, {
      gaCategory: category,
      gaLabel: label,
    });
  } catch (err) {}
}
