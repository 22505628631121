import React from "react";

export function useMediaQuery(str: string): boolean {
  const [matches, setMatches] = React.useState(() => {
    try {
      return window.matchMedia(str).matches;
    } catch (err) {
      return false;
    }
  });

  React.useEffect(() => {
    const mql = window.matchMedia(str);
    const handleChange = () => setMatches(mql.matches);

    handleChange();
    mql.addListener(handleChange);
    return () => mql.removeListener(handleChange);
  }, [str]);

  return matches;
}
