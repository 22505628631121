import { Typography } from "antd";

/**
 * Top title for each panel. Always omits top margin. We could have merged the
 * functionality with subject title, but it's easier to keep them separate I
 * think.
 */
export function PanelTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography.Title level={3} style={{ marginTop: 0 }}>
      {children}
    </Typography.Title>
  );
}
