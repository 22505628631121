import { assert } from "@gemini/common";
import { useRouter } from "next/router";
import { LoadingIndicator, PageContainer } from "~/components";
import {
  getAssessmentByReferenceMap,
  useReviewStore,
} from "~/features/reviews";
import { usePartStore } from "~/features/reviews/part";
import { notify } from "~/modules/notifications";
import { AssessmentContent, AssessmentHeader } from "./components";
import { AssessmentSidebar } from "./sidebar";

export function AssessmentRedirectPage() {
  const { review, assessments } = useReviewStore();
  const partStore = usePartStore();
  const router = useRouter();
  const reviewId = router.query.reviewId as string;
  const partId = router.query.partId as string;
  const reference = router.query.reference as string;

  const { is_read_only } = review.data ?? {};
  const { guideline } = partStore;

  assert(guideline, "Missing guideline data");

  const { citation_code, sql_id, scenarios } = guideline.data;

  const rootScenario = scenarios.__root;
  const assessmentByReference = getAssessmentByReferenceMap(
    assessments.documents
  );

  const hasAssessment = !!assessmentByReference[reference];
  const page = hasAssessment ? "summary" : "assess";

  const url =
    is_read_only && !hasAssessment
      ? `/reviews/${reviewId}/parts/${partId}`
      : `/reviews/${reviewId}/parts/${partId}/assessments/${reference}/${page}`;

  router.replace(url).catch((err) => notify.error(err));

  return (
    <PageContainer
      testingName="assessment-redirect-page"
      aside={<AssessmentSidebar />}
    >
      {
        /**
         * A read only review will redirect to the part overview page when the
         * assessment does not exist. The part overview page does not render an
         * AssessmentHeader, which means that rendering it here will result in a
         * flickering UI. Therefore we will not render it when the review is
         * read-only.
         */
        !review.data?.is_read_only && (
          <AssessmentHeader
            title={rootScenario.title}
            citationCode={citation_code}
            sqlId={sql_id}
          />
        )
      }
      <AssessmentContent>
        <LoadingIndicator />
      </AssessmentContent>
    </PageContainer>
  );
}
