import { mapValues, isEmpty } from "remeda";
import { isReviewStructurePart } from "~/structures/identity";
import type { ReviewStructure } from "~/schemas";

export function replaceReference(
  structure: ReviewStructure,
  fromReference: string,
  toReferences: string[]
): { newSections: ReviewStructure["sections"]; hasSmallSections: boolean } {
  let hasSmallSections = false;

  const sections = mapValues(structure.sections, (x) => {
    if (
      isReviewStructurePart(x) &&
      x.guideline_references.includes(fromReference)
    ) {
      const index = x.guideline_references.indexOf(fromReference);

      const newReferences = structuredClone(x.guideline_references);

      /** Splice mutates in-place */
      newReferences.splice(index, 1, ...toReferences);

      console.log(
        `Replaced ${fromReference} with ${
          isEmpty(toReferences) ? "(nothing)" : toReferences.join(", ")
        }`
      );

      const referenceCount = newReferences.length;

      if (referenceCount <= 3) {
        hasSmallSections = true;
      }

      return {
        ...x,
        guideline_references: newReferences,
      };
    } else {
      return x;
    }
  });

  return { newSections: sections, hasSmallSections };
}
