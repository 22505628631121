import React from "react";
import type { IconName } from "~/components";
import { Icon } from "~/components";
import { space } from "~/theme";

export function IconLabel({
  label,
  icon,
  iconSize = 16,
  gap = space.sm,
}: {
  label: string;
  icon: IconName;
  iconSize?: number | string;
  gap?: number | string;
}) {
  return (
    <span css={{ display: "inline-flex", alignItems: "center", gap }}>
      <span>{label}</span>
      <Icon name={icon} size={iconSize} />
    </span>
  );
}
