import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { PageLoader } from "~/components";
import { useAuth } from "../modules/auth";
import { AppStore } from "./store";

const storeContext = React.createContext<AppStore | null>(null);

export function AppStoreProvider({ children }: { children: React.ReactNode }) {
  const { claims } = useAuth();

  const store = useMemo(
    () => new AppStore(claims.accountId),
    [claims.accountId]
  );

  return (
    <storeContext.Provider value={store}>
      <AppLoader>{children}</AppLoader>
    </storeContext.Provider>
  );
}

export function useAppStore() {
  const store = React.useContext(storeContext);
  assert(store, "Missing StoreProvider in component tree");

  return store;
}

/**
 * The loader initializes the store based on the route if necessary, and waits
 * for the data to load before children rendered.
 */
const AppLoader = observer(({ children }: { children: React.ReactNode }) => {
  const store = useAppStore();

  if (store.isLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
});
