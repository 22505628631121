import { assert } from "@gemini/common";
import type { MouseEvent } from "react";
import React from "react";
import type { CSSProp } from "styled-components";
import styled, { css } from "styled-components";
import { isDefined } from "ts-is-present";
import { colors, fontSizes, fontWeights, lineHeights, space } from "~/theme";

type TableColumn<T> = {
  render: (data: T, index: number) => React.ReactNode;
  size?: string;
  align?: "left" | "right";
  verticalAlign?: "top" | "middle" | "bottom";
  key?: string;

  /**
   * When React maps over the columns it needs to set a unique `key`. By default
   * it will use the column's optional `key` field. If there's no `key` field it
   * will use the `name`-field. If the `name`-field contains a ReactNode,
   * TypeScript will make sure that `key` is a required field.
   */
} & ({ key?: never; name: string } | { key: string; name: React.ReactNode });

type TableProps<T> = {
  getKey: (data: T, index: number) => string;
  data: T[];
  columns: (TableColumn<T> | undefined)[];
  cxRow?: CSSProp;
  onRowClick?: (data: T, evt: MouseEvent) => void;
};

export function Table<T>({
  getKey,
  data,
  columns,
  cxRow,
  onRowClick,
}: TableProps<T>) {
  const filteredColumns = columns.filter(isDefined);
  return (
    <StyledTable>
      <thead>
        <tr>
          {filteredColumns.map((column) => (
            <TableHeadCol
              key={getColumnKey(column)}
              size={column.size}
              align={column.align}
            >
              {column.name}
            </TableHeadCol>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => (
          <TableRow css={cxRow} key={getKey(item, index)}>
            {filteredColumns.map((column) => (
              <TableCol
                key={getColumnKey(column)}
                align={column.align}
                verticalAlign={column.verticalAlign}
                onClick={(evt) =>
                  onRowClick ? onRowClick(item, evt) : undefined
                }
              >
                {column.render(item, index)}
              </TableCol>
            ))}
          </TableRow>
        ))}
      </tbody>
    </StyledTable>
  );
}

function getColumnKey<T>(column: TableColumn<T>) {
  if ("key" in column && column.key !== undefined) {
    return column.key;
  }
  assert(
    typeof column.name === "string",
    "Columns should have a `key`-field if the `name` field is not of type string"
  );

  return column.name;
}

export const StyledTable = styled.table`
  width: 100%;

  & a {
    color: ${colors.charcoal};
  }
`;

export const tableHeadStyle = css<{
  align?: "left" | "right";
  size?: string;
}>`
  font-size: ${fontSizes.xs};
  line-height: ${lineHeights.xl};
  font-weight: ${fontWeights.semibold};
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${colors.slateGray};
  white-space: nowrap;
  text-align: ${({ align = "left" }) => align};
  width: ${(x) => x.size};
  vertical-align: middle;
`;

export const TableHeadCol = styled.th<{
  size?: string;
  align?: "left" | "right";
}>`
  ${tableHeadStyle};
  border-bottom: 1px solid ${colors.ghostGray};
  padding: ${space.sm};

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const TableCol = styled.td<{
  align?: "left" | "right";
  verticalAlign?: "top" | "middle" | "bottom";
}>`
  text-align: ${({ align = "left" }) => align};
  vertical-align: ${({ verticalAlign = "top" }) => verticalAlign};
  padding: ${space.md} ${space.sm};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  ${(x) => x.css};
`;

export const TableRow = styled.tr`
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.athensGray};
  }

  &:last-child ${TableCol} {
    padding-bottom: 0;
  }
`;
