import type { DerivedReview, Review } from "@gemini/common";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { first } from "lodash-es";
import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import {
  BoxWithBorder,
  Button,
  LoadingIndicator,
  Modal,
  PageContainer,
  Paragraph,
  SpacedChildren,
  Title,
  VimeoPlayer,
} from "~/components";
import { Spacer } from "~/components/spacer";
import { baymard } from "~/config";
import { useAuth, useUserId } from "~/modules/auth";
import { db } from "~/modules/firebase";
import type { Document } from "~/modules/firestore";
import { useTypedCollection } from "~/modules/firestore";
import { useBreakpoints } from "~/modules/hooks";
import { colors, fontWeights, mediaQueries, space } from "~/theme";
import { NextReviewAction, ReviewList } from "./components";

const INITIAL_LIMIT = 10;

export const ReviewsListPage: NextPage = observer(() => {
  const { claims } = useAuth();
  const userId = useUserId();

  const [accountSelfReviewsLimit, setAccountReviewsLimit] =
    React.useState(INITIAL_LIMIT);
  const [accountAuditsLimit, setAccountAuditsLimit] =
    React.useState(INITIAL_LIMIT);
  const [accountTrainingsLimit, setAccountTrainingsLimit] =
    React.useState(INITIAL_LIMIT);
  const [caseStudiesLimit, setCaseStudiesLimit] = React.useState(INITIAL_LIMIT);
  const [ownReviewsLimit, setOwnReviewsLimit] = React.useState(INITIAL_LIMIT);

  const [accountSelfReviews, isLoadingAccountSelfReviews] =
    useTypedCollection<Review>(
      query(
        collection(db, `__derived_account/${claims.accountId}/reviews`),
        where("type", "==", "self-review"),
        limit(accountSelfReviewsLimit),
        orderBy("modified_at", "desc")
      )
    );

  const [accountAudits, isLoadingAccountAudits] = useTypedCollection<Review>(
    query(
      collection(db, `__derived_account/${claims.accountId}/reviews`),
      where("type", "==", "audit"),
      limit(accountAuditsLimit),
      orderBy("modified_at", "desc")
    )
  );

  const [accountTrainings, isLoadingAccountTrainings] =
    useTypedCollection<Review>(
      query(
        collection(db, `__derived_account/${claims.accountId}/reviews`),
        where("type", "==", "training"),
        limit(accountTrainingsLimit),
        orderBy("modified_at", "desc")
      )
    );

  const [caseStudies, isLoadingCaseStudies] = useTypedCollection<Review>(
    query(
      collection(db, `__derived_account/${claims.accountId}/reviews`),
      where("type", "==", "case-study"),
      limit(caseStudiesLimit),
      orderBy("modified_at", "desc")
    )
  );

  const [ownReviews, isLoadingOwnReviews] = useTypedCollection<Review>(
    query(
      collection(db, `__derived_account/${claims.accountId}/reviews`),
      where("created_by", "==", userId),
      limit(ownReviewsLimit),
      orderBy("modified_at", "desc")
    )
  );

  return (
    <PageContainer>
      <Introduction review={first(ownReviews)} />

      <SpacedChildren size="lg">
        <ReviewSection
          title="All Reviews Created by You"
          loadAllLabel="reviews"
          reviews={ownReviews}
          description={
            ownReviews.length ? (
              <span>
                They are accessible and editable by anyone at{" "}
                {claims.accountName}.
              </span>
            ) : (
              <NoReviewsYet />
            )
          }
          hasMore={
            ownReviews.length === INITIAL_LIMIT &&
            ownReviewsLimit === INITIAL_LIMIT
          }
          onLoadMore={() => setOwnReviewsLimit(Infinity)}
          isLoading={isLoadingOwnReviews}
        />

        <ReviewSection
          title={`All Site Reviews at ${claims.accountName}`}
          loadAllLabel="reviews"
          reviews={accountSelfReviews}
          showUsernames
          description={
            <span>
              All site reviews on the {claims.accountName} account (i.e. the
              above reviews are repeated here). You can view and edit all these
              reviews.
            </span>
          }
          hasMore={
            accountSelfReviews.length === INITIAL_LIMIT &&
            accountSelfReviewsLimit === INITIAL_LIMIT
          }
          onLoadMore={() => setAccountReviewsLimit(Infinity)}
          isLoading={isLoadingAccountSelfReviews}
        />

        <ReviewSection
          gray
          title="Audits by Baymard"
          loadAllLabel="audits"
          reviews={accountAudits}
          description={
            <span>
              Get Baymard&rsquo;s team of UX researchers to{" "}
              <a
                href={`${baymard.baseUrl}/premium/audits`}
                css={css`
                  font-weight: ${fontWeights.semibold};
                  color: ${colors.indigo};
                `}
              >
                conduct a UX audit
              </a>{" "}
              of your site, reviewing more than 500 guidelines.
            </span>
          }
          hasMore={
            accountAudits.length === INITIAL_LIMIT &&
            accountAuditsLimit === INITIAL_LIMIT
          }
          onLoadMore={() => setAccountAuditsLimit(Infinity)}
          isLoading={isLoadingAccountAudits}
        />

        <ReviewSection
          title={`UX Training Reviews at ${claims.accountName}`}
          loadAllLabel="audits"
          reviews={accountTrainings}
          description={
            <span>
              All users who use Baymard's{" "}
              <a href="https://baymard.com/premium/training">
                Training & Certification program
              </a>{" "}
              are creating a review scorecard as they are completing their
              training. Below you will find all {claims.accountName} employees
              scorecards created through the UX training program. Do not edit
              them if they are not your own.
            </span>
          }
          hasMore={
            accountTrainings.length === INITIAL_LIMIT &&
            accountTrainingsLimit === INITIAL_LIMIT
          }
          onLoadMore={() => setAccountTrainingsLimit(Infinity)}
          isLoading={isLoadingAccountTrainings}
        />

        {claims.isAdmin && (
          <ReviewSection
            gray
            title="Case-Studies by Baymard"
            loadAllLabel="case-studies"
            reviews={caseStudies}
            description={
              <span>The case-studies feature is still under development</span>
            }
            hasMore={
              caseStudies.length === INITIAL_LIMIT &&
              caseStudiesLimit === INITIAL_LIMIT
            }
            onLoadMore={() => setCaseStudiesLimit(Infinity)}
            isLoading={isLoadingCaseStudies}
          />
        )}
      </SpacedChildren>
    </PageContainer>
  );
});

function NoReviewsYet() {
  return (
    <React.Fragment>
      You haven't created a review yet.{" "}
      <Link
        href="/reviews/create"
        css={css`
          font-weight: ${fontWeights.semibold};
          color: ${colors.slateGray};
        `}
      >
        Create a review
      </Link>
    </React.Fragment>
  );
}

function Introduction({ review }: { review?: Document<DerivedReview> }) {
  const [showVideo, setShowVideo] = React.useState(false);
  const breakpoints = useBreakpoints();

  return (
    <div
      css={css`
        margin-bottom: ${space.lg};

        @media ${mediaQueries.md} {
          margin-bottom: ${space.xl};
        }
      `}
    >
      <Title noMargin level={1}>
        Review Tool
      </Title>

      <Spacer size="sm" />

      <Paragraph size="large">
        With the review tool you can self-audit your site or any website across
        all 700+ guidelines in Baymard Premium. By default each review is
        organized into 7 broad themes, each containing a list of related topics.
        Each topic contains a list of guidelines to work through. Completing the
        full review will give you a scorecard similar to the Case Study
        benchmark scorecards. Read more about the{" "}
        <a
          css={{ fontWeight: fontWeights.semibold }}
          href={`${baymard.baseUrl}/research/methodology`}
        >
          UX scoring methodology
        </a>
        .
      </Paragraph>

      <Spacer size="lg" />

      <ActionContainer>
        <ButtonContainer>
          <Button href="/reviews/create" testingName="create-review-cta">
            Create a new review
          </Button>

          <Button variant="secondary" onClick={() => setShowVideo(true)}>
            Introductory Video
          </Button>

          <Modal
            isOpen={showVideo}
            onClose={() => setShowVideo(false)}
            width={800}
          >
            <div css={{ background: "black" }}>
              <VimeoPlayer
                src="https://player.vimeo.com/video/421107060"
                autoPlay
                ratio={800 / 450}
              />
            </div>
          </Modal>
        </ButtonContainer>

        {breakpoints.md && review && (
          <NextReviewAction review={review} showReviewTitle ignoreUpdates />
        )}
      </ActionContainer>
    </div>
  );
}

type ReviewSectionProps = {
  reviews: Document<Review | DerivedReview>[];
  title: React.ReactNode;
  description: React.ReactNode;
  loadAllLabel: string;
  gray?: boolean;
  showUsernames?: boolean;
  hasMore?: boolean;
  onLoadMore: () => void;
  isLoading: boolean;
};

function ReviewSection({
  title,
  description,
  gray,
  reviews,
  showUsernames,
  loadAllLabel,
  hasMore,
  onLoadMore,
  isLoading,
}: ReviewSectionProps) {
  return (
    <BoxWithBorder gray={gray}>
      <Title level={3} as="h2">
        {title}
      </Title>
      <div css={{ marginBottom: reviews.length ? space.md : 0 }}>
        <Paragraph noMargin>{description}</Paragraph>
      </div>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <SpacedChildren size="lg">
          <div css={{ overflowX: "auto" }}>
            {reviews.length > 0 && (
              <ReviewList reviews={reviews} showUsernames={showUsernames} />
            )}
          </div>

          {hasMore && (
            <div css={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={onLoadMore} size="small" variant="tertiary">
                Load all {loadAllLabel}
              </Button>
            </div>
          )}
        </SpacedChildren>
      )}
    </BoxWithBorder>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: baseline;

  & > *:not(:last-child) {
    margin-right: ${space.md};
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
