import { isReviewStructurePart } from "~/structures";
import type { ReviewStructure } from "~/schemas";

export function somePartsContainReference(
  structure: ReviewStructure,
  reference: string
) {
  return Object.values(structure.sections)
    .filter(isReviewStructurePart)
    .some((s) => s.guideline_references.includes(reference));
}
