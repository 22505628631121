import {
  judgementLabels,
  judgementShortcodes,
  type JudgementValue,
} from "@gemini/common";
import styled, { css } from "styled-components";
import { colors, fontSizes, fontWeights, radii } from "~/theme";

type JudgementPillProps = {
  value: JudgementValue | undefined;
  /**
   * @todo Store judgement values as shortcode in the database, then we do not
   *   need to make this distinction everywhere with IR/AH. See
   *   https://app.shortcut.com/baymard/story/2316/store-judgements-as-shortcodes
   */
  isIssueResolved?: boolean;

  /** Renders an abbreviated label such as "VH" instead of "Violated High". */
  isAbbreviated?: boolean;
};

const pillStyle = css`
  display: inline-block;
  border-radius: ${radii.sm};
  white-space: nowrap;
  text-transform: uppercase;
  font-size: ${fontSizes.xxxs};
  line-height: 1.6;
  font-weight: ${fontWeights.semibold};
  border: 1px solid;
  width: 94px;
  text-align: center;
`;

const pillStyleAbbreviated = css`
  ${pillStyle}
  width: auto;
  padding: 0 4px;
`;

export function JudgementPill({
  value,
  isIssueResolved,
  isAbbreviated,
}: JudgementPillProps) {
  return value === undefined ? (
    <NotYetRatedPill isAbbreviated={isAbbreviated} />
  ) : (
    <StyledJudgementPill
      value={value}
      isIssueResolved={isIssueResolved ?? false}
      isAbbreviated={isAbbreviated ?? false}
    >
      {isIssueResolved
        ? isAbbreviated
          ? "IR"
          : "Issue Resolved"
        : isAbbreviated
          ? judgementShortcodes[value]
          : judgementLabels[value]}
    </StyledJudgementPill>
  );
}

const StyledJudgementPill = styled.span<{
  value: JudgementValue;
  isIssueResolved: boolean;
  isAbbreviated: boolean;
}>`
  ${(x) => (x.isAbbreviated ? pillStyleAbbreviated : pillStyle)};
  color: white;
  border-color: ${(x) =>
    x.isIssueResolved
      ? colors.translationValue["ir"]
      : colors.judgement[x.value]};
  background-color: ${(x) =>
    x.isIssueResolved
      ? colors.translationValue["ir"]
      : colors.judgement[x.value]};
`;

export function NotYetRatedPill({
  highContrast,
  isAbbreviated,
}: {
  highContrast?: boolean;
  isAbbreviated?: boolean;
}) {
  return (
    <StyledNotYetRatedPill
      highContrast={highContrast}
      isAbbreviated={isAbbreviated}
    >
      {isAbbreviated ? <>&mdash;</> : "Not yet rated"}
    </StyledNotYetRatedPill>
  );
}

const StyledNotYetRatedPill = styled.span<{
  highContrast?: boolean;
  isAbbreviated?: boolean;
}>`
  ${(x) => (x.isAbbreviated ? pillStyleAbbreviated : pillStyle)};
  ${(x) =>
    x.highContrast
      ? {
          color: colors.slateGray,
          borderColor: colors.ghostGray,
        }
      : {
          color: colors.grayMedium,
          borderColor: colors.athensGray,
        }}
`;
