import { assert, assertIsDefined } from "@gemini/common";
import { useRouter } from "next/router";
import type { ReactNode } from "react";
import React, { useMemo } from "react";
import { EditStore } from "./store";

const EditStoreContext = React.createContext<EditStore | null>(null);

export const EditStoreProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const reference = router.query.reference as string | undefined;

  assertIsDefined(reference);

  const store = useMemo(() => new EditStore(reference), [reference]);

  return (
    <EditStoreContext.Provider value={store}>
      {children}
    </EditStoreContext.Provider>
  );
};

export const useEditStore = () => {
  const store = React.useContext(EditStoreContext);
  assert(store, "Missing StoreProvider in component tree");

  return store;
};
