import React from "react";
import styled from "styled-components";
import { mediaQueries, space } from "~/theme";

export function AssessmentContent({ children }: { children: React.ReactNode }) {
  return <StyledAssessmentContent>{children}</StyledAssessmentContent>;
}

const StyledAssessmentContent = styled.div`
  position: relative;
  height: 100%;

  padding: ${space.lg} ${space.md};

  @media ${mediaQueries.md} {
    padding: ${space.lg};
  }
`;
