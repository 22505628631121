import {
  causeOfChangeLabels,
  getImpactScore,
  type Assessment,
} from "@gemini/common";
import slugify from "@sindresorhus/slugify";
import type { AlgoliaHit } from "instantsearch.js";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Link from "next/link";
import { useRouter } from "next/router";
import { transparentize } from "polished";
import { Fragment } from "react";
import { useHits, useSortBy } from "react-instantsearch";
import styled, { css } from "styled-components";
import { isDefined } from "ts-is-present";
import type { IconName } from "~/components";
import { Icon, JudgementPill, SpacedChildren, Table, Text } from "~/components";
import { TextButton } from "~/components/text-button";
import { WithTooltip } from "~/components/with-tooltip";
import { useReviewStore } from "~/features/reviews";
import { useAuth } from "~/modules/auth";
import { isOpenInNewTabEvent } from "~/modules/event";
import type { Document } from "~/modules/firestore";
import {
  colors,
  durations,
  fontSizes,
  fontWeights,
  timingFunctions,
} from "~/theme";
import type { GuidelineHit } from "../logic";
import { getPlatformLabel } from "../logic";
import { DownloadGuidelinesCsvButton } from "./download-guidelines-csv-button";

export function Hits({ enableSuggestionsFilter = false }) {
  const router = useRouter();
  const hits = useHits<GuidelineHit>();
  const { review, title, getChildrenFromStructureGroup } = useReviewStore();
  /**
   * If there's only one root group we'll omit it from the breadcrumb because it
   * doesn't add a lot of value.
   */
  const hideFirstBreadcrumb =
    getChildrenFromStructureGroup("__root").length === 1;

  /** Always push guidelines without an impact score to the bottom of the list. */
  const sortedHits = hits.hits.sort((a, b) =>
    !isDefined(a.impact) ? 1 : !isDefined(b.impact) ? -1 : 0
  );

  /**
   * Only show the Observation column if the guideline has a comparison document
   * attached.
   */
  const hasObservationColumn = isDefined(review.data?.review_comparison_id);

  return (
    <SpacedChildren size="lg">
      <div css={{ display: "flex", justifyContent: "space-between" }}>
        <Text>{hits.results?.nbHits} Guidelines</Text>
        <DownloadGuidelinesCsvButton
          hits={sortedHits}
          hideFirstBreadcrumb={hideFirstBreadcrumb}
          hasObservationColumn={hasObservationColumn}
          hasSuggestionsColumn={enableSuggestionsFilter}
          filename={() =>
            `${slugify(title ?? "")}-${moment().format("YYYYMMDDTHHmmss")}`
          }
        />
      </div>

      <div css={{ fontSize: fontSizes.sm }}>
        <Table
          data={sortedHits}
          getKey={(x) => x.reference}
          cxRow={tableRowStyles}
          onRowClick={(hit, evt) => {
            if (isOpenInNewTabEvent(evt)) {
              return window.open(hit.link);
            }
            router
              .push(hit.link)
              .catch(() => (window.location.href = hit.link));
          }}
          columns={[
            {
              name: "Guideline",
              render: (hit) => (
                <GuidelineTitle
                  hit={hit}
                  reviewId={review.id}
                  hideFirstBreadcrumb={hideFirstBreadcrumb}
                />
              ),
            },
            {
              name: "Platform",
              verticalAlign: "middle",
              render: (hit) => (
                <span css={{ whiteSpace: "nowrap" }}>
                  {getPlatformLabel(hit.platform)}
                </span>
              ),
            },
            {
              name: "Highlights",
              verticalAlign: "middle",
              render: (hit) => <Highlights hit={hit} />,
            },
            {
              name: "Judgement",
              verticalAlign: "middle",
              render: (hit) => (
                <AssessmentJudgementPill assessment={hit.assessment} />
              ),
            },
            {
              key: "impact",
              verticalAlign: "middle",
              name: <SortByImpact />,
              render: (hit) => <Impact hit={hit} />,
            },

            hasObservationColumn
              ? {
                  name: "Observation",
                  verticalAlign: "middle",
                  render: (hit) =>
                    hit.causeOfChange && causeOfChangeLabels[hit.causeOfChange],
                }
              : undefined,
          ]}
        />
      </div>
    </SpacedChildren>
  );
}

const GuidelineLink = styled(Link)({
  fontWeight: fontWeights.semibold,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
});

const BreadcrumbLink = styled(Link)({
  color: `${colors.blueGray} !important`,
  textDecoration: "none",
  "&:hover": { textDecoration: "underline" },
  position: "relative",
  zIndex: 2,
});

const tableRowStyles = css`
  transition-property: background-color;
  transition-duration: ${durations.lg};
  transition-timing-function: ${timingFunctions.out};

  &:hover {
    transition-duration: 0ms;
    background: ${transparentize(0.75, colors.whiteLilac)};
    cursor: pointer;
    ${GuidelineLink} {
      text-decoration: underline;
    }
  }
`;

function AssessmentJudgementPill({
  assessment,
}: {
  assessment?: Document<Assessment>;
}) {
  const extraLabel = assessment?.data.is_manual_judgement
    ? "(manually set)"
    : isDefined(assessment?.data.judgement_nudge_value) &&
        assessment?.data.judgement_nudge_value !== 0
      ? "(adjusted)"
      : undefined;

  return (
    <div css={{ position: "relative" }}>
      <JudgementPill
        value={assessment?.data.judgement}
        isIssueResolved={assessment?.data.is_issue_resolved}
      />

      {extraLabel && (
        <span
          css={{
            whiteSpace: "nowrap",
            textAlign: "center",
            fontSize: fontSizes.xs,
            display: "block",
          }}
        >
          {extraLabel}
        </span>
      )}
    </div>
  );
}

const GuidelineTitle = observer(function GuidelineTitle({
  hit,
  reviewId,
  hideFirstBreadcrumb,
}: {
  hit: AlgoliaHit<GuidelineHit>;
  reviewId: string;
  hideFirstBreadcrumb: boolean;
}) {
  const lastPartId = hit.breadcrumb[hit.breadcrumb.length - 1];
  const parts = hideFirstBreadcrumb
    ? hit.breadcrumbParts.slice(1)
    : hit.breadcrumbParts;

  return (
    <div>
      <Text>
        {parts.map((x, index, list) => (
          <Fragment key={x.id}>
            {index === list.length - 1 ? (
              <BreadcrumbLink
                href={`/reviews/${reviewId}/parts/${lastPartId}`}
                onClick={(evt) => evt.stopPropagation()}
              >
                {x.title}
              </BreadcrumbLink>
            ) : (
              x.title
            )}
            {x.id !== lastPartId && <>{" > "}</>}
          </Fragment>
        ))}
      </Text>

      <br />

      <GuidelineLink href={hit.link}>
        {hit.state.citation_code} {hit.state.title}
      </GuidelineLink>
    </div>
  );
});

const Impact = observer(function Impact({
  hit,
}: {
  hit: AlgoliaHit<GuidelineHit>;
}) {
  return (
    <div css={{ textAlign: "right", whiteSpace: "nowrap" }}>
      {isDefined(hit.impact) ? (
        <>
          {hit.impact}{" "}
          <strong
            css={{
              width: "3ch",
              display: "inline-block",
              fontVariantNumeric: "tabular-nums",
            }}
          >
            {getImpactScore(hit.state, hit.assessment?.data)?.toFixed(1)}
          </strong>
        </>
      ) : (
        <span css={{ color: colors.ghostGray }}>n/a</span>
      )}
    </div>
  );
});

const Highlights = observer(function HighLights({
  hit,
}: {
  hit: AlgoliaHit<GuidelineHit>;
}) {
  const { claims } = useAuth();

  return (
    <SpacedChildren
      horizontal
      size="md"
      css={{
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
      }}
    >
      {hit.isLowCost && (
        <HighlightsIcon iconName="dollar" tooltip="Is Low Cost" />
      )}

      {hit.isMissedOpportunity && (
        <HighlightsIcon iconName="star" tooltip="Is Missed Opportunity" />
      )}

      {hit.includesNotes && hit.assessment?.data.comment && (
        <HighlightsIcon
          iconName="comment"
          tooltip={hit.assessment.data.comment}
        />
      )}

      {claims.isAdmin && hit.isSuggestion && (
        <HighlightsIcon iconName="circleTick" tooltip="Is Suggestion" />
      )}
    </SpacedChildren>
  );
});

function HighlightsIcon({
  iconName,
  tooltip,
}: {
  iconName: IconName;
  tooltip: string;
}) {
  return (
    <WithTooltip
      content={<div css={{ whiteSpace: "pre-line" }}>{tooltip}</div>}
    >
      <span
        tabIndex={-1}
        css={{
          color: colors.charcoal,
          width: 16,
          height: 16,
          display: "inline-block",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Icon name={iconName} size={16} />
      </span>
    </WithTooltip>
  );
}

function SortByImpact() {
  const { refine, currentRefinement } = useSortBy({
    items: [
      { value: "impact_asc", label: "impact asc" },
      { value: "impact_desc", label: "impact desc" },
    ],
  });

  return (
    <TextButton
      css={{ width: "100%" }}
      onClick={() =>
        refine(
          currentRefinement === "impact_asc" ? "impact_desc" : "impact_asc"
        )
      }
    >
      <span css={{ textAlign: "right", width: "100%" }}>Impact</span>
      <Icon
        name={currentRefinement === "impact_asc" ? "chevronUp" : "chevronDown"}
        size={18}
      />
    </TextButton>
  );
}
