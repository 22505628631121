import type { ReviewPartProgress } from "@gemini/common";
import React from "react";
import { plural } from "~/modules/string";

type PartProgressProps = {
  partProgress: ReviewPartProgress;
};

export function PartProgress({ partProgress }: PartProgressProps) {
  const { completed_count = 0, total_count = 0 } = partProgress;

  return (
    <span title={getTitle(completed_count, total_count)}>
      {completed_count}/{total_count}
    </span>
  );
}

function getTitle(completed: number, total: number) {
  return total
    ? `${completed} of ${total} ${plural("guideline", total)} rated`
    : undefined;
}
