import type { Assessment, Dictionary, Review } from "@gemini/common";
import type { PerformanceChartConfiguration } from "@gemini/performance-chart";
import "@gemini/performance-chart/index.css";
import { set } from "lodash-es";
import Link from "next/link";
import { useRouter } from "next/router";
import qs from "querystring";
import { useCallback } from "react";
import { isDefined } from "ts-is-present";
import { baymard } from "~/config";
import {
  getAssessmentByReferenceMap,
  useReviewStore,
} from "~/features/reviews";
import type { Document } from "~/modules/firestore";
import { PerformanceChart } from "./performance-chart";

type PerformanceChartWithCompareProps = {
  review: Document<Review>;
  viewStructure: PerformanceChartConfiguration["viewStructure"];
  isEmbeddedInPart?: boolean;
  hideAssumedRange?: boolean;
};

export function PerformanceChartWithCompare({
  review,
  viewStructure,
  isEmbeddedInPart = false,
  hideAssumedRange,
}: PerformanceChartWithCompareProps) {
  const router = useRouter();
  const compareTo = router.query["compare-to"] as string | undefined;
  const compareToReviewIds = compareTo ? getCompareToIds(compareTo) : [];
  const renderGuidelineLink = useRenderGuidelineLink(isEmbeddedInPart);

  const configuration: PerformanceChartConfiguration = {
    focalSiteReviewId: getAmbrosiaReviewId(review.id, "review-tool").internal,
    comparisonSiteReviewIds: compareToReviewIds,
    habitat: "premium",
    summaryLayerVisualization: "barChartAndPileplot",
    viewStructure,
    hideAssumedRange,
  };

  const compareUrl = createCompareUrl(review.id, compareTo);

  return (
    <PerformanceChart
      isEmbeddedInPart={isEmbeddedInPart}
      renderGuidelineLink={renderGuidelineLink}
      chartProps={{
        cacheKey: review.data.modified_at_all.toString(),
        configuration,
        compareUrl,
        showExpandAllLink: true,
      }}
    />
  );
}

function createCompareUrl(reviewId: string, compareTo: string | undefined) {
  const args = qs.stringify({
    /**
     * @todo Candidate for cleanup Taking a shortcut here with directly
     *   referencing the window. I am aware that this might break in the future,
     *   but for now I'm certain that this performance chart will never be
     *   rendered server-side.
     */
    "return-url": `${window.location.href}`,
    "compare-against": getAmbrosiaReviewId(reviewId, "review-tool").external,
    ...(compareTo ? { "compare-to": compareTo } : {}),
  });

  return `${baymard.baseUrl}/premium/chart-comparison-options?${args}`;
}

const performanceChartReviewType = [
  "review-tool",
  "site-review",
  "case-study",
] as const;

type PerformanceChartReviewType = (typeof performanceChartReviewType)[number];

function isValidType(
  type: PerformanceChartReviewType
): type is PerformanceChartReviewType {
  return performanceChartReviewType.includes(type);
}

function getCompareToIds(typeAndIds: string) {
  return typeAndIds
    .split(",")
    .map(
      (typeAndId) =>
        typeAndId.split(":") as [PerformanceChartReviewType, string]
    )
    .filter(([type]) => isValidType(type))
    .map(([type, id]) => getAmbrosiaReviewId(id, type).internal);
}

function getAmbrosiaReviewId(id: string, type: PerformanceChartReviewType) {
  switch (type) {
    case "review-tool":
      return {
        internal: `gemini-${id}`,
        external: `review-tool:${id}`,
      };
    case "site-review":
      return {
        internal: `site-review-${id}`,
        external: `site-review:${id}`,
      };
    case "case-study":
      return {
        internal: id,
        external: `case-study:${id} `,
      };
    default:
      throw new Error(`Trying to resolve reviewId with unknown type ${type}`);
  }
}

function useRenderGuidelineLink(isEmbeddedInPart: boolean) {
  const assessmentLinks = useAssessmentLinks(isEmbeddedInPart);
  return useCallback(
    (guidelineReference: string, title: string) => {
      const link = assessmentLinks[guidelineReference];

      if (!link) {
        console.error(
          `No link data available for reference ${guidelineReference}`
        );
        return <span>{title}</span>;
      }

      return <Link href={link}>{title}</Link>;
    },
    [assessmentLinks]
  );
}

/**
 * Creates an object which maps a guideline reference to its assessment link.
 *
 * @todo Get assessments data in here so we can point to the summary page of the
 *   assessment when it was already made. We probably have to move the
 *   assessment fetching from the part store to the review store to do this
 *   efficiently, because otherwise we would have two places where all
 *   assessments are being fetched.
 *
 *   Alternatively we could compile a list of assessed guideline references, so
 *   that the review store doesn't need to fetch them but they maybe become part
 *   of the review data.
 */
function useAssessmentLinks(isEmbeddedInPart: boolean) {
  const { review, assessments, partsFromStructure } = useReviewStore();
  const assessmentByReference = getAssessmentByReferenceMap(
    assessments.documents
  );
  const excludedReferences = Object.keys(
    review.data?.excluded_guidelines || {}
  );

  return partsFromStructure
    .flatMap((part) =>
      createLinkPropsPerReference(
        part.guideline_references,
        review.id,
        part.id,
        excludedReferences,
        isEmbeddedInPart,
        assessmentByReference,
        !!review.data?.is_read_only
      )
    )
    .filter(isDefined)
    .reduce(
      (acc, { reference, ...linkProps }) => set(acc, reference, linkProps.href),
      {} as Dictionary<string>
    );
}

function createLinkPropsPerReference(
  references: string[],
  reviewId: string,
  partId: string,
  excludedReferences: string[],
  /**
   * When the graph is rendered in a part overview page, we link to a different
   * excluded guideline page that allows the user to navigate back to the part
   * overview page.
   */
  isEmbeddedInPart: boolean,
  assessmentByReference: Dictionary<Assessment>,
  isReadOnly: boolean
) {
  const linksForIncluded = references.map((reference) => {
    const hasAssessment = !!assessmentByReference[reference];
    const page = hasAssessment ? "summary" : "assess";
    const href = `/reviews/${reviewId}/parts/${partId}/assessments/${reference}/${page} `;

    /**
     * A review in read-only mode (eg. audits) cannot link to the assess page.
     * Therefore any guideline without an assessment won't be rendered as link.
     */
    if (hasAssessment || !isReadOnly) {
      return { reference, href } as const;
    }

    return undefined;
  });

  const linksForExcluded = excludedReferences.map((reference) => {
    const href = isEmbeddedInPart
      ? `/reviews/${reviewId}/parts/${partId}/excluded/${reference}`
      : `/reviews/${reviewId}/excluded/${reference}`;

    return { reference, href } as const;
  });

  return [...linksForIncluded, ...linksForExcluded];
}
