import type { Guideline } from "@gemini/common";
import { assert, judgementLabels, judgementValues } from "@gemini/common";
import { observer } from "mobx-react-lite";
import { css } from "styled-components";
import {
  GuidelineLink,
  Paragraph,
  RadioWithLabelAndDescription,
  SpacedChildren,
  Title,
} from "~/components";
import { judgementDescriptions } from "~/constants";
import type { Document } from "~/modules/firestore";
import { colors, radii, space } from "~/theme";
import { useAssessmentStore } from "..";

const judgementOptions = judgementValues.map((value) => ({
  value,
  label: judgementLabels[value],
  description: judgementDescriptions[value],
}));

export const ManualJudgementForm = observer(function ManualJudgementForm({
  guideline,
}: {
  guideline: Document<Guideline>;
}) {
  const assessmentStore = useAssessmentStore();

  assert(guideline, "Missing guideline data");

  const { sql_id } = guideline.data;

  const { manualJudgement, setManualJudgementValue } = assessmentStore;

  return (
    <SpacedChildren size="lg">
      <SpacedChildren>
        <Title level={5}>Manually rate your site&rsquo;s implementation</Title>

        <Paragraph>
          Because no assessment option was selected, the appropriate UX
          judgement for your site&rsquo;s implementation cannot be determined
          automatically. You therefore need to make this judgement yourself.
        </Paragraph>
        <Paragraph>
          You should{" "}
          <GuidelineLink sqlId={sql_id}>read the guideline text</GuidelineLink>{" "}
          thoroughly and gauge the extent to which your site addresses or avoids
          its underlying UX issue. Use this to select the judgement.
        </Paragraph>

        {guideline.data?.issue_text && (
          <div
            css={css`
              background: ${colors.whiteLilac};
              padding: ${space.md} ${space.lg};
              display: flex;
              border-radius: ${radii.md};
              font-style: italic;
            `}
          >
            <strong css={{ marginRight: space.sm }}>Issue:</strong>
            <span>{guideline.data.issue_text}</span>
          </div>
        )}
      </SpacedChildren>

      <SpacedChildren>
        <Title level={5}>Select a judgement</Title>

        {judgementOptions.map((x) => (
          <RadioWithLabelAndDescription
            key={x.value}
            checked={manualJudgement === x.value}
            onChange={(checked) => checked && setManualJudgementValue(x.value)}
            label={x.label}
            description={x.description}
          />
        ))}
      </SpacedChildren>
    </SpacedChildren>
  );
});
