import React from "react";
import { mediaQueries } from "~/theme";
import { useMediaQuery } from "./use-media-query";

export function useBreakpoints() {
  const sm = useMediaQuery(mediaQueries.sm);
  const md = useMediaQuery(mediaQueries.md);
  const lg = useMediaQuery(mediaQueries.lg);
  const xl = useMediaQuery(mediaQueries.xl);

  return React.useMemo(
    () => ({
      xs: true,
      sm,
      md,
      lg,
      xl,

      /**
       * The above breakpoints are all determined with min-width. We can use the
       * following properties if we'd like to know if a certain breakpoint is an
       * exact match.
       */
      xsExact: !sm,
      smExact: !md,
      mdExact: !lg,
      lgExact: !xl,
      xlExact: xl,
    }),
    [sm, md, lg, xl]
  );
}
