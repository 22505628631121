import { times } from "lodash-es";
import { createUniqueId } from "~/ids";
import type { Scenario, ScenarioMap } from "~/schemas";

export function createTestScenario(
  scenarioId?: string,
  parentId = "__root",
  index = 0
): Scenario {
  const id = scenarioId ?? createUniqueId();

  return {
    id,
    parent_id: parentId,
    title: `Title for scenario ${id}`,
    position: index,
    has_children: false,
  };
}

export function createTestScenarios(
  count: number,
  parentId = "__no_parent"
): ScenarioMap {
  return times(count, (index) =>
    createTestScenario(undefined, parentId, index)
  ).reduce((acc, scenario) => {
    return { ...acc, [scenario.id]: scenario };
  }, {});
}
