import React from "react";
import type { CSSProp } from "styled-components";
import { css } from "styled-components";

export type IconName = keyof typeof icons;

export type IconProps = {
  name: IconName;
  size?: number | string;
  cx?: CSSProp;
};

export function Icon({ name, size, cx }: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      css={css`
        ${sizeStyle({ size })}
        ${cx}
      `}
    >
      {icons[name]}
    </svg>
  );
}

export function IconInline({ name, size, cx }: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      css={css`
        position: relative;
        top: 0.375em;

        width: 1.5em;
        height: 1.5em;

        ${sizeStyle({ size })};
        ${cx};
      `}
    >
      {icons[name]}
    </svg>
  );
}

export function IconButton({
  icon,
  onClick,
  size = 40,
  title,
  cx,
  disabled,
}: {
  icon: keyof typeof icons;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  size?: string | number;
  title?: string;
  cx?: CSSProp;
  disabled?: boolean;
}) {
  return (
    <button
      title={title}
      onClick={onClick}
      css={css`
        ${sizeStyle({ size })};
        cursor: ${disabled ? "not-allowed" : "pointer"}
        opacity: ${disabled ? 0.5 : 1};
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
        ${cx}
      `}
      disabled={disabled}
    >
      <Icon name={icon} />
    </button>
  );
}

function sizeStyle({ size }: Pick<IconProps, "size">) {
  if (size) {
    return {
      width: size,
      height: size,
    };
  }
}

const chevron = (
  <path
    fill="currentcolor"
    d="M8.477 17.494A.75.75 0 009.54 18.55l5.992-6.027a.75.75 0 00-.002-1.06l-6-5.973A.75.75 0 108.47 6.554l5.47 5.444-5.464 5.496z"
  />
);
const plus = (
  <path
    fill="currentcolor"
    d="M12 4c.414 0 .75.448.75 1v6.25H19c.51 0 .93.286.992.656L20 12c0 .414-.448.75-1 .75h-6.25V19c0 .51-.286.93-.656.992L12 20c-.414 0-.75-.448-.75-1v-6.25H5c-.51 0-.93-.286-.992-.656L4 12c0-.414.448-.75 1-.75h6.25V5c0-.51.286-.93.656-.992z"
  />
);

export const icons = {
  chevronUp: <g transform="rotate(-90 12 12.023)">{chevron}</g>,
  chevronDown: <g transform="rotate(90 12 12.023)">{chevron}</g>,
  chevronLeft: <g transform="rotate(180 12 12.023)">{chevron}</g>,
  chevronRight: <g>{chevron}</g>,
  close: <g transform="rotate(45 12 12)">{plus}</g>,
  plus: <g>{plus}</g>,
  minus: (
    <path
      fill="currentcolor"
      d="M5 12.75h14c.552 0 1-.336 1-.75s-.448-.75-1-.75H5c-.552 0-1 .336-1 .75s.448.75 1 .75z"
    />
  ),
  circlePlus: (
    <path
      fill="currentcolor"
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5zm.188 4.5a.75.75 0 01.743.648l.007.102v4.5h4.5a.75.75 0 01.743.648l.006.102a.75.75 0 01-.648.743l-.102.007h-4.5v4.5a.75.75 0 01-.648.743l-.101.007a.75.75 0 01-.744-.648l-.007-.102v-4.5h-4.5a.75.75 0 01-.743-.648L6.188 12a.75.75 0 01.648-.743l.101-.007h4.5v-4.5a.75.75 0 01.649-.743L12.187 6z"
    />
  ),
  circleMinus: (
    <path
      fill="currentcolor"
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5zm5.438 9.75a.75.75 0 110 1.5h-10.5a.75.75 0 110-1.5z"
    />
  ),
  tick: (
    <path
      fill="currentcolor"
      d="M10.813 16.75L6.04 11.979a.75.75 0 00-1.06 1.06l5.386 5.387a.75.75 0 001.132-.083l8.935-12.01a.75.75 0 10-1.204-.896l-8.416 11.315z"
    />
  ),
  circleTick: (
    <path
      fill="currentcolor"
      d="M12 0a12 12 0 110 24 12 12 0 010-24zm0 1.5a10.5 10.5 0 100 21 10.5 10.5 0 000-21zm3.89 6.08a.75.75 0 111.22.87l-5.65 8a.75.75 0 01-1.15.09l-3.35-3.48a.75.75 0 011.08-1.04l2.72 2.82z"
    />
  ),
  error: (
    <path
      fill="currentcolor"
      d="M13.283 1.67c.338.17.613.445.782.783l8.882 17.764a1.75 1.75 0 01-1.565 2.533H3.618a1.75 1.75 0 01-1.565-2.533l8.882-17.764a1.75 1.75 0 012.348-.782zm-1.007 1.454L3.394 20.888a.25.25 0 00.224.362h17.764a.25.25 0 00.224-.362L12.724 3.124a.25.25 0 00-.448 0zM12.5 17.5a1 1 0 110 2 1 1 0 010-2zm0-8.75a.75.75 0 01.75.75v6a.75.75 0 11-1.5 0v-6a.75.75 0 01.75-.75z"
    />
  ),
  image: (
    <path
      fill="currentcolor"
      d="M2 20c-1.105 0-2-.848-2-1.895V3.895C0 2.848.895 2 2 2h20c1.105 0 2 .848 2 1.895v14.21C24 19.152 23.105 20 22 20H2zM22 3.184H2c-.414 0-.75.318-.75.71v14.211c0 .393.336.71.75.71h3.333L14.317 10l8.425 8.208a.68.68 0 00.008-.103V3.895c0-.393-.336-.71-.75-.71zm-7.68 8.566l-7.199 7.065h14.451L14.32 11.75zM7 6a3 3 0 110 6 3 3 0 010-6zm0 1.2a1.8 1.8 0 100 3.6 1.8 1.8 0 000-3.6z"
    />
  ),
  edit: (
    <path
      fill="currentcolor"
      d="M4.5 16.55v2.533c0 .234.183.417.417.417H7.45a.39.39 0 00.292-.125l9.1-9.092-3.125-3.125-9.092 9.092a.41.41 0 00-.125.3zm14.758-8.683a.83.83 0 000-1.175l-1.95-1.95a.83.83 0 00-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
    />
  ),
  editOutline: (
    <path
      fill="currentcolor"
      d="M15.7 1.3c.7-.6 1.7-.7 2.4-.1l.1.1 2.5 2.5c.6.6.7 1.6.1 2.3l-.1.2L6 20.8c-.2.2-.4.4-.7.4H2c-.6 0-1.1-.5-1.2-1.1V16.8c0-.3 0-.6.3-.8v-.1L15.8 1.3ZM14.2 5l-12 12v2.9h3l12-12-3-3Zm3-2.6a.4.4 0 0 0-.4 0l-1.5 1.4 3 3 1.4-1.4c.1-.1.1-.3 0-.4v-.1l-2.5-2.5Z"
    />
  ),
  comment: (
    <path
      fill="currentcolor"
      d="M22 2a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H10l-7 5 .6-5H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h20Zm0 1.3H2c-.4 0-.8.3-.8.7v13c0 .4.4.8.8.8h3l-.4 3.5 5-3.6H22c.4 0 .8-.3.8-.7V4c0-.4-.4-.8-.8-.8Zm-4.4 8.6a.6.6 0 0 1 0 1.2H6.4a.6.6 0 1 1 0-1.2Zm0-4a.6.6 0 0 1 0 1.2H6.4a.6.6 0 1 1 0-1.2Z"
    />
  ),
  duplicate: (
    <path
      fill="currentcolor"
      d="M20 2.25c.97 0 1.75.78 1.75 1.75v12.4c0 .97-.78 1.75-1.75 1.75h-3.65V20c0 .97-.78 1.75-1.75 1.75H4c-.97 0-1.75-.78-1.75-1.75V7.6c0-.97.78-1.75 1.75-1.75h3.65V4c0-.97.78-1.75 1.75-1.75H20zM7.65 7.35H4a.25.25 0 00-.25.25V20c0 .14.11.25.25.25h10.6c.14 0 .25-.11.25-.25v-1.85H9.4c-.97 0-1.75-.78-1.75-1.75V7.35zM20 3.75H9.4a.25.25 0 00-.25.25v12.4c0 .14.11.25.25.25H20c.14 0 .25-.11.25-.25V4a.25.25 0 00-.25-.25z"
    />
  ),
  info: (
    <path
      fill="currentcolor"
      d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,1.5 C6.20101013,1.5 1.5,6.20101013 1.5,12 C1.5,17.7989899 6.20101013,22.5 12,22.5 C17.7989899,22.5 22.5,17.7989899 22.5,12 C22.5,6.20101013 17.7989899,1.5 12,1.5 Z M12.25,11 C12.6642136,11 13,11.1958754 13,11.4375 L13,11.4375 L13,17.5625 C13,17.8041246 12.6642136,18 12.25,18 C11.8357864,18 11.5,17.8041246 11.5,17.5625 L11.5,17.5625 L11.5,11.4375 C11.5,11.1958754 11.8357864,11 12.25,11 Z M12.25,7 C12.8022847,7 13.25,7.44771525 13.25,8 C13.25,8.55228475 12.8022847,9 12.25,9 C11.6977153,9 11.25,8.55228475 11.25,8 C11.25,7.44784646 11.6975025,7.00021274 12.2496064,7 L12.2496064,7 Z"
    />
  ),
  search: (
    <path
      fill="currentcolor"
      d="M10 3a7 7 0 015.48 11.35l5.3 5.2a.8.8 0 01-1.03 1.22l-.1-.08-5.3-5.2A7 7 0 1110 3zm0 1.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
    />
  ),
  dollar: (
    <path
      fill="currentcolor"
      d="M11.4 0c.3 0 .6.3.7.6V2c3.2.3 5.6 2.2 5.9 4.7V7a.7.7 0 0 1-1.4 0c0-1.8-1.8-3.4-4.3-3.7h-.2v8l.6.3.5.2.5.2.6.2c2.3 1 3.7 2.7 3.7 4.6 0 2.6-2.4 4.7-5.6 5l-.3.1v1.3c0 .3-.2.6-.5.7h-.2a.7.7 0 0 1-.7-.6V22c-3.2-.3-5.6-2.2-5.9-4.7V17a.7.7 0 0 1 1.4 0c0 1.8 1.8 3.4 4.2 3.7h.3v-8.1l-.4-.2-.6-.2-.6-.2-.7-.2C6.2 10.7 4.8 9 4.8 7c0-2.6 2.4-4.7 5.6-5l.3-.1V.7c0-.3.2-.6.5-.7h.1Zm.7 13v7.6c2.5-.2 4.4-1.7 4.5-3.5v-.2c0-1.3-1-2.6-2.8-3.3l-.5-.2-.8-.3-.4-.2Zm-1.4-9.6C8 3.6 6.2 5.2 6.2 7c0 1.3 1 2.6 2.7 3.3l.9.3.7.3h.2V3.4Z"
    />
  ),
  star: (
    <path
      fill="currentcolor"
      d="M12.3.6c.2 0 .3.2.4.4l2.9 7.2 7.7.5a.8.8 0 0 1 .4 1.4l-6 5 2 7.4a.8.8 0 0 1-1.2.9L12 19.2l-6.5 4.2a.8.8 0 0 1-1.2-.9l2-7.5-6-5a.8.8 0 0 1 .4-1.3l7.7-.5L11.3 1c.1-.4.6-.6 1-.4ZM12 2.9 9.7 8.7c-.2.4-.6.8-1.1.9h-.2l-6.1.4L7 14c.4.3.6.8.6 1.3v.1l-1.6 6 5.2-3.3c.4-.3 1-.3 1.4 0h.2l5.2 3.3-1.5-6c-.2-.5 0-1 .3-1.4h.1l4.8-4-6.2-.4c-.5 0-1-.4-1.2-.8v-.1L12 2.9Z"
    />
  ),
  list: (
    <path
      fill="currentcolor"
      d="M2.4 17a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm19.5.6a2.1 2.1 0 1 1 0 4.3H8.7a2.1 2.1 0 1 1 0-4.3H22Zm-19.5.8a1 1 0 1 0 0 2.2 1 1 0 0 0 0-2.2Zm19.5.6H8.7a.7.7 0 0 0 0 1.5H22a.7.7 0 0 0 0-1.5ZM2.4 9.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm19.5.4a2.1 2.1 0 1 1 0 4.2H8.7a2.1 2.1 0 1 1 0-4.2H22Zm-19.5 1a1 1 0 1 0 0 2.1 1 1 0 0 0 0-2.1Zm19.5.4H8.7a.7.7 0 0 0 0 1.4H22a.7.7 0 0 0 0-1.4ZM2.4 1.9a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm19.5.2a2.1 2.1 0 1 1 0 4.3H8.7a2.1 2.1 0 0 1 0-4.3H22ZM2.4 3.3a1 1 0 1 0 0 2.2 1 1 0 0 0 0-2.2Zm19.5.2H8.7a.7.7 0 0 0 0 1.5H22a.7.7 0 0 0 0-1.5Z"
    />
  ),
  filter: (
    <path
      fill="currentcolor"
      d="M5.3 17.6c1 0 2 .7 2.3 1.6h15.7a.7.7 0 0 1 .1 1.4H7.6a2.5 2.5 0 0 1-4.8 0H.7a.7.7 0 0 1 0-1.4h2.2c.4-1 1.3-1.6 2.4-1.6Zm0 1.3a1 1 0 1 0 0 2.2 1 1 0 0 0 0-2.2Zm13-9.3c1 0 2 .7 2.3 1.7h2.7a.7.7 0 0 1 .1 1.4h-2.7a2.5 2.5 0 0 1-4.8 0H.7a.7.7 0 0 1 0-1.4h15.2c.3-1 1.3-1.8 2.4-1.8Zm0 1.4a1 1 0 1 0 0 2.1 1 1 0 0 0 0-2.1Zm-13-9.4c1.1 0 2 .7 2.4 1.8h15.6a.7.7 0 0 1 0 1.4H7.6a2.5 2.5 0 0 1-4.7 0H.7a.7.7 0 0 1 0-1.4h2.2c.3-1 1.2-1.8 2.4-1.8Zm0 1.4a1 1 0 1 0 0 2.1 1 1 0 0 0 0-2.1Z"
    />
  ),
  sort: (
    <path fill="currentcolor" d="M6.5 10 4 13l-2.5-3h5ZM4 3l2.5 3h-5L4 3Z" />
  ),
  download: (
    <path
      fill="currentcolor"
      d="M19.1 20.4a.7.7 0 0 1 0 1.3H4.9a.7.7 0 0 1 0-1.3h14.2ZM12 2.3c.3 0 .6.3.7.6v13.6l5.3-5.8a.7.7 0 0 1 1.1.9l-6.6 7.2c-.3.3-.7.3-1 .1l-6.7-7.2a.7.7 0 0 1 1-1l5.5 5.9V3c0-.4.3-.7.7-.7Z"
    />
  ),
};
