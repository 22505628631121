import type { Assessment, Guideline } from "@gemini/common";
import { assert, parseCitationCode } from "@gemini/common";
import { doc, getDoc } from "firebase/firestore";
import { isEmpty } from "lodash-es";
import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { type ReactNode } from "react";
import styled, { css } from "styled-components";
import {
  AdaptiveCarouselContextProvider,
  BackLink,
  Icon,
  JudgementPill,
  LineBreak,
  PageContainer,
  PageLoader,
  Paragraph,
  SpacedChildren,
  Table,
  Text,
  Title,
  WordBreak,
  type IconName,
} from "~/components";
import { StatusCircle } from "~/components/status-circle";
import { WithTooltip } from "~/components/with-tooltip";
import { useAuth } from "~/modules/auth";
import { db } from "~/modules/firebase";
import type { Document } from "~/modules/firestore";
import { getImageSourceFromPath, imageSourcePresets } from "~/modules/image";
import { notify } from "~/modules/notifications";
import { colors, fontWeights, preset, space } from "~/theme";
import { FileList, ImageFieldThumbnail } from "../assessment/components";
import { WithCitationCodePrefix } from "./components";
import { useReviewStore } from "./store";

export const ForeignAssessmentsPage: NextPage = observer(() => {
  const { claims } = useAuth();
  const { review, structure } = useReviewStore();
  const router = useRouter();
  const reviewId = router.query.reviewId as string;

  assert(review.data, "Cannot render without review data");

  const references = review.data.structure_foreign_references;

  const [assessments, isLoadingAssessments] = useGetDocumentsByPath<Assessment>(
    references.map(
      (reference) => `reviews/${reviewId}/assessments/${reference}`
    )
  );

  const guidelineIds = Object.values(assessments).map(
    (x) => x.data.guideline_id_linked
  );

  const [guidelines, isLoadingGuidelines] = useGetDocumentsByPath<Guideline>(
    isLoadingAssessments
      ? undefined
      : guidelineIds.map((id) => `guidelines/${id}`)
  );

  const guidelinesByReference = Object.values(guidelines || {}).reduce(
    (result, guideline) => {
      result[guideline.data.reference] = guideline;
      return result;
    },
    {} as Record<string, Document<Guideline>>
  );

  if (review.isLoading || isLoadingAssessments || isLoadingGuidelines) {
    return <PageLoader />;
  }

  const sortedAssessments = Object.values(assessments).sort(
    (a, b) =>
      parseCitationCode(
        guidelinesByReference[a.data.guideline_reference].data.citation_code
      )[0] -
      parseCitationCode(
        guidelinesByReference[b.data.guideline_reference].data.citation_code
      )[0]
  );

  return (
    <PageContainer>
      <SpacedChildren size="lg">
        <SpacedChildren>
          <BackLink href={`/reviews/${reviewId}`}>Back</BackLink>
          <Title level={2} as="h1">
            Foreign Assessments
          </Title>
          <Paragraph>
            The {guidelineIds.length} guideline assessments listed below have
            been assessed in this site review, but aren't part of the currently
            selected review structure: "<em>{structure.data?.title}</em>". These{" "}
            {guidelineIds.length} guideline assessments won't be displayed in
            the performance scorecard or in the assessment list. To include
            them, you have to edit what you{" "}
            <Link href={`/reviews/${review.id}/edit`}>
              <strong>want to review</strong>
            </Link>
            .
          </Paragraph>
        </SpacedChildren>

        <Table
          getKey={(x) => x.id}
          data={sortedAssessments}
          columns={[
            {
              name: "Guideline",
              size: "50%",
              render(assessment) {
                const guideline =
                  guidelinesByReference[assessment.data.guideline_reference];
                return (
                  <WithCitationCodePrefix
                    citationCode={guideline.data.citation_code}
                  >
                    <GuidelineTitle>
                      {guideline.data.scenarios.__root.title}
                    </GuidelineTitle>
                  </WithCitationCodePrefix>
                );
              },
            },
            {
              name: "Implementation",
              size: "50%",
              render(assessment) {
                const guideline =
                  guidelinesByReference[assessment.data.guideline_reference];
                const implementation = assessment.data.selected_scenario_ids
                  ?.map((id) => guideline.data.scenarios[id]?.title)
                  .join(" • ");

                return <Text size="small">{implementation}</Text>;
              },
            },
            {
              name: "Judgement",
              render(assessment) {
                return (
                  <JudgementPill
                    value={assessment.data.judgement}
                    isIssueResolved={assessment.data.is_issue_resolved}
                  />
                );
              },
            },
            {
              name: "",
              render(assessment) {
                const hasImages = !isEmpty(assessment.data.images);
                const hasFiles = !isEmpty(assessment.data.files);
                return (
                  <SpacedChildren
                    horizontal
                    size="sm"
                    css={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {(hasImages || hasFiles) && (
                      <HighlightsIcon
                        iconName="image"
                        tooltip={
                          <div>
                            {hasImages && (
                              <AdaptiveCarouselContextProvider>
                                <div
                                  css={css`
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin: 0 -${space.xs};
                                  `}
                                >
                                  {assessment.data.images!.map((src) => (
                                    <ImageFieldThumbnail
                                      src={getImageSourceFromPath(src)}
                                      srcThumb={getImageSourceFromPath(
                                        src,
                                        imageSourcePresets.thumbnail
                                      )}
                                      key={src}
                                    />
                                  ))}
                                </div>
                              </AdaptiveCarouselContextProvider>
                            )}

                            {hasFiles && (
                              <>
                                <p
                                  css={{
                                    fontWeight: fontWeights.bold,
                                    marginTop: hasImages ? space.md : undefined,
                                  }}
                                >
                                  Files
                                </p>
                                <FileList
                                  files={assessment.data.files!}
                                  reference={assessment.id}
                                  reviewId={review.id}
                                  accountId={
                                    review.data?.account_id ?? "__no_account_id"
                                  }
                                />
                              </>
                            )}
                          </div>
                        }
                      />
                    )}
                    {assessment.data.comment?.trim() && (
                      <HighlightsIcon
                        iconName="comment"
                        tooltip={
                          <div>
                            <p css={{ fontWeight: fontWeights.bold }}>
                              {claims.isAdmin
                                ? "Customer-facing notes"
                                : "Notes for you and your team"}
                            </p>
                            <WordBreak>
                              <LineBreak>{assessment.data.comment}</LineBreak>
                            </WordBreak>
                          </div>
                        }
                      />
                    )}
                    {claims.isAdmin &&
                      assessment.data.auditor_comment?.trim() && (
                        <HighlightsIcon
                          iconName="info"
                          tooltip={
                            <div>
                              <p css={{ fontWeight: fontWeights.bold }}>
                                Internal notes (for Baymard only)
                              </p>

                              <p css={{ marginBottom: space.sm }}>
                                <WordBreak>
                                  <LineBreak>
                                    {assessment.data.auditor_comment}
                                  </LineBreak>
                                </WordBreak>
                              </p>

                              <SpacedChildren
                                horizontal
                                css={{ marginBottom: space.xs }}
                              >
                                <StatusCircle
                                  active={
                                    assessment.data.is_marked_for_suggestion
                                  }
                                >
                                  suggestion
                                </StatusCircle>

                                <StatusCircle
                                  active={
                                    assessment.data.is_marked_for_discussion
                                  }
                                >
                                  discussion
                                </StatusCircle>
                              </SpacedChildren>
                            </div>
                          }
                        />
                      )}
                    &nbsp;
                  </SpacedChildren>
                );
              },
            },
          ]}
        />
      </SpacedChildren>
    </PageContainer>
  );
});

const GuidelineTitle = styled.span`
  ${preset.typography.subtitle2};
  text-decoration: none;
`;

function useGetDocumentsByPath<T>(paths: string[] | undefined) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [documents, setDocuments] = React.useState<Record<string, Document<T>>>(
    {}
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pathsMemoized = React.useMemo(() => paths, [JSON.stringify(paths)]);

  React.useEffect(() => {
    if (!pathsMemoized) return setIsLoading(true);

    let isCancelled = false;
    setIsLoading(true);

    Promise.all(pathsMemoized.map((path) => getDoc(doc(db, path))))
      .then((docs) => {
        const documentsResult = {} as Record<string, Document<T>>;
        docs.forEach((doc) => {
          documentsResult[doc.id] = {
            id: doc.id,
            data: doc.data() as T,
            ref: doc.ref,
          };
        });
        if (!isCancelled) {
          setDocuments(documentsResult);
          setIsLoading(false);
        }
      })
      .catch((err) => notify.error(err));

    return () => {
      isCancelled = true;
    };
  }, [pathsMemoized]);

  return [documents, isLoading] as const;
}

function HighlightsIcon({
  iconName,
  tooltip,
}: {
  iconName: IconName;
  tooltip: ReactNode;
}) {
  return (
    <WithTooltip
      interactive
      content={<div css={{ whiteSpace: "pre-line" }}>{tooltip}</div>}
    >
      <span
        tabIndex={-1}
        css={{
          color: colors.charcoal,
          width: 16,
          height: 16,
          display: "inline-block",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Icon name={iconName} size={16} />
      </span>
    </WithTooltip>
  );
}
