import type {
  ReviewStructureGroup,
  ReviewStructureGroupFmtAdmin,
  ReviewStructurePart,
  ReviewStructurePartFmtAdmin,
} from "@gemini/common";

export function isReviewStructurePart(
  section: ReviewStructureGroup | ReviewStructurePart
): section is ReviewStructurePart {
  return (section as ReviewStructurePart).guideline_references !== undefined;
}

export function isReviewStructureGroup(
  section: ReviewStructureGroup | ReviewStructurePart
): section is ReviewStructureGroup {
  return (section as ReviewStructurePart).guideline_references === undefined;
}

export function isReviewStructurePartFmtAdmin(
  section: ReviewStructureGroupFmtAdmin | ReviewStructurePartFmtAdmin
): section is ReviewStructurePartFmtAdmin {
  return (
    (section as ReviewStructurePartFmtAdmin).guideline_citation_codes !==
    undefined
  );
}

export function isReviewStructureGroupFmtAdmin(
  section: ReviewStructureGroupFmtAdmin | ReviewStructurePartFmtAdmin
): section is ReviewStructureGroupFmtAdmin {
  return (section as ReviewStructureGroupFmtAdmin).sections !== undefined;
}
