import type { IndustryId, ScenarioMap, TagId } from "@gemini/common";
import {
  assert,
  getChildScenarios,
  getConditionalInstructionTexts,
  getRuling,
  hasSomeOfTheseMasterTexts,
  hasUnusedMasterTexts,
} from "@gemini/common";
import { Checkbox, Radio, Tag } from "antd";
import { first, flatten } from "lodash-es";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { isDefined } from "ts-is-present";
import { ValueTag } from "~/features/admin/components";
import { PointScoreTag } from "~/features/admin/components/point-score-tag";
import { AdminMarkdownWrapper } from ".";
import { CopyToClipboardButton } from "./copy-to-clipboard-button";
import { PasteFromClipboard } from "./paste-from-clipboard";

const ItemWrapper = styled.div`
  margin-bottom: 8px; // em doesn't seem to work somehow
  font-size: 14px; // the RadioGroup sets font-size to 0 so we need to reset it
`;

type ScenarioBranchAdminProps = {
  renderScenarioId: string;
  tagIds: TagId[];
  industryId: IndustryId;
  scenarios: ScenarioMap;
  level: number;
  onSelectionChange: (renderScenarioId: string, values?: string[]) => void;
  selectedIdsMap: Record<string, string[]>;
  isDisabled?: boolean;
  focusedScenarioId?: string;
  onFocus?: (scenarioId: string) => void;
};

/**
 * @todo Rename to ScenarioTree(Admin) because we are rendering both branch and
 *   leaf nodes. But after PR because this has snowballed too much already...
 */
export const ScenarioBranchAdmin = observer(
  ({
    renderScenarioId,
    tagIds,
    industryId,
    scenarios,
    level,
    onSelectionChange,
    selectedIdsMap,
    isDisabled,
    focusedScenarioId,
    onFocus,
  }: ScenarioBranchAdminProps) => {
    const handleChange = (values: string[]) =>
      onSelectionChange(renderScenarioId, values);

    const childScenarios = getChildScenarios(scenarios, renderScenarioId);

    const handleScenarioFocus = (
      evt: React.SyntheticEvent,
      scenarioId: string
    ) => {
      /**
       * Only when there is a onFocus function do we prevent the user from
       * selecting the option when clicking on the text. This focus thing is
       * only useful for the edit view. In the assessment we still want to
       * toggle selections by clicking on the text.
       */
      if (typeof onFocus === "function") {
        evt.preventDefault();
        onFocus(scenarioId);
      }
    };

    const radioOrCheckboxStyle = {
      marginLeft: `${level * 28}px`,
    };

    const getDescriptionStyle = (isActive?: boolean) => ({
      marginLeft: `${(level + 1) * 28}px`,
      color: isActive ? "#777" : "#ccc",
      fontStyle: "italic",
      marginBottom: "8px", // em doesn't seem to work somehow
    });

    const getInstructionStyle = (isActive?: boolean) => ({
      marginLeft: `${(level + 1) * 28}px`,
      color: isActive ? "#777" : "#ccc",
      marginBottom: "8px", // em doesn't seem to work somehow
    });

    const parentScenario = scenarios[renderScenarioId];

    assert(parentScenario, `Failed to find scenario ${renderScenarioId}`);

    const selectedIds = selectedIdsMap[renderScenarioId];

    const allSelectedIds = Object.values(selectedIdsMap).flat() as string[];

    /**
     * The __root scenario is always active but does not occur in the selected
     * ids list, so we add it here.
     *
     * The isLevelActive value is used to dim text in branch preview mode that
     * would otherwise be hidden like description text.
     */
    allSelectedIds.push("__root");
    const isLevelActive = allSelectedIds.includes(parentScenario.id);

    if (parentScenario.logic === "or") {
      /**
       * With OR logic there is only one selected scenario and it will be the
       * the first value in the array.
       */
      const selectedId = first(selectedIds);

      const items = childScenarios.map((scenario) => {
        const {
          id,
          title,
          description,
          translation_value,
          instruction_text,
          master_text,
          master_texts,
          rule,
        } = scenario;
        const ruling = getRuling(scenario, industryId, tagIds);

        const conditionalInstructionTexts = getConditionalInstructionTexts(
          scenario,
          industryId,
          tagIds
        );

        const scenarioListItem = (
          <ItemWrapper>
            <Radio
              key={id}
              value={id}
              style={{ ...radioOrCheckboxStyle, opacity: isDisabled ? 0.5 : 1 }}
            >
              <span
                onClick={(evt) => handleScenarioFocus(evt, id)}
                style={{
                  color: focusedScenarioId === id ? "#1890ff" : undefined,
                }}
              >
                <span style={{ marginRight: "1em", fontWeight: "500" }}>
                  {title}
                </span>
                <ValueTag
                  value={
                    ruling.overrideValue !== undefined
                      ? ruling.overrideValue
                      : translation_value
                  }
                />
                {master_text && (
                  <Tag title="Has legacy master text" color="blue">
                    M*
                  </Tag>
                )}
                {hasSomeOfTheseMasterTexts(master_texts, [
                  "intro",
                  "recommendation",
                ]) && (
                  <Tag title="Has master text" color="purple">
                    M
                  </Tag>
                )}
                {hasUnusedMasterTexts(scenario.id, scenarios) && (
                  <Tag title="Some master texts will be ignored" color="red">
                    !!
                  </Tag>
                )}
                {rule && (
                  <Tag title="Has rule" color="magenta">
                    R
                  </Tag>
                )}
              </span>
              <CopyToClipboardButton scenarioId={id} />
              <PasteFromClipboard scenarioId={id} />
            </Radio>
          </ItemWrapper>
        );

        const isSelected = id === selectedId;

        if (scenario.has_children) {
          return (
            <ItemWrapper key={id}>
              {scenarioListItem}
              {description && (
                <div style={getDescriptionStyle(isLevelActive)}>
                  <AdminMarkdownWrapper sources={[description]} />
                </div>
              )}
              {instruction_text && (
                <div style={getInstructionStyle(isSelected)}>
                  <AdminMarkdownWrapper sources={[instruction_text]} />
                </div>
              )}
              {conditionalInstructionTexts && (
                <div style={getInstructionStyle(isSelected)}>
                  {conditionalInstructionTexts.map((text, index) => (
                    <AdminMarkdownWrapper key={index} sources={[text]} />
                  ))}
                </div>
              )}

              <ScenarioBranchAdmin
                scenarios={scenarios}
                industryId={industryId}
                tagIds={tagIds}
                renderScenarioId={id}
                level={level + 1}
                selectedIdsMap={selectedIdsMap}
                onSelectionChange={onSelectionChange}
                isDisabled={!isSelected || isDisabled}
                onFocus={onFocus}
                focusedScenarioId={focusedScenarioId}
              />
            </ItemWrapper>
          );
        } else {
          return (
            <ItemWrapper key={id}>
              {scenarioListItem}
              {description && (
                <div style={getDescriptionStyle(isLevelActive)}>
                  <AdminMarkdownWrapper sources={[description]} />
                </div>
              )}
            </ItemWrapper>
          );
        }
      });

      return (
        <Radio.Group
          onChange={(evt) => handleChange([evt.target.value])}
          value={selectedId}
          disabled={isDisabled}
        >
          {flatten(items)}
        </Radio.Group>
      );
    } else {
      /**
       * Logic AND and point-based both use checkboxes and can have multiple
       * selections so we render them together.
       */
      const hasPointBasedParent = parentScenario.logic === "points";

      const items = childScenarios.map((scenario) => {
        const {
          id,
          title,
          description,
          instruction_text,
          translation_value,
          point_score,
          master_text,
          master_texts,
          rule,
        } = scenario;
        const ruling = getRuling(scenario, industryId, tagIds);

        const conditionalInstructionTexts = getConditionalInstructionTexts(
          scenario,
          industryId,
          tagIds
        );

        const isSelected = selectedIds && selectedIds.includes(id);

        const branchListItem = (
          <ItemWrapper>
            <Checkbox key={id} value={id} style={radioOrCheckboxStyle}>
              <span
                onClick={(evt) => handleScenarioFocus(evt, id)}
                style={{
                  color: focusedScenarioId === id ? "#1890ff" : undefined,
                }}
              >
                <span style={{ marginRight: "1em", fontWeight: "500" }}>
                  {title}
                </span>

                {hasPointBasedParent ? (
                  <PointScoreTag
                    value={
                      isDefined(ruling.overridePointScore)
                        ? ruling.overridePointScore
                        : point_score
                    }
                  />
                ) : (
                  <ValueTag
                    value={
                      isDefined(ruling.overrideValue)
                        ? ruling.overrideValue
                        : translation_value
                    }
                  />
                )}

                {master_text && (
                  <Tag title="Has legacy master text" color="purple">
                    M*
                  </Tag>
                )}
                {hasSomeOfTheseMasterTexts(master_texts, [
                  "intro",
                  "recommendation",
                ]) && (
                  <Tag title="Has master text" color="purple">
                    M
                  </Tag>
                )}
                {hasUnusedMasterTexts(scenario.id, scenarios) && (
                  <Tag title="Some master texts will be ignored" color="red">
                    !!
                  </Tag>
                )}
                {rule && (
                  <Tag title="Has rule" color="magenta">
                    R
                  </Tag>
                )}
              </span>
              <CopyToClipboardButton scenarioId={id} />
              <PasteFromClipboard scenarioId={id} />
            </Checkbox>
          </ItemWrapper>
        );

        if (scenario.has_children) {
          return (
            <ItemWrapper key={id}>
              {branchListItem}
              {description && (
                <div style={getDescriptionStyle(isLevelActive)}>
                  <AdminMarkdownWrapper sources={[description]} />
                </div>
              )}

              {instruction_text && (
                <div style={getInstructionStyle(isSelected)}>
                  <AdminMarkdownWrapper sources={[instruction_text]} />
                </div>
              )}
              {conditionalInstructionTexts && (
                <div style={getInstructionStyle(isSelected)}>
                  {conditionalInstructionTexts.map((text, index) => (
                    <AdminMarkdownWrapper key={index} sources={[text]} />
                  ))}
                </div>
              )}

              <ScenarioBranchAdmin
                scenarios={scenarios}
                industryId={industryId}
                tagIds={tagIds}
                renderScenarioId={id}
                level={level + 1}
                selectedIdsMap={selectedIdsMap}
                onSelectionChange={onSelectionChange}
                isDisabled={!isSelected || isDisabled}
                onFocus={onFocus}
                focusedScenarioId={focusedScenarioId}
              />
            </ItemWrapper>
          );
        } else {
          return (
            <ItemWrapper key={id}>
              {branchListItem}
              {description && (
                <div
                  key={`${id}_description}`}
                  style={getDescriptionStyle(isLevelActive)}
                >
                  <AdminMarkdownWrapper sources={[description]} />
                </div>
              )}
            </ItemWrapper>
          );
        }
      });

      return (
        <Checkbox.Group
          onChange={(values) => handleChange(values as string[])}
          disabled={isDisabled}
          value={selectedIds}
          style={{
            display: "block",
          }}
        >
          {parentScenario.logic === "and" && (
            <Tag
              title="Logic and mode"
              key="__annotation"
              style={{
                marginLeft: `${level * 28}px`,
              }}
              color={
                parentScenario.logic_and_mode === "avg" ? "magenta" : "cyan"
              }
            >
              {parentScenario.logic_and_mode === "avg" ? "/" : "+"}
            </Tag>
          )}

          {flatten(items)}
        </Checkbox.Group>
      );
    }
  }
);
