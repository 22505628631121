import Link from "next/link";
import { darken } from "polished";
import React from "react";
import styled from "styled-components";
import { colors, preset } from "~/theme";

export function FatLink({
  href,
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
}) {
  if (!href) {
    return (
      <StyledFatLink disabled={disabled} as="button" onClick={onClick}>
        {children}
      </StyledFatLink>
    );
  }

  return (
    <StyledFatLink
      href={disabled ? "#" : href}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledFatLink>
  );
}

const StyledFatLink = styled(Link)<{ disabled?: boolean }>`
  ${preset.typography.button};
  display: inline-block;
  color: ${colors.slateGray};
  text-decoration: none;
  opacity: ${(x) => (x.disabled ? 0.5 : 1)};
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;

  &:hover {
    color: ${darken(0.1, colors.slateGray)};
  }
`;
