import type {
  ReviewContext,
  Scenario,
  ScenarioIdsByParent,
  TranslationValue,
} from "@gemini/common";
import {
  assert,
  compileMasterTextMarkdown,
  getDeliverableTextSegmentsRecursively,
  getJudgementFromTranslationValue,
} from "@gemini/common";
import {
  AdaptiveCarouselContextProvider,
  BackLink,
  Button,
  MarkdownText,
  SpacedChildren,
  Title,
} from "~/components";
import {
  JudgementDisplay,
  JudgementSummary,
} from "~/features/assessment/components";
import { EmbeddedContent } from "./embedded-content";

export function SummaryDummy({
  translationValue,
  scenarios,
  selectedIdsMap,
  reviewContext,
  onBack,
}: {
  translationValue?: TranslationValue;
  scenarios: Record<string, Scenario>;
  selectedIdsMap: ScenarioIdsByParent;
  reviewContext: ReviewContext;
  onBack: () => void;
}) {
  assert(translationValue, "Can not render Summary without translationValue");

  const judgement = getJudgementFromTranslationValue(translationValue);
  const isIssueResolved = translationValue === "ir";
  const judgementNudgeValue = 0;
  const isManualJudgement = false;

  const masterTextSegments = getDeliverableTextSegmentsRecursively({
    scenarioId: "__root",
    allScenarios: scenarios,
    allSelectedScenarioIds: Object.values(selectedIdsMap).flat(),
    reviewContext,
  });

  const masterText = compileMasterTextMarkdown({
    segments: masterTextSegments,
    judgement,
    judgementNudgeValue,
    isManualJudgement,
  });

  const goBackButton = <BackLink onClick={onBack}>Back to assessment</BackLink>;

  const goToReviewToolButton = (
    <Button
      href={document.location.origin}
      target="_blank"
      rel="noopener noreferrer"
    >
      Go to the Review Tool
    </Button>
  );

  return (
    <EmbeddedContent
      footerLeft={goBackButton}
      footerRight={goToReviewToolButton}
    >
      <SpacedChildren size="lg">
        <JudgementDisplay
          judgement={judgement}
          isIssueResolved={isIssueResolved}
        />

        <div>
          <Title level={5}>Assessment output</Title>
          <JudgementSummary
            calculatedTranslationValue={translationValue}
            judgement={judgement}
            judgementNudgeValue={judgementNudgeValue}
            isManualJudgement={isManualJudgement}
          />
        </div>

        {masterText && (
          <AdaptiveCarouselContextProvider>
            <MarkdownText>{masterText}</MarkdownText>
          </AdaptiveCarouselContextProvider>
        )}
      </SpacedChildren>
    </EmbeddedContent>
  );
}
