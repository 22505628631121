import { transparentize } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import { colors, radii, space } from "~/theme";
import { StyledLabel } from "./scenario-input";

type ContainerProps = {
  level: number;
  selected: boolean;
  disabled: boolean;
  children: React.ReactNode;
};

export function Container({ children, ...props }: ContainerProps) {
  return (
    <StyledContainer {...props}>
      <div css={{ position: "relative" }}>{children}</div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div<ContainerProps>`
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: ${space.md};

  &:last-child {
    margin-bottom: 0;
  }

  ${(x) =>
    x.disabled &&
    css`
      color: ${transparentize(0.5, colors.charcoal)};
      ${StyledLabel} {
        cursor: default;
      }
    `}

  ${(x) =>
    x.level === 0
      ? css`
          border: 1px solid ${x.selected ? colors.indigo : colors.ghostGray};
          border-radius: ${radii.xl};
          padding: ${space.md};
          padding-left: ${space.xl};
        `
      : css`
          padding-left: ${space.lg};
        `}
`;
