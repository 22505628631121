import type { ReviewStructure } from "@gemini/common";
import {
  assert,
  causeOfChangeLabels,
  getPartsFromStructure,
  parseCitationCode,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import {
  BoxWithBorder,
  Button,
  JudgementPill,
  LoadingIndicator,
  Paragraph,
  SpacedChildren,
  Table,
  Text,
  Title,
} from "~/components";
import {
  getAssessmentByReferenceMap,
  useReviewStore,
} from "~/features/reviews";
import { useTypedDocument } from "~/modules/firestore";
import { colors } from "~/theme";
import { WithCitationCodePrefix } from "../../components";
import { useUpdateStore } from "../store";
import { GuidelineTitle } from "./guideline-title";
import { UpdateComplete } from "./update-complete";

export const ConfirmTypeUpdates = observer(() => {
  const router = useRouter();
  const { review, guidelineObservations } = useReviewStore();
  const {
    confirmTypeUpdates,
    confirmTypeJudgements,
    confirmTypeGuidelines,
    confirmTypeAssessments,
    applyConfirmUpdates,
  } = useUpdateStore();
  const [reviewStructure, isLoading] = useTypedDocument<ReviewStructure>(
    "review_structures",
    review.data?.structure_id ?? "__no_structure_id"
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  assert(reviewStructure, "Missing review structure");

  const parts = getPartsFromStructure(reviewStructure.data);

  const reviewId = router.query.reviewId as string;
  const confirmCount = confirmTypeUpdates.length;

  const assessmentByReference = getAssessmentByReferenceMap(
    confirmTypeAssessments
  );

  const sortedGuidelines = confirmTypeGuidelines.sort(
    (a, b) =>
      parseCitationCode(a.data.citation_code)[0] -
      parseCitationCode(b.data.citation_code)[0]
  );

  const title = (
    <Title level={3} as="h2">
      Upgrades
    </Title>
  );

  if (confirmCount === 0) {
    return (
      <BoxWithBorder>
        <SpacedChildren>
          {title}
          <UpdateComplete message="All upgrades have been applied" />
        </SpacedChildren>
      </BoxWithBorder>
    );
  }

  return (
    <BoxWithBorder>
      <SpacedChildren>
        <Title level={3} as="h2">
          Upgrades
        </Title>

        <Paragraph>
          We’ve updated <strong>{confirmTypeUpdates.length}</strong> of our
          guidelines. These won't affect your implementation scenarios but may
          effect your judgements. Please see below.
        </Paragraph>

        <Button onClick={applyConfirmUpdates} variant="secondary">
          Apply All Upgrades
        </Button>

        <Table
          getKey={(x) => x.id}
          data={sortedGuidelines}
          columns={[
            {
              name: "Guideline",
              size: "50%",
              render(guideline) {
                const { reference } = guideline.data;

                const part = parts.find((x) =>
                  x.guideline_references.includes(reference)
                );

                const pathname = part
                  ? `/reviews/${reviewId}/parts/${part.id}/assessments/${reference}/summary`
                  : undefined;

                return (
                  <WithCitationCodePrefix
                    citationCode={guideline.data.citation_code}
                  >
                    <GuidelineTitle
                      title={guideline.data.scenarios.__root.title}
                      pathname={pathname}
                    />
                  </WithCitationCodePrefix>
                );
              },
            },
            {
              name: "Implementation",
              size: "50%",
              render(guideline) {
                const assessment =
                  assessmentByReference[guideline.data.reference];

                const implementation = assessment?.selected_scenario_ids
                  ?.map((id) => guideline.data.scenarios[id]?.title)
                  .join(" • ");

                return <Text size="small">{implementation}</Text>;
              },
            },

            /**
             * Only show the Observation column if the guideline has a
             * comparison document attached.
             */
            review.data?.review_comparison_id
              ? {
                  name: "Observation",
                  render(guideline) {
                    const observation = guidelineObservations?.documents.find(
                      (x) => x.id === guideline.data.reference
                    );

                    if (!observation) {
                      return (
                        <Text size="small" cx={{ color: colors.slateGray }}>
                          —
                        </Text>
                      );
                    }

                    return (
                      <Text size="small">
                        {causeOfChangeLabels[observation.data.cause_of_change]}
                      </Text>
                    );
                  },
                }
              : undefined,
            {
              key: "original-judgement",
              name: (
                <>
                  Original
                  <br />
                  Judgement
                </>
              ),
              render(guideline) {
                const assessment =
                  assessmentByReference[guideline.data.reference];
                return (
                  <JudgementPill
                    value={assessment?.judgement}
                    isIssueResolved={assessment?.is_issue_resolved}
                  />
                );
              },
            },
            {
              key: "updated-judgement",
              name: (
                <>
                  Updated
                  <br />
                  Judgement
                </>
              ),
              render(guideline) {
                const judgement =
                  confirmTypeJudgements[guideline.data.reference];
                const assessment =
                  assessmentByReference[guideline.data.reference];

                return (
                  <JudgementPill
                    value={judgement}
                    isIssueResolved={assessment?.is_issue_resolved}
                  />
                );
              },
            },
          ]}
        />
      </SpacedChildren>
    </BoxWithBorder>
  );
});
