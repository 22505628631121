import React from "react";
import styled from "styled-components";
import { colors, mediaQueries, space } from "~/theme";

export type FooterProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
};

export function Footer({ left, right }: FooterProps) {
  return (
    <StyledFooter>
      <div>{left}</div>
      <div>{right}</div>
    </StyledFooter>
  );
}

const StyledFooter = styled.div`
  border-top: 1px solid ${colors.athensGray};
  background: white;
  padding: ${space.md};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${mediaQueries.md} {
    padding: ${space.md} ${space.lg};
  }
`;
