import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import styled from "styled-components";
import { Sidebar } from "~/components";
import { usePartStore, useReviewStore } from "~/features/reviews";
import { JumpToGuideline } from "~/features/reviews/components";
import { useAuth } from "~/modules/auth";
import { mediaQueries, space } from "~/theme";
import { AuditDeliveryStatus } from "../reviews/components/audit-delivery-status";
import { GuidelineNavigation, PartProgress } from "./components";

export const AssessmentSidebar = observer(() => {
  const { claims } = useAuth();
  const router = useRouter();
  const reviewStore = useReviewStore();
  const partStore = usePartStore();

  const { progress, part, navigation } = partStore;
  const { review, isDelivered } = reviewStore;

  const reviewId = router.query.reviewId as string;
  const partId = router.query.partId as string;

  const breadcrumbs = [
    {
      href: "/reviews",
      name: "Reviews",
    },
    {
      href: `/reviews/${reviewId}`,
      name: review.data?.title || "__no_review_title",
    },
    {
      href: `/reviews/${reviewId}/parts/${partId}`,
      name: part?.title || "__no_part_title",
    },
  ];

  const showAuditDeliveryInfo = claims.isAdmin && isDelivered;

  return (
    <Sidebar
      variant={review.data?.type === "case-study" ? "secondary" : "primary"}
      breadcrumbs={breadcrumbs}
    >
      {showAuditDeliveryInfo && <AuditDeliveryStatus />}
      <PartsContainer>
        <Sidebar.Part area="guidelines">
          <GuidelineNavigation {...navigation} />
        </Sidebar.Part>

        <Sidebar.Part area="partProgress">
          <PartProgress percentage={progress.percentage} />
        </Sidebar.Part>

        <Sidebar.Part area="text">
          <Sidebar.Anchor href={`/reviews/${reviewId}/parts/${partId}`}>
            Topic overview
          </Sidebar.Anchor>

          <br />
          <JumpToGuideline>
            {(onClick) => (
              <Sidebar.AnchorButton onClick={onClick}>
                Jump to guideline or topic&hellip;
              </Sidebar.AnchorButton>
            )}
          </JumpToGuideline>
        </Sidebar.Part>
      </PartsContainer>
    </Sidebar>
  );
});

const PartsContainer = styled.div`
  display: grid;
  grid-template-areas:
    "guidelines"
    "partProgress"
    "text"
    "jumpToLink";

  grid-column-gap: ${space.lg};
  grid-row-gap: ${space.md};

  @media ${mediaQueries.sm} {
    grid-template-columns: 1fr 1fr 1px 2fr;
    grid-template-areas:
      "guidelines partProgress separator text"
      "button     .             separator jumpToLink";
  }

  @media ${mediaQueries.md} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "guidelines"
      "partProgress"
      "text"
      "jumpToLink";
  }
`;
