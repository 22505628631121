import type {
  CloneGuidelinePayload,
  CreateReviewStructurePayload,
  FindReviewStructuresPayload,
  FindReviewStructuresResult,
} from "@gemini/common";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

/**
 * @todo Remove all of these and use approach like in the api file without
 *   wrappers
 */
const httpsCloneGuideline = httpsCallable<CloneGuidelinePayload, void>(
  functions,
  "cloneGuideline"
);

export function callCloneGuideline(
  srcGuidelineId: string,
  dstGuidelineId: string
) {
  return httpsCloneGuideline({
    srcGuidelineId,
    dstGuidelineId,
  });
}

const httpsPublishVersion = httpsCallable(functions, "publishVersion");

export function callPublishVersion(guidelineId: string) {
  return httpsPublishVersion({
    id: guidelineId,
  });
}

const httpsUnPublishVersion = httpsCallable(functions, "unPublishVersion");

export function callUnPublishVersion(guidelineId: string) {
  return httpsUnPublishVersion({
    id: guidelineId,
  });
}

const httpsCreateReviewStructure = httpsCallable(
  functions,
  "createReviewStructure"
);

export async function callCreateReviewStructure(
  payload: CreateReviewStructurePayload
) {
  const result = await httpsCreateReviewStructure(payload);
  return result.data as { structure_id: string };
}

const httpsFindReviewStructures = httpsCallable(
  functions,
  "findReviewStructures"
);

export async function callFindReviewStructures(
  payload: FindReviewStructuresPayload
) {
  const result = await httpsFindReviewStructures(payload);

  return result.data as FindReviewStructuresResult;
}
