import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { copyScenarioBranchToClipboard } from "~/features/admin/logic/clipboard";
import { useHotkey } from "~/modules/hooks";
import { colors, fontSizes, fonts } from "~/theme";
import { useEditStore } from "../store";

export const CopyToClipboardButton = observer(
  ({ scenarioId }: { scenarioId: string }) => {
    const store = useEditStore();
    const isFocused = store.focusedScenarioId === scenarioId;

    const copyScenarioToClipboard = () => {
      copyScenarioBranchToClipboard(store.scenarios, scenarioId);
    };

    useHotkey("command+c", copyScenarioToClipboard, {
      disabled: !isFocused,
      disableTextInputs: true,
    });

    return (
      <Button size="small" onClick={copyScenarioToClipboard}>
        <span
          css={{
            fontFamily: fonts.monospace,
            fontSize: fontSizes.xxs,
            color: colors.grayDark,
          }}
        >
          {scenarioId}
        </span>
      </Button>
    );
  }
);
