import Head from "next/head";
import { isProduction, phase } from "~/config";

const dir = "/__public/favicon";

export function DocumentHead({ title }: { title?: string }) {
  return (
    <>
      <Head>
        <title>{createTitle(title)}</title>
        <link rel="stylesheet" href="https://use.typekit.net/bcc3nll.css" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${dir}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${dir}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${dir}/favicon-16x16.png`}
        />
      </Head>
    </>
  );
}

function createTitle(title = "") {
  const phasePrefix = isProduction ? "" : `[${phase}]`;
  const titleWithSeparator = title ? title + " - " : "";
  const fullTitle = `${phasePrefix} ${titleWithSeparator} Review Tool - Baymard Premium`;
  return fullTitle.replace(/  +/g, " ").trim();
}
