import type {
  Scenario as ScenarioSchema,
  TranslationValue,
} from "@gemini/common";
import { isPointScore } from "@gemini/common";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled, { css } from "styled-components";
import { isDefined } from "ts-is-present";
import { Checkbox } from "~/components/checkbox";
import { PointScoreTag } from "~/components/point-score-tag";
import { Radio } from "~/components/radio";
import { SpacedChildren } from "~/components/spaced-children";
import { TranslationValueTag } from "~/components/translation-value-tag";
import { preset, space } from "~/theme";
import { CopyScenarioIdButton } from "./copy-scenario-id-button";

type InputProps = {
  label: string;
  id: string;
  logic: ScenarioSchema["logic"];
  disabled: boolean;
  readOnly: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  noMargin?: boolean;
  name: string;
  level: number;
  valueOrPointScore: TranslationValue | number | undefined;
  showScenarioId: boolean;
  testingName?: string;
};

export const ScenarioInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      logic,
      label,
      id,
      noMargin,
      level,
      valueOrPointScore,
      showScenarioId,
      readOnly,
      ...inputProps
    }: InputProps,
    ref
  ) => {
    const input =
      logic === "and" || logic === "points" ? (
        <Checkbox ref={ref} {...inputProps} disabled={readOnly} />
      ) : (
        <Radio ref={ref} {...inputProps} disabled={readOnly} />
      );

    return (
      <StyledLabel noMargin={noMargin}>
        <StyledInput level={level}>{input}</StyledInput>
        <SpacedChildren
          as="span"
          horizontal
          css={css`
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: baseline;
            position: relative;
          `}
        >
          <span css={[preset.typography.h5, { paddingRight: space.md }]}>
            {label} {showScenarioId && <CopyScenarioIdButton id={id} />}
          </span>
          <AnimatePresence>
            {isDefined(valueOrPointScore) && (
              <motion.span
                initial="hide"
                exit="hide"
                animate="show"
                variants={{
                  hide: { opacity: 0 },
                  show: { opacity: 1 },
                }}
                style={{
                  display: "block",
                  position: "absolute",
                  right: 0,
                  top: -4,
                }}
              >
                {isPointScore(valueOrPointScore) ? (
                  <PointScoreTag value={valueOrPointScore} />
                ) : (
                  <TranslationValueTag value={valueOrPointScore} />
                )}
              </motion.span>
            )}
          </AnimatePresence>
        </SpacedChildren>
      </StyledLabel>
    );
  }
);

ScenarioInput.displayName = "ScenarioInput";

export const StyledLabel = styled.label<{ noMargin?: boolean }>`
  padding: ${space.xs} 0;
  &:not(:has(:disabled)) {
    cursor: pointer;
  }

  display: flex;
  align-items: baseline;
  margin-bottom: ${(x) => (x.noMargin ? undefined : space.sm)};
`;

export const StyledInput = styled.div<{ level: number }>`
  display: flex;
  justify-content: center;
  position: absolute;
  left: ${(x) => (x.level === 0 ? "-42px" : "-32px")};
  /**
   * We apply some right padding to have a clickable space between the input
   * and the label.
   */
  padding-right: 32px;
`;
