import type { ScenarioIdsByParent, ScenarioMap } from "../schemas";

export function deriveSelectedIdsMap(
  scenarioMap: ScenarioMap,
  selectedIds: string[]
): ScenarioIdsByParent {
  return selectedIds.reduce<ScenarioIdsByParent>((acc, id) => {
    const scenario = scenarioMap[id];

    if (!scenario) {
      // This is possible when we start deleting scenarios in the admin and
      // the selected ids data is stale.
      return acc;
    }

    const otherIds = acc[scenario.parent_id] ?? [];
    acc[scenario.parent_id] = [...otherIds, scenario.id];

    return acc;
  }, {});
}
