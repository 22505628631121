import React from "react";

type Options = {
  disabled?: boolean;
};

export function useFormValue<T extends string | undefined>(
  initialValue: T,
  { disabled }: Options = {}
) {
  const [value, setValue] = React.useState(initialValue);
  const onChange = React.useCallback(
    (value: T) => {
      if (!disabled) {
        setValue(value);
      }
    },
    [disabled]
  );

  const inputProps = {
    value,
    onChange,
    disabled,
  };

  return {
    value,
    inputProps,
  };
}

export function useFormBooleanValue<T extends boolean>(
  initialValue: T,
  { disabled }: Options = {}
) {
  const [value, setValue] = React.useState(initialValue);
  const onChange = React.useCallback(
    (value: T) => !disabled && setValue(value),
    [disabled]
  );

  const inputProps = {
    checked: value,
    onChange,
    disabled,
  };

  return {
    value,
    inputProps,
  };
}

export function useFormValueArray<T extends string>(
  initialValues: T[],
  allValues: T[],
  { disabled }: Options = {}
) {
  const [values, setValues] = React.useState(initialValues);
  const onChange = React.useCallback(
    (value: T, checked: boolean) => {
      if (!disabled) {
        checked
          ? setValues(values.concat(value))
          : setValues(values.filter((x) => x !== value));
      }
    },
    [values, disabled]
  );

  const inputs = allValues.map((value) => ({
    value,
    checked: values.includes(value),
    onChange: (checked: boolean) => onChange(value, checked),
    disabled,
  }));

  return { value: values, inputs };
}
