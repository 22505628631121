/**
 * The review store is mainly responsible for keeping the review data in memory
 * over different pages and part navigation.
 *
 * Everything related to part navigation and guidelines is located in the part
 * store.
 *
 * Everything related to making the actual assessment is located in the
 * assessment store.
 */
import type {
  Assessment,
  Dictionary,
  GetReviewStructureGuidelineStatesResult,
  GuidelineLevelComparison,
  GuidelineObservation,
  Review,
  ReviewComparison,
  ReviewStructure,
  ReviewStructureGroup,
  ReviewStructurePart,
} from "@gemini/common";
import {
  assert,
  getGroupsFromStructure,
  getPartsFromStructure,
} from "@gemini/common";
import autoBind from "auto-bind";
import { collection } from "firebase/firestore";
import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import { getReviewStructureGuidelineStates } from "~/modules/api";
import { db } from "~/modules/firebase";
import {
  ObservableCollection,
  ObservableDocument,
} from "~/modules/firestore-mobx";
import { notify } from "~/modules/notifications";
import type { GuidelineHit } from "../filter-guidelines/logic";
import { createGuidelineHits } from "../filter-guidelines/logic";

export class ReviewStore {
  review = new ObservableDocument<Review>(collection(db, "reviews"));

  structure = new ObservableDocument<ReviewStructure>(
    collection(db, "review_structures")
  );

  assessments = new ObservableCollection<Assessment>();

  isFetchingGuidelineStates = false;
  guidelineStatesByReference: GetReviewStructureGuidelineStatesResult = {};

  /**
   * ObservableDocument can be empty, meaning `.data` can be undefined, but the
   * type definition does not reflect this (e.g. it won't error when calling
   * `reviewComparison.data.foo`, when data is undefined). This was by design
   * but something we should consider changing.
   *
   * Linear ticket GEM-195
   */

  reviewComparison = new ObservableDocument<ReviewComparison>(
    collection(db, "review_comparisons")
  );

  /**
   * @todo For the multi-review comparison we will have more comparison reviews
   *   and assessments, and results, so we might need to make these a record or
   *   array, and possibly move everything to a comparisonStore.
   */
  comparisonReview = new ObservableDocument<Review>(collection(db, "reviews"));
  comparisonAssessments = new ObservableCollection<Assessment>();
  comparisonResults = new ObservableCollection<GuidelineLevelComparison>();

  guidelineObservations = new ObservableCollection<GuidelineObservation>(
    undefined,
    (query) => query // fetch get all documents
  );

  constructor(reviewId: string) {
    console.log(`[review store] constructor for ${reviewId}`);
    autoBind(this);

    makeAutoObservable(this);

    this.review.attachTo(reviewId);

    /**
     * We can only load the structure when the review data has finished loading,
     * and for this we can use the onData callback which triggers on every data
     * change. As a result a different structure will be loaded whenever the
     * review structure_id changes.
     *
     * When attachTo is called multiple times with the same id it has no effect,
     * so other data changes in the review document will not affect the loading
     * state.
     */
    this.review.onData((data) => {
      this.structure.attachTo(data.structure_id);

      if (data.review_comparison_id) {
        this.reviewComparison.attachTo(data.review_comparison_id);
        this.guidelineObservations.attachTo(
          collection(
            db,
            "review_comparisons",
            data.review_comparison_id,
            "guideline_observations"
          )
        );
      } else {
        this.reviewComparison.attachTo(undefined);
        this.guidelineObservations.attachTo(undefined);
      }

      runInAction(() => {
        this.isFetchingGuidelineStates = true;
      });

      getReviewStructureGuidelineStates({ structureId: data.structure_id })
        .then((result) => {
          runInAction(() => (this.guidelineStatesByReference = result.data));
        })
        .catch((err) => notify.error(err))
        .finally(() =>
          runInAction(() => (this.isFetchingGuidelineStates = false))
        );
    });

    this.reviewComparison.onData((data) => {
      /**
       * Assessment flow comparison documents only have one comparison review,
       * so we can safely assume the first one is the one we want.
       */
      const comparisonReviewId = data.comparison_review_ids.at(0);
      assert(comparisonReviewId, "Missing comparison review id");

      this.comparisonReview.attachTo(comparisonReviewId);
      this.comparisonAssessments.attachTo(
        collection(db, "reviews", comparisonReviewId, "assessments")
      );

      assert(
        this.review.data?.review_comparison_id,
        "Expected review comparison id to be set"
      );

      this.comparisonResults.attachTo(
        collection(
          db,
          "review_comparisons",
          this.review.data.review_comparison_id,
          "results_per_review",
          comparisonReviewId,
          "results_per_assessment"
        )
      );
    });

    this.assessments.attachTo(
      collection(db, "reviews", reviewId, "assessments")
    );
  }

  get isLoading() {
    return (
      this.review.isLoading ||
      this.assessments.isLoading ||
      this.structure.isLoading ||
      this.reviewComparison.isLoading ||
      this.comparisonReview.isLoading
    );
  }

  get title() {
    return this.review.data?.title;
  }

  get progress() {
    return this.review.data?.progress;
  }

  get groupsFromStructure() {
    assert(this.structure.data, "Cannot get groups without structure");

    return getGroupsFromStructure(this.structure.data).sort(
      (a, b) => a.position - b.position
    );
  }

  get partsFromStructure(): ReviewStructurePart[] {
    assert(this.structure.data, "Cannot get parts without structure");

    return getPartsFromStructure(this.structure.data).sort(
      (a, b) => a.position - b.position
    );
  }

  getChildrenFromStructureGroup(groupId: string, isRecursive?: boolean) {
    let children: (ReviewStructureGroup | ReviewStructurePart)[] = [
      ...this.groupsFromStructure.filter((x) => x.parent_group_id === groupId),
      ...this.partsFromStructure.filter((x) => x.parent_group_id === groupId),
    ];

    if (isRecursive) {
      children = children.reduce(
        (acc, cur) => {
          acc.push(cur);
          acc.push(...this.getChildrenFromStructureGroup(cur.id, true));
          return acc;
        },
        [] as (ReviewStructureGroup | ReviewStructurePart)[]
      );
    }

    return children.sort((a, b) => a.position - b.position);
  }

  get partIdByGuidelineReference() {
    return this.partsFromStructure.reduce((result, part) => {
      part.guideline_references.forEach((reference) => {
        result[reference] = part.id;
      });
      return result;
    }, {} as Dictionary<string>);
  }

  /**
   * @todo The term "hit" is maybe a little confusing. I think a hit typically
   *   means a match that you get out of a search query. In this case hits seems
   *   to refer to the complete collection of data that is searchable.
   */
  get guidelineSearchHits(): GuidelineHit[] {
    if (
      this.isFetchingGuidelineStates ||
      this.guidelineObservations.isLoading
    ) {
      return [];
    }

    const hits = createGuidelineHits({
      stateByReference: this.guidelineStatesByReference,
      assessments: this.assessments.documents,
      structure: this.structure.document,
      reviewId: this.review.id,
      observations: this.guidelineObservations?.documents,
    });

    /**
     * Some view structures contain duplicate guidelines, we'll dedupe them. see
     * https://baymard.slack.com/archives/CKJM9EMQW/p1654699035093359
     */
    return hits.filter(
      (x, i) => hits.findIndex((y) => y.reference === x.reference) === i
    );
  }

  get isDelivered() {
    return Boolean(this.review.data?.audit_delivery);
  }

  /**
   * An audit is redeliverable if it was delivered less than a month ago. A cron
   * job will mark reviews as read-only after a month, but to be sure we also
   * check the timestamps here.
   */
  get isRedeliverable() {
    if (this.review.data?.is_read_only) {
      return false;
    }

    if (!this.review.data?.audit_delivery) {
      return false;
    }

    const deliveredAtTimestamp =
      this.review.data.audit_delivery.delivered_at.toMillis();
    const oneMonthAgo = moment().subtract(1, "months").toDate().getTime();

    return deliveredAtTimestamp > oneMonthAgo;
  }

  get isDeliverableOutdated() {
    return this.review.data?.audit_delivery?.is_outdated ?? false;
  }
}
