import type { Assessment, ScenarioMap } from "@gemini/common";
import { observer } from "mobx-react-lite";
import type { ReactNode } from "react";
import {
  AdaptiveCarouselContextProvider,
  AsideBox,
  Icon,
  JudgementPill,
  LineBreak,
  LoadingIndicator,
  NotYetRatedPill,
  SpacedChildren,
  Title,
  Truncate,
  WordBreak,
} from "~/components";
import { StatusCircle } from "~/components/status-circle";
import { TextButton } from "~/components/text-button";
import { WithTooltip } from "~/components/with-tooltip";
import { useAuth } from "~/modules/auth";
import { copyTextToClipboard } from "~/modules/clipboard";
import { getImageSourceFromPath, imageSourcePresets } from "~/modules/image";
import { colors, space } from "~/theme";
import { AssessmentSelectionList } from "../../assessment-selection-list";
import { ImageFieldThumbnail } from "../../image-field-thumbnail";

export function LoadingCompareView() {
  return (
    <AsideBox>
      <LoadingIndicator />
    </AsideBox>
  );
}

/**
 * Used in the aside for rendering both the counterpart and comparison
 * assessments.
 */
export const AssessmentSummary = observer(function AssessmentSummary({
  title,
  titleTooltipContent,
  scenarios,
  showJudgement,
  assessment,
}: {
  title: ReactNode;
  titleTooltipContent?: ReactNode;
  scenarios: ScenarioMap;
  showJudgement: boolean;
  assessment?: Assessment;
}) {
  const claims = useAuth().claims;
  const titleEl = (
    <SectionTitle>
      {title}

      {titleTooltipContent && (
        <WithTooltip content={titleTooltipContent}>
          <span tabIndex={-1}>
            <Icon
              name="info"
              size={16}
              cx={{ marginLeft: 5, position: "relative", top: 2 }}
            />
          </span>
        </WithTooltip>
      )}
    </SectionTitle>
  );

  return (
    <>
      {assessment ? (
        <SpacedChildren size="md">
          <div>{titleEl}</div>

          <div
            /**
             * Negative margin to correct the AssessmentSelectionList's
             * outside-margin
             */
            css={{ marginBottom: "-0.8rem" }}
          >
            <AssessmentSelectionList
              scenarios={scenarios}
              selectedIds={assessment.selected_scenario_ids}
              isManualJudgement={assessment.is_manual_judgement}
            />
          </div>

          {showJudgement && (
            <div>
              <SectionTitle>Overall rating</SectionTitle>
              <div>
                <JudgementPill
                  value={assessment.judgement}
                  isIssueResolved={assessment.is_issue_resolved}
                />
              </div>
            </div>
          )}

          <div>
            <SectionTitle>
              {claims.isAdmin
                ? "Customer-facing notes"
                : "Notes for you and your team"}

              {claims.isAdmin && assessment.comment && (
                <CopyButton text={assessment.comment} />
              )}
            </SectionTitle>

            {assessment.comment ? (
              <CommentSection comment={assessment.comment} />
            ) : (
              <em>
                {claims.isAdmin
                  ? "No customer-facing notes"
                  : "There are no notes"}
              </em>
            )}
          </div>

          {assessment.images && assessment.images.length > 0 && (
            <div>
              <SectionTitle>Screenshots</SectionTitle>
              <ScreenshotsSection images={assessment.images} />
            </div>
          )}

          {claims.isAdmin && (
            <div>
              <SectionTitle>
                Internal notes (for Baymard only)
                {claims.isAdmin && assessment.internal_comment && (
                  <CopyButton text={assessment.internal_comment} />
                )}
              </SectionTitle>
              <SpacedChildren horizontal css={{ marginBottom: space.xs }}>
                <StatusCircle active={assessment.is_marked_for_suggestion}>
                  suggestion
                </StatusCircle>
                <StatusCircle active={assessment.is_marked_for_discussion}>
                  discussion
                </StatusCircle>
              </SpacedChildren>

              {assessment.internal_comment ? (
                <CommentSection comment={assessment.internal_comment} />
              ) : (
                <em>no internal notes</em>
              )}
            </div>
          )}
        </SpacedChildren>
      ) : (
        <div>
          {title} <NotYetRatedPill highContrast />
        </div>
      )}
    </>
  );
});

export function SectionTitle({ children }: { children: ReactNode }) {
  return (
    <Title level={5} variant="overline" noMargin cx={{ textWrap: "pretty" }}>
      {children}
    </Title>
  );
}

function CopyButton({ text }: { text: string }) {
  return (
    <TextButton
      css={{
        display: "inline-block",
        marginLeft: space.sm,
        textDecoration: "underline",
        color: colors.slateGray,
      }}
      onClick={() =>
        copyTextToClipboard(
          text,
          `Copied "${text.slice(0, 18)}..." to clipboard`
        )
      }
    >
      copy
    </TextButton>
  );
}

function ScreenshotsSection({ images }: { images: string[] }) {
  return (
    <AdaptiveCarouselContextProvider>
      <div
        css={{ display: "flex", flexWrap: "wrap", margin: `0 -${space.xs}` }}
      >
        {images.map((src) => (
          <ImageFieldThumbnail
            thumbnailSize="4rem"
            src={getImageSourceFromPath(src)}
            srcThumb={getImageSourceFromPath(src, imageSourcePresets.thumbnail)}
            key={src}
          />
        ))}
      </div>
    </AdaptiveCarouselContextProvider>
  );
}

function CommentSection({ comment }: { comment: string }) {
  return (
    <Truncate lines={2}>
      {(isTruncated) =>
        isTruncated ? (
          comment
        ) : (
          <WordBreak>
            <LineBreak>{comment.trim()}</LineBreak>
          </WordBreak>
        )
      }
    </Truncate>
  );
}
