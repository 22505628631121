import type { ScenarioIdsByParent } from "@gemini/common";
import {
  assert,
  calculateTranslationValueRecursively,
  updateScenarioSelectionInPlace,
} from "@gemini/common";
import { usePubsubChannel } from "@klaasman/pubsub-channel";
import { observer } from "mobx-react-lite";
import React from "react";
import { LoadingIndicator, MeasureHeight } from "~/components";
import { AssessDummy, SummaryDummy } from "./components";
import { useAssessmentQueryParameters } from "./logic/use-assessment-query-parameters";
import { useGuidelineByReference } from "./logic/use-guideline-by-reference";
import { embeddedPubsubChannels } from "./pubsub";

/**
 * This is a dummy version of the assessment page that is used for the embedded
 * version of the review app. It is not connected to the assessment store and
 * does not have any functionality besides showing the assessment scenario tree
 * and the summaries/judgement of the selected scenarios.
 */
export const EmbeddedDummyAssessmentPage = observer(
  function EmbeddedDummyAssessmentPage() {
    const { reference, industryId, tagIds } = useAssessmentQueryParameters();
    const [guideline, isLoadingGuideline] = useGuidelineByReference(reference);
    const { selectedIds, updateSelectedIds } = useSelectedIds();

    const [page, setPage] = usePubsubChannel(
      embeddedPubsubChannels.pageType,
      "assess"
    );

    if (isLoadingGuideline) {
      return <LoadingIndicator />;
    }

    assert(guideline, `Could not find guideline with reference ${reference}`);

    const calculationResult = calculateTranslationValueRecursively({
      allScenarios: guideline.data.scenarios,
      allSelectedScenarioIds: Object.values(selectedIds).flat(),
      reviewContext: { industryId, tagIds },
    });

    return (
      <MeasureHeight
        onChange={(height) =>
          embeddedPubsubChannels.height.publish(height, window.parent)
        }
      >
        {page === "summary" ? (
          <SummaryDummy
            translationValue={calculationResult.value}
            onBack={() => setPage("assess", window.parent)}
            scenarios={guideline.data.scenarios}
            selectedIdsMap={selectedIds}
            reviewContext={{
              industryId,
              tagIds,
            }}
          />
        ) : (
          <AssessDummy
            onSubmit={() => setPage("summary", window.parent)}
            guideline={guideline}
            validationErrors={calculationResult.validationErrors}
            industryId={industryId}
            tagIds={tagIds}
            onChange={updateSelectedIds}
            selectedIds={selectedIds}
          />
        )}
      </MeasureHeight>
    );
  }
);

function useSelectedIds() {
  const [selectedIds, setSelectedIds] = React.useState<ScenarioIdsByParent>({});

  const updateSelectedIds = React.useCallback(
    (parentId: string, values?: string[] | undefined) => {
      setSelectedIds((selectedIds) => {
        const clonedIds = structuredClone(selectedIds);
        updateScenarioSelectionInPlace(clonedIds, parentId, values);

        return clonedIds;
      });
    },
    []
  );

  return {
    selectedIds,
    updateSelectedIds,
  };
}
