import type { CropConfig, ImageUpload } from "@gemini/common";
import { observer } from "mobx-react-lite";
import { transparentize } from "polished";
import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import {
  AdaptiveCarouselContextProvider,
  FileInput,
  ModalBare,
  Reorderable,
  SpacedChildren,
  Text,
} from "~/components";
import { readFileIntoMemory } from "~/modules/file";
import { useOnClipboardPaste, useOnFileDrop } from "~/modules/hooks";
import type { ImageSourceOptions } from "~/modules/image";
import { getImageSourceFromPath, imageSourcePresets } from "~/modules/image";
import { notify } from "~/modules/notifications";
import { colors, space } from "~/theme";
import { wrapBackgroundTask } from "~/utils/wrap-background-task";
import { ImageFieldThumbnail } from "./image-field-thumbnail";
import type { CropRegionsConfig } from "./select-crop-regions";
import {
  SelectCropRegions,
  getCropRegionsConfigForFile,
} from "./select-crop-regions";

const validateImageFile = (file: File) => file.type.startsWith("image/");

type ImageFieldProps = {
  disabled: boolean;
  imageUploads: ImageUpload[];
  onAdd: (files: File[], cropConfigs?: (CropConfig | undefined)[]) => void;
  onRemove: (item: ImageUpload) => void;
  onReorder: (imageUploads: ImageUpload[]) => void;
  label: string;
  extraLabel?: string;
  allowScreenshotCropping?: boolean;
};

export const ImageField = observer(
  ({
    disabled,
    label,
    extraLabel,
    imageUploads,
    onAdd,
    onRemove,
    onReorder,
    allowScreenshotCropping,
  }: ImageFieldProps) => {
    const [cropOptions, setCropOptions] = useState<{
      file: File;
      regionsConfig: CropRegionsConfig;
    }>();

    const handleNewFiles = useCallback(
      async (files: File[]) => {
        if (disabled) {
          return;
        }

        /**
         * A File instance will become useless when the "physical" file is
         * removed from the file system. This is problematic for file uploads.
         * To fix this issue, the file is read into memory as soon as possible.
         */
        const filesInMemory = await Promise.all(
          files.map((file) => readFileIntoMemory(file))
        );

        /** Skip cropping if disabled. */
        if (!allowScreenshotCropping) {
          return onAdd(filesInMemory);
        }

        /**
         * If there's already an image to crop, we will uploaded that one
         * uncropped. This is by design and allows users to quickly upload
         * multiple screenshots after each other.
         */
        if (cropOptions) {
          onAdd([cropOptions.file]);
          setCropOptions(undefined);

          notify.info({
            message: "Screenshot cropping",
            description: (
              <>
                Your screenshot is uploaded <strong>uncropped</strong>.
              </>
            ),
          });
        }

        /**
         * When more than one image is added at once, we will not crop any of
         * them.
         */
        if (filesInMemory.length !== 1) {
          return onAdd(filesInMemory);
        }

        const uncroppedFile = filesInMemory[0];

        try {
          /**
           * Get crop regions config for file. Possibly undefined if the given
           * image does not match certain conditions.
           */
          const regionsConfig =
            await getCropRegionsConfigForFile(uncroppedFile);

          if (regionsConfig) {
            setCropOptions({ file: uncroppedFile, regionsConfig });
            return;
          }
        } catch (error) {
          /**
           * If we fail to get crop regions config for the file, we'll just
           * upload it as is after this catch block.
           */
        }

        /** When the above all fails perform a regular upload without cropping. */
        onAdd(filesInMemory);
      },
      [disabled, allowScreenshotCropping, cropOptions, onAdd]
    );

    const isDragging = useOnFileDrop(
      (files) => wrapBackgroundTask(handleNewFiles(files)),
      validateImageFile
    );
    const getImageSource = useImageUploadCache();
    useOnClipboardPaste(
      (files) => wrapBackgroundTask(handleNewFiles(files)),
      validateImageFile
    );

    return (
      <SpacedChildren size="sm">
        {isDragging && !disabled && (
          <DragLayer>
            Drag and drop screenshots of the website UI to upload them as
            evidence for your guideline assessment.
            <br />
            <br />
            (to upload files, like a video recording, instead click the “upload
            file” link)
          </DragLayer>
        )}

        <div css={disabled ? { cursur: "default", opacity: 0.75 } : undefined}>
          <FileInput
            acceptFileTypes="image/*"
            onChange={(files) => wrapBackgroundTask(handleNewFiles(files))}
            label={label}
            disabled={disabled}
          />
          {extraLabel && <Text size="small">{extraLabel}</Text>}
        </div>

        <AdaptiveCarouselContextProvider>
          <ImageGrid>
            {disabled ? (
              <>
                {imageUploads.map((imageUpload) => (
                  <ImageFieldThumbnail
                    key={imageUpload.path}
                    src={getImageSource(imageUpload)}
                    srcThumb={getImageSource(
                      imageUpload,
                      imageSourcePresets.thumbnail
                    )}
                  />
                ))}
              </>
            ) : (
              <Reorderable
                items={imageUploads}
                onReorder={onReorder}
                getId={(x) => x.path}
                renderItem={(imageUpload) => (
                  <ImageFieldThumbnail
                    key={imageUpload.path}
                    src={getImageSource(imageUpload)}
                    srcThumb={getImageSource(
                      imageUpload,
                      imageSourcePresets.thumbnail
                    )}
                    uploadProgress={disabled ? undefined : imageUpload.progress}
                    onRemove={
                      disabled ? undefined : () => onRemove(imageUpload)
                    }
                  />
                )}
              />
            )}
          </ImageGrid>
        </AdaptiveCarouselContextProvider>

        <ModalBare
          isOpen={!!cropOptions}
          onClose={() => {
            setCropOptions(undefined);
            notify.info("Cropping has been aborted.");
          }}
        >
          {cropOptions && (
            <SelectCropRegions
              image={cropOptions.file}
              regionsConfig={cropOptions.regionsConfig}
              onSelect={(cropConfig) => {
                /**
                 * CropConfig can be `undefined` when the user chooses not to
                 * crop the image. We can still pass an undefined cropConfig to
                 * the onAdd - it will be ignored and processed as a regular
                 * image upload.
                 */
                onAdd([cropOptions.file], [cropConfig]);
                setCropOptions(undefined);
              }}
            />
          )}
        </ModalBare>
      </SpacedChildren>
    );
  }
);

const ImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${space.xs};
`;

const DragLayer = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${transparentize(0.13, colors.blueZodiac)};
  color: white;
  padding: ${space.xl};
`;

function useImageUploadCache() {
  const cache = useRef(new Map<string, string>());

  return useCallback(
    (imageUpload: ImageUpload, options?: ImageSourceOptions) => {
      const { path, objectUrl } = imageUpload;

      if (objectUrl) {
        /**
         * While the object is still uploading return the objectUrl as the image
         * source and store it in the cache so that we can find it afterwards
         * based on path.
         */
        cache.current.set(path, objectUrl);
        return objectUrl;
      } else {
        const objectUrl = cache.current.get(path);
        return objectUrl || getImageSourceFromPath(path, options);
      }
    },
    [cache]
  );
}
