import type { Guideline } from "@gemini/common";
import { useTypedDocumentOnce } from "~/modules/firestore";
import { useAppStore } from "~/store";

export function useGuidelineByReference(reference: string) {
  const appStore = useAppStore();
  const guidelineId = appStore.getGuidelineIdForReference(reference);

  const [guideline, isLoadingGuideline] = useTypedDocumentOnce<Guideline>(
    "guidelines",
    guidelineId
  );

  const isLoading = appStore.isLoading || isLoadingGuideline;

  return [guideline, isLoading] as const;
}
