import type { Scenario, ScenarioLogic } from "@gemini/common";
import React from "react";
import { useAuth } from "~/modules/auth";
import { Container, MarkdownWrapper, ScenarioInput } from ".";
import { useBranchContext } from "../context";
import { useChildScenarios } from "../hooks";
import { ScenarioContent } from "./scenario-content";

type ScenarioProps = {
  onChange: (checked: boolean, id: string) => void;
  level: number;
  parentId: string;
  logic: ScenarioLogic | undefined;
  scenario: Scenario;
  selectedIds: string[];
  children: React.ReactNode;
  testingName?: string;
};

export function Scenario({
  onChange,
  parentId,
  level,
  logic,
  scenario,
  selectedIds,
  children,
  testingName,
}: ScenarioProps) {
  const { claims } = useAuth();
  const { isDisabled, isExpanded, readOnly } = useBranchContext();
  const hasChildScenarios = useChildScenarios(scenario.id).length > 0;
  const isSelected = selectedIds.includes(scenario.id);
  const showValue = !!claims.isAdmin;
  const showScenarioId = !!claims.isAdmin;

  return (
    <Container level={level} selected={isSelected} disabled={isDisabled}>
      <ScenarioInput
        name={`level-${parentId}-${level}`}
        testingName={testingName}
        label={scenario.title}
        id={scenario.id}
        logic={logic}
        disabled={isDisabled}
        readOnly={readOnly}
        checked={isSelected}
        valueOrPointScore={
          showValue
            ? logic === "points"
              ? scenario.point_score
              : scenario.translation_value
            : undefined
        }
        onChange={(checked: boolean) => onChange(checked, scenario.id)}
        noMargin={!scenario.description}
        level={level}
        showScenarioId={showScenarioId}
      />

      <MarkdownWrapper noMargin sources={[scenario.description]} />

      {hasChildScenarios && (
        <ScenarioContent scenario={scenario} expand={isExpanded || isSelected}>
          {children}
        </ScenarioContent>
      )}
    </Container>
  );
}
