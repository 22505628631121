import useFocusTrap from "@charlietango/use-focus-trap";
import { assert } from "@gemini/common";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { transparentize } from "polished";
import type { MouseEvent } from "react";
import React from "react";
import ReactDOM from "react-dom";
import styled, { createGlobalStyle, css } from "styled-components";
import { useHotkey } from "~/modules/hooks";
import { colors, radii, space, zIndices } from "~/theme";
import { Icon } from "./icon";

const ModalContext = React.createContext<
  | {
      modal: HTMLDivElement | undefined;
    }
  | undefined
>(undefined);

type BareModalProps = {
  children: React.ReactNode;
  onClose?: () => void;
  isOpen: boolean;
};

type ModalProps = {
  width?: number | string;
} & BareModalProps;

export function ModalBare({
  children,
  isOpen,
  onClose = () => undefined,
}: BareModalProps) {
  const ref = useFocusTrap();
  useHotkey("esc", () => isOpen && onClose());

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalBackdrop ref={ref} onClick={onClose}>
          {isOpen && <BodyOverflowStyle />}
          {children}
          {isOpen && (
            <CloseButton
              onClick={(evt: MouseEvent) => {
                evt.stopPropagation();
                onClose();
              }}
            />
          )}
        </ModalBackdrop>
      )}
    </AnimatePresence>
  );
}

export function Modal({ children, onClose, isOpen, width = 560 }: ModalProps) {
  return (
    <ModalBare isOpen={isOpen} onClose={onClose}>
      <StyledModal size={width} onClick={(evt) => evt.stopPropagation()}>
        {children}
      </StyledModal>
    </ModalBare>
  );
}

function CloseButton({ onClick }: { onClick?: (evt: MouseEvent) => void }) {
  return (
    <button
      title="Close modal window"
      onClick={onClick}
      css={css`
        cursor: pointer;
        position: fixed;
        top: ${space.xs};
        right: ${space.xs};
        width: 40px;
        height: 40px;
        color: white;
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
        filter: drop-shadow(0 0 0.75rem rgba(11, 35, 60, 0.87));
      `}
    >
      <Icon name="close" />
    </button>
  );
}

type ModalBackdropProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

const ModalBackdrop = React.forwardRef<HTMLDivElement, ModalBackdropProps>(
  ({ children, onClick }, ref) => {
    const router = useRouter();
    const { modal } = useModalContext();

    return modal
      ? ReactDOM.createPortal(
          <StyledModalBackdrop
            ref={ref}
            onClick={onClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, pointerEvents: "all" }}
            exit={{ opacity: 0, pointerEvents: "none" }}
            transition={{ ease: "circOut", duration: 0.2 }}
            style={{ willChange: "opacity" }}
            $embedded={router.pathname.startsWith("/embedded")}
          >
            {children}
          </StyledModalBackdrop>,
          modal
        )
      : null;
  }
);

ModalBackdrop.displayName = "ModalBackdrop";

const BodyOverflowStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

function useModalContext() {
  const context = React.useContext(ModalContext);
  assert(context, "Missing ModalProvider in component tree");
  return context;
}

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [context, setContext] = React.useState({
    modal: undefined as HTMLDivElement | undefined,
  });

  React.useEffect(
    () => setContext(() => ({ modal: modalRef.current || undefined })),
    []
  );

  return (
    <React.Fragment>
      <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
      <div ref={modalRef} />
    </React.Fragment>
  );
}

const StyledModalBackdrop = styled(motion.div)<{ $embedded: boolean }>`
  position: fixed;
  z-index: ${zIndices.modal};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${transparentize(0.13, colors.blueZodiac)};
  overflow: auto;
  overflow-x: hidden;
  border-radius: ${(x) => (x.$embedded ? radii.lg : 0)};
`;

const StyledModal = styled.div<{ size?: number | string }>`
  position: relative;
  width: ${(x) => (typeof x.size === "number" ? `${x.size}px` : x.size)};
  margin: 0 auto;
  max-width: 90%;
  background: white;
  border-radius: ${radii.lg};
  overflow: hidden;
  margin: ${space.xl} auto;
`;
