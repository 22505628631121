import { observer } from "mobx-react-lite";
import dynamic from "next/dynamic";
import React from "react";
import { LoadingIndicator, Modal, SpacedChildren } from "~/components";
import { useHotkey } from "~/modules/hooks";
import { sizes, space } from "~/theme";
import { GroupsAndParts } from "../groups-and-parts";
import { GuidelineSearchInput } from "./guideline-search-input";

type JumpToGuidelineProps = {
  children: (onClick: () => void) => React.ReactNode;
};

export const JumpToGuideline = observer(
  ({ children }: JumpToGuidelineProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    useHotkey(["shift+f", "/"], () => setIsOpen(true), {
      disableTextInputs: true,
    });

    return (
      <React.Fragment>
        {children(() => setIsOpen(true))}

        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          width={sizes.pageWidth}
        >
          <SpacedChildren
            size="lg"
            css={{ padding: space.lg, minHeight: "75vh" }}
          >
            <WithGuidelineSearch>
              {(GuidelineSearch) => (
                <React.Fragment>
                  <GuidelineSearch />
                  <GroupsAndParts />
                </React.Fragment>
              )}
            </WithGuidelineSearch>
          </SpacedChildren>
        </Modal>
      </React.Fragment>
    );
  }
);

const WithGuidelineSearch = dynamic(
  async () => {
    const { GuidelineSearch } = await import("./guideline-search");
    return ({
      children,
    }: {
      children: (x: typeof GuidelineSearch) => JSX.Element;
    }) => children(GuidelineSearch);
  },
  {
    ssr: false,
    loading: function Loading() {
      return (
        <SpacedChildren>
          <GuidelineSearchInput disabled />
          <LoadingIndicator />
        </SpacedChildren>
      );
    },
  }
);
