import type {
  DerivedCurrentGuidelines,
  DerivedCurrentReviewStructures,
  Dictionary,
} from "@gemini/common";
import { getErrorMessage } from "@gemini/common";
import * as Sentry from "@sentry/browser";
import { doc } from "firebase/firestore";
import { set } from "lodash-es";
import { makeAutoObservable } from "mobx";
import { db } from "~/modules/firebase";
import { ObservableDocument } from "~/modules/firestore-mobx";

export class AppStore {
  accountId: string;

  derivedUserNames: ObservableDocument<Record<string, string>>;

  derivedCurrentGuidelines: ObservableDocument<DerivedCurrentGuidelines>;
  derivedCurrentReviewStructures: ObservableDocument<DerivedCurrentReviewStructures>;

  previewBranches = false;

  constructor(accountId: string) {
    console.log(`[app store] constructor for account ${accountId}`);

    this.accountId = accountId;

    this.derivedUserNames = new ObservableDocument<Record<string, string>>(
      doc(db, `__derived_user_names/${accountId}`)
    );

    this.derivedCurrentGuidelines =
      new ObservableDocument<DerivedCurrentGuidelines>(
        doc(db, "__derived/current_guidelines")
      );

    this.derivedCurrentReviewStructures =
      new ObservableDocument<DerivedCurrentReviewStructures>(
        doc(db, "__derived/current_review_structures")
      );

    makeAutoObservable(this, {
      derivedUserNames: false,
      derivedCurrentGuidelines: false,
      derivedCurrentReviewStructures: false,
    });
  }

  getGuidelineIdForReference(reference: string) {
    return (
      this.derivedCurrentGuidelines.data?.by_reference[reference].id ??
      "__no_data"
    );
  }

  get userNames() {
    try {
      return this.derivedUserNames.document.data;
    } catch (err) {
      /**
       * If the first user of an account signs in, there is no derived document
       * yet. We catch it and do nothing, except log to sentry so we can know if
       * other errors occur or it appears more frequently then expected.
       */
      Sentry.captureException(
        `Failed on derivedUserNames. Possibly because of a first-time account user: ${getErrorMessage(
          err
        )}`
      );

      return {};
    }
  }

  get currentGuidelines() {
    /**
     * We only ever really use the by_reference property, so return its content
     * directly.
     */
    return this.derivedCurrentGuidelines.data?.by_reference ?? {};
  }

  get currentReviewStructures() {
    /**
     * We only ever really use the by_reference property, so return its content
     * directly.
     */
    return this.derivedCurrentReviewStructures.data?.by_reference ?? {};
  }

  get guidelineReferenceByCitationCode() {
    return Object.values(this.currentGuidelines).reduce(
      (acc, v) => set(acc, v.citation_code, v.reference),
      {} as Dictionary<string>
    );
  }

  get isLoading() {
    return (
      this.derivedUserNames.isLoading ||
      this.derivedCurrentGuidelines.isLoading ||
      this.derivedCurrentReviewStructures.isLoading
    );
  }
}
