import router from "next/router";
import { BackLink, PageContainer, SpacedChildren, Title } from "~/components";
import { CreateReviewForm } from "./components";

export function ReviewsCreatePage() {
  return (
    <PageContainer narrowContent testingName="create-page">
      <SpacedChildren>
        <BackLink href="/reviews">Back</BackLink>
        <Title level={2} as="h1">
          Create a New Site Review
        </Title>
      </SpacedChildren>
      <CreateReviewForm
        onFinish={(reviewId) =>
          void router.push("/reviews/[reviewId]", `/reviews/${reviewId}`)
        }
        onCancel={() => void router.push("/reviews", "/reviews")}
      />
    </PageContainer>
  );
}
