import type {
  Assessment,
  DerivedReview,
  Dictionary,
  Review,
  ReviewStructure,
} from "@gemini/common";
import { getPartsFromStructure } from "@gemini/common";
import { set } from "lodash-es";
import type { Document } from "~/modules/firestore";

export function getLastSubmittedPart(
  review: Review | DerivedReview,
  structure: ReviewStructure
) {
  const { last_submitted_reference: reference } = review.progress;

  if (reference) {
    return getPartsFromStructure(structure).find((x) =>
      x.guideline_references.includes(reference)
    );
  }
}

export function getAssessmentByReferenceMap(
  assessments: Document<Assessment>[]
) {
  return assessments.reduce(
    (result, assessment) =>
      set(result, assessment.data.guideline_reference, assessment.data),
    {}
  ) as Dictionary<Assessment>;
}
