import type { Rule, RuleAction } from "@gemini/common";
import { ruleActions } from "@gemini/common";
import { InputNumber, Radio, Select } from "antd";
import { omit } from "lodash-es";
import { SectionTitle } from "../guideline/edit/components";
import { ConditionEditor } from "../guideline/edit/components/condition-editor";

const rootRuleActions = omit(ruleActions, ["value", "pointScore", "preAssess"]);

const ruleLabels: Record<string, string> = {
  value: "Value",
  pointScore: "Point Score",
  include: "Include",
  exclude: "Exclude",
  preAssess: "Pre-assess",
};

type RuleEditorProps = {
  rule: Rule;
  isRootScenario?: boolean;
  onChange: (value: Rule) => void;
};

export function RuleEditor(props: RuleEditorProps) {
  const { condition, action, value, point_score } = props.rule;

  return (
    <div>
      <SectionTitle small>Action</SectionTitle>
      <Select<string>
        style={{ minWidth: "12em", marginBottom: "0.5em", marginRight: "1em" }}
        value={action}
        onChange={(ruleAction) => {
          const action = ruleAction as RuleAction;
          props.onChange({
            action,
            condition,
            value,
          });
        }}
        size="small"
      >
        {Object.keys(props.isRootScenario ? rootRuleActions : ruleActions).map(
          (action) => (
            <Select.Option key={action} value={action}>
              {ruleLabels[action]}
            </Select.Option>
          )
        )}
      </Select>
      {action === "value" && (
        <Radio.Group
          value={value}
          onChange={(e) =>
            props.onChange({
              action,
              condition,
              value: e.target.value,
            })
          }
          size="small"
          style={{ marginBottom: "0.5em" }}
        >
          <Radio.Button value={"vh"}>VH</Radio.Button>
          <Radio.Button value={"vl"}>VL</Radio.Button>
          <Radio.Button value={"neutral"}>N</Radio.Button>
          <Radio.Button value={"al"}>AL</Radio.Button>
          <Radio.Button value={"ah"}>AH</Radio.Button>
          <Radio.Button value={"ir"}>IR</Radio.Button>
          <Radio.Button value={"na"}>NA</Radio.Button>
        </Radio.Group>
      )}
      {action === "pointScore" && (
        <InputNumber
          value={point_score}
          onChange={(v) =>
            props.onChange({
              action,
              condition,
              point_score: v ?? 0,
            })
          }
          size="small"
        />
      )}
      <SectionTitle small>Condition</SectionTitle>
      <ConditionEditor
        condition={condition}
        onChange={(condition) =>
          props.onChange({
            action,
            condition,
            value,
          })
        }
      />
    </div>
  );
}
