import type { ReviewStructureGroup } from "@gemini/common";
import React from "react";
import { Title } from "~/components";
import { fontWeights, space } from "~/theme";
import { NestedGroup } from "./nested-group";

type RootGroupProps = {
  group: ReviewStructureGroup;
  multiColumn?: boolean;
};

export function RootGroup({ group, multiColumn }: RootGroupProps) {
  return (
    <div>
      <GroupHeader multiColumn={multiColumn}>{group.title}</GroupHeader>
      {multiColumn ? (
        <NestedGroup level={0} group={group} isMultiColumn />
      ) : (
        <NestedGroup level={0} group={group} />
      )}
    </div>
  );
}

function GroupHeader({
  children,
  multiColumn,
}: {
  children: React.ReactNode;
  multiColumn?: boolean;
}) {
  return (
    <Title
      noMargin
      level={multiColumn ? 4 : 5}
      cx={{
        fontWeight: fontWeights.semibold,
        marginBottom: multiColumn ? space.lg : space.sm,
      }}
    >
      {children}
    </Title>
  );
}
