import type { IndustryId, Scenario, TagId, TranslationValue } from "./schemas";

import { matchesCondition } from "./conditionals";

export function getRuling(
  scenario: Scenario,
  industryId: IndustryId,
  tagIds: TagId[]
): {
  exclude: boolean;
  overrideValue: TranslationValue | undefined;
  overridePointScore: number | undefined;
  preAssess: boolean;
} {
  let overrideValue: TranslationValue | undefined;
  let overridePointScore: number | undefined;
  let exclude = false;
  let preAssess = false;

  const { rule } = scenario;

  if (rule) {
    switch (rule.action) {
      case "exclude":
        if (matchesCondition(rule.condition, industryId, tagIds)) {
          exclude = true;
        }
        break;
      case "include":
        /** Include is basically exclude for anything that does not match. */
        if (!matchesCondition(rule.condition, industryId, tagIds)) {
          exclude = true;
        }
        break;
      case "value":
        if (matchesCondition(rule.condition, industryId, tagIds)) {
          overrideValue = rule.value;
        }
        break;
      case "pointScore":
        if (matchesCondition(rule.condition, industryId, tagIds)) {
          overridePointScore = rule.point_score;
        }
        break;
      case "preAssess":
        if (matchesCondition(rule.condition, industryId, tagIds)) {
          preAssess = true;
        }
        break;
      default:
        console.error(new Error(`Ignoring unknown rule action ${rule.action}`));
    }
  }

  return {
    exclude,
    overrideValue,
    overridePointScore,
    preAssess,
  };
}
