import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { getScenarioBranchFromClipboard } from "~/features/admin/logic/clipboard";
import { useHotkey } from "~/modules/hooks";
import { notify } from "~/modules/notifications";
import { useEditStore } from "../store";

/**
 * We made this a component instead of a hook, because it's unclear how to
 * observe properties of the store. Also it contains JSX so probably an odd
 * abstraction to have a s hook anyway.
 */
export const PasteFromClipboard = observer(
  ({ scenarioId }: { scenarioId: string }) => {
    const store = useEditStore();

    const isFocused = store.focusedScenarioId === scenarioId;

    const handlePaste = useCallback(() => {
      getScenarioBranchFromClipboard()
        .then((scenarioBranch) => {
          if (scenarioBranch) {
            const { scenarios, branchRootId } = scenarioBranch;

            const undo = store.mountScenarioBranch(scenarios, branchRootId);
            notify.success({
              duration: 20,
              description: (
                <>
                  {`Pasted scenarios from ${branchRootId} to ${store.focusedScenarioId} `}
                  <Button size="small" onClick={undo}>
                    undo
                  </Button>
                </>
              ),
            });
          }
        })
        .catch((err) => {
          notify.error(err);
        });
    }, [store]);

    useHotkey("command+v", handlePaste, {
      disabled: !isFocused,
      disableTextInputs: true,
    });

    return null;
  }
);
