import {
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  SwapOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { DEFAULT_POINTS_MAPPING } from "@gemini/common";
import { Button, Input, InputNumber, Popover, Radio, Space, Tabs } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { Spacer } from "~/components/spacer";
import { RuleEditor } from "~/features/admin/components";
import type { EditStore } from "../store/store";
import { Comment } from "./comment";
import { ConditionalTextEditor } from "./conditional-text-editor";
import { MasterTextsEditor } from "./master-texts-editor";
import { OptionalTextEditor } from "./optional-text-editor";
import { PointsToValueMapping } from "./points-to-value-mapping";
import { SectionTitle } from "./section-title";

type ScenarioEditViewProps = {
  store: EditStore;
};

export const ScenarioEditView: React.FC<ScenarioEditViewProps> = observer(
  ({ store }) => {
    const scenario = store.editScenario;

    const {
      has_children,
      logic,
      logic_and_mode,
      title,
      description,
      translation_value,
      point_score,
      points_mapping,
      instruction_text,
      rule,
      conditional_instruction_text,
      master_text,
    } = scenario;

    const hasPointBasedParent = store.parentScenario.logic === "points";
    const hasPointBasedChildren = store.childScenarios.some(
      (x) => x.logic === "points"
    );

    return (
      <Tabs
        animated={false}
        activeKey={store.lastSelectedScenarioTabKey}
        onChange={(activeKey) => (store.lastSelectedScenarioTabKey = activeKey)}
        items={[
          {
            label: "Common",
            key: "common",
            children: (
              <>
                <SectionTitle noTopMargin>Title</SectionTitle>
                <Input
                  value={title}
                  onChange={(e) => store.setScenarioTitle(e.target.value)}
                />

                <OptionalTextEditor
                  title="Description"
                  value={description}
                  setValue={(v) =>
                    store.setScenarioOptionalTextField("description", v)
                  }
                />

                {!has_children && (
                  <>
                    <SectionTitle>Instruction Texts</SectionTitle>
                    <Comment>
                      Universal and conditional instruction texts can only be
                      added for scenarios that have children.
                    </Comment>
                  </>
                )}

                <OptionalTextEditor
                  title="Instruction Text"
                  value={instruction_text}
                  setValue={(v) =>
                    store.setScenarioOptionalTextField("instruction_text", v)
                  }
                  comment="Instruction text is only visible when the scenario has children and has been selected."
                  showOnlyToRemove={!has_children}
                />

                {has_children && (
                  <>
                    <SectionTitle>Conditional Instruction Text</SectionTitle>
                    <Comment>
                      Conditional instruction text is visible below the regular
                      instructions when the review matches the tag and industry
                      settings.
                    </Comment>
                    {conditional_instruction_text ? (
                      <>
                        <ConditionalTextEditor
                          conditionalText={conditional_instruction_text}
                          onChange={store.setScenarioConditionalText}
                        />
                        {!scenario.has_children && (
                          <Comment>
                            This scenario does not have children. Instructions
                            are only rendered when a scenario has children.
                          </Comment>
                        )}
                      </>
                    ) : (
                      <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        ghost
                        onClick={() =>
                          store.setScenarioConditionalText({
                            conditionals: [
                              {
                                text: "Some conditional text",
                                condition: {
                                  tag_ids: [],
                                },
                              },
                            ],
                          })
                        }
                      >
                        Add
                      </Button>
                    )}
                  </>
                )}

                {hasPointBasedParent ? (
                  <>
                    <SectionTitle>Points</SectionTitle>
                    <InputNumber
                      value={point_score}
                      onChange={(v) => store.setScenarioPointScore(v)}
                    />
                  </>
                ) : (
                  <>
                    <SectionTitle>Translation Value</SectionTitle>
                    <Space>
                      <Radio.Group
                        value={translation_value}
                        onChange={(e) =>
                          store.setScenarioTranslationValue(e.target.value)
                        }
                        size="small"
                      >
                        <Radio.Button value={"vh"}>VH</Radio.Button>
                        <Radio.Button value={"vl"}>VL</Radio.Button>
                        <Radio.Button value={"neutral"}>N</Radio.Button>
                        <Radio.Button value={"al"}>AL</Radio.Button>
                        <Radio.Button value={"ah"}>AH</Radio.Button>
                        <Radio.Button value={"ir"}>IR</Radio.Button>
                        <Radio.Button value={"na"}>NA</Radio.Button>
                      </Radio.Group>

                      {translation_value && (
                        <Button
                          danger
                          icon={<CloseOutlined />}
                          ghost
                          size="small"
                          onClick={() =>
                            store.setScenarioTranslationValue(undefined)
                          }
                        />
                      )}
                    </Space>
                  </>
                )}

                {has_children && (
                  <>
                    <SectionTitle>Logic Mode</SectionTitle>
                    <Radio.Group
                      value={logic}
                      onChange={(e) => store.setScenarioLogic(e.target.value)}
                      size="small"
                    >
                      <Radio.Button value={"and"}>AND</Radio.Button>
                      <Radio.Button value={"or"}>OR</Radio.Button>

                      {
                        /**
                         * Point based scenarios should not be allowed to have
                         * other point based scenarios nested directly one level
                         * below or above, because then we would have to somehow
                         * map score => tv => score => tv. We have no way to map
                         * a translation value back to a point score.
                         */
                        !hasPointBasedParent && !hasPointBasedChildren && (
                          <Radio.Button value={"points"}>Points</Radio.Button>
                        )
                      }
                    </Radio.Group>
                    <Spacer size="sm" />

                    {logic === "and" ? (
                      <>
                        <Radio.Group
                          value={logic_and_mode}
                          onChange={(e) =>
                            store.setScenarioLogicAndMode(e.target.value)
                          }
                          size="small"
                        >
                          <Radio.Button value={"acc"}>Accumulate</Radio.Button>
                          <Radio.Button value={"avg"}>Average</Radio.Button>
                        </Radio.Group>
                        <Spacer size="sm" />
                      </>
                    ) : null}

                    {logic === "points" ? (
                      points_mapping ? (
                        <PointsToValueMapping
                          mapping={points_mapping}
                          validation={store.validation}
                          onChange={(x) => store.setChildrenPointsMapping(x)}
                        />
                      ) : (
                        <Button
                          onClick={() =>
                            store.setChildrenPointsMapping(
                              DEFAULT_POINTS_MAPPING
                            )
                          }
                        >
                          Add Mapping
                        </Button>
                      )
                    ) : null}
                  </>
                )}

                <SectionTitle>Scenario Rule</SectionTitle>
                <Comment>
                  A rule can be used to include or exclude a scenario, or change
                  its translation value based on the review industry and/or
                  tags.
                </Comment>
                {rule ? (
                  <>
                    <RuleEditor rule={rule} onChange={store.setScenarioRule} />
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="small"
                      ghost
                      onClick={() => store.setScenarioRule()}
                    >
                      Remove Rule
                    </Button>
                  </>
                ) : (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    ghost
                    size="small"
                    onClick={() =>
                      store.setScenarioRule({
                        action: hasPointBasedParent ? "pointScore" : "value",
                        condition: {},
                        [hasPointBasedParent ? "point_score" : "value"]:
                          hasPointBasedParent
                            ? point_score ?? 0
                            : translation_value,
                      })
                    }
                  >
                    Add Rule
                  </Button>
                )}

                <SectionTitle>Add / Remove Scenarios</SectionTitle>
                <Space>
                  {!hasPointBasedParent && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      size="small"
                      ghost
                      onClick={() => store.addChildScenario()}
                    >
                      Add Child Scenario
                    </Button>
                  )}

                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    size="small"
                    disabled={has_children}
                    ghost
                    onClick={() => store.deleteScenario()}
                  >
                    Remove Selected Scenario
                  </Button>
                </Space>

                <SectionTitle>Position</SectionTitle>
                <Space>
                  <Button
                    type="primary"
                    icon={<UpOutlined />}
                    size="small"
                    ghost
                    onClick={() => store.moveScenario("up")}
                    disabled={scenario.position === 0}
                  >
                    Move Up
                  </Button>

                  <Button
                    type="primary"
                    icon={<DownOutlined />}
                    size="small"
                    ghost
                    onClick={() => store.moveScenario("down")}
                    disabled={scenario.position >= store.siblingCount - 1}
                  >
                    Move Down
                  </Button>

                  <Popover
                    content={
                      <Comment>
                        When scenario position data is corrupt, you will
                        sometimes get an error when trying to move a scenario.
                        This action will re-order all siblings so that they have
                        correct positional data. The result might not have any
                        visible impact.
                      </Comment>
                    }
                  >
                    <Button
                      type="primary"
                      icon={<SwapOutlined />}
                      size="small"
                      ghost
                      onClick={() => store.reorderSiblings()}
                    >
                      Reorder Siblings
                    </Button>
                  </Popover>
                </Space>
              </>
            ),
          },
          {
            label: "Master / Deliverable",
            key: "master",
            children: (
              <>
                <OptionalTextEditor
                  title="Legacy Master Text"
                  value={master_text}
                  setValue={(v) =>
                    store.setScenarioOptionalTextField("master_text", v)
                  }
                  showOnlyToRemove={true} // Never add a new legacy text, only remove
                />

                <MasterTextsEditor
                  scenario={scenario}
                  setFieldValue={store.setMasterTextsField}
                  swapPolarity={store.swapMasterTextsPolarity}
                  setIncludeMasterTextIfUnchecked={
                    store.setIncludeMasterTextIfUnchecked
                  }
                  hasPointBasedParent={hasPointBasedParent}
                />
              </>
            ),
          },
        ]}
      />
    );
  }
);
