import React from "react";
import type { CSSProp } from "styled-components";
import styled from "styled-components";
import { colors, mediaQueries, radii, space } from "~/theme";

export type BoxWithBorderProps = {
  cx?: CSSProp;
  children?: React.ReactNode;
  gray?: boolean;
  flat?: boolean;
  noPadding?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
};

export const BoxWithBorder = React.forwardRef<
  HTMLDivElement,
  BoxWithBorderProps
>(({ children, noPadding, ...props }, ref) => (
  <StyledBoxWithBorder {...props} ref={ref}>
    {noPadding ? children : <PaddedContent>{children}</PaddedContent>}
  </StyledBoxWithBorder>
));

BoxWithBorder.displayName = "BoxWithBorder";

export const StyledBoxWithBorder = styled.div.withConfig({
  shouldForwardProp: (x) => !["cx", "gray", "flat", "noPadding"].includes(x),
})<BoxWithBorderProps>`
  overflow-x: auto;
  border-radius: ${radii.lg};
  background-color: ${(x) => (x.gray ? colors.whiteLilac : "white")};
  color: ${(x) => (x.gray ? colors.charcoal : undefined)};
  box-shadow: ${(x) =>
    x.flat ? undefined : "0 2px 6px 0 rgba(11, 35, 60, 0.15)"};

  ${(x) => x.cx}
`;

const PaddedContent = styled.div`
  padding: ${space.md};
  @media ${mediaQueries.md} {
    padding: 2rem 2.4rem;
  }
`;
