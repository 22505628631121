import type { JudgementValue } from "@gemini/common";
import { judgementValues, NU_VALUE } from "@gemini/common";
import { AnimatePresence, motion } from "framer-motion";
import type { ReactNode } from "react";
import styled, { css } from "styled-components";
import {
  Checkbox,
  CheckboxWithLabel,
  JudgementPill,
  Paragraph,
} from "~/components";
import { useAuth } from "~/modules/auth";
import { colors, fontWeights, radii, space } from "~/theme";

type ManualJudgementProps = {
  disabled: boolean;
  manualJudgement: JudgementValue;
  isManualJudgement: boolean;
  setIsManualJudgement: (value: boolean) => void;
  setManualJudgementValue: (value: JudgementValue) => void;
  feedbackLinkHref: string;
  hasScenarios: boolean;
};

export function ManualJudgement({
  disabled,
  manualJudgement,
  isManualJudgement,
  setIsManualJudgement,
  setManualJudgementValue,
  feedbackLinkHref,
  hasScenarios,
}: ManualJudgementProps) {
  const { claims } = useAuth();

  if (claims.isAdmin) {
    return (
      <QuickManualJudgementForm
        disabled={disabled}
        manualJudgement={manualJudgement}
        isManualJudgement={isManualJudgement}
        setIsManualJudgement={setIsManualJudgement}
        setManualJudgementValue={setManualJudgementValue}
      />
    );
  }

  return (
    <ManualJudgementCheckbox
      /** @todo Check non-admin manual judgement flow */
      // disabled={disabled}
      checked={isManualJudgement}
      onChange={setIsManualJudgement}
      feedbackLinkHref={feedbackLinkHref}
      label={
        hasScenarios
          ? "None of the above applies"
          : "Manually judge this guideline"
      }
    />
  );
}

type ManualJudgementFieldProps = {
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  label: string;
  feedbackLinkHref?: string;
  childrenWhenChecked?: ReactNode;
};

export function ManualJudgementCheckbox({
  checked,
  onChange,
  feedbackLinkHref,
  label,
  childrenWhenChecked,
}: ManualJudgementFieldProps) {
  return (
    <div>
      <div css={{ paddingBottom: space.md }}>
        <CheckboxWithLabel onChange={onChange} checked={checked}>
          <span css={{ fontWeight: checked ? fontWeights.bold : undefined }}>
            {label}
          </span>
        </CheckboxWithLabel>
      </div>

      <AnimatePresence initial={false}>
        {checked && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            style={{ overflow: "hidden" }}
          >
            <div
              css={css`
                background-color: ${colors.whiteLilac};
                border-radius: ${radii.md};
                padding: ${space.md} ${space.lg};
              `}
            >
              {childrenWhenChecked || (
                <>
                  <Paragraph size="small">
                    You will be asked to manually set a judgement for this
                    guideline on the next page. It can therefore be a good idea
                    to leave notes below to provide context for that judgement.
                  </Paragraph>

                  {feedbackLinkHref && (
                    <Paragraph size="small" noMargin>
                      If you feel options are missing from this guideline,
                      please <a href={feedbackLinkHref}>tell us</a>.
                    </Paragraph>
                  )}
                </>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

type QuickManualJudgementFormProps = {
  disabled: boolean;
  manualJudgement: JudgementValue;
  isManualJudgement: boolean;
  setIsManualJudgement: (value: boolean) => void;
  setManualJudgementValue: (value: JudgementValue) => void;
};

function QuickManualJudgementForm({
  disabled,
  manualJudgement,
  isManualJudgement,
  setIsManualJudgement,
  setManualJudgementValue,
}: QuickManualJudgementFormProps) {
  return (
    <div
      data-t="quick-manual-judgement-form"
      css={css`
        & > * {
          margin-right: ${space.sm};
          margin-bottom: ${space.sm};
        }
        display: flex;
        flex-wrap: wrap;
      `}
    >
      {judgementValues.map((value) => (
        <Label
          key={value}
          checked={isManualJudgement && manualJudgement === value}
          css={disabled ? { opacity: 0.75, cursor: "default" } : undefined}
        >
          <Checkbox
            disabled={disabled}
            checked={isManualJudgement && manualJudgement === value}
            onChange={() => {
              if (isManualJudgement && manualJudgement === value) {
                // reset to the default Neutral value
                setManualJudgementValue(NU_VALUE);
                setIsManualJudgement(false);
                return;
              }

              if (!isManualJudgement) {
                setManualJudgementValue(value);
                setIsManualJudgement(true);
                return;
              }

              setManualJudgementValue(value);
            }}
          />
          <JudgementPill value={value} isIssueResolved={false} />
        </Label>
      ))}
    </div>
  );
}

const Label = styled.label<{ checked: boolean }>`
  border: 1px solid ${colors.grayLight};
  border-radius: ${radii.sm};
  padding: ${space.sm};
  background: none;
  cursor: pointer;

  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${space.sm};
  }
`;
