import type { CauseOfChange } from "./types";

export const causesOfChange = [
  "altered_content",
  "new_content",
  "altered_functionality",
  "scenario_selection",
  "scenario_or_rating_definition",
  "misrating",
] as const;

export const causeOfChangeLabels: Record<CauseOfChange, string> = {
  altered_content: "Altered Content",
  new_content: "New Content",
  altered_functionality: "Altered Functionality",
  scenario_selection: "Scenario Selection",
  scenario_or_rating_definition: "Scenario or Rating Definition",
  misrating: "Misrating",
};

/**
 * Labels used in sentences to describe the cause of a change, e.g. "Differs
 * from review X due to {causeOfChangeSentenceParts}"
 */
export const causeOfChangeSentenceParts: Record<CauseOfChange, string> = {
  altered_content: "a change in site content",
  new_content: "content encountered",
  altered_functionality: "a change in site functionality",
  scenario_selection: "a change in how the guideline is rated",
  scenario_or_rating_definition:
    "a change in guideline scenarios or assigned ratings",
  misrating: "a misrating",
};
