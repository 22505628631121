import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import type { PointsToValueMapping, TranslationValue } from "@gemini/common";
import {
  DEFAULT_POINTS_MAPPING_MAX,
  DEFAULT_POINTS_MAPPING_MIN,
} from "@gemini/common";
import { Button, InputNumber, Radio, Slider, Space, Typography } from "antd";
import { useState } from "react";
import { SpacedChildren } from "~/components";

const { Title, Text } = Typography;

export function PointsToValueMapping({
  mapping,
  validation,
  onChange,
}: {
  mapping: PointsToValueMapping[];
  validation: {
    isValid: boolean;
    validationError?: string;
  };
  onChange: (pointsMapping: PointsToValueMapping[]) => void;
}) {
  const [sliderMinimum, setSliderMinimum] = useState(getMinimum(mapping));
  const [sliderMaximum, setSliderMaximum] = useState(getMaximum(mapping));

  const handleRangeChange = (index: number, newRange: PointsToValueMapping) => {
    const newMapping = [...mapping];
    newMapping[index] = newRange;
    onChange(newMapping);
  };

  const handleSort = () => {
    const newMapping = [...mapping];
    newMapping.sort((a, b) => a.from - b.from);
    onChange(newMapping);
  };

  const handleRemove = (index: number) => {
    const newMapping = [...mapping];
    newMapping.splice(index, 1);
    onChange(newMapping);
  };

  const handleAdd = () => {
    const newMapping = [...mapping];
    newMapping.push({ from: 0, to: 0, value: "neutral" });
    onChange(newMapping);
  };

  return (
    <SpacedChildren>
      <Title level={5}>
        Points to Value Mapping{" "}
        {validation.isValid ? (
          <Text type="success">
            <CheckOutlined />
          </Text>
        ) : (
          <Text type="danger">
            <CloseOutlined />
          </Text>
        )}
      </Title>
      <Space>
        Min{" "}
        <InputNumber
          size="small"
          onChange={(v) => setSliderMinimum(v || getMinimum(mapping))}
          value={sliderMinimum}
        />
        Max{" "}
        <InputNumber
          size="small"
          onChange={(v) => setSliderMaximum(v || getMaximum(mapping))}
          value={sliderMaximum}
        />
      </Space>
      <ol
        css={`
          list-style: none;
          padding: 0;
        `}
      >
        {mapping.map((range, index) => (
          <SingleRange
            range={range}
            key={index}
            min={sliderMinimum}
            max={sliderMaximum}
            onChange={(range) => handleRangeChange(index, range)}
            onRemove={() => handleRemove(index)}
          />
        ))}
      </ol>
      <Space>
        <Button size="small" onClick={handleAdd}>
          Add Range
        </Button>
        <Button size="small" onClick={handleSort}>
          Sort Ranges
        </Button>
        {validation.isValid ? (
          <Text type="success">
            <CheckOutlined /> "Mapping is valid"
          </Text>
        ) : (
          <Text type="danger">
            <CloseOutlined /> {validation.validationError}
          </Text>
        )}
      </Space>
    </SpacedChildren>
  );
}

function SingleRange({
  range,
  min,
  max,
  onChange,
  onRemove,
}: {
  range: PointsToValueMapping;
  min: number;
  max: number;
  onChange: (range: PointsToValueMapping) => void;
  onRemove: () => void;
}) {
  const handleValueChange = (value: TranslationValue) =>
    onChange({ ...range, value });

  const handleFromToChange = (fromTo: number[]) =>
    onChange({ ...range, from: fromTo[0], to: fromTo[1] });

  return (
    <li>
      <Slider
        min={min}
        max={max}
        range
        value={[range.from, range.to]}
        onChange={handleFromToChange}
      />
      <Space>
        <Radio.Group
          value={range.value}
          onChange={(e) => handleValueChange(e.target.value)}
          size="small"
        >
          <Radio.Button value={"vh"}>VH</Radio.Button>
          <Radio.Button value={"vl"}>VL</Radio.Button>
          <Radio.Button value={"neutral"}>N</Radio.Button>
          <Radio.Button value={"al"}>AL</Radio.Button>
          <Radio.Button value={"ah"}>AH</Radio.Button>
        </Radio.Group>
        <Button
          danger
          icon={<DeleteOutlined />}
          ghost
          size="small"
          onClick={onRemove}
        />
      </Space>
    </li>
  );
}

function getMinimum(mapping: PointsToValueMapping[]) {
  if (mapping.length === 0) {
    return DEFAULT_POINTS_MAPPING_MIN;
  }

  return mapping.reduce((acc, v) => (v.from < acc ? v.from : acc), Infinity);
}
function getMaximum(mapping: PointsToValueMapping[]) {
  if (mapping.length === 0) {
    return DEFAULT_POINTS_MAPPING_MAX;
  }
  return mapping.reduce((acc, v) => (v.to > acc ? v.to : acc), -Infinity);
}
