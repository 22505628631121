import type { Scenario, ScenarioMap } from "@gemini/common";
import { getAllBranchScenarios, getErrorMessage } from "@gemini/common";
import { notify } from "~/modules/notifications";

/**
 * We use "web custom formats" as a special mimetype to store gemini scenario
 * data. On copy, both text/plain with the regular ID as the custom format with
 * the full scenario data are copied to the clipboard.
 *
 * Note that the custom format is a chromium-only feature, so this will only
 * work in chromium-based browsers.
 *
 * Read more:
 * https://developer.chrome.com/blog/web-custom-formats-for-the-async-clipboard-api/
 */
const CLIPBOARD_SCENARIO_BRANCH_MIME_TYPE = "web gemini/scenario-branch";

const SOURCE_ID = "cloned_scenario_branch";

export type ClipboardScenarioBranch = {
  __source: typeof SOURCE_ID;
  branchRootId: string;
  scenarios: Scenario[];
};

export function copyScenarioBranchToClipboard(
  scenarios: ScenarioMap,
  id: string
) {
  const item = new window.ClipboardItem({
    "text/plain": new Blob([id], { type: "text/plain" }),
    [CLIPBOARD_SCENARIO_BRANCH_MIME_TYPE]: new Blob(
      [convertBranchToClipboardText(scenarios, id)],
      { type: CLIPBOARD_SCENARIO_BRANCH_MIME_TYPE }
    ),
  });

  navigator.clipboard
    .write([item])
    .then(() => notify.info(`Copied "${id}" to clipboard`))
    .catch((err) => {
      console.error("Failed to copy to clipboard: ", err);
      notify.error("Failed to copy to clipboard");
    });
}

export async function getScenarioBranchFromClipboard() {
  try {
    for (const clipboardItem of await navigator.clipboard.read()) {
      for (const type of clipboardItem.types) {
        if (type === CLIPBOARD_SCENARIO_BRANCH_MIME_TYPE) {
          const blob = await clipboardItem.getType(type);
          const json = await blob.text();
          return parseClipboardScenarioJson(json);
        }
      }
    }
  } catch (err) {
    console.error(`Failed to read clipboard: ${getErrorMessage(err)}`);
    notify.error("Failed to read clipboard");
  }
}

function convertBranchToClipboardText(
  scenarios: ScenarioMap,
  branchRootId: string
) {
  const branchScenarios = getAllBranchScenarios(scenarios, branchRootId);

  const clipboardContent: ClipboardScenarioBranch = {
    __source: "cloned_scenario_branch",
    branchRootId,
    scenarios: branchScenarios,
  };

  return JSON.stringify(clipboardContent);
}

function parseClipboardScenarioJson(json: string) {
  try {
    const clipboardContent = JSON.parse(json) as ClipboardScenarioBranch;

    if (clipboardContent.__source !== SOURCE_ID) {
      throw new Error("Invalid clipboard content");
    }

    return clipboardContent;
  } catch (err) {
    /**
     * We don't want to show an error message if the clipboard content is not a
     * valid scenario branch. This is a common case, so we just ignore it.
     */
  }
}
