import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import type { ReactNode } from "react";
import { useEffect } from "react";

declare global {
  /* eslint-disable-next-line @typescript-eslint/consistent-type-definitions */
  interface Window {
    posthog: typeof posthog | undefined;
  }
}

if (typeof window !== "undefined" && !window.posthog) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: "/_ingest",
    session_recording: {
      recordCrossOriginIframes: true,
    },

    /** Disable session recording for all users. */
    disable_session_recording: true,
  });
  window.posthog = posthog;
}

export function PosthogAnalyticsProvider({
  children,
}: {
  children: ReactNode;
}) {
  usePosthogTrackPageViews();

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

function usePosthogTrackPageViews() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);
}

export function optOutCapturing() {
  posthog.opt_out_capturing();
}

export function identifyUser(userId: string) {
  posthog.identify(userId);
}
