import React from "react";
import styled, { keyframes } from "styled-components";

export function LoadingIndicator() {
  return (
    <Box>
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 144">
        <Path
          index={0}
          transformOrigin="3px 61px"
          fill="#8B181B"
          d="M8.3 10S31 2.7 34.8 0L38 1.7s-7.5 24.6-22 55.8L3.2 65 0 62.8s10.1-34 8.3-52.8z"
        />
        <Path
          index={1}
          transformOrigin="33px 60px"
          fill="#5D7333"
          d="M80.1 39S97.6 54.9 102 56.8l.1 3.5S77 66 42.9 69L30 61.6l.4-3.8S64.8 49.8 80 39z"
        />
        <Path
          index={2}
          transformOrigin="49px 84px"
          fill="#2B4973"
          d="M92 114.4s-5.1 23.1-4.6 27.7l-3 1.9S66.7 125.4 47 97.4V82.6l3.5-1.6S75 106.7 92 114.4z"
        />
      </Svg>
    </Box>
  );
}

const Box = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const fade = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) rotateZ(-25deg);
  }

  10% {
    opacity: 0;
    transform:  scale(0.8) rotateZ(-25deg);
  }

  35% {
    opacity: 1;
  }

  45% {

    transform: scale(1) rotateZ(0deg);
  }

  80% {
    opacity: 1;
    transform: rotateZ(0deg);
  }

  90% {
    opacity: 0;
    transform: rotateZ(15deg);
  }
`;

type PathProps = {
  index: 0 | 1 | 2;
  transformOrigin: string;
};

const Svg = styled.svg`
  width: 25px;
`;

const Path = styled.path<PathProps>`
  opacity: 0;
  animation: ${fade} 1800ms ease-out infinite;
  animation-delay: ${(x) => x.index * 120}ms;
  transform-origin: ${(x) => x.transformOrigin};
`;
