import styled from "styled-components";

export const TextButton = styled.button<{ color?: string }>`
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  color: currentColor;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }

  display: flex;
  align-items: center;
`;
