import React from "react";
import { css } from "styled-components";
import { Icon } from "~/components";
import { getFileArray } from "~/modules/file";
import { colors, space } from "~/theme";

export function FileInput({
  disabled,
  label,
  onChange,
  acceptFileTypes,
}: {
  disabled?: boolean;
  label: string;
  onChange: (files: File[]) => void;
  acceptFileTypes?: string;
}) {
  return (
    <label
      css={css`
        display: flex;
        align-items: center;
        cursor: ${disabled ? "default" : "pointer"};
        &:hover {
          color: ${disabled ? undefined : colors.charcoal};
        }
      `}
    >
      <input
        disabled={disabled}
        type="file"
        multiple
        accept={acceptFileTypes}
        onChange={(evt) => onChange(getFileArray(evt.target.files))}
        css={css`
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        `}
      />

      <Icon name="circlePlus" size={16} />
      <span css={{ marginLeft: space.sm }}>{label}</span>
    </label>
  );
}
