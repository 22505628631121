import type { JudgementValue, TranslationValue } from "@gemini/common";
import {
  getJudgementFromTranslationValue,
  getTranslationValueFromNumericValue,
  judgementLabels,
  translationValues,
} from "@gemini/common";

export type JudgementFilterValue = (typeof judgementFilterValues)[number];

export const judgementFilterValues = [
  ...translationValues.filter(
    (value): value is Exclude<TranslationValue, "ir"> => value !== "ir"
  ),
  "not_yet_rated" as const,
];

export function sortJudgements<T extends { name: string }>(a: T, b: T) {
  return (
    judgementFilterValues.indexOf(a.name as JudgementFilterValue) -
    judgementFilterValues.indexOf(b.name as JudgementFilterValue)
  );
}

export function getJudgementFilterValueFromJudgement(
  judgement: JudgementValue | undefined
): JudgementFilterValue {
  return judgement === undefined
    ? "not_yet_rated"
    : judgement === "na"
      ? "na"
      : getTranslationValueFromNumericValue(judgement);
}

export function getJudgementLabel(judgementFilterValue: JudgementFilterValue) {
  if (judgementFilterValue === "not_yet_rated") {
    return "Not Yet Rated";
  }

  const judgement = getJudgementFromTranslationValue(judgementFilterValue);
  return judgementLabels[judgement];
}

export function isJudgementFilterValue(x: string): x is JudgementFilterValue {
  return judgementFilterValues.includes(x as JudgementFilterValue);
}
