import React from "react";
import { FatLink } from "./fat-link";
import { IconInline } from "./icon";

export function BackLink({
  href,
  children,
  disabled,
  onClick,
  hideIcon,
}: {
  children: React.ReactNode;
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
  hideIcon?: boolean;
}) {
  return (
    <FatLink href={href} disabled={disabled} onClick={onClick}>
      {!hideIcon && <IconInline name="chevronLeft" cx={{ marginLeft: -6 }} />}{" "}
      {children}
    </FatLink>
  );
}
