import type {
  ReviewStructure,
  ReviewStructurePart,
  ReviewStructureGroup,
} from "~/schemas";
import { isReviewStructurePart, isReviewStructureGroup } from "./identity";
import { set } from "remeda";

export function getPartsFromStructure(
  structure: ReviewStructure
): ReviewStructurePart[] {
  return Object.values(structure.sections).filter(isReviewStructurePart);
}

export function getPartsFromStructureById(structure: ReviewStructure) {
  return getPartsFromStructure(structure).reduce(
    (acc, part) => set(acc, part.id, part),
    {} as Record<string, ReviewStructurePart>
  );
}

export function getGroupsFromStructure(
  structure: ReviewStructure
): ReviewStructureGroup[] {
  return Object.values(structure.sections).filter(isReviewStructureGroup);
}
