import { customAlphabet } from "nanoid";

/**
 * With a size of 8, and a alphabet of numbers and lower case letters, it still
 * takes 239 years to have 1% chance of collision. See
 * https://zelark.github.io/nano-id-cc/. I think we can live with that.
 *
 * In the future we could patch all reference ids to also have a prefix like gl_
 * and sn_ (for guideline and scenario) similar to how Stripe prefixes API keys
 * to avoid confusion.
 */
const ID_SIZE = 8;
const alphabet = "0123456789abcdefghijklmnopqrstuvwxyz";
const nanoid = customAlphabet(alphabet, ID_SIZE);

export function createUniqueId() {
  return nanoid();
}
