import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { PageLoader } from "~/components";
import { useGuidelineByReference } from "~/features/embedded";
import {
  usePartStore,
  useReviewStore,
  useUpdateStore,
} from "~/features/reviews";
import type { AssessmentFlow } from "./store";
import { AssessmentStore } from "./store";

const AssessmentStoreContext = React.createContext<AssessmentStore | null>(
  null
);

export function AssessmentStoreProvider({
  children,
  flow,
}: {
  children: React.ReactNode;
  flow: AssessmentFlow;
}) {
  const { review } = useReviewStore();

  return (
    <AssessmentStoreContext.Provider
      value={useMemo(
        () => new AssessmentStore({ review: review.document, flow }),
        [review, flow]
      )}
    >
      {flow === "update-flow" && (
        <UpdateAssessmentLoader>{children}</UpdateAssessmentLoader>
      )}

      {flow === "embedded-assessment-flow" && (
        <EmbeddedAssessmentLoader>{children}</EmbeddedAssessmentLoader>
      )}

      {flow === "review-flow" && (
        <AssessmentLoader>{children}</AssessmentLoader>
      )}
    </AssessmentStoreContext.Provider>
  );
}

/**
 * The loader for the regular assessment gets the guideline and assessment data
 * from the PartStore.
 */
const AssessmentLoader = observer(
  ({ children }: { children: React.ReactNode }) => {
    const store = useAssessmentStore();
    const { guideline, assessment, guidelineObservation } = usePartStore();

    assert(guideline, "No guideline document available");

    /** No loading state is needed because initialize is synchronous here */
    store.initialize({ guideline, assessment, guidelineObservation });

    return <>{children}</>;
  }
);

/**
 * The loader for the "embedded review assessment" flow does not include a
 * part-store, therefore we manually load the guideline in this loader.
 */
const EmbeddedAssessmentLoader = observer(
  ({ children }: { children: React.ReactNode }) => {
    const router = useRouter();

    const assessmentStore = useAssessmentStore();
    const reviewStore = useReviewStore();

    const guidelineReference = router.query.reference as string;
    const assessment = reviewStore.assessments.documents.find(
      (x) => x.id === guidelineReference
    );

    const [guideline, isLoadingGuideline] =
      useGuidelineByReference(guidelineReference);

    if (isLoadingGuideline) {
      return <PageLoader />;
    }

    assert(
      guideline,
      `Guideline with reference ${guidelineReference} not found`
    );

    assessmentStore.initialize({ guideline, assessment });

    return <>{children}</>;
  }
);

/**
 * The loader for the update flow gets the guideline and assessment data from
 * the UpdateStore, because there is no review structure when performing
 * updates.
 */
const UpdateAssessmentLoader = observer(
  ({ children }: { children: React.ReactNode }) => {
    const store = useAssessmentStore();
    const { guideline, assessment, isLoading } = useUpdateStore();

    if (isLoading || !guideline) return <PageLoader />;

    assert(guideline, "No guideline document available");

    /** No loading state is needed because initialize is synchronous here */
    store.initialize({ guideline, assessment });

    return <>{children}</>;
  }
);

export const useAssessmentStore = () => {
  const store = React.useContext(AssessmentStoreContext);
  assert(store, "Missing AssessmentStoreProvider in component tree");

  return store;
};
