import { InlineLoadingIndicator } from "./inline-loading-indicator";
import * as React from "react";
import styled from "styled-components";

const Box = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export function LoadingIndicator() {
  return (
    <Box>
      <InlineLoadingIndicator size={24} />
    </Box>
  );
}
