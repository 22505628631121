import type { GuidelineState, Assessment } from "~/schemas";

export function getImpactScore(
  guideline: GuidelineState,
  assessment?: Assessment
) {
  return typeof assessment?.judgement === "number"
    ? (guideline.weight / 10) * assessment.judgement
    : undefined;
}
