export function assert(condition: unknown, msg: string): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}

export function assertIsDefined<T>(
  val: T,
  msg = `Expected 'val' to be defined, but received ${val}`
): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(msg);
  }
}
