import React from "react";
import { preset } from "~/theme";
import { SpacedChildren } from "./spaced-children";

export function AdminFieldWrapper({
  children,
  message = <em>Admin-only field</em>,
}: {
  children: React.ReactNode;
  message?: React.ReactNode;
}) {
  return (
    <SpacedChildren size="sm">
      {children}
      {message && <div css={preset.typography.body2}>{message}</div>}
    </SpacedChildren>
  );
}
