import { darken } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import { isDefined } from "ts-is-present";
import {
  Icon,
  ScaleAppear,
  Spinner,
  useAdaptiveCarouselEventHandlers,
} from "~/components";
import {
  colors,
  durations,
  fontSizes,
  fontWeights,
  space,
  timingFunctions,
} from "~/theme";

type ImageFieldThumbnailProps = {
  src: string;
  srcThumb: string;
  uploadProgress?: number;
  onRemove?: () => void;
  thumbnailSize?: number | string;
};

const fullSizeCenter = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
} as const;

export function ImageFieldThumbnail({
  src,
  srcThumb,
  uploadProgress,
  onRemove,
  thumbnailSize = "8rem",
}: ImageFieldThumbnailProps) {
  const [isHovering, setIsHovering] = React.useState(false);
  const carouselProps = useAdaptiveCarouselEventHandlers(src);
  const isUploading = isDefined(uploadProgress) ? uploadProgress < 100 : false;

  return (
    <div
      css={css`
        position: relative;
        display: inline-block;
        padding: ${space.xs};
      `}
      onMouseEnter={() => setIsHovering(true)}
      onMouseMove={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <StyledThumbnail
        src={srcThumb}
        disabled={isUploading}
        onFocus={() => setIsHovering(true)}
        css={{ width: thumbnailSize }}
        {...carouselProps}
      >
        <span
          css={css`
            ${fullSizeCenter};
            background: rgba(0, 0, 0, 0.5);
            color: white;
            opacity: ${isUploading ? 1 : 0};
            transition-property: opacity;
            transition-duration: ${durations.lg};
            transition-timing-function: ${timingFunctions.out};
          `}
        >
          <ScaleAppear style={fullSizeCenter}>
            {isUploading && (
              <>
                <Spinner size={26} />

                <span
                  css={css`
                    font-size: ${fontSizes.xxxs};
                    font-weight: ${fontWeights.bold};
                  `}
                >
                  {uploadProgress}%
                </span>
              </>
            )}
          </ScaleAppear>
        </span>
      </StyledThumbnail>

      {onRemove && (
        <div
          css={css`
            position: absolute;
            top: -2px;
            right: -2px;
            width: 20px;
            height: 20px;
            z-index: 1;
          `}
        >
          <ScaleAppear>
            {isHovering && (
              <RemoveButton onClick={onRemove} title="Remove screenshot">
                <Icon name="close" size={16} />
              </RemoveButton>
            )}
          </ScaleAppear>
        </div>
      )}
    </div>
  );
}

const StyledThumbnail = styled.button<{ src: string }>`
  aspect-ratio: 4 / 3;
  background-image: url(${(x) => x.src});
  background-size: cover;
  background-position: center;
  border: 0;
  padding: 0;
  margin: 0;
  display: block;
  position: relative;
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  width: 20px;
  height: 20px;

  background: ${colors.danger};
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition-property: background-color;
  transition-duration: ${durations.sm};
  transition-timing-function: ${timingFunctions.out};

  &:hover {
    background-color: ${darken(0.1, colors.danger)};
  }
`;
