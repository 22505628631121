import React from "react";
import styled from "styled-components";
import { MaxWidthContainer, SiteFooter, SiteHeader } from "~/components";
import { colors, mediaQueries, space } from "~/theme";

export type PageContainerProps = {
  children?: React.ReactNode;
  aside?: React.ReactNode;
  footerBar?: React.ReactNode;
  narrowContent?: boolean;
  grey?: boolean;
  testingName?: string;
  hideSiteFooter?: boolean;
  hero?: React.ReactNode;
};

export function PageContainer({
  aside,
  footerBar,
  narrowContent,
  children,
  grey,
  testingName,
  hideSiteFooter = false,
  hero,
}: PageContainerProps) {
  return (
    <Layout hasAside={!!aside} grey={grey} data-t={testingName}>
      <div css={{ gridArea: "header" }}>
        <SiteHeader fullWidth={!!aside} logoOnly={false} />
      </div>
      {aside && (
        <div css={{ gridArea: "aside" }}>
          <AsideContainer>{aside}</AsideContainer>
        </div>
      )}
      <div
        css={{
          gridArea: "content",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {hero}

        {aside ? (
          children
        ) : (
          <MaxWidthContainer narrow={narrowContent}>
            <VerticalPadding>{children}</VerticalPadding>
          </MaxWidthContainer>
        )}
      </div>
      {footerBar && (
        <FooterBar css={{ gridArea: "footer", zIndex: 1 }}>
          {footerBar}
        </FooterBar>
      )}

      {
        /** Show the baymard footer on pages without aside */
        !aside && !hideSiteFooter && (
          <div css={{ gridArea: "footer" }}>
            <SiteFooter />
          </div>
        )
      }
    </Layout>
  );
}

const VerticalPadding = styled.div`
  padding-top: ${space.lg};
  padding-bottom: ${space.lg};

  @media ${mediaQueries.md} {
    padding-top: ${space.xl};
    padding-bottom: ${space.xl};
  }
`;

const AsideContainer = styled.div`
  @media ${mediaQueries.md} {
    width: 20vw;
    height: 100%;
    min-width: 300px;
    max-width: 380px;

    display: flex;
    flex-direction: column;

    & > * {
      flex: 1;
    }
  }
`;

const FooterBar = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Layout = styled.div<{ hasAside: boolean; grey?: boolean }>`
  background-color: ${(x) => (x.grey ? colors.whiteLilac : "white")};
  max-width: 2070px;
  min-height: 100vh;
  margin: 0 auto;
  display: grid;

  grid-template-areas:
    "header"
    "aside"
    "content"
    "footer";

  grid-template-rows: min-content min-content 1fr;

  @media ${mediaQueries.md} {
    grid-template-areas:
      "header header"
      "aside content"
      "aside footer";

    grid-template-rows: min-content 1fr;
    grid-template-columns: min-content 1fr;
  }
`;
