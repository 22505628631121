import type { JudgementNudgeValue } from "@gemini/common";
import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import {
  AdaptiveCarouselContextProvider,
  Button,
  MarkdownText,
  MeasureHeight,
  Modal,
  SpacedChildren,
  Title,
} from "~/components";
import { useAssessmentStore } from "~/features/assessment";
import {
  JudgementDisplay,
  JudgementSummary,
  NudgeForm,
} from "~/features/assessment/components";
import { useReviewStore } from "~/features/reviews";
import { useUserId } from "~/modules/auth";
import { space } from "~/theme";
import { wrapBackgroundTask } from "~/utils/wrap-background-task";

export const Summary = observer(function Summary() {
  const assessmentStore = useAssessmentStore();
  const reviewStore = useReviewStore();
  const [showNudgeForm, toggleNudgeForm] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);

  const { review } = reviewStore;

  const {
    calculatedTranslationValue,
    submittedAssessmentData,
    isSubmitting,
    masterText,
  } = assessmentStore;

  assert(
    submittedAssessmentData,
    "Can not render Summary without submittedAssessmentData"
  );

  const {
    judgement,
    is_manual_judgement,
    is_issue_resolved,
    judgement_nudge_value,
  } = submittedAssessmentData;
  const userId = useUserId();

  const isNudgeable =
    !is_manual_judgement && !is_issue_resolved && judgement !== "na";

  async function handleNudgeSubmit(nudgeValue: JudgementNudgeValue) {
    assessmentStore.setJudgementNudgeValue(nudgeValue);
    toggleNudgeForm(false);
    wrapBackgroundTask(assessmentStore.submitAssessment(userId));
  }

  const isValueNudged = judgement_nudge_value !== 0;

  return (
    <div style={{ minHeight: showNudgeForm ? modalHeight + 150 : 0 }}>
      <SpacedChildren size="lg">
        <div css={{ marginBottom: space.lg }}>
          <JudgementDisplay
            judgement={judgement}
            isIssueResolved={is_issue_resolved}
            extraLabel={
              is_manual_judgement
                ? "(manually set judgement)"
                : isValueNudged
                  ? "(adjusted)"
                  : undefined
            }
          />
        </div>

        <SpacedChildren css={{ marginBottom: space.lg }}>
          <div>
            <Title level={5}>Assessment output</Title>
            <JudgementSummary
              calculatedTranslationValue={calculatedTranslationValue.value}
              judgement={judgement}
              judgementNudgeValue={judgement_nudge_value}
              isManualJudgement={is_manual_judgement}
            />
          </div>

          {!review.data?.is_read_only && isNudgeable && (
            <React.Fragment>
              <Button
                size="small"
                variant={showNudgeForm ? "secondary" : "tertiary"}
                onClick={() => toggleNudgeForm(!showNudgeForm)}
                testingName="adjust-rating-button"
              >
                Adjust rating
              </Button>

              <Modal
                isOpen={showNudgeForm}
                onClose={() => toggleNudgeForm(false)}
              >
                <MeasureHeight onChange={setModalHeight}>
                  <NudgeForm
                    calculatedTranslationValue={
                      calculatedTranslationValue.value || "neutral"
                    }
                    judgementNudgeValue={judgement_nudge_value}
                    onCancel={() => toggleNudgeForm(false)}
                    onSubmit={(nudgeValue) =>
                      wrapBackgroundTask(handleNudgeSubmit(nudgeValue))
                    }
                    disabled={isSubmitting}
                  />
                </MeasureHeight>
              </Modal>
            </React.Fragment>
          )}
        </SpacedChildren>

        {masterText && (
          <AdaptiveCarouselContextProvider>
            <MarkdownText>{masterText}</MarkdownText>
          </AdaptiveCarouselContextProvider>
        )}
      </SpacedChildren>
    </div>
  );
});
