import type { CropConfig } from "@gemini/common";
import { firebaseConfig } from "~/config";

export type ImageSourceOptions = {
  width?: number;
  height?: number;
  crop?: boolean;
  /** Device pixel ratio - prevents blurry rendering on retina screens */
  dpr?: number;
};

export function getImageSourceFromPath(
  path: string,
  options: ImageSourceOptions = {}
) {
  const url = new URL(`https://${firebaseConfig.projectId}.imgix.net/${path}`);

  url.searchParams.set("auto", "compress,format");

  if (options.width !== undefined) {
    url.searchParams.set("w", options.width.toString());
  }

  if (options.height !== undefined) {
    url.searchParams.set("h", options.height.toString());
  }

  if (options.crop !== undefined) {
    url.searchParams.set("fit", "crop");
  }

  if (options.dpr !== undefined) {
    url.searchParams.set("dpr", options.dpr.toString());
  }

  return url.toString();
}

export const imageSourcePresets: Record<"thumbnail", ImageSourceOptions> = {
  thumbnail: { width: 80, height: 60, crop: true, dpr: 2 },
};

export async function getImageDimensions(file: File) {
  const image = await createImageBitmap(file);

  return {
    width: image.width,
    height: image.height,
  };
}

export async function cropImage(image: File, cropConfig: CropConfig) {
  return new Promise<Blob>((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;

    const img = new Image();
    img.onload = () => {
      ctx?.drawImage(
        img,
        cropConfig.x,
        cropConfig.y,
        cropConfig.width,
        cropConfig.height,
        0,
        0,
        cropConfig.width,
        cropConfig.height
      );

      canvas.toBlob((blob) =>
        blob ? resolve(blob) : reject("Failed to crop image")
      );
    };

    img.src = URL.createObjectURL(image);
  });
}
