import type { Assessment } from "@gemini/common";
import { assessmentFileDirectoryTemplate, setPathParams } from "@gemini/common";
import { uniq } from "lodash-es";
import { observer } from "mobx-react-lite";
import { SpacedChildren } from "~/components";
import { useTypedDocument } from "~/modules/firestore";
import { FileUploadLink } from "./file-upload-link";

export type FileListProps = {
  files: string[];
  pendingFileUploads?: string[];
  filesToDelete?: string[];
  reference: string;
  reviewId: string;
  accountId: string;
  onRemove?: (fileName: string) => void;
};

export const FileList = observer(
  ({
    files,
    pendingFileUploads = [],
    filesToDelete = [],
    reference,
    accountId,
    reviewId,
    onRemove,
  }: FileListProps) => {
    const [assessment] = useTypedDocument<Assessment>(
      `/reviews/${reviewId}/assessments/${reference}`
    );
    const linkedFiles = assessment?.data.files || [];

    return (
      <SpacedChildren as="ul" size="xs">
        {uniq(files.concat(pendingFileUploads)).map((fileName) => {
          const isPartOfDocument = linkedFiles.includes(fileName);
          const isUploading =
            pendingFileUploads.includes(fileName) ||
            (assessment && !isPartOfDocument);

          return (
            <li key={fileName}>
              <FileUploadLink
                name={fileName}
                path={
                  isPartOfDocument
                    ? setPathParams(
                        assessmentFileDirectoryTemplate,
                        {
                          accountId,
                          reviewId,
                          reference,
                        },
                        fileName
                      )
                    : undefined
                }
                isUploading={isUploading}
                isFlaggedForDeletion={filesToDelete.includes(fileName)}
                onRemove={
                  !onRemove || isUploading
                    ? undefined
                    : () => onRemove(fileName)
                }
              />
            </li>
          );
        })}
      </SpacedChildren>
    );
  }
);
