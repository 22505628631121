import {
  detectChange,
  getJudgementDelta,
  type GuidelineLevelComparison,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { AsideBox, Icon, JudgementPill } from "~/components";
import { WithTooltip } from "~/components/with-tooltip";
import { useAssessmentStore } from "~/features/assessment";
import { usePartStore, useReviewStore } from "~/features/reviews";
import { colors } from "~/theme";
import { SectionTitle } from "./assessment-summary";

export const ComputedDifference = observer(function ComputedDifference() {
  const { comparisonResult } = useComparisonResult();
  const { comparisonReview } = useReviewStore();
  const { comparisonAssessment } = usePartStore();
  const { judgement, isIssueResolved, judgementNudgeValue } =
    useAssessmentStore();

  /**
   * There is no need in showing the computed difference if there is no
   * comparison review.
   */
  if (!comparisonReview.data) {
    return null;
  }

  if (!comparisonResult) {
    return (
      <ComputedDifferenceBox>
        There is no valid selection to compare
      </ComputedDifferenceBox>
    );
  }

  return (
    <ComputedDifferenceBox>
      <table
        css={{
          "& td": { color: colors.grayDark },
          "& td + td": { paddingLeft: 10, color: colors.charcoal },
        }}
      >
        <tbody>
          {comparisonResult.judgement_delta !== 0 && (
            <tr>
              <td>
                <span
                  css={{
                    display: "inline-block",
                    position: "relative",
                    top: -2,
                  }}
                >
                  <JudgementPill
                    value={comparisonAssessment?.data.judgement}
                    isIssueResolved={
                      comparisonAssessment?.data.is_issue_resolved
                    }
                    isAbbreviated
                  />
                  <span css={{ position: "relative", top: 2 }}> &#x2192; </span>
                  <JudgementPill
                    value={judgement}
                    isIssueResolved={isIssueResolved}
                    isAbbreviated
                  />
                  {judgementNudgeValue !== 0 && (
                    <span css={{ color: colors.grayMedium }}>
                      {" "}
                      (nudged {judgementNudgeValue})
                    </span>
                  )}
                </span>
              </td>
            </tr>
          )}

          <tr>
            <td>Type of change:</td>
            <td>
              {comparisonResult.change_type ? (
                <strong>{comparisonResult.change_type}</strong>
              ) : (
                "no change"
              )}
            </td>
          </tr>

          {comparisonResult.moved_from_part && (
            <tr>
              <td>Moved from:</td>
              <td>
                <Link
                  href={`/reviews/${comparisonReview.id}/parts/${comparisonResult.moved_from_part.id}`}
                  target="_blank"
                >
                  {comparisonResult.moved_from_part.title}
                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </ComputedDifferenceBox>
  );
});

function ComputedDifferenceBox({ children }: { children: React.ReactNode }) {
  return (
    <AsideBox>
      {children && (
        <>
          <SectionTitle>
            <span css={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                Computed Difference
                <WithTooltip content="The detected difference between this assessment and the one from the comparison review">
                  <span tabIndex={-1}>
                    <Icon
                      name="info"
                      size={16}
                      cx={{ marginLeft: 5, position: "relative", top: 2 }}
                    />
                  </span>
                </WithTooltip>
              </span>
            </span>
          </SectionTitle>

          {children}
        </>
      )}
    </AsideBox>
  );
}

/**
 * Get the comparison result from the server, and if not available (yet)
 * calculate it client-side. The server-side data is more complete as it will
 * also detect how the guideline position changed in the structure.
 */
function useComparisonResult():
  | { isServerSide: true; comparisonResult: GuidelineLevelComparison }
  | {
      isServerSide: false;
      comparisonResult:
        | Omit<GuidelineLevelComparison, "guideline_reference" | "created_at">
        | undefined;
    } {
  const { comparisonResult, comparisonAssessment } = usePartStore();
  const {
    judgement,
    judgementNudgeValue,
    selectedIds,
    hasAssessment,
    isSubmitDisabled,
    isSelectionDirty,
  } = useAssessmentStore();

  const serverSideData = comparisonResult?.data;

  /** Only make comparison result if it doesn't exist yet server-side */
  if (!isSelectionDirty && serverSideData) {
    return { isServerSide: true, comparisonResult: serverSideData };
  }

  /**
   * We can only calculate a comparison result if we have assessment data to
   * compare against. The `isSubmitDisabled` flag indicates that the user has
   * not yet selected any.
   */
  if (!hasAssessment && isSubmitDisabled) {
    return { isServerSide: false, comparisonResult: undefined };
  }

  const subjectAssessment = {
    judgement,
    judgement_nudge_value: judgementNudgeValue,
    selected_scenario_ids: selectedIds,
  };

  const changeType = detectChange(
    subjectAssessment,
    comparisonAssessment?.data
  );

  const hasChanged = !!changeType;
  const judgementDelta = changeType
    ? getJudgementDelta(
        changeType,
        subjectAssessment.judgement,
        comparisonAssessment?.data.judgement
      )
    : undefined;

  return {
    isServerSide: false,
    comparisonResult: {
      has_changed: hasChanged,
      change_type: changeType,
      judgement_delta: judgementDelta,
      /**
       * If the selection is dirty, and there is server-side data, we can still
       * use the moved information from that.
       */
      has_moved: serverSideData?.has_moved ?? false,
      moved_from_part: serverSideData?.moved_from_part,
    },
  };
}
