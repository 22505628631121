import { getErrorMessage } from "@gemini/common";
import React from "react";
import styled, { css } from "styled-components";
import { useAuth } from "~/modules/auth";
import { copyTextToClipboard } from "~/modules/clipboard";
import { space, theme } from "~/theme";
import { BoxWithBorder } from "./box-with-border";
import { ErrorBoundary } from "./error-boundary";
import { PageContainer } from "./page-container";
import { Paragraph, Title } from "./typography";

export function AppErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary
      fallback={(error, errorId) => (
        <ErrorMessage error={error} errorId={errorId} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
}

function ErrorMessage({ error, errorId }: { error: Error; errorId: string }) {
  const { user, claims } = useAuth();
  const errorReport = formatErrorReport(error, {
    error_id: errorId,
    user_id: user?.uid,
    account_id: claims.accountId,
  });

  return (
    <PageContainer grey testingName="app-error-boundary">
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        `}
      >
        <BoxWithBorder
          cx={css`
            max-width: 600px;
            margin: ${space.xl};
          `}
        >
          <Title level={4}>Something broke the Review Tool</Title>
          <Paragraph>
            Our sincerest apologies, but an error occurred which caused the
            Review Tool to crash. Please try to{" "}
            <ReloadButton>reload the page</ReloadButton> and see if that
            resolves the issue.
          </Paragraph>
          <Paragraph>
            If the problem persists, please visit our{" "}
            <a href="https://review-tool.baymard.com/troubleshooting">
              troubleshooting page
            </a>{" "}
            to find out if the problem could be related to your network
            configuration.
          </Paragraph>
          <Paragraph>
            If the network is ok, Baymard should be notified about the issue,
            but feel free to contact us at{" "}
            <a href="mailto:info@baymard.com">info@baymard.com</a> or call
            either our US number +1 (315) 216-7151 or our EU number +45 3696
            9567.
          </Paragraph>
          <Paragraph>Error: {getErrorMessage(error)}</Paragraph>

          <details open={claims.isAdmin}>
            <summary>
              Show error details (
              <span css={css({ fontFamily: "monospace" })}>{errorId}</span>)
            </summary>
            <ErrorMessageContainer
              onClick={() =>
                copyTextToClipboard(
                  errorReport,
                  "The error report is copied to your clipboard"
                )
              }
            >
              {errorReport}
            </ErrorMessageContainer>
          </details>
        </BoxWithBorder>
      </div>
    </PageContainer>
  );
}

const ErrorMessageContainer = styled.pre`
  font-family: ${theme.fonts.monospace} !important;
  font-size: ${theme.fontSizes.xs} !important;
  cursor: copy;
  color: ${theme.colors.grayMedium};
  position: relative;

  &::after {
    content: "Click to copy error report to clipboard";
    display: none;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.grayDark};
    margin-top: ${space.sm};
    position: absolute;
    top: 50px;
    right: 50px;
    left: 50px;
    padding: ${space.lg};
    text-align: center;
    background-color: ${theme.colors.grayLight};
  }

  &:hover {
    &::after {
      display: block;
    }
  }
`;

const ReloadButton = styled.button.attrs({
  onClick: () => window.location.reload(),
})`
  padding: 0;
  margin: 0;
  border: 0;
  background: 0;
  font-family: inherit;
  font-size: inherit;
  text-decoration: underline;
  color: currentColor;
  cursor: pointer;
`;

function formatErrorReport(
  error: Error,
  other: Record<string, string | number | undefined> = {}
) {
  const report = [
    ...Object.entries(other).map(([key, value]) => `${key}: ${value}`),
    "----",
    `url: ${window.location.href}`,
    `platform: ${navigator.platform}`,
    `user agent: ${navigator.userAgent}`,
    `browser dimensions: ${window.innerWidth}x${window.innerHeight}`,
    `screen resolution: ${window.screen.width * window.devicePixelRatio}x${
      window.screen.height * window.devicePixelRatio
    }`,
    `message: ${error.message}`,
    `stacktrace:`,
    error.stack ?? "No stack trace available",
  ];

  return report.join("\n");
}
