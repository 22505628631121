import type { Scenario } from "@gemini/common";
import { getConditionalInstructionTexts } from "@gemini/common";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { isDefined } from "ts-is-present";
import { space } from "~/theme";
import { MarkdownWrapper } from ".";
import { useBranchContext } from "../context";
import { useChildScenarios } from "../hooks";

type ScenarioContentProps = {
  scenario: Scenario;
  expand: boolean;
  children: React.ReactNode;
};

export function ScenarioContent({
  scenario,
  expand,
  children,
}: ScenarioContentProps) {
  const instructionTexts = useInstructionTexts(scenario);
  const hasChildScenarios = useChildScenarios(scenario.id).length > 0;

  // @TODO this can be improved by not calculating padding based on content type
  const paddingTop =
    instructionTexts.length > 0 ? space.sm : hasChildScenarios ? space.md : 0;

  return (
    <AnimatePresence initial={false}>
      {expand && (
        <AnimatedContent key={scenario.id}>
          <div css={{ paddingTop }}>
            <MarkdownWrapper sources={instructionTexts} />
            {children}
          </div>
        </AnimatedContent>
      )}
    </AnimatePresence>
  );
}

function AnimatedContent({ children }: { children: React.ReactNode }) {
  return (
    <motion.div
      style={{
        overflow: "hidden",
        paddingLeft: 1,
        marginLeft: -1,
      }}
      initial={{
        height: 0,
        opacity: 0,
      }}
      animate={{ height: "auto", opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}

function useInstructionTexts(scenario: Scenario) {
  const { industryId, tagIds } = useBranchContext();

  return React.useMemo(
    () =>
      [
        scenario.instruction_text,
        ...(getConditionalInstructionTexts(scenario, industryId, tagIds) || []),
      ].filter(isDefined),
    [scenario, industryId, tagIds]
  );
}
