import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import { Logo } from "~/components";
import { baymard, isProduction } from "~/config";
import { useAuth } from "~/modules/auth";
import {
  colors,
  durations,
  fontSizes,
  mediaQueries,
  sizes,
  space,
  timingFunctions,
} from "~/theme";

const NAV_FONT_SIZE = "15.4px";
const NAV_FONT_PADDING = "11px";

export function SiteHeader({
  fullWidth,
  logoOnly,
}: {
  fullWidth: boolean;
  logoOnly: boolean;
}) {
  return (
    <SiteHeaderContainer>
      <Inner fullWidth={fullWidth}>
        <div css={{ position: "relative", left: -5 }}>
          <LogoLink href={`${baymard.baseUrl}/premium`}>
            <Logo inverted />
          </LogoLink>
        </div>
        {!logoOnly && <ContentNavigation />}
        {!logoOnly && <AccountNavigation />}
      </Inner>
    </SiteHeaderContainer>
  );
}

function ContentNavigation() {
  const { claims } = useAuth();

  return (
    <Nav css={{ fontSize: NAV_FONT_SIZE }}>
      <NavLink href={`${baymard.baseUrl}/premium`}>Dashboard</NavLink>
      <NavLink href={`${baymard.baseUrl}/premium/guideline-collections`}>
        Guidelines
      </NavLink>
      <NavLink href={`${baymard.baseUrl}/premium/training`}>Training</NavLink>
      <NavLink href={`${baymard.baseUrl}/premium/benchmarks`}>
        Benchmarks
      </NavLink>
      <NavLink href={`${baymard.baseUrl}/premium/page-designs`}>
        Page Designs
      </NavLink>
      <div css={{ display: "inline", position: "relative" }}>
        <NavLink href="/" $isActive>
          Review Tool
        </NavLink>
        {claims.isAdmin && (
          <NavLink
            href="/admin"
            css={{
              position: "absolute",
              bottom: -10,
              left: 0,
              fontSize: fontSizes.xxs,
            }}
          >
            → go to Admin
          </NavLink>
        )}
      </div>
      <NavLink href={`${baymard.baseUrl}/premium/your-audits`}>Audits</NavLink>
    </Nav>
  );
}

function AccountNavigation() {
  return (
    <Nav css={{ fontSize: "13.86px", position: "relative", top: "1px" }}>
      <NavLink href={`${baymard.baseUrl}/premium/search?autofocus=true`}>
        Search
      </NavLink>
      <NavLink href={`${baymard.baseUrl}/premium/tags`}>Tags</NavLink>
      <NavLink
        css={css`
          margin-right: -${NAV_FONT_PADDING};
        `}
        href={`${baymard.baseUrl}/premium/account`}
      >
        Account
      </NavLink>
    </Nav>
  );
}

const SiteHeaderContainer = styled.div`
  background: ${isProduction ? colors.blueZodiac : colors.charcoal};
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  padding: 3px 0 4px;
  -webkit-font-smoothing: subpixel-antialiased;
`;

const Inner = styled.div<{ fullWidth: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  ${(x) => ({ maxWidth: x.fullWidth ? undefined : sizes.pageWidth })};

  padding: 0 ${space.md};

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  @media ${mediaQueries.md} {
    padding: 0 ${space.lg};
  }
`;

const Nav = styled.nav``;

const NavLink = styled(Link)<{ $isActive?: boolean }>`
  font-weight: ${(x) => (x.$isActive ? 600 : 400)};
  text-decoration: none;
  line-height: 1.3;

  display: inline-block;
  padding: 0 ${NAV_FONT_PADDING};

  transition-property: background-color, border-color, color;
  transition-duration: ${durations.lg};
  transition-timing-function: ${timingFunctions.out};

  color: ${(x) =>
    x.$isActive ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.9)"};

  &:hover {
    text-decoration: ${(x) => (x.$isActive ? "none" : "underline")};
    cursor: ${(x) => (x.$isActive ? "default" : "pointer")};
  }
`;

const LogoLink = styled(Link)`
  display: block;
  width: 150px;
  height: calc(${NAV_FONT_SIZE} + 2 * ${NAV_FONT_PADDING});
  display: flex;
  align-items: center;
  justify-content: center;
`;
