import type { Platform } from "./schemas";
import { assert } from "./utils";

export function parseCitationCode(citationCode: string) {
  /** https://regex101.com/r/hDnr3s/1 */

  const regex = /^#(\d+)(\w*)$/gm;

  const matches = regex.exec(citationCode);

  assert(matches !== null, `Failed to parse citation code ${citationCode}`);

  const [, universalCode, platformCode] = matches; // 0 is full string match, 1 is first group

  return [
    parseInt(universalCode, 10),
    getPlatformForCode(platformCode),
  ] as const;
}

type PlatformCode = "A" | "D" | "M";

function getPlatformForCode(platformCode: string): Platform | undefined {
  /**
   * @todo Remove allowance of missing platform codes. After guidelines without
   *   platform postfix citation codes have been removed from the system, we
   *   should be able to throw an error on this
   */
  if (!platformCode) {
    return;
  }
  const platformByCode: Record<PlatformCode, Platform> = {
    A: "app",
    D: "desktop",
    M: "mobile",
  };

  const platform = platformByCode[platformCode.toUpperCase() as PlatformCode];

  assert(platform, `Invalid platform code: ${platformCode || "(empty)"}`);

  return platform;
}
