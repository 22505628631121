import {
  causeOfChangeLabels,
  causesOfChange,
  type CauseOfChange,
} from "@gemini/common";

export function sortCauseOfChange<T extends { name: string }>(a: T, b: T) {
  return (
    causesOfChange.indexOf(b.name as CauseOfChange) -
    causesOfChange.indexOf(a.name as CauseOfChange)
  );
}

export function getCauseOfChangeLabel(observation: CauseOfChange) {
  return causeOfChangeLabels[observation];
}

export function isCauseOfChange(value: string): value is CauseOfChange {
  return causesOfChange.includes(value as CauseOfChange);
}
