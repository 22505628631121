import React from "react";
import { createGlobalStyle } from "styled-components";
import { preset } from "~/theme";
import { colors, fonts, fontSizes, lineHeights } from "../theme";
import { reset } from "./reset";

const GlobalStyles = createGlobalStyle<{
  reset: boolean;
  transparent: boolean;
}>`
  ${(x) => x.reset && reset};

  html,
  body {
    margin: 0;
    padding: 0;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    color: ${colors.blueGray};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    height: 100%;
  }

  body {
    ${preset.typography.body1};
    font-size: ${fontSizes.md};
    line-height: ${lineHeights.lg};
    font-family: ${fonts.body};
    background-color: ${(x) =>
      x.transparent ? "transparent" : colors.whiteLilac};
    min-height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${colors.charcoal};
  }

  button {
    font-family: ${fonts.body};
  }

  #antd {
    & p {
      margin: 0;
    }
  }
`;

export type ThemeRootProps = {
  children: React.ReactNode;
  isAdminRoute: boolean;
  isEmbeddedRoute: boolean;
};

export function ThemeRoot({
  children,
  isAdminRoute,
  isEmbeddedRoute,
}: ThemeRootProps) {
  return (
    <>
      <GlobalStyles reset={!isAdminRoute} transparent={isEmbeddedRoute} />
      {children}
    </>
  );
}
