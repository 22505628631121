import type { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import type { ReactNode } from "react";
import React from "react";
import type { UseRefinementListProps } from "react-instantsearch";
import { useRefinementList } from "react-instantsearch";
import { CheckboxWithLabel, DropDownButton } from "~/components";
import type { GuidelineHit } from "../logic";

type RefinementListDropdownProps = {
  label: string;
  attribute: keyof GuidelineHit;
  sortBy?: UseRefinementListProps["sortBy"];
  getLabel?: (item: RefinementListItem) => ReactNode;
};

export function RefinementListDropDown({
  label,
  attribute,
  sortBy,
  getLabel = (x) => `${x.label} (${x.count})`,
}: RefinementListDropdownProps) {
  const { refine, items } = useRefinementList({
    attribute,
    sortBy,
    limit: 999,
  });

  const nbSelected = items.filter((x) => x.isRefined).length;

  const [lastAction, setAction] = React.useState<{
    action: "check" | "uncheck";
    index: number;
  }>();
  const [hoveringIndex, setHoveringIndex] = React.useState(-1);

  return (
    <DropDownButton
      isDisabled={items.length === 0}
      onChange={(isOpen) => {
        if (!isOpen) {
          setAction(undefined);
          setHoveringIndex(-1);
        }
      }}
      label={
        <>
          {label} {nbSelected ? `(${nbSelected})` : ""}
        </>
      }
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          userSelect: "none",
        }}
      >
        {items.map((x, i) => (
          <div key={i}>
            <CheckboxWithLabel
              isHovering={
                hoveringIndex > -1 &&
                lastAction?.action === "check" &&
                indexBetween(i, hoveringIndex, lastAction?.index)
              }
              disabled={x.count === 0 && !x.isRefined}
              checked={
                hoveringIndex > -1
                  ? x.isRefined &&
                    !(
                      lastAction?.action === "uncheck" &&
                      indexBetween(i, hoveringIndex, lastAction?.index)
                    )
                  : x.isRefined
              }
              onClick={(evt) => {
                if (evt.shiftKey) {
                  evt.preventDefault();

                  const start = Math.min(lastAction?.index ?? i, i);
                  const end = Math.max(lastAction?.index ?? i, i);
                  const action = lastAction?.action ?? "check";

                  items.slice(start, end + 1).forEach((x) => {
                    if (x.isRefined !== (action === "check")) {
                      refine(x.value);
                    }
                  });
                }
              }}
              onChange={(checked) => {
                refine(x.value);
                setAction({
                  action: checked ? "check" : "uncheck",
                  index: i,
                });
              }}
              onPointerEnter={(evt) => evt.shiftKey && setHoveringIndex(i)}
              onPointerLeave={() => setHoveringIndex(-1)}
            >
              <span css={{ whiteSpace: "nowrap" }}>{getLabel(x)}</span>
            </CheckboxWithLabel>
          </div>
        ))}
      </div>
    </DropDownButton>
  );
}

function indexBetween(index: number, a: number, b: number) {
  return index >= Math.min(a, b) && index <= Math.max(a, b);
}
