import styled from "styled-components";
import { useHotkey } from "~/modules/hooks";
import { colors, fontSizes, fontWeights, space } from "~/theme";

type ExpandButtonProps = {
  isExpanded: boolean;
  onClick: () => void;
};

export function ExpandButton({ isExpanded, onClick }: ExpandButtonProps) {
  useHotkey(["command+e", "control+e"], onClick);

  return (
    <StyledExpandButtonContainer>
      <StyledExpandButton onClick={onClick}>
        {isExpanded ? "Collapse all" : "Expand all"}
      </StyledExpandButton>
    </StyledExpandButtonContainer>
  );
}

const StyledExpandButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
`;

const StyledExpandButton = styled.button`
  padding: ${space.xs} ${space.sm};
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  position: absolute;
  transform: translateY(-100%);
  font-size: ${fontSizes.sm};
  white-space: nowrap;
  color: ${colors.slateGray};
  text-decoration: underline;
  font-weight: ${fontWeights.bold};
`;
