import {
  type CauseOfChange,
  causeOfChangeSentenceParts,
  type GuidelineObservation,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { AsideBox, Icon, Text } from "~/components";
import { WithTooltip } from "~/components/with-tooltip";
import { usePartStore, useReviewStore } from "~/features/reviews";
import { fontWeights, space } from "~/theme";
import { SectionTitle } from "./assessment-summary";
import { ComparisonAssessmentSummary } from "./comparison-assessment-summary";

export const AuditorObservationResult = observer(
  function AuditorObservationResult({
    observation,
  }: {
    observation:
      | { cause_of_change: CauseOfChange; text?: string }
      | GuidelineObservation
      | undefined;
  }) {
    const { guideline, comparisonAssessment } = usePartStore();
    const { reviewComparison } = useReviewStore();

    if (!observation || !reviewComparison.data || !guideline?.data) {
      return null;
    }

    const reviewId = reviewComparison.data.comparison_review_ids.at(0);
    const title = reviewComparison.data.review_titles[reviewId!];
    const url = `/reviews/${reviewId}/guideline/${guideline?.data.citation_code.replace("#", "")}`;

    return (
      <AsideBox>
        <SectionTitle>
          <span css={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              Observation
              <WithTooltip content="The observed difference between this assessment and the one it is compared against">
                <span tabIndex={-1}>
                  <Icon
                    name="info"
                    size={16}
                    cx={{ marginLeft: 5, position: "relative", top: 2 }}
                  />
                </span>
              </WithTooltip>
            </span>
          </span>
        </SectionTitle>

        <p css={{ marginTop: space.sm, whiteSpace: "pre-line" }}>
          Differs from{" "}
          <Link target="_blank" href={url}>
            {title}
          </Link>{" "}
          due to {causeOfChangeSentenceParts[observation.cause_of_change]}.
        </p>

        {observation.text && (
          <p css={{ marginTop: 10, whiteSpace: "pre-line" }}>
            <strong>Additional information:</strong>
            <br />
            {observation.text}
          </p>
        )}

        {comparisonAssessment?.data && (
          <details css={{ marginTop: space.md }}>
            <summary css={{ marginBottom: space.sm, cursor: "pointer" }}>
              <Text variant="button" cx={{ fontWeight: fontWeights.semibold }}>
                Show compared assessment
              </Text>
            </summary>

            <ComparisonAssessmentSummary contentOnly />
          </details>
        )}
      </AsideBox>
    );
  }
);
