export function makeQueryString(
  params: { [key: string]: string | number | boolean | undefined } = {}
) {
  const queryString = new URLSearchParams();

  Object.entries(params)
    .filter(([, value]) => typeof value !== undefined)
    .forEach(([name, value]) => queryString.append(name, String(value)));

  return queryString.toString();
}
