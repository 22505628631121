import { typedObjectKeys } from "./utils/typed-object-keys";

export const BAYMARD_SYSTEM_USER_ID = "__baymard_system_user";
export const BAYMARD_SYSTEM_USER_NAME = "a Baymard system";
export const BAYMARD_EMPLOYEE_USER_ID = "__baymard_employee";
export const BAYMARD_EMPLOYEE_USER_NAME = "a Baymard researcher";
export const BAYMARD_ACCOUNT_ID = "1";
export const DELETED_USER_NAME = "a former user";

/**
 * Some parts of the code like guideline counterpart linking use this magic
 * string so we can query the ones that have no counterpart. Using undefined
 * would no allow that.
 */
export const NO_REFERENCE = "__no_reference";

export const activeIndustries = {
  __no_industry: "None",
  apparel: "Apparel & Accessories",
  automotive: "Automotive",
  banking: "Banking",
  b2b_medical_and_pharma: "B2B Medical & Pharma",
  b2b_construction: "B2B Construction",
  b2b_components_and_machinery: "B2B Components & Machinery",
  digital_services: "Digital Subscriptions & SaaS",
  electronics: "Electronics",
  furniture: "Furniture",
  groceries: "Groceries",
  health_and_beauty: "Health & Beauty",
  hobbies: "Hobbies",
  home_and_hardware: "Home Improvement & Hardware",
  houseware: "Houseware",
  insurance: "Insurance",
  mass_merchant: "Mass Merchant",
  meal_kit_subscriptions: "Meal Kit Subscriptions",
  merchandise_and_print_to_order: "Merchandise & Print-to-Order",
  office: "Office",
  online_learning: "Online Learning",
  sports: "Sports",
  takeout_and_food_delivery: "Takeout & Food Delivery",
  telco: "Telco",
  toys: "Toys",
  travel_tours_and_experiences: "Travel Tours & Experiences",
  travel_and_hospitality: "Travel Accommodations",
  travel_transportation: "Travel Transportation",
  vitamins_and_supplements: "Vitamins & Supplements",
} as const;

export const legacyIndustries = {
  /** Add legacy industries for data migration */
  consumable_subscription_services: "Consumable Subscription Services",
  electronics_and_office: "Electronics & Office",
  apparel_and_accessories: "Apparel & Accessories",
  houseware_and_furnishing: "Houseware & Furnishing",
  sport_toys_and_hobbies: "Sport, Toys & Hobbies",
  business_to_business: "Business to Business",
} as const;

export const allIndustries = Object.assign(
  {},
  activeIndustries,
  legacyIndustries
);

export type IndustryId = keyof typeof activeIndustries;
export type LegacyIndustryId = keyof typeof legacyIndustries;

export const activeIndustryIds = typedObjectKeys(activeIndustries);

export const legacyIndustryIds = typedObjectKeys(legacyIndustries);

export const industrySelectOptions = Object.entries(activeIndustries);

export const allIndustryIds = Object.keys(allIndustries) as Array<
  IndustryId | LegacyIndustryId
>;

export const tags = {
  visually_driven: {
    name: "Visually driven",
    description:
      "Product catalog consists almost entirely (85%+) of products which are mainly sold by their aesthetics (e.g. apparel, home decor, art)",
  },
  spec_driven: {
    name: "Spec driven",
    description:
      "Product catalog consists almost entirely (85%+) of products which are mainly sold by specific non-visual features and specifications (e.g. some electronics, tools & hardware, drugs)",
  },
  single_brand: {
    name: "Single brand",
    description:
      "Product catalog is exclusively from one brand, typically the company’s own (e.g. Nike.com)",
  },
  small_catalog: {
    name: "Narrow product selection",
    description:
      "Product catalog is either very small (typically below 25 products) or extremely niche (e.g. only sells fishing rods), or both",
  },
  luxury_products: {
    name: "Luxury Products",
    description:
      "The site sells only luxury high-end products at high price points for the given product type (Gucci, Tiffanys, Cartier, Dior, etc.)",
  },
  consumable_subscription_services: {
    name: "Consumable Subscription Services",
    description:
      "Consumables and replenishment subscription service sites that offer physical products on a subscription basis (e.g. box of groceries, fashion or razor subscription, etc.)",
  },
} as const;

export type TagId = keyof typeof tags;

export const tagIds = Object.keys(tags) as TagId[];

export const ruleActions = {
  include: "Include this scenario",
  exclude: "Exclude this scenario",
  value: "Alter the translation value of this scenario",
  pointScore: "Alter the point score of this scenario",
  preAssess: "Pre-assess this guideline based on this scenario selection",
} as const;

export type RuleAction = keyof typeof ruleActions;

export const reviewTypes = [
  "self-review",
  "audit",
  "case-study",
  "training",
] as const;

export type ReviewType = (typeof reviewTypes)[number];

export const translationValues = [
  "vh",
  "vl",
  "neutral",
  "al",
  "ah",
  "ir",
  "na",
] as const;

export const AH_VALUE = 2;
export const AL_VALUE = 1;
export const NU_VALUE = 0;
export const VL_VALUE = -1;
export const VH_VALUE = -2;
export const NA_VALUE = "na";

export type TranslationValue = (typeof translationValues)[number];

export const judgementValues = [
  AH_VALUE,
  AL_VALUE,
  NU_VALUE,
  VL_VALUE,
  VH_VALUE,
  NA_VALUE,
] as const;

export type JudgementValue = (typeof judgementValues)[number];

export const judgementLabels: Record<JudgementValue, string> = {
  [AH_VALUE]: "Adhered High",
  [AL_VALUE]: "Adhered Low",
  [NU_VALUE]: "Neutral",
  [VL_VALUE]: "Violated Low",
  [VH_VALUE]: "Violated High",
  [NA_VALUE]: "Not Applicable",
} as const;

export type JudgementShortcode = "ah" | "al" | "nu" | "vl" | "vh" | "na";
export const judgementShortcodes: Record<JudgementValue, JudgementShortcode> = {
  [AH_VALUE]: "ah",
  [AL_VALUE]: "al",
  [NU_VALUE]: "nu",
  [VL_VALUE]: "vl",
  [VH_VALUE]: "vh",
  [NA_VALUE]: "na",
} as const;

export const judgementNudgeValues = [-2, -1, 0, 1, 2] as const;

export type JudgementNudgeValue = (typeof judgementNudgeValues)[number];

export const reviewUpdateTypes = [
  "silent",
  "confirm",
  "assess",
  "add",
  "remove",
] as const;

export type ReviewUpdateType = (typeof reviewUpdateTypes)[number];

export const platforms = ["app", "mobile", "desktop"] as const;

/**
 * This needs to be an object because we need to reference them when passing to
 * pubsub()
 */
export const pubSubTopics = {
  applySilentUpdates: "applySilentUpdates",
  calculatePartProgress: "calculatePartProgress",
  calculatePublishAffectedReviews: "calculatePublishAffectedReviews",
  clearScorecardCache: "clearScorecardCache",
  createPendingUpdate: "createPendingUpdate",
  excludeAndPreAssess: "excludeAndPreAssess",
  recalculatePendingUpdatesInfo: "recalculatePendingUpdatesInfo",
} as const;

export type PubSubTopicName = keyof typeof pubSubTopics;

export const viewStructureSqlIds = [
  "benchmark-v1",
  "benchmark-v2-sans-self-service-and-mobile",
  "benchmark-v2-sans-self-service",
  "benchmark-v2",
  "benchmark-v3-sans-self-service",
  "benchmark-v3",
  "cart-and-checkout-v1",
  "cart-and-checkout-v2",
  "cross-selling-v1",
  "glamnetic-v1",
  "homepage-and-category-v1",
  "image-personalization-v1",
  "mobile-v1",
  "mobile-v2",
  "product-lists-v1",
  "product-page-v1",
  "search-v1",
  "self-service-v1",
  "travel-v1",
  "account-only-v1",
  "fb-micro-audit-form-heavy-sign-up-flow-v1",
  "fb-micro-audit-traditional-ecom-v1",
  "full-site-audit-v1",
  "mobile-v3",
  "benchmark-v4",
  "full-site-audit-v1-the-500-to-rate-v1",
  "benchmark-v5",
  "full-site-audit-v2-the-500-to-rate-v1",
  "full-site-audit-v2",
  "mobile-v4",
  "google-micro-audit-v1",
] as const;

export type ViewStructureSqlId = (typeof viewStructureSqlIds)[number];

export const assessmentFileDirectoryTemplate =
  "account/{accountId}/reviews/{reviewId}/assessments/{reference}/files";

export const auditReleaseFileDirectoryTemplate =
  "account/{accountId}/reviews/{reviewId}/audit-release";

export const auditReleaseFileDirectoryTemplateParams = [
  "accountId",
  "reviewId",
] as const;

export const assessmentFileUploadDirectoryTemplate =
  "account-temp/{accountId}/reviews/{reviewId}/assessments/{reference}/files";

export const assessmentFileDirectoryTemplateParams = [
  "accountId",
  "reviewId",
  "reference",
] as const;

export const assessmentImageDirectoryTemplate =
  "reviews/{reviewId}/assessments/{reference}/images";

export type CropConfig = {
  x: number;
  y: number;
  width: number;
  height: number;
};
