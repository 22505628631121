import { wrap } from "@popmotion/popcorn";
import React from "react";
import { useHotkey } from "~/modules/hooks";
import { preloadImage } from "./logic";

/**
 * Animation + logic is copy pasted from
 * https://codesandbox.io/s/framer-motion-image-gallery-pqvx3
 */

export type Direction = -1 | 0 | 1;

export type CarouselNavigation = {
  page: number;
  direction: Direction;
  hasMultipleItems: boolean;
  showIndex: (index: number) => void;
  hide: () => void;
  next: () => void;
  prev: () => void;
};

export function useCarouselNavigation(sources: string[], onClose?: () => void) {
  const [isActive, setIsActive] = React.useState(false);
  const [[page, dir], setPage] = React.useState<[number, Direction]>([0, 0]);

  const srcIndex = wrap(0, sources.length, page);
  const src = isActive ? sources[srcIndex] : undefined;

  const paginate = React.useCallback(
    (newDirection: -1 | 1) => {
      if (isActive && sources.length > 1) {
        setPage(([page]) => [page + newDirection, newDirection]);
      }
    },
    [isActive, sources.length]
  );

  const showIndex = React.useCallback((index: number) => {
    setIsActive(true);
    setPage([index, 0]);
  }, []);

  const hide = React.useCallback(() => {
    setIsActive(false);
    if (onClose) onClose();
  }, [onClose]);

  useHotkey("left", () => paginate(-1), { allowRepeat: true, disabled: !src });
  useHotkey("right", () => paginate(+1), { allowRepeat: true, disabled: !src });

  React.useEffect(() => {
    if (isActive) {
      // preload the next and previous image in the carousel
      preloadImage(sources[wrap(0, sources.length, page - 1)]);
      preloadImage(sources[wrap(0, sources.length, page + 1)]);
    }
  }, [isActive, page, sources]);

  return {
    src,
    navigation: {
      page,
      showIndex,
      hide,
      hasMultipleItems: sources.length > 1,
      next: React.useCallback(() => paginate(+1), [paginate]),
      prev: React.useCallback(() => paginate(-1), [paginate]),
      direction: dir,
    },
  };
}
