import { AnimatePresence, motion } from "framer-motion";
import { BoxWithBorder, type BoxWithBorderProps } from "~/components";
import { fontSizes, space } from "~/theme";

const MotionBoxWithBorder = motion(BoxWithBorder);

export type AsideBoxProps = BoxWithBorderProps;

export function AsideBox({ children, ...props }: AsideBoxProps) {
  return (
    <AnimatePresence initial={false}>
      {children && (
        <MotionBoxWithBorder
          {...props}
          gray
          flat
          exit="hidden"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { height: 0, opacity: 0, marginBottom: "0rem" },
            visible: { height: "auto", opacity: 1, marginBottom: space.md },
          }}
          style={{
            overflow: "hidden",
            fontSize: fontSizes.sm,
            height: "100%",
          }}
        >
          {children}
        </MotionBoxWithBorder>
      )}
    </AnimatePresence>
  );
}
