import type { Platform } from "@gemini/common";
import { platforms } from "@gemini/common";

const platformOrder = ["desktop", "mobile", "app"];
export function sortPlatforms<T extends { name: string }>(a: T, b: T) {
  return platformOrder.indexOf(a.name) - platformOrder.indexOf(b.name);
}

export function getPlatformLabel(platform: Platform) {
  switch (platform) {
    case "app":
      return "Mobile App";
    case "mobile":
      return "Mobile Web";
    case "desktop":
      return "Desktop";
  }
}

export function isPlatform(x: string): x is Platform {
  return platforms.includes(x as Platform);
}
