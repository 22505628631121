import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import styled from "styled-components";
import { PageContainer, PageLoader, SpacedChildren } from "~/components";
import { PerformanceChartWithCompare } from "~/components/performance-chart-with-compare";
import { mediaQueries, space } from "~/theme";
import { useReviewStore } from "../store";
import { GuidelineList } from "./components";
import { PartSidebar } from "./sidebar";
import { usePartStore } from "./store";

export const ReviewPartOverviewPage: NextPage = observer(() => {
  const router = useRouter();
  const partStore = usePartStore();
  const { review } = useReviewStore();

  const aside = <PartSidebar />;
  const reviewId = router.query.reviewId as string;

  if (partStore.isLoading) {
    return <PageLoader aside={aside} />;
  }

  const { part, viewStructure, guidelinesForPart } = partStore;

  if (!part) {
    return (
      <PageContainer testingName="overview-page-no-data">
        No data for part {router.query.partId}
      </PageContainer>
    );
  }

  return (
    <PageContainer aside={aside} testingName="overview-page">
      <Content>
        <SpacedChildren size="lg">
          {viewStructure && (
            <PerformanceChartWithCompare
              review={review.document}
              viewStructure={viewStructure}
              hideAssumedRange={
                review?.data?.chart_options?.is_assumed_range_hidden
              }
              isEmbeddedInPart={true}
            />
          )}
          <GuidelineList reviewId={reviewId} guidelines={guidelinesForPart} />
        </SpacedChildren>
      </Content>
    </PageContainer>
  );
});

const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  max-width: 1048px;
  padding: ${space.md};

  @media ${mediaQueries.md} {
    padding-top: 7.8rem;
    padding-bottom: ${space.xl};
    padding-left: ${space.lg};
    padding-right: ${space.lg};
  }

  @media ${mediaQueries.xl} {
    max-width: 1348px;

    padding-top: 7.8rem;
    padding-bottom: ${space.xl};
    padding-left: ${space.xl};
    padding-right: ${space.xl};
  }
`;
