import React from "react";
import styled from "styled-components";
import { copyTextToClipboard } from "~/modules/clipboard";
import {
  colors,
  durations,
  fonts,
  fontSizes,
  radii,
  timingFunctions,
} from "~/theme";

export function CopyScenarioIdButton({ id }: { id: string }) {
  return (
    <StyledCopyScenarioIdButton
      title={`Copy ${id} to clipboard`}
      type="button"
      onClick={(evt) => {
        evt.stopPropagation();
        copyTextToClipboard(id, `Copied "${id}" to clipboard`);
      }}
    >
      {id}
    </StyledCopyScenarioIdButton>
  );
}

const StyledCopyScenarioIdButton = styled.button`
  color: ${colors.grayDark};
  font-family: ${fonts.monospace};
  font-size: ${fontSizes.xxs};
  padding: 4px 6px;
  margin: 0;
  border: 0;
  border-radius: ${radii.sm};
  background: none;
  cursor: pointer;
  white-space: nowrap;

  transition-property: background-color, color;
  transition-duration: ${durations.lg};
  transition-timing-function: ${timingFunctions.out};

  &:hover {
    background: ${colors.athensGray};
  }
`;
