import type { StandardBehaviorOptions } from "scroll-into-view-if-needed";
import scrollIntoView from "scroll-into-view-if-needed";

export function confirmUnload() {
  const handleBeforeunload = (evt: BeforeUnloadEvent) => {
    evt.preventDefault();
    evt.returnValue = "";
  };
  window.addEventListener("beforeunload", handleBeforeunload);
  return () => {
    window.removeEventListener("beforeunload", handleBeforeunload);
  };
}

export function maybeScrollIntoView<T extends Element>(
  el: T | null,
  options?: StandardBehaviorOptions
) {
  if (el) {
    scrollIntoView(el, {
      behavior: "smooth",
      scrollMode: "if-needed",
      block: "nearest",
      inline: "nearest",
      ...options,
    });
  }
}
