import { useEffect, useMemo } from "react";

/** Transforms a FileList or DataTransferItemList to an array of files */
export function getFileArray(
  list: DataTransferItemList | FileList | undefined | null
) {
  if (!list) return [];

  const files: File[] = [];

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const file = item instanceof DataTransferItem ? item.getAsFile() : item;

    if (file) {
      files.push(file);
    }
  }

  return files;
}

/**
 * It reads a File instance into memory. This prevents the file from becoming
 * inaccessible when it gets deleted from the file system before it is
 * uploaded.
 */
export async function readFileIntoMemory(file: File) {
  return new Promise<File>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(
        new File([new Uint8Array(reader.result as ArrayBuffer)], file.name, {
          type: file.type,
        })
      );
    };

    reader.onerror = function () {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export function useObjectUrl(object: Blob | MediaSource) {
  const url = useMemo(() => URL.createObjectURL(object), [object]);

  /** We need to revoke an old URL to allow garbage collection. */
  useEffect(() => {
    return url ? () => URL.revokeObjectURL(url) : undefined;
  }, [url]);

  return url;
}
