import { first, isUndefined, omit, pick } from "lodash-es";
import { outdent } from "outdent";
import { isPresent } from "ts-is-present";
import type {
  JudgementNudgeValue,
  JudgementValue,
  TranslationValue,
} from "./constants";
import { getRuling } from "./rules";
import type {
  Bipolar,
  DeliverableMasterTexts,
  DeliverableTextSegment,
  GuidelineEditBuffer,
  ReviewContext,
  Scenario,
  ScenarioMap,
} from "./schemas";
import {
  calculateTranslationValueRecursively,
  getNumberFromTranslationValue,
  getTranslationValueForContext,
  isPointScore,
} from "./translation-value";
import { assert } from "./utils";

const TWO_NEWLINES = "\n\n";

/**
 * Take or calculate the translation value for the scenario, and return the
 * master text segments from the matching polarity. Then drill down, returning
 * the texts from the selected children using the same strategy.
 */
export function getDeliverableTextSegmentsRecursively({
  scenarioId,
  allScenarios,
  allSelectedScenarioIds,
  reviewContext,
}: {
  scenarioId: string;
  allScenarios: ScenarioMap;
  allSelectedScenarioIds: string[];
  reviewContext: ReviewContext;
}): DeliverableTextSegment[] {
  const scenario = allScenarios[scenarioId];

  assert(scenario, `Failed to find scenario for id ${scenarioId}`);

  /** Pick only the scenarios that are selected children of the given scenarioId */
  const selectedChildScenarios = Object.values(allScenarios)
    .filter((scenario) => scenario.parent_id === scenarioId)
    .filter((scenario) => allSelectedScenarioIds.includes(scenario.id))
    /**
     * Sorting is required for the texts to appear in order of scenario position
     * instead of sequence of selection events.
     */
    .sort((a, b) => a.position - b.position);

  const hasChildSelection = selectedChildScenarios.length > 0;

  if (!hasChildSelection && scenarioId === "__root") {
    return [];
  }

  const ownValue = getTranslationValueForContext(scenario, reviewContext);

  const { value: calculatedValue } = calculateTranslationValueRecursively({
    scenarioId,
    allScenarios,
    allSelectedScenarioIds,
    reviewContext,
  });

  /**
   * If there is no translation value to be found, we return nothing, because we
   * do not use fallbacks / default polarity anymore. The calculated value will
   * be the same as own value if no children are selected.
   */
  if (!calculatedValue) {
    return [];
  }

  const isLeafNode = !scenario.has_children;

  if (isLeafNode) {
    /**
     * If something is a leaf node, the logic setting does not matter (as it
     * only applies to children) so we can return the text matching the
     * calculated value polarity.
     */
    return getDeliverableTextSegmentsForScenario({
      scenario,
      valueOrPointScore: calculatedValue,
      isLeafNode,
    });
  }

  switch (scenario.logic) {
    case "points": {
      /**
       * For points we return the parent segments plus segments from:
       *
       * - All selected children with normal behavior
       * - All unselected children with inverted behavior
       */
      const relevantChildScenarios = Object.values(allScenarios)
        .filter((scenario) => scenario.parent_id === scenarioId)
        .filter(
          (scenario) =>
            (allSelectedScenarioIds.includes(scenario.id) &&
              !scenario.use_master_text_if_unchecked) ||
            (!allSelectedScenarioIds.includes(scenario.id) &&
              scenario.use_master_text_if_unchecked)
        )
        .sort((a, b) => a.position - b.position);

      const segments: DeliverableTextSegment[] = [
        ...getDeliverableTextSegmentsForScenario({
          scenario,
          valueOrPointScore: ownValue ?? calculatedValue,
          isLeafNode,
        }),
        ...relevantChildScenarios.flatMap((scenario) => {
          /**
           * First we need to see what the actual point score is based on the
           * review context
           */
          const { overridePointScore } = getRuling(
            scenario,
            reviewContext.industryId,
            reviewContext.tagIds
          );

          const pointScore = overridePointScore ?? scenario.point_score;

          if (isUndefined(pointScore)) {
            return [];
          }

          return getDeliverableTextSegmentsForScenario({
            scenario,
            valueOrPointScore: pointScore,
            isLeafNode,
          });
        }),
      ];

      return segments;
    }
    case "and": {
      /** For AND we return the parent segments + all selected child segments. */
      const segments: DeliverableTextSegment[] = [
        ...getDeliverableTextSegmentsForScenario({
          scenario,
          valueOrPointScore: ownValue ?? calculatedValue,
          isLeafNode,
        }),
        ...selectedChildScenarios.flatMap((x) =>
          getDeliverableTextSegmentsRecursively({
            scenarioId: x.id,
            allScenarios,
            allSelectedScenarioIds,
            reviewContext,
          })
        ),
      ];

      return segments;
    }
    case "or": {
      /**
       * For OR we only return the texts from the selected children (if there
       * are any). Master texts on non-leaf OR scenarios are not supported. They
       * can exist as a result of admin edits, but should otherwise be ignored.
       *
       * With OR logic we can assume a single selected child at most.
       */
      const selectedChildScenario = first(selectedChildScenarios);

      if (selectedChildScenario) {
        const segments: DeliverableTextSegment[] = [
          ...getDeliverableTextSegmentsRecursively({
            scenarioId: selectedChildScenario.id,
            allScenarios,
            allSelectedScenarioIds,
            reviewContext,
          }),
        ];

        return segments;
      }
    }
    default:
      throw new Error(`Unsupported logic ${scenario.logic}`);
  }
}

/**
 * Get the relevant master text segments from a scenario based on its
 * translation value or point score. For branch nodes we do not return any
 * presentation texts.
 */
function getDeliverableTextSegmentsForScenario({
  scenario,
  valueOrPointScore,
  isLeafNode,
}: {
  scenario: Scenario;
  valueOrPointScore: TranslationValue | number;
  isLeafNode: boolean;
}): DeliverableTextSegment[] {
  const isIssue = isPointScore(valueOrPointScore)
    ? valueOrPointScore < 0
    : getNumberFromTranslationValue(valueOrPointScore) < 0;

  const { master_text: legacyText } = scenario;

  const { positive, negative } = scenario.master_texts || {};

  const { intro, recommendation } = (isIssue ? negative : positive) ?? {};

  const segments: DeliverableTextSegment[] = [];

  if (!intro && !recommendation && legacyText) {
    segments.push({
      type: "legacy",
      text: legacyText,
      id: scenario.id,
      parent_id: scenario.parent_id,
    });
  }

  if (intro) {
    segments.push({
      type: "intro",
      text: intro,
      id: scenario.id,
      parent_id: scenario.parent_id,
      polarity: isIssue ? "negative" : "positive",
    });
  }

  if (isIssue) {
    /** For issues, we expect a recommendation text to be present. */
    if (!recommendation) {
      console.error(
        new Error(
          `Missing recommendation text for issue scenario ${scenario.id}`
        )
      );
    } else {
      segments.push({
        type: "recommendation",
        text: recommendation,
        id: scenario.id,
        parent_id: scenario.parent_id,
        polarity: "negative",
      });
    }

    /**
     * For leaf scenarios that are considered issues, we return presentation
     * texts.
     */
    if (isLeafNode) {
      const { custom_guideline_title, company_text_template, presenter_notes } =
        scenario.master_texts?.negative ?? {};

      if (custom_guideline_title) {
        segments.push({
          type: "custom_guideline_title",
          text: custom_guideline_title,
          id: scenario.id,
          parent_id: scenario.parent_id,
          polarity: "negative",
        });
      }

      if (company_text_template) {
        segments.push({
          type: "company_text_template",
          text: company_text_template,
          id: scenario.id,
          parent_id: scenario.parent_id,
          polarity: "negative",
        });
      }

      /** @todo Remove this after migrated to guideline root */
      if (presenter_notes) {
        segments.push({
          type: "presenter_notes",
          text: presenter_notes,
          id: scenario.id,
          parent_id: scenario.parent_id,
          polarity: "negative",
        });
      }
    }
  }

  return segments;
}

/**
 * Get the final markdown text for the summary page and scorecard api response.
 *
 * We take all segments and try to create a text using the new intro and
 * recommendation type segments (which do not include header). If they do not
 * exist for the given scenarios, we fall back to the legacy text which
 * typically does include headers.
 */
export function compileMasterTextMarkdown({
  segments,
  judgement,
  isManualJudgement,
  judgementNudgeValue,
}: {
  segments: DeliverableTextSegment[];
  judgement: JudgementValue;
  isManualJudgement: boolean;
  judgementNudgeValue: JudgementNudgeValue;
}) {
  const isNotApplicable = judgement === "na";
  const isIssue = !isNotApplicable && judgement < 0;

  /**
   * Master text segments could in theory be a mixture of positive and negative.
   * What type of headers should be used can therefore only be determined by the
   * final judgement value. If the value was nudged, or the rating was set
   * manually, then we should either not output the master texts, or add a
   * warning that the text might be (partially) irrelevant
   */
  return isManualJudgement
    ? MARKDOWN_MANUAL_JUDGEMENT
    : judgementNudgeValue !== 0
      ? outdent`
      ${MARKDOWN_NUDGE_WARNING}

      ${formatMarkDownFromSegments({ segments, isIssue, isNotApplicable })}
    `
      : formatMarkDownFromSegments({ segments, isIssue, isNotApplicable });
}

const MARKDOWN_MANUAL_JUDGEMENT = outdent`
  ### Manual Judgement

  For manually rated guidelines we can not provide any analysis or recommendation
`;

const MARKDOWN_NUDGE_WARNING = outdent`
  ### Warning

  You have nudged the judgement value in either positive or negative direction, therefore the provided text below might be (partially) irrelevant to your situation.
`;

export const POSITIVE_INTRO_HEADER_TEXT = "Why is this good UX?";
export const NEGATIVE_INTRO_HEADER_TEXT = "What's the UX issue?";
export const NEGATIVE_RECOMMENDATION_HEADER_TEXT = "How to improve the UX?";
export const NA_INTRO_HEADER_TEXT = "Why is this not applicable?";

/** @todo Use the polarity to group segments and use headers accordingly. */
function formatMarkDownFromSegments({
  segments,
  isIssue,
  isNotApplicable,
}: {
  segments: DeliverableTextSegment[];
  isIssue: boolean;
  isNotApplicable: boolean;
}) {
  const introText = segments
    .filter((x) => x.type === "intro" && !!x.text)
    .map((x) => x.text)
    .join(TWO_NEWLINES);

  const recommendationText = segments
    .filter((x) => x.type === "recommendation" && !!x.text)
    .map((x) => x.text)
    .join(TWO_NEWLINES);

  const hasIntroText = introText !== "";
  const hasRecommendationText = recommendationText !== "";
  const shouldUseNewTexts = hasIntroText || hasRecommendationText;

  let result = "";

  if (shouldUseNewTexts) {
    if (hasIntroText) {
      if (isNotApplicable) {
        return outdent`
        ### ${NA_INTRO_HEADER_TEXT}

        ${introText}
      `;
      } else {
        result = isIssue
          ? outdent`
        ### ${NEGATIVE_INTRO_HEADER_TEXT}

        ${introText}
      `
          : outdent`
        ### ${POSITIVE_INTRO_HEADER_TEXT}

        ${introText}
      `;
      }
    }
    if (hasRecommendationText) {
      result += outdent`

        ### ${NEGATIVE_RECOMMENDATION_HEADER_TEXT}

        ${recommendationText}
      `;
    }

    return result;
  } else {
    return segments
      .filter((x) => x.type === "legacy")
      .map((x) => x.text)
      .join(TWO_NEWLINES);
  }
}

/** Check for presence of text in the picked fields in both polarities */
function hasSomeMasterTexts(texts?: Bipolar<DeliverableMasterTexts>) {
  return (
    texts &&
    (Object.values(texts.positive ?? {}).some(isPresent) ||
      Object.values(texts.negative ?? {}).some(isPresent))
  );
}

/** Check for presence of text in the picked fields in both polarities */
export function hasSomeOfTheseMasterTexts(
  texts: Bipolar<DeliverableMasterTexts> | undefined,
  pickFields: Array<keyof DeliverableMasterTexts>
) {
  return (
    texts &&
    (Object.values(pick(texts.positive, pickFields)).some(isPresent) ||
      Object.values(pick(texts.negative, pickFields)).some(isPresent))
  );
}

/** Check for presence of text in both polarities apart from the given fields. */
function hasSomeApartFromTheseMasterTexts(
  texts: Bipolar<DeliverableMasterTexts> | undefined,
  omitFields: Array<keyof DeliverableMasterTexts>
) {
  return (
    texts &&
    (Object.values(omit(texts.positive, omitFields)).some(isPresent) ||
      Object.values(omit(texts.negative, omitFields)).some(isPresent))
  );
}

export function hasUnusedMasterTexts(
  scenarioId: string,
  allScenarios: ScenarioMap
) {
  const {
    has_children,
    logic,
    master_text: legacyText,
    master_texts,
  } = allScenarios[scenarioId];

  /**
   * With AND logic, the parent could become the leaf node when no children are
   * selected and the scenario has a translation value defined, but since we
   * can't guarantee that no child selection is made we better flag the presence
   * of legacy text as problematic.
   *
   * With the current (old) setup, it happens that in guidelines the legacy text
   * is used on a parent while none of the children have master texts. In those
   * cases we should still output the legacy text even though children exist and
   * could be selected. So here we check if any of the children have intro or
   * recommendation texts.
   */
  if (has_children && logic === "and" && legacyText) {
    return Object.values(allScenarios)
      .filter((x) => x.parent_id === scenarioId)
      .some((x) =>
        hasSomeOfTheseMasterTexts(x.master_texts, ["intro", "recommendation"])
      );
  }

  /** With or logic, we do not support legacy text on parent scenarios */
  if (has_children && logic === "or" && legacyText) {
    return true;
  }

  /**
   * On leaf scenarios, if there is a legacy text and also a new intro or
   * recommendation text the legacy text will be ignored.
   */
  if (
    !has_children &&
    legacyText &&
    hasSomeOfTheseMasterTexts(master_texts, ["intro", "recommendation"])
  ) {
    return true;
  }

  /**
   * When the children's logic is OR, we will not use any master texts of the
   * parent. With OR logic a child selection is mandatory, so it is not possible
   * that the parent is selected as the final / leaf node.
   */
  if (has_children && logic === "or" && hasSomeMasterTexts(master_texts)) {
    return true;
  }

  /**
   * When the children's logic is AND, we will use the intro and recommendation
   * fields from the parent but others would be unused.
   */
  if (
    has_children &&
    logic === "and" &&
    hasSomeApartFromTheseMasterTexts(master_texts, ["intro", "recommendation"])
  ) {
    return true;
  }

  return false;
}

/**
 * A function to convert the new master text segments into the old deliverable
 * master texts format.
 *
 * This should be deprecated when we implement a smarter way to compile stuff
 * based on polarity.
 */
export function makeDeliverableMasterTexts(
  _guideline: GuidelineEditBuffer,
  segments: DeliverableTextSegment[]
): DeliverableMasterTexts[] {
  /** Group segments by id */
  const groupedSegments = segments.reduce(
    (acc, segment) => {
      if (!acc[segment.id]) {
        acc[segment.id] = {};
      }
      acc[segment.id][segment.type] = segment.text;
      return acc;
    },
    {} as Record<
      string,
      Partial<Record<DeliverableTextSegment["type"], string>>
    >
  );

  /** Create DeliverableMasterTexts for each id */
  return Object.values(groupedSegments).map((segmentsForId) => ({
    custom_guideline_title: segmentsForId.custom_guideline_title,
    intro: segmentsForId.intro,
    recommendation: segmentsForId.recommendation,
    company_text_template: segmentsForId.company_text_template,
    presenter_notes: segmentsForId.presenter_notes,
  }));
}
