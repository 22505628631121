import { Space } from "antd";
import { isDefined } from "ts-is-present";
import { AdaptiveCarouselContextProvider, MarkdownText } from "~/components";

/** Variation on MarkdownWrapper to style the admin interface independently */
export function AdminMarkdownWrapper({
  sources,
}: {
  noMargin?: boolean;
  sources: Array<string | undefined>;
}) {
  const filteredSources = sources.filter(isDefined);
  if (filteredSources.length === 0) return null;
  return (
    <Space>
      <AdaptiveCarouselContextProvider>
        {filteredSources.map((text, index) => (
          <MarkdownText key={index}>{text}</MarkdownText>
        ))}
      </AdaptiveCarouselContextProvider>
    </Space>
  );
}
