import { disableNetwork } from "firebase/firestore";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { css } from "styled-components";
import { db, maintenanceDocumentRef } from "~/modules/firebase";
import { useDebug } from "~/modules/hooks";
import { notify } from "~/modules/notifications";
import { space } from "~/theme";
import { BoxWithBorder } from "./box-with-border";
import { Logo } from "./logo";
import { NotifyNewVersion } from "./notify-new-version";
import { PageContainer } from "./page-container";
import { Paragraph } from "./typography";

export type Maintenance = {
  is_active: boolean;
  message: string;
};

export function MaintenanceLock({ children }: { children: React.ReactNode }) {
  const { isDebugMode } = useDebug();
  const [isLocked, setIsLocked] = React.useState(false);
  const [isLockBypassed, setIsLockBypassed] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [maintenance] = useDocumentData<Maintenance>(maintenanceDocumentRef);

  React.useEffect(() => {
    const isActive = maintenance?.is_active ?? false;

    if (isLocked && !isActive) {
      /** The app has been released from its lock - refresh the page */
      return document.location.reload();
    }

    setIsLocked(isActive);

    if (isActive) {
      setMessage(maintenance?.message ?? "");
      disableNetwork(db).catch((err) => notify.error(err));
    }
  }, [maintenance, isLocked]);

  return (
    <React.Fragment>
      <NotifyNewVersion enabled={!isLocked} />
      {isLocked ? (
        isLockBypassed ? (
          children
        ) : (
          <LockMessage
            message={message}
            onClose={isDebugMode ? () => setIsLockBypassed(true) : undefined}
          />
        )
      ) : (
        children
      )}
    </React.Fragment>
  );
}

function LockMessage({
  message,
  onClose,
}: {
  message: string;
  onClose?: () => void;
}) {
  return (
    <PageContainer grey testingName="maintenance-lock">
      <div css={{ margin: "0 auto", maxWidth: 500 }}>
        <BoxWithBorder>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-bottom: ${space.md};
            `}
          >
            <div css={{ maxWidth: 300 }}>
              <Logo />
            </div>
          </div>
          <Paragraph>{message}</Paragraph>
          {onClose && <button onClick={onClose}>close</button>}
        </BoxWithBorder>
      </div>
    </PageContainer>
  );
}
