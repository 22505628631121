import { every } from "lodash-es";
import type { Condition, IndustryId, Scenario, TagId } from "./schemas";

/**
 * A condition match is currently calculated as follows:
 *
 * - The review industry must match one of the industries in the condition if
 *   given.
 * - The review tags must contain all of the tags in the condition to match.
 * - If only industries OR tags are part of the condition, only those are matched.
 * - If industries AND tags are part of the condition, both need to match.
 *
 * See tests for more details.
 */
export function matchesCondition(
  condition: Condition,
  /**
   * Industry is mandatory in a review, tagIds are not. Even if no industry is
   * chosen we still have it set to __no_industry which is a valid value.
   */
  industryId: IndustryId,
  tagIds?: TagId[]
) {
  if (isEmpty(condition.industry_ids)) {
    /** If no industry is defined, we only match on tags. */
    if (isEmpty(condition.tag_ids)) {
      /**
       * But if no tags are set either, then it's not a match. This is important
       * because we don't want to execute rules if they have no industry or tag
       * settings since that would then match every review.
       */
      return false;
    }

    /** If we have tags, match all of them */
    return matchesEveryTag(condition, tagIds);
  }

  if (condition.industry_ids?.includes(industryId)) {
    /** If the industry matches, go on the match the tags too. */
    if (isEmpty(condition.tag_ids)) {
      /** If no tags are defined, we only match on industry */
      return true;
    }

    /** If both industry and tags are defined we match on both and all tags. */
    return matchesEveryTag(condition, tagIds);
  } else {
    /** If industry doesn't match there is no need to try to match tags anymore */
    return false;
  }
}

function isEmpty(v: string[] | undefined) {
  return v === undefined || v.length === 0;
}

function matchesEveryTag(condition: Condition, tagIds?: string[]) {
  if (isEmpty(condition.tag_ids) || isEmpty(tagIds)) {
    return false;
  }

  return every(condition.tag_ids, (tagId) => tagIds?.includes(tagId));
}

/**
 * Return the texts for all matching conditions, or default otherwise.
 *
 * Alternatively we could choose to only return the first matching condition,
 * but @TODO discuss what's feasible.
 */
export function getConditionalInstructionTexts(
  scenario: Scenario,
  industryId: IndustryId,
  tagIds: TagId[]
): string[] | undefined {
  const definition = scenario.conditional_instruction_text;

  if (!definition) {
    return;
  }

  const result: string[] = [];

  for (const conditional of definition.conditionals) {
    const isMatch = matchesCondition(conditional.condition, industryId, tagIds);

    if (isMatch) {
      result.push(conditional.text);
    }
  }

  if (isEmpty(result)) {
    /** If none of the conditions were met, we return the default text if exists */
    return definition.default ? [definition.default] : undefined;
  } else {
    return result;
  }
}
