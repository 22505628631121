import type {
  Guideline,
  IndustryId,
  ScenarioIdsByParent,
  TagId,
  ValidationError,
} from "@gemini/common";
import {
  Button,
  ScenarioBranch,
  ScenarioInstructionText,
  SpacedChildren,
  Title,
  getPlatformText,
} from "~/components";
import type { Document } from "~/modules/firestore";
import { EmbeddedContent } from "./embedded-content";

export function AssessDummy({
  guideline,
  industryId,
  tagIds,
  onChange,
  onSubmit,
  selectedIds,
  validationErrors,
}: {
  guideline: Document<Guideline>;
  industryId: IndustryId;
  tagIds: TagId[];
  onChange: (parentId: string, values?: string[]) => void;
  onSubmit: () => void;
  selectedIds: ScenarioIdsByParent;
  validationErrors?: ValidationError[];
}) {
  const rootScenario = guideline.data.scenarios.__root;
  const isDisabled =
    (validationErrors && validationErrors?.length > 0) ||
    Object.values(selectedIds).flat().length === 0;

  const continueToResultsButton = (
    <Button
      disabled={isDisabled}
      onClick={onSubmit}
      testingName="submit-button"
    >
      Continue to results
    </Button>
  );

  return (
    <EmbeddedContent
      footerRight={continueToResultsButton}
      title={
        <Title level={3} variant="h4" noMargin>
          Test your {getPlatformText(guideline.data.platform)} for the following
        </Title>
      }
    >
      <SpacedChildren size="lg">
        <ScenarioInstructionText
          scenario={rootScenario}
          industryId={industryId}
          tagIds={tagIds}
        />
        <ScenarioBranch
          industryId={industryId}
          readOnly={false}
          isDisabled={false}
          onSelectionChange={onChange}
          scenarios={guideline.data.scenarios}
          selectedIdsMap={selectedIds}
          tagIds={tagIds}
          validationErrors={validationErrors}
        />
      </SpacedChildren>
    </EmbeddedContent>
  );
}
