import { observer } from "mobx-react-lite";
import React from "react";
import { useAppStore } from "~/store";

export const Username = observer(({ id }: { id: string }) => {
  const { userNames } = useAppStore();
  const username: string | undefined = userNames[id];

  return <React.Fragment>{username ?? "…"}</React.Fragment>;
});
