import React from "react";
import { Text } from "~/components";

/**
 * This prefix is for use in guideline list tables. The citation code has a
 * fixed width, because otherwise the titles do not align as codes can have
 * different lengths
 */
export function WithCitationCodePrefix({
  citationCode,
  children,
}: {
  citationCode: string;
  children: React.ReactNode;
}) {
  return (
    <div
      css={`
        display: flex;
      `}
    >
      <Text
        size="small"
        cx={`
        min-width: 3em;
        margin-right: 1em;
      `}
      >
        {citationCode}
      </Text>

      {children}
    </div>
  );
}
