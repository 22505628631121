import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { PageLoader } from "~/components";
import { notify } from "~/modules/notifications";
import { useReviewStore } from "../../store";
import { UpdateStore } from "./store";

const storeContext = React.createContext<UpdateStore | null>(null);

export const UpdateStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const reviewStore = useReviewStore();

  return (
    <storeContext.Provider
      value={useMemo(() => new UpdateStore(reviewStore), [reviewStore])}
    >
      <UpdateLoader>{children}</UpdateLoader>
    </storeContext.Provider>
  );
};

export const useUpdateStore = () => {
  const store = React.useContext(storeContext);
  assert(store, "Missing UpdateStoreProvider in component tree");
  return store;
};

/**
 * The loader initializes the store based on the route if necessary, and waits
 * for the data to load.
 */
const UpdateLoader = observer(({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const store = useUpdateStore();

  store
    .initialize(router.query.reference as string | undefined)
    .catch((err) => notify.error(err));

  if (store.isLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
});
