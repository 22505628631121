import type { DerivedGuideline } from "@gemini/common";
import { parseCitationCode } from "@gemini/common";
import { groupBy } from "lodash-es";
import Link from "next/link";
import { theme } from "~/theme";

export function GuidelineList({
  guidelines,
}: {
  guidelines: DerivedGuideline[];
}) {
  const groupedGuidelines = groupBy(guidelines, (x) => {
    const [universalCode] = parseCitationCode(x.citation_code);
    return universalCode;
  });

  const items = Object.entries(groupedGuidelines)
    .sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
    .map(([universalCode, guidelines]) => (
      <li
        key={universalCode}
        css={`
          break-inside: avoid;
        `}
      >
        <div
          css={`
            display: flex;
            align-items: baseline;
          `}
        >
          <div
            css={`
              color: #aaa;
              font-size: 12px;
              margin-right: 1em;
            `}
          >
            #{universalCode}
          </div>
          <div
            css={`
              font-family: ${theme.fonts.body};
              margin-bottom: 0.2em;
            `}
          >
            <span
              css={`
                margin-right: 0.5em;
              `}
            >
              {guidelines[0].title}
            </span>
            <span>
              {guidelines.map((x) => {
                return (
                  <Link
                    href={`/admin/guidelines/${x.reference}/edit`}
                    key={x.citation_code}
                    css={`
                      margin-right: 0.5em;
                      font-weight: ${x.has_draft ? "bold" : "inherit"};
                    `}
                    rel="noopener noreferrer"
                  >
                    {`${
                      parseCitationCode(x.citation_code)[1] ||
                      "[orphaned / awaiting fix]"
                    }${x.has_draft ? "*" : ""}`}
                  </Link>
                );
              })}
            </span>
          </div>
        </div>
      </li>
    ));

  return (
    <ol
      css={`
        font-family: ${theme.fonts.monospace};
        list-style-type: none;
        column-count: 2;
        padding: 0;
        margin: 0;
      `}
    >
      {items}
    </ol>
  );
}
