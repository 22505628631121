import type { ReviewStructureGroup, ReviewStructurePart } from "@gemini/common";
import type { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import type { ReactNode } from "react";
import React, { useCallback } from "react";
import type { UseRefinementListProps } from "react-instantsearch";
import { useRefinementList } from "react-instantsearch";
import { isDefined } from "ts-is-present";
import { CheckboxWithLabel, DropDownButton } from "~/components";
import { colors, space } from "~/theme";
import { isReviewStructurePart } from "~/utils/identity";
import { useReviewStore } from "../../store";
import type { GuidelineHit } from "../logic/create-guideline-hits";

type RefinementListDropdownProps = {
  label: string;
  attribute: keyof GuidelineHit;
  sortBy?: UseRefinementListProps["sortBy"];
  getLabel?: (item: RefinementListItem) => ReactNode;
  alignContent?: "left" | "right";
};

type ChangeHandler = (
  item: ReviewStructurePart | ReviewStructureGroup,
  isChecked: boolean
) => void;

export function TopicDropDown({
  label,
  attribute,
  sortBy,
  alignContent,
}: RefinementListDropdownProps) {
  const { refine, items } = useRefinementList({
    attribute,
    sortBy,
    limit: 999,
  });
  const { getChildrenFromStructureGroup } = useReviewStore();
  const nbSelected = items.filter((x) => x.isRefined).length;

  const refineRecursive = useCallback<ChangeHandler>(
    (item, isChecked) => {
      const children = getChildrenFromStructureGroup(item.id, true);
      const childrenIds = children.map((x) => x.id);
      const childrenItems = items.filter((x) => childrenIds.includes(x.value));

      /**
       * An item w/o children should refine (read: toggle) itself. See it as a
       * leaf of a tree.
       */
      if (!children.length) {
        refine(item.id);
      } else {
        childrenItems.forEach((x) => {
          if ((isChecked && !x.isRefined) || (!isChecked && x.isRefined)) {
            refine(x.value);
          }
        });
      }
    },
    [getChildrenFromStructureGroup, items, refine]
  );

  return (
    <DropDownButton
      label={`${label} ${nbSelected ? `(${nbSelected})` : ""}`}
      alignContent={alignContent}
    >
      <RootGroupsAndParts onChange={refineRecursive} items={items} />
    </DropDownButton>
  );
}

function RootGroupsAndParts({
  onChange,
  items,
}: {
  onChange: ChangeHandler;
  items: RefinementListItem[];
}) {
  const { getChildrenFromStructureGroup } = useReviewStore();
  let rootChildren = getChildrenFromStructureGroup("__root");
  if (rootChildren.length === 1) {
    rootChildren = getChildrenFromStructureGroup(rootChildren[0].id);
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {rootChildren.map((x) => (
        <Group key={x.id} group={x} onChange={onChange} items={items} />
      ))}
    </div>
  );
}

function Group({
  group,
  onChange,
  items,
}: {
  group: ReviewStructureGroup;

  onChange: ChangeHandler;
  items: RefinementListItem[];
}) {
  const { getChildrenFromStructureGroup } = useReviewStore();
  const children = getChildrenFromStructureGroup(group.id);

  return (
    <>
      <GroupOrPartCheckbox item={group} items={items} onChange={onChange} />
      {children.length > 0 && (
        <div
          css={{
            paddingLeft: 15,
            display: "flex",
            flexDirection: "column",
            gap: space.sm,
            borderLeft: `1px solid ${colors.grayLight}`,
          }}
        >
          {children.map((x) => {
            return isReviewStructurePart(x) ? (
              <GroupOrPartCheckbox
                key={x.id}
                item={x}
                items={items}
                onChange={onChange}
              />
            ) : (
              <Group key={x.id} group={x} items={items} onChange={onChange} />
            );
          })}
        </div>
      )}
    </>
  );
}

function GroupOrPartCheckbox({
  item,
  items,
  onChange,
}: {
  item: ReviewStructurePart | ReviewStructureGroup;
  items: RefinementListItem[];
  onChange: ChangeHandler;
}) {
  const { getChildrenFromStructureGroup } = useReviewStore();
  const refinementListItem = items.find((x) => x.value === item.id);
  const childrenItems = getChildrenFromStructureGroup(item.id, true)
    .map((x) => items.find((y) => y.value === x.id))
    .filter(isDefined);

  const refinedChildren = childrenItems.filter((x) => x?.isRefined);

  const isIndeterminate =
    refinedChildren.length !== 0 &&
    refinedChildren.length !== childrenItems.length;

  const isChecked =
    ((refinedChildren.length > 0 &&
      refinedChildren.length === childrenItems.length) ||
      refinementListItem?.isRefined) ??
    false;

  const isDisabled =
    refinementListItem?.count === 0 && !refinementListItem?.isRefined;

  return (
    <CheckboxWithLabel
      indeterminate={isIndeterminate}
      checked={isChecked}
      disabled={isDisabled}
      onChange={(isChecked) => onChange(item, isChecked)}
    >
      <span css={{ whiteSpace: "nowrap" }}>
        {item.title} ({refinementListItem?.count})
      </span>
    </CheckboxWithLabel>
  );
}
