import type { MouseEvent, PointerEvent } from "react";
import React, { useEffect, useImperativeHandle } from "react";
import styled, { css } from "styled-components";
import { colors, durations, radii, timingFunctions } from "~/theme";
import { InputWithLabel } from "./input-with-label";

export function CheckboxWithLabel({
  children,
  indeterminate,
  onPointerEnter,
  onPointerLeave,
  onClick,
  ...checkboxProps
}: CheckboxProps & {
  children?: React.ReactNode;
  onPointerEnter?: (evt: PointerEvent) => void;
  onPointerLeave?: (evt: PointerEvent) => void;
  onClick?: (evt: MouseEvent) => void;
}) {
  return (
    <InputWithLabel
      input={<Checkbox indeterminate={indeterminate} {...checkboxProps} />}
      disabled={checkboxProps.disabled}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onClick={onClick}
    >
      {children}
    </InputWithLabel>
  );
}

type CheckboxProps = {
  checked: boolean;
  onChange: (
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  disabled?: boolean;
  name?: string;
  value?: string;
  testingName?: string;
  indeterminate?: boolean;
  isHovering?: boolean;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      onChange,
      disabled,
      name,
      value,
      testingName,
      indeterminate,
      isHovering,
    }: CheckboxProps,
    ref
  ) => {
    const internalRef = React.useRef<HTMLInputElement>(null);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => internalRef.current
    );

    useEffect(() => {
      if (internalRef.current) {
        internalRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    const handleChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) =>
        onChange(evt.target.checked, evt),
      [onChange]
    );

    return (
      <CheckboxContainer
        data-t={testingName}
        disabled={disabled}
        data-state={isHovering ? "hover" : undefined}
      >
        <HiddenCheckbox
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          name={name}
          value={value}
          ref={internalRef}
        />
        <VisibleCheckbox disabled={disabled}>
          <CheckedIcon checked={checked} />
          <IndeterminateIcon checked={indeterminate ?? false} />
        </VisibleCheckbox>
      </CheckboxContainer>
    );
  }
);

Checkbox.displayName = "Checkbox";

type IconProps = {
  className?: string;
  checked?: boolean;
};

function Icon({ className }: IconProps) {
  return (
    <svg className={className} viewBox="0 0 9 8">
      <g fill="none" fillRule="evenodd" transform="translate(-6 -6)">
        <path
          fill="currentcolor"
          fillRule="nonzero"
          d="M8.12 9.77a.96.96 0 00-1.32-.02.92.92 0 00-.03 1.32l2.15 2.15c.4.4 1.08.36 1.43-.09L14.31 8a.913.913 0 00-.2-1.31.96.96 0 00-1.31.19l-3.3 4.28-1.38-1.38z"
        ></path>
      </g>
    </svg>
  );
}

const CheckboxContainer = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  cursor: ${(x) => !x.disabled && "pointer"};
`;

const HiddenCheckbox = styled.input.attrs(() => ({ type: "checkbox" }))`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    background: red;
  }
`;

const VisibleCheckbox = styled.div<{ disabled?: boolean }>`
  border: 1px solid ${colors.ghostGray};
  border-radius: ${radii.sm};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  color: white;

  transition-property: background-color, border-color, color;
  transition-duration: ${durations.sm};
  transition-timing-function: ${timingFunctions.out};

  opacity: ${(x) => (x.disabled ? 0.5 : 1)};

  ${(x) =>
    !x.disabled &&
    css`
      &:hover {
        color: ${colors.ghostGray};
        border-color: ${colors.indigo};
      }

      [data-state="hover"] & {
        color: ${colors.ghostGray};
      }
    `}

  ${HiddenCheckbox}:hover, [data-state="hover"] ${HiddenCheckbox}:hover {
    &:not(:disabled) + & {
      color: ${colors.ghostGray};
      border-color: ${colors.slateGray};
    }
    &:not(:disabled):focus + &,
    &:not(:disabled):checked + &,
    &:not(:disabled):indeterminate + & {
      border-color: ${colors.indigo};
      color: white;
    }
  }

  ${HiddenCheckbox}:focus + & {
    border-color: ${colors.indigo};
    box-shadow: 0 0 0px 1px ${colors.indigo};
  }

  ${HiddenCheckbox}:checked + &,
  ${HiddenCheckbox}:indeterminate + & {
    color: white;
    background-color: ${colors.indigo};
    border-color: ${colors.indigo};
  }
`;

const CheckedIcon = styled(Icon)`
  width: 8px;
  transform: scale(0);
  transition-property: transform;
  transition-duration: ${durations.md};
  transition-timing-function: ${timingFunctions.out};

  ${HiddenCheckbox}:not(:indeterminate):hover + ${VisibleCheckbox} > &,
  [data-state="hover"] ${HiddenCheckbox}:not(:indeterminate) + ${VisibleCheckbox} > & {
    transform: scale(1);
  }

  ${(x) =>
    x.checked &&
    css`
      transition-duration: ${durations.lg};
      transform: scale(1);
    `}
`;

const IndeterminateIcon = styled.div<{ checked: boolean }>`
  position: absolute;
  width: 8px;
  height: 2px;
  background: white;
  border-radius: ${radii.sm};
  transform: scale(0);
  transition-property: transform;
  transition-duration: ${durations.md};
  transition-timing-function: ${timingFunctions.out};

  ${(x) =>
    x.checked &&
    css`
      transition-duration: ${durations.lg};
      transform: scale(1);
    `}
`;
