import { getErrorMessage } from "./errors";
import { waitNumSeconds } from "./wait-num-seconds";

/**
 * Retries the given function until it succeeds given a number of retries and an
 * interval between them, with the interval doubling on every attempt, so 1, 2,
 * 4 secs. If useExponentialBackoff is set to false, the interval will be the
 * same for every retry.
 */
export async function callWithRetry<T>(
  asyncFn: () => Promise<T>,
  opts: {
    useExponentialBackoff?: boolean;
    retries?: number;
    delaySecs?: number;
    onError?: (error: unknown) => void;
  } = {
    useExponentialBackoff: true,
    retries: 3,
    delaySecs: 1,
  }
): Promise<T> {
  try {
    return await asyncFn();
  } catch (error) {
    if (opts.onError) {
      /**
       * Call the error handler function so that the context can choose to log
       * all errors.
       */
      opts.onError(error);
    }

    await waitNumSeconds(opts.delaySecs ?? 1);
    return __retry(asyncFn, opts, opts.retries ?? 3, opts.delaySecs ?? 1);
  }
}

async function __retry<T>(
  asyncFn: () => Promise<T>,
  opts: {
    useExponentialBackoff?: boolean;
    retries?: number;
    delaySecs?: number;
    onError?: (error: unknown) => void;
  },
  retriesLeft: number,
  delaySecs: number
): Promise<T> {
  try {
    return await asyncFn();
  } catch (error) {
    if (opts.onError) {
      /**
       * Call the error handler function so that the context can choose to log
       * all errors.
       */
      opts.onError(error);
    }

    if (retriesLeft) {
      await waitNumSeconds(delaySecs);
      return __retry(
        asyncFn,
        opts,
        retriesLeft - 1,
        opts.useExponentialBackoff ? delaySecs * 2 : delaySecs
      );
    } else {
      throw new Error(getErrorMessage(error));
    }
  }
}
