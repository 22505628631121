import type { ScenarioMap } from "@gemini/common";
import {
  getJudgementFromTranslationValue,
  getSortedSelectedScenarios,
} from "@gemini/common";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { JudgementPill } from "~/components";
import { useIsMountedRef } from "~/modules/hooks";
import { space } from "~/theme";

type AssessmentSelectionListProps = {
  selectedIds: string[];
  scenarios: ScenarioMap;
  isManualJudgement?: boolean;
  parentId?: string;
  showValues?: boolean;
};

export const AssessmentSelectionList: React.FC<AssessmentSelectionListProps> = (
  props
) => {
  const isMountedRef = useIsMountedRef();
  return (
    <ListContainer>
      <ScenarioList {...props} enableMotion={isMountedRef.current} />
    </ListContainer>
  );
};

const ScenarioList = observer(
  ({
    selectedIds,
    scenarios,
    isManualJudgement,
    parentId = "__root",
    showValues,
    enableMotion = false,
  }: AssessmentSelectionListProps & { enableMotion: boolean }) => {
    const items = getSortedSelectedScenarios(scenarios, selectedIds, parentId);
    return (
      <List data-t="assessment-selection-list">
        <AnimatePresence>
          {isManualJudgement ? (
            <Item
              key={`manual-judgement-scenario-${parentId}`}
              enableMotion={enableMotion}
              indentationLevel={0}
            >
              <ScenarioRow>None of the above applies</ScenarioRow>
            </Item>
          ) : (
            items.map((scenario) => (
              <Item
                key={scenario.id}
                enableMotion={enableMotion}
                indentationLevel={findScenarioIndentationLevel(
                  scenario.parent_id,
                  scenarios
                )}
              >
                <ScenarioRow>
                  <span>{scenario.title}</span>
                  {showValues && scenario.translation_value && (
                    <span css={{ marginLeft: space.sm }}>
                      <JudgementPill
                        value={getJudgementFromTranslationValue(
                          scenario.translation_value
                        )}
                        isIssueResolved={scenario.translation_value === "ir"}
                      />
                    </span>
                  )}
                </ScenarioRow>
              </Item>
            ))
          )}
        </AnimatePresence>
      </List>
    );
  }
);

function Item({
  children,
  enableMotion,
  indentationLevel,
}: {
  enableMotion: boolean;
  children: React.ReactNode;
  indentationLevel: number;
}) {
  return (
    <StyledItem
      $indentationLevel={indentationLevel}
      initial={enableMotion && { height: 0, opacity: 0 }}
      animate={{
        height: "auto",
        opacity: 1,
      }}
      exit={{ height: 0, opacity: 0 }}
    >
      {children}
    </StyledItem>
  );
}

const indentSize = "1.5ex";

const ListContainer = styled.div`
  margin-left: ${indentSize};
  margin-bottom: -${space.sm};
`;

const List = styled.ol`
  margin-left: -${indentSize};
`;

const ScenarioRow = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: ${space.sm};
`;

const StyledItem = styled(motion.li)<{ $indentationLevel: number }>`
  overflow: hidden;
  padding-left: ${indentSize};
  margin-left ${(x) => x.$indentationLevel * 1.5 + "ex"};
  list-style: none;
  position: relative;

  &::before {
    content: "";
    width: 0.5ex;
    height: 0.5ex;
    border-radius: 0.25ex;
    background: currentColor;
    position: absolute;
    left: 0;
    top: 1.25ex;
  }
`;

function findScenarioIndentationLevel(
  scenarioParentId: string,
  scenarios: ScenarioMap
): number {
  let level = 0;

  while (scenarioParentId !== "__root") {
    const scenario = scenarios[scenarioParentId];
    if (!scenario) break;

    scenarioParentId = scenario.parent_id;
    level++;
  }

  return level;
}
