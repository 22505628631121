import { assert } from "@gemini/common";
import { debounce, defaults, isEqual } from "lodash-es";
import { isDefined } from "ts-is-present";

/**
 * Based on
 * https://medium.com/better-programming/full-featured-hotkeys-library-in-200-lines-of-javascript-code-81a74e3138cc
 */

const KEY_MAP: Record<string, string> = {
  esc: "escape",
  "+": "plus",
  " ": "space",
  command: "meta",
  control: "ctrl",
  left: "arrowleft",
  right: "arrowright",
  up: "arrowup",
  down: "arrowdown",
};

const MODIFIER_KEYS = ["ctrl", "shift", "alt", "meta"];

export type Callback = () => void;

export type Options = {
  /**
   * Duration (ms) to detect key sequences
   *
   * Default: 500
   */
  debounceTime?: number;
  /**
   * Disable hotkey trigger while focus is on a textarea or input[type=text]
   *
   * Default: false
   */
  disableTextInputs?: boolean;
  /**
   * Allow repeated callback triggers during long press of keys
   *
   * Default: false
   */
  allowRepeat?: boolean;
  /**
   * Prevent default when hotkey is matched
   *
   * Default: true
   */
  preventDefault?: boolean;
};

type Hotkey = {
  [key: string]: boolean;
};

type Listener = {
  hotkey: Hotkey[];
  callback: Callback;
};

const defaultOptions: Options = {
  debounceTime: 500,
  disableTextInputs: false,
  allowRepeat: false,
  preventDefault: true,
};

export function createContext(options: Options = {}) {
  const listeners: Listener[] = [];
  const handler = createKeyListener(
    listeners,
    defaults({}, options, defaultOptions)
  );

  /**
   * Must be keydown event, a keyup event won't work with key combinations like
   * command+e
   */
  document.addEventListener("keydown", handler);

  return {
    register: createListenersFn(listeners, registerListener),
    unregister: createListenersFn(listeners, unregisterListener),
    destroy: () => document.removeEventListener("keydown", handler),
  };
}

function createListenersFn(
  listeners: Listener[],
  fn: typeof registerListener | typeof unregisterListener
) {
  return (hotkey: string | string[], callback: Callback) => {
    const hotkeys: string[] = [];
    hotkeys.concat(hotkey).forEach((x) => fn(listeners, x, callback));
  };
}

function unregisterListener(
  listeners: Listener[],
  hotkeyStr: string,
  callback: Callback
) {
  const hotkey = normalizeHotkey(hotkeyStr);

  const index = listeners.findIndex(
    (x) => x.callback === callback && isEqual(hotkey, x.hotkey)
  );

  if (index !== -1) {
    listeners.splice(index, 1);
  }
}

function registerListener(
  listeners: Listener[],
  hotkey: string,
  callback: Callback
) {
  listeners.push({ hotkey: normalizeHotkey(hotkey), callback });
}

function createKeyListener(listeners: Listener[], options: Options) {
  let buffer: Hotkey[] = [];
  const clearBuffer = debounce(() => {
    buffer = [];
  }, options.debounceTime);

  return (evt: KeyboardEvent) => {
    if (!options.allowRepeat && evt.repeat) {
      return;
    }

    if (options.disableTextInputs && hasFocusOnTextInput()) {
      return;
    }

    /** @todo Figure out whether it is useful to support single modifier hotkeys */
    // if (evt.getModifierState(evt.key)) {
    //   return;
    // }

    clearBuffer();

    const hotkey: Hotkey = {
      [getMappedKey(evt.key)]: true,
    };

    MODIFIER_KEYS.map((key) => ({
      key,
      eventKey: `${key}Key` as "ctrlKey" | "shiftKey" | "altKey" | "metaKey",
    })).forEach(({ key, eventKey }) => {
      if (evt[eventKey]) {
        hotkey[key] = true;
      }
    });

    buffer.push(hotkey);

    const listener = listeners.find((x) => matchHotkey(buffer, x.hotkey));

    if (listener) {
      options.preventDefault && evt.preventDefault();
      listener.callback();
    }
  };
}

function getMappedKey(key: string) {
  key = key.toLowerCase();
  return KEY_MAP[key] || key;
}

export function matchHotkey(buffer: Hotkey[], hotkey: Hotkey[]) {
  if (buffer.length < hotkey.length) {
    return false;
  }

  const indexDiff = buffer.length - hotkey.length;

  for (let i = hotkey.length - 1; i >= 0; i -= 1) {
    if (!isEqual(buffer[indexDiff + i], hotkey[i])) {
      return false;
    }
  }

  return true;
}

function createHotkey(keys: string[]): Hotkey {
  return keys.reduce((obj, key) => ({ ...obj, [key]: true }), {});
}

export function normalizeHotkey(hotkey: string) {
  return hotkey.split(/ +/g).map((part) => {
    const keys = part.split("+").filter(isDefined).map(getMappedKey);
    const result = createHotkey(keys);

    assert(
      Object.keys(result).length >= keys.length,
      `Hotkey combination has duplicates "${hotkey}"`
    );

    return result;
  });
}

function hasFocusOnTextInput() {
  const element = document.activeElement;
  const tagName = element?.tagName.toLowerCase();

  if (tagName === "textarea") {
    return true;
  }

  if (tagName === "input") {
    return (element as HTMLInputElement).type === "text";
  }

  return false;
}
