import React, { forwardRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import type { CSSProp } from "styled-components";
import styled from "styled-components";
import { inputStyle } from "./input";

export type TextAreaProps = {
  value: string | undefined;
  onChange: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  cx?: CSSProp;
  disabled?: boolean;
  testingName?: string;
  autoFocus?: boolean;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea({ onChange, testingName, ...props }, ref) {
    const handleChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLTextAreaElement>) =>
        onChange(evt.target.value),
      [onChange]
    );
    return (
      <StyledTextArea
        ref={ref}
        onChange={handleChange}
        data-t={testingName}
        {...props}
      />
    );
  }
);

const StyledTextArea = styled(TextareaAutosize)<
  Omit<TextAreaProps, "onChange">
>`
  ${inputStyle};
  display: block;
  padding: 1.8rem 2rem;
  min-height: 80px;
  ${(x) => x.cx};
`;
