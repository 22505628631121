import { enableStaticRendering } from "mobx-react-lite";
import type { AppProps } from "next/app";
import NextError from "next/error";
import type { Router } from "next/router";
import { useRouter } from "next/router";
import React from "react";
import {
  AppErrorBoundary,
  DocumentHead,
  LoadingIndicator,
  MaintenanceLock,
  ModalProvider,
  PageLoader,
  SiteHeader,
} from "~/components";
import { AssessmentStoreProvider } from "~/features/assessment";
import {
  PartStoreProvider,
  ReviewStoreProvider,
  UpdateStoreProvider,
} from "~/features/reviews";
import { PosthogAnalyticsProvider } from "~/modules/posthog";
import { AppStoreProvider } from "~/store";
import { ThemeRoot } from "~/theme/components";
import { AuthProvider, useAuth } from "../modules/auth";

/**
 * Check if transpiler is set up correctly. See
 * https://mobx.js.org/installation.html
 */
if (
  !new (class {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    x: any;
  })().hasOwnProperty("x")
) {
  throw new Error("Transpiler is not configured correctly");
}

/** To avoid leaking memory with observers in SSR */
const isServer = typeof window === "undefined";
if (isServer) {
  enableStaticRendering(true);
}

export default function App({
  Component,
  pageProps,
  router,
  err,
}: AppProps & { err?: Error }) {
  const isAdminRoute = router.route.startsWith("/admin");
  const isEmbeddedRoute = router.route.startsWith("/embedded");

  // Workaround for https://github.com/zeit/next.js/issues/8592
  if (err) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorComponentProps = { err } as any;
    return <Component {...errorComponentProps} />;
  }

  return (
    <React.Fragment>
      <DocumentHead />
      <ThemeRoot isAdminRoute={isAdminRoute} isEmbeddedRoute={isEmbeddedRoute}>
        <PosthogAnalyticsProvider>
          <AuthProvider
            loadingAuthElement={
              <LoadingAuth
                showSiteHeader={!router.asPath.startsWith("/embedded")}
              />
            }
          >
            <MaintenanceLock>
              <AppErrorBoundary>
                <ModalProvider>
                  <AuthProtected>
                    <AppStoreProvider>
                      <RouteBasedProviders router={router}>
                        <Component {...pageProps} />
                      </RouteBasedProviders>
                    </AppStoreProvider>
                  </AuthProtected>
                </ModalProvider>
              </AppErrorBoundary>
            </MaintenanceLock>
          </AuthProvider>
        </PosthogAnalyticsProvider>
      </ThemeRoot>
    </React.Fragment>
  );
}

function RouteBasedProviders({
  router,
  children,
}: {
  router: Router;
  children: React.ReactNode;
}) {
  const { pathname } = router;

  /**
   * The order of pattern testing is important. They go from least specific to
   * most specific, so they should be tested the other way around.
   */
  const paths = {
    review: "/reviews/[reviewId]",
    part: "/reviews/[reviewId]/parts/[partId]",
    assessments: "/reviews/[reviewId]/parts/[partId]/assessments",
    updates: "/reviews/[reviewId]/updates",
    updateAssessments: "/reviews/[reviewId]/updates/assessments",
    embeddedAssessment: "/embedded/review/[reviewId]/assessment/[reference]",
  };

  if (pathname.startsWith(paths.embeddedAssessment)) {
    return (
      <ReviewStoreProvider>
        <AssessmentStoreProvider flow="embedded-assessment-flow">
          {children}
        </AssessmentStoreProvider>
      </ReviewStoreProvider>
    );
  }

  if (pathname.startsWith(paths.updateAssessments)) {
    return (
      <ReviewStoreProvider>
        <UpdateStoreProvider>
          <AssessmentStoreProvider flow="update-flow">
            {children}
          </AssessmentStoreProvider>
        </UpdateStoreProvider>
      </ReviewStoreProvider>
    );
  }

  if (pathname.startsWith(paths.updates)) {
    return (
      <ReviewStoreProvider>
        <UpdateStoreProvider>{children}</UpdateStoreProvider>
      </ReviewStoreProvider>
    );
  }

  if (pathname.startsWith(paths.assessments)) {
    return (
      <ReviewStoreProvider>
        <PartStoreProvider>
          <AssessmentStoreProvider flow="review-flow">
            {children}
          </AssessmentStoreProvider>
        </PartStoreProvider>
      </ReviewStoreProvider>
    );
  }

  if (pathname.startsWith(paths.part)) {
    return (
      <ReviewStoreProvider>
        <PartStoreProvider>{children}</PartStoreProvider>
      </ReviewStoreProvider>
    );
  }

  if (pathname.startsWith(paths.review)) {
    return <ReviewStoreProvider>{children}</ReviewStoreProvider>;
  }

  return <>{children}</>;
}

/**
 * If the user is not logged in we render a completely different "app",
 * consisting of just the login page. This simplifies things because the whole
 * app is only usable for logged in users anyway. It prevents redirects to/from
 * /login and gives maximum protection. Inspired by:
 * https://kentcdodds.com/blog/authentication-in-react-applications
 */
function AuthProtected({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();

  if (!user) {
    return <PageLoader />;
  }

  return <ClaimsProtected>{children}</ClaimsProtected>;
}

function ClaimsProtected({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { claims } = useAuth();

  if (!claims) {
    return <PageLoader />;
  }

  if (!claims.isAdmin && router.asPath.startsWith("/admin")) {
    /** Don't render admin routes to non-admin users */
    return <NextError statusCode={404} />;
  }

  return <>{children}</>;
}

/**
 * Component to show while auth is loading. This component will also be rendered
 * on the server.
 */
function LoadingAuth({ showSiteHeader }: { showSiteHeader: boolean }) {
  return (
    <div css={{ height: "100vmin", display: "flex", flexDirection: "column" }}>
      {showSiteHeader && <SiteHeader fullWidth={false} logoOnly={true} />}
      <div css={{ flexGrow: 1, display: "grid", placeItems: "center" }}>
        <LoadingIndicator />
      </div>
    </div>
  );
}
