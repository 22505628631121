import React from "react";
import TruncateMarkup from "react-truncate-markup";
import styled from "styled-components";
import { colors, fontWeights, space } from "~/theme";

type TruncateProps = {
  lines: number;
  children: React.ReactNode | ((isTruncated: boolean) => React.ReactNode);
};

export function Truncate({ lines, children }: TruncateProps) {
  const [isTruncated, setIsTruncated] = React.useState(true);

  const toggle = (
    <LinkButton onClick={() => setIsTruncated(!isTruncated)}>
      {isTruncated ? "more" : "less"}
    </LinkButton>
  );

  const content =
    typeof children === "function" ? children(isTruncated) : children;

  return isTruncated ? (
    <TruncateMarkup lines={lines} ellipsis={<span>&hellip; {toggle}</span>}>
      <div>{content}</div>
    </TruncateMarkup>
  ) : (
    <div>
      {content} {toggle}
    </div>
  );
}

const LinkButton = styled.button`
  display: inline;
  margin: 0 ${space.sm} 0 0;
  padding: 0;
  border: 0;
  background: none;
  text-decoration: underline;
  color: ${colors.slateGray};
  font-size: inherit;
  font-weight: ${fontWeights.semibold};
  cursor: pointer;
`;
