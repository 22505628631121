import React from "react";
import styled from "styled-components";
import { colors } from "~/theme";

export function EmbeddedContent({
  children,
  title,
  footerRight,
  footerLeft,
  style,
}: {
  children: React.ReactNode;
  title?: React.ReactNode;
  footerRight?: React.ReactNode;
  footerLeft?: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <div css={{ overflow: "hidden" }} style={style}>
      {title && <StyledTitle>{title}</StyledTitle>}
      <StyledContent>{children}</StyledContent>
      {(footerRight || footerLeft) && (
        <StyledFooter>
          <div>{footerLeft}</div>
          <div>{footerRight}</div>
        </StyledFooter>
      )}
    </div>
  );
}

const StyledTitle = styled.div`
  padding: 1.4rem 2.8rem;
  background: ${colors.slateGray};
  color: white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
`;

const StyledContent = styled.div`
  padding: 2.8rem;
`;

const StyledFooter = styled.div`
  border-top: 1px solid ${colors.athensGray};
  background: white;
  padding: 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
