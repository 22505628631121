import {
  causeOfChangeLabels,
  type CauseOfChange,
  type Platform,
} from "@gemini/common";
import { useEffect, useRef } from "react";
import {
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch";
import styled from "styled-components";
import { Icon } from "~/components";
import { TextButton } from "~/components/text-button";
import { plural } from "~/modules/string";
import { maybeScrollIntoView } from "~/modules/window";
import { colors, fontSizes, radii, space } from "~/theme";
import { useReviewStore } from "../../store";
import type { itemsJsOptions } from "../config";
import type { JudgementFilterValue } from "../logic";
import { getJudgementLabel, getPlatformLabel } from "../logic";

export function CurrentRefinements() {
  const { structure } = useReviewStore();
  const { items, refine } = useCurrentRefinements();
  const { refine: clearAll } = useClearRefinements();

  const filters = items.flatMap((x) => x.refinements);
  const lastFilter = filters[filters.length - 1];

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const lasterFilterRef = useRef<HTMLButtonElement>(null);

  useEffect(
    () =>
      maybeScrollIntoView(lasterFilterRef.current, {
        boundary: scrollContainerRef.current,
      }),
    [lastFilter?.attribute, lastFilter?.value]
  );

  if (!filters.length) return null;

  return (
    <div css={{ display: "flex", gap: space.md }}>
      <ScrollContainer ref={scrollContainerRef}>
        {filters.map((y) => {
          let label: string;
          switch (
            y.attribute as keyof (typeof itemsJsOptions)["aggregations"]
          ) {
            case "judgement": {
              label = getJudgementLabel(y.value as JudgementFilterValue);
              break;
            }
            case "includesNotes": {
              label = "Includes Notes";
              break;
            }
            case "isSuggestion": {
              label = "Is Suggestion";
              break;
            }
            case "isLowCost": {
              label = "Is Low Cost";
              break;
            }
            case "isMissedOpportunity": {
              label = "Is Missed Opportunity";
              break;
            }
            case "platform": {
              label = getPlatformLabel(y.value as Platform);
              break;
            }
            case "breadcrumb": {
              label = structure.data?.sections[y.value].title ?? "";
              break;
            }
            case "causeOfChange": {
              label = causeOfChangeLabels[y.value as CauseOfChange];
              break;
            }
            default: {
              label = y.label;
              break;
            }
          }
          return (
            <Tag
              key={y.attribute + y.value}
              onClick={() => refine(y)}
              ref={y === lastFilter ? lasterFilterRef : null}
            >
              {label}
              <Icon name="close" size={16} />
            </Tag>
          );
        })}
      </ScrollContainer>

      <TextButton
        onClick={clearAll}
        css={{
          textDecoration: "underline",
          "&:hover": { textDecoration: "none" },
          marginBottom: space.md,
          fontSize: fontSizes.sm,
        }}
      >
        Clear {filters.length > 1 && filters.length}{" "}
        {plural("filter", filters.length)}
      </TextButton>
    </div>
  );
}

const ScrollContainer = styled.div`
  display: flex;
  gap: ${space.md};
  overflow: auto;
  padding-bottom: ${space.md};
`;

const Tag = styled.button`
  border: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${space.sm};
  background: ${colors.whiteLilac};
  font-size: ${fontSizes.sm};
  line-height: 20px;
  padding: ${space.xs} ${space.sm} ${space.xs} ${space.md};
  border-radius: ${radii.md};
  white-space: nowrap;

  &:hover {
    text-decoration: line-through;
  }
`;
