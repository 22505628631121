import type { Review } from "@gemini/common";
import { duplicateReview } from "~/modules/api";
import type { ReviewFormData } from "./review-form";
import { ReviewForm } from "./review-form";

export function DuplicateReviewForm({
  reviewId,
  review,
  onFinish,
  onCancel,
}: {
  reviewId: string;
  review: Review;
  onFinish: (reviewId: string) => void;
  onCancel: () => void;
}) {
  return (
    <ReviewForm
      review={review}
      submitLabel="Duplicate Review"
      onSubmit={handleSubmit}
      onCancel={onCancel}
      disableOptions
      disableReviewType
    />
  );

  async function handleSubmit(data: ReviewFormData) {
    const { title, description, structureId, isAssumedRangeHidden } = data;

    const response = await duplicateReview({
      reviewId,
      title,
      description,
      structureId,
      isAssumedRangeHidden,
    });
    onFinish(response.data.reviewId);
  }
}
