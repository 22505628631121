import type {
  DerivedGuidelinesByReference,
  ReviewStructure,
  ReviewStructurePart,
} from "~/schemas";
import { assert } from "~/utils";
import { isReviewStructurePart } from "~/structures";
import { parseComparableVersionString } from "..";

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type ReviewStructureFmtAmbrosia = {
  id: string;
  title?: string;

  summaries: (
    | ReviewStructureGroupFmtAmbrosia
    | ReviewStructurePartFmtAmbrosia
  )[];
};

type ReviewStructureGroupFmtAmbrosia = {
  id: string;
  title: string;
  summaries: (
    | ReviewStructureGroupFmtAmbrosia
    | ReviewStructurePartFmtAmbrosia
  )[];
  semanticLevel?: string;
  sentenceReferenceKey?: string;
};

type ReviewStructurePartFmtAmbrosia = {
  id: string;
  title: string;
  guidelineIds: string[];
  semanticLevel?: string;
  sentenceReferenceKey?: string;
};

export function convertStructureToAmbrosiaFormat(
  structureId: string,
  structureData: ReviewStructure,
  currentGuidelines: DerivedGuidelinesByReference
): ReviewStructureFmtAmbrosia {
  const { version, title, sections } = structureData;

  return {
    id: `gemini-${structureId}`,
    title: `${title} (v${parseComparableVersionString(version)})`,
    summaries: convertSections(sections, currentGuidelines),
  };
}

export function convertPartToAmbrosiaFormat(
  partId: string,
  partData: ReviewStructurePart,
  currentGuidelines: DerivedGuidelinesByReference
): ReviewStructurePartFmtAmbrosia {
  const { title, guideline_references } = partData;

  return {
    id: partId,
    title,
    guidelineIds: guideline_references.map((x) => {
      const data = currentGuidelines[x];
      assert(data, `Missing derived data for reference ${x}`);
      return `guideline-${data.sql_id}`;
    }),
  };
}

function convertSections(
  sections: ReviewStructure["sections"],
  currentGuidelines: DerivedGuidelinesByReference,
  parentGroupId = "__root"
): (ReviewStructureGroupFmtAmbrosia | ReviewStructurePartFmtAmbrosia)[] {
  const result = Object.values(sections)
    .filter((x) => x.parent_group_id === parentGroupId)
    .sort((a, b) => a.position - b.position)
    .map((x) => {
      if (isReviewStructurePart(x)) {
        const part: ReviewStructurePartFmtAmbrosia = {
          id: x.id,
          title: x.title,
          guidelineIds: x.guideline_references.map((x) => {
            const data = currentGuidelines[x];
            assert(data, `Missing derived data for reference ${x}`);
            return `guideline-${data.sql_id}`;
          }),
          semanticLevel: x.semantic_level,
          sentenceReferenceKey: x.sentence_reference_key,
        };
        return part;
      } else {
        const group: ReviewStructureGroupFmtAmbrosia = {
          id: x.id,
          title: x.title,
          summaries: convertSections(sections, currentGuidelines, x.id),
          semanticLevel: x.semantic_level,
          sentenceReferenceKey: x.sentence_reference_key,
        };

        return group;
      }
    });

  return result;
}
