import {
  assert,
  assessmentFileUploadDirectoryTemplate,
  setPathParams,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import {
  PageLoader,
  ScenarioBranch,
  ScenarioInstructionText,
  SpacedChildren,
} from "~/components";
import { Spacer } from "~/components/spacer";
import {
  CommentField,
  FileField,
  FileList,
  ImageField,
  ManualJudgementCheckbox,
  ManualJudgementForm,
} from "~/features/assessment/components";
import { useAssessmentStore } from "~/features/assessment/store";
import { useReviewStore } from "~/features/reviews";
import { useAuth } from "~/modules/auth";
import { notify } from "~/modules/notifications";
import { space } from "~/theme";
import { wrapBackgroundTask } from "~/utils/wrap-background-task";
import { useAssessmentQueryParameters } from "../logic/use-assessment-query-parameters";
import { useGuidelineByReference } from "../logic/use-guideline-by-reference";

export const Assess = observer(function Assess() {
  const { reference, industryId, tagIds } = useAssessmentQueryParameters();
  const [guideline, isLoadingGuideline] = useGuidelineByReference(reference);
  const { review } = useReviewStore();
  const { claims } = useAuth();
  const {
    calculatedTranslationValue,
    comment,
    imageUploads,
    isManualJudgement,
    selectedIdsMap,
    setComment,
    setIsManualJudgement,
    updateSelection,
    addImages,
    removeImage,
    reorderImages,
    files,
    pendingFileUploads,
    filesToDelete,
    addFiles,
    removeFile,
    scenarios,
  } = useAssessmentStore();

  if (isLoadingGuideline) return <PageLoader />;
  assert(guideline, `Guideline not found for reference ${reference}`);

  const rootScenario = scenarios.__root;
  const hasScenarios = Object.keys(scenarios).length > 1;

  assert(review.data, "Review data not available");

  return (
    <div>
      <SpacedChildren size="lg">
        <ScenarioInstructionText
          scenario={rootScenario}
          industryId={industryId}
          tagIds={tagIds}
        />
        <ScenarioBranch
          industryId={industryId}
          readOnly={review.data.is_read_only}
          isDisabled={isManualJudgement}
          onSelectionChange={updateSelection}
          scenarios={scenarios}
          selectedIdsMap={selectedIdsMap}
          tagIds={tagIds}
          validationErrors={calculatedTranslationValue.validationErrors}
        />

        <ManualJudgementCheckbox
          checked={isManualJudgement}
          childrenWhenChecked={<ManualJudgementForm guideline={guideline} />}
          onChange={setIsManualJudgement}
          label={
            hasScenarios
              ? "None of the above applies"
              : "Manually judge this guideline"
          }
        />
      </SpacedChildren>

      <Spacer size="md" />

      <SpacedChildren size="lg" css={{ marginBottom: space.lg }}>
        <div id="comment">
          <CommentField
            disabled={review.data.is_read_only}
            value={comment}
            onChange={setComment}
          />
        </div>

        <ImageField
          disabled={review.data.is_read_only}
          imageUploads={imageUploads}
          onAdd={(files, cropConfig) =>
            wrapBackgroundTask(addImages(files, cropConfig))
          }
          onRemove={removeImage}
          onReorder={reorderImages}
          label="Add a screenshot of your site"
          extraLabel="You can also drag and drop images or directly paste a screenshot from your clipboard"
          allowScreenshotCropping={review.data.type === "case-study"}
        />

        <FileField
          disabled={review.data.is_read_only}
          label="Upload files"
          extraLabel={`Upload files like a video recording, prototype design files, etc. Max ${
            claims.isAdmin ? "2GB" : "90MB"
          } each. Files will continue to upload in the background when you submit the assessment.`}
          onAdd={(files) => {
            addFiles(
              files,
              setPathParams(assessmentFileUploadDirectoryTemplate, {
                accountId: claims.accountId,
                reviewId: review.id,
                reference,
              }),
              claims.isAdmin ? 2048 : 90
            ).catch(notify.error);
          }}
        >
          <FileList
            accountId={review.data.account_id}
            files={files}
            reference={reference}
            reviewId={review.id}
            pendingFileUploads={pendingFileUploads}
            filesToDelete={filesToDelete}
            onRemove={review.data.is_read_only ? undefined : removeFile}
          />
        </FileField>
      </SpacedChildren>
    </div>
  );
});
