import type { ReviewPartProgress } from "@gemini/common";
import React from "react";
import styled, { css } from "styled-components";
import { Icon, SpacedChildren } from "~/components";
import {
  colors,
  durations,
  fontWeights,
  radii,
  space,
  timingFunctions,
} from "~/theme";
import { PartProgress } from "./part-progress";

type PartRowProps = {
  level: number;
  progress: ReviewPartProgress | undefined;
  active?: boolean;
  children: React.ReactNode;
};

export function PartRow({ level, progress, active, children }: PartRowProps) {
  return (
    <StyledPartRow level={level} active={active}>
      <Label>{children}</Label>
      <Progress progress={progress} />
    </StyledPartRow>
  );
}

const Label = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledPartRow = styled.span<{ level: number; active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  color: ${colors.charcoal};
  font-weight: ${fontWeights.semibold};
  padding: 5px 8px 5px ${(x) => 8 + Math.max(x.level, 0) * 20}px;
  margin: 0 -8px;

  border-radius: ${radii.md};
  transition-property: background-color;
  transition-duration: ${durations.lg};
  transition-timing-function: ${timingFunctions.out};

  background: ${(x) => (x.active ? colors.whiteLilac : undefined)};

  &:hover {
    transition-duration: 0ms;
    background: ${colors.whiteLilac};
  }
`;

function Progress({ progress }: { progress?: ReviewPartProgress }) {
  return (
    <SpacedChildren
      as="span"
      horizontal
      size="sm"
      css={css`
        padding-left: ${space.md};
        color: ${colors.slateGray};
        display: flex;
        justify-content: center;
      `}
    >
      {progress ? (
        <PartProgress partProgress={progress} />
      ) : (
        <span>&nbsp;</span>
      )}
      <span
        css={css`
          height: 20px;
          display: flex;
          align-items: baseline;
          position: relative;
        `}
      >
        <Icon name="chevronRight" size={20} />
      </span>
    </SpacedChildren>
  );
}
