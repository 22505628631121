import { assert } from "@gemini/common";
import { useHotkey } from "./use-hotkey";
import { useKeyIsPressed } from "./use-key-is-pressed";

type State = "primary" | "secondary" | "tertiary";

export type SubmitAction = {
  label: string;
  onSubmit: () => void;
};

const keyMap: Record<State, string[]> = {
  primary: ["command+enter", "control+enter"],
  secondary: ["shift+enter", "shift+command+enter", "shift+control+enter"],
  tertiary: ["alt+enter", "alt+command+enter", "alt+control+enter"],
};

export function useSubmitAction(actions: {
  [key in State]: SubmitAction | undefined;
}) {
  assert(actions.primary, "Primary action is required");

  const isTertiaryActive = useKeyIsPressed("alt");
  const isSecondaryActive = useKeyIsPressed("shift");

  const stateBasedOnKeypress: State = isTertiaryActive
    ? "tertiary"
    : isSecondaryActive
      ? "secondary"
      : "primary";

  /**
   * Secondary and tertiary actions can be undefined, we'll fallback to the
   * primary action.
   */
  const state = actions[stateBasedOnKeypress]
    ? stateBasedOnKeypress
    : "primary";

  const action = actions[state];

  const onSubmit = action?.onSubmit ?? (() => {});

  useHotkey(keyMap[state], onSubmit);

  return {
    label: action?.label ?? "__no_label",
    handleSubmit: onSubmit,
  };
}
