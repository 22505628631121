import React from "react";
import styled, { keyframes } from "styled-components";

type SpinnerProps = {
  size: string | number;
};

export function Spinner({ size }: SpinnerProps) {
  return <StyledSpinner size={size} />;
}

const StyledSpinner = styled.span<SpinnerProps>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  ${sizeStyle};
  border: 3px solid currentColor;
  border-radius: 50%;
  animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
  :nth-child(1) {
    animation-delay: -0.45s;
  }
  :nth-child(2) {
    animation-delay: -0.3s;
  }
  :nth-child(3) {
    animation-delay: -0.15s;
  }
`;

function animation() {
  return keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
}

function sizeStyle({ size }: SpinnerProps) {
  if (size) {
    return {
      width: size,
      height: size,
    };
  }
}
