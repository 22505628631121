import type { ScenarioMap } from "@gemini/common";
import { getChildScenarios } from "@gemini/common";
import { validateScenario } from "./validate-scenario";

/** Validate all scenarios in the tree and bail on the first found error */
export function validateTree(
  scenarioId: string,
  scenarios: ScenarioMap
): {
  isValid: boolean;
  validationError?: string;
} {
  const validation = validateScenario(scenarioId, scenarios);

  if (!validation.isValid) {
    return validation;
  }

  const childScenarios = getChildScenarios(scenarios, scenarioId);

  for (const child of childScenarios) {
    const validation = validateTree(child.id, scenarios);
    if (!validation.isValid) {
      return validation;
    }
  }

  return { isValid: true };
}
