import type { DerivedReviewStructure, ReviewStructure } from "@gemini/common";
import {
  assert,
  getErrorMessage,
  parseComparableVersionString,
} from "@gemini/common";
import * as Sentry from "@sentry/browser";
import { uniq } from "lodash-es";
import React, { useMemo } from "react";
import type { InlineSelectOption } from "~/components";
import { getDocument } from "~/modules/firestore";
import { useIsMountedRef } from "~/modules/hooks";

export function useReviewStructureOptions({
  structures,
  selectedGroup,
  selectedStructureId,
  accountId,
}: {
  structures: Record<string, DerivedReviewStructure>;
  selectedGroup: string | undefined;
  selectedStructureId: string | undefined;
  accountId: string;
}) {
  const isMountedRef = useIsMountedRef();

  /**
   * Save initial value in local state to prevent the option from potentially
   * unmounting when it is deselected.
   */
  const initialValue = React.useRef(selectedStructureId);

  /** The default structure is the benchmark reference */
  const defaultStructureId = structures["benchmark"].id;

  assert(
    defaultStructureId,
    "Failed to find benchmark structure to use as default"
  );

  /**
   * The user should see only the public structures plus the private ones that
   * are linked to the account (currently only relevant for Baymard users)
   */
  const visibleStructures = useMemo(
    () =>
      Object.values(structures).filter(
        (x) => x.is_publicly_available || x.account_id === accountId
      ),
    [structures, accountId]
  );

  /**
   * The final list of structures equals the visible structures plus potentially
   * the currently used structure / version if it is not part of that list.
   */
  const [structuresList, setStructuresList] =
    React.useState<DerivedReviewStructure[]>(visibleStructures);

  React.useEffect(() => {
    if (
      initialValue.current &&
      !visibleStructures.find((x) => x.id === initialValue.current)
    ) {
      /**
       * The review structure id is not part of the list, which can happen when
       * the review structure is not publicly available. We'll manually load the
       * review structure and inject it in the options array.
       */
      getDocument<ReviewStructure>("review_structures", initialValue.current)
        .then((structure) =>
          setStructuresList([
            {
              ...structure.data,
              id: structure.id,
            },
            ...visibleStructures,
          ])
        )
        .catch((err) => {
          Sentry.captureException(
            `Could not find review structure with id "${
              initialValue.current
            }": ${getErrorMessage(err)}`
          );
          console.error(err);
        });
    }
  }, [isMountedRef, visibleStructures]);

  /** Derive options from list + current group */
  const structureOptions = useMemo(() => {
    /**
     * If there is no group selection we can return the full list. This would
     * also be the case for regular users that do not see the group selection.
     */
    const relevantStructures = selectedGroup
      ? structuresList.filter((x) => x.grouping_tags?.includes(selectedGroup))
      : structuresList;

    return createStructureOptions(relevantStructures);
  }, [structuresList, selectedGroup]);

  const groupOptions = useMemo(() => {
    return createGroupOptions(structuresList);
  }, [structuresList]);

  return {
    defaultStructureId,
    structureOptions,
    groupOptions,
  } as const;
}

function createStructureOptions(
  structures: DerivedReviewStructure[]
): InlineSelectOption<string>[] {
  return structures
    .map((x) => ({
      label: `${x.title} v${parseComparableVersionString(x.version)}`,
      value: x.id,
      description: x.description ?? "",
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

function createGroupOptions(
  structures: DerivedReviewStructure[]
): InlineSelectOption<string>[] {
  const groupingTags = uniq(structures.flatMap((x) => x.grouping_tags)).sort(
    (a, b) => a.localeCompare(b)
  );

  const options = groupingTags.map((x) => {
    return {
      label: x === "__ungrouped" ? "Ungrouped" : x,
      value: x,
    };
  });

  return options;
}
