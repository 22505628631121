import type { TranslationValue } from "@gemini/common";
import styled, { css } from "styled-components";
import { translationValueTagLabels } from "~/constants";
import { colors, fontSizes, radii, space } from "~/theme";

type TranslationValueTagProps = {
  value: TranslationValue;
};

export function TranslationValueTag({ value }: TranslationValueTagProps) {
  return (
    <StyledTranslationValueTag value={value}>
      {translationValueTagLabels[value]}
    </StyledTranslationValueTag>
  );
}

const StyledTranslationValueTag = styled.span<TranslationValueTagProps>`
  display: inline-block;
  padding: 0 ${space.xs};
  border-radius: ${radii.sm};
  white-space: nowrap;
  text-transform: uppercase;
  font-size: ${fontSizes.xxs};
  line-height: 1.6;
  border: 1px solid;
  text-align: center;

  ${(x) => css`
    color: white;
    border-color: ${colors.translationValue[x.value]};
    background-color: ${colors.translationValue[x.value]};
  `}
`;
