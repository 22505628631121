import { observer } from "mobx-react-lite";
import styled from "styled-components";
import {
  AdminFieldWrapper,
  Button,
  Checkbox,
  Field,
  SpacedChildren,
  TextArea,
} from "~/components";
import { useReviewStore } from "~/features/reviews";
import { useUserId } from "~/modules/auth";
import { notify } from "~/modules/notifications";
import { fontSizes, space } from "~/theme";
import { useAssessmentStore } from "../store";

type InternalNotesProps = {
  saveable?: boolean;
  onFinish?: () => void;
};

export const InternalNotes = observer(
  ({ saveable, onFinish }: InternalNotesProps) => {
    const userId = useUserId();
    const { review } = useReviewStore();
    const {
      internalComment,
      setInternalComment,
      isMarkedForDiscussion,
      setIsMarkedForDiscussion,
      isMarkedForSuggestion,
      setIsMarkedForSuggestion,
      submitAssessment,
      submittedAssessmentData,
    } = useAssessmentStore();

    if (!review.data) {
      return null;
    }

    function handleSave() {
      submitAssessment(userId).catch((err) => notify.error(err));
      onFinish && onFinish();
    }

    function handleCancel() {
      const {
        internal_comment,
        is_marked_for_discussion,
        is_marked_for_suggestion,
      } = submittedAssessmentData || {};

      setInternalComment(internal_comment ?? "");
      setIsMarkedForDiscussion(is_marked_for_discussion ?? false);
      setIsMarkedForSuggestion(is_marked_for_suggestion ?? false);
      onFinish && onFinish();
    }

    const submitButtons = saveable && (
      <SpacedChildren
        horizontal
        css={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: space.sm,
        }}
      >
        <Button
          disabled={review.data.is_read_only}
          size="small"
          variant="tertiary"
          onClick={handleCancel}
        >
          cancel
        </Button>
        <Button
          disabled={review.data.is_read_only}
          size="small"
          onClick={handleSave}
        >
          Save auditor notes
        </Button>
      </SpacedChildren>
    );

    return (
      <div>
        <AdminFieldWrapper
          message={
            <div>
              <em>Admin-only field</em>
              {submitButtons}
            </div>
          }
        >
          <Field
            as="div"
            label="Internal notes &ndash; only Baymard employees can see these notes"
          >
            <SpacedChildren css={{ fontSize: fontSizes.sm }}>
              <SpacedChildren
                horizontal
                css={{ display: "flex", flexWrap: "wrap" }}
              >
                <Label checked={isMarkedForSuggestion} css={{ paddingLeft: 0 }}>
                  <Checkbox
                    disabled={review.data.is_read_only}
                    checked={isMarkedForSuggestion}
                    onChange={setIsMarkedForSuggestion}
                  />
                  <span>Suggestion</span>
                </Label>

                <Label checked={isMarkedForDiscussion}>
                  <Checkbox
                    disabled={review.data.is_read_only}
                    checked={isMarkedForDiscussion}
                    onChange={setIsMarkedForDiscussion}
                  />
                  <span>Discussion</span>
                </Label>
              </SpacedChildren>

              <TextArea
                disabled={review.data.is_read_only}
                value={internalComment}
                onChange={setInternalComment}
              />
            </SpacedChildren>
          </Field>
        </AdminFieldWrapper>
      </div>
    );
  }
);

const Label = styled.label<{ checked: boolean }>`
  padding: ${space.xs};
  background: none;

  &:not(:has(:disabled)) {
    cursor: pointer;
  }

  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${space.sm};
  }
`;
