import { makeQueryString } from "./query-string";

const vimeoIdRegex =
  /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/;

export function getVimeoId(src: string) {
  const match = src.match(vimeoIdRegex);
  return match ? match[1] : undefined;
}

export function createVimeoUrl(id: string, params: { autoPlay?: boolean }) {
  const queryString = makeQueryString({
    /**
     * See
     * https://vimeo.zendesk.com/hc/en-us/articles/360001494447-Using-Player-Parameters
     * for more vimeo parameters
     */
    autoplay: !!params.autoPlay,
  });

  return `https://player.vimeo.com/video/${id}?${queryString}`;
}
