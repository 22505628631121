import { DislikeOutlined, LikeOutlined, SwapOutlined } from "@ant-design/icons";
import type { Polarity, Scenario } from "@gemini/common";
import { getNumberFromTranslationValue } from "@gemini/common";
import { Button, Checkbox, Space, Tabs } from "antd";
import { capitalize, isEmpty } from "lodash-es";
import { observer } from "mobx-react-lite";
import { isDefined } from "ts-is-present";
import { Comment } from "./comment";
import type { SetFieldValueFunction } from "./master-texts-editor-fields";
import { MasterTextsEditorFields } from "./master-texts-editor-fields";
import { SectionTitle } from "./section-title";

export const MasterTextsEditor = observer(
  ({
    scenario,
    setFieldValue,
    setIncludeMasterTextIfUnchecked,
    swapPolarity,
    hasPointBasedParent,
  }: {
    scenario: Scenario;
    setFieldValue: SetFieldValueFunction;
    setIncludeMasterTextIfUnchecked: (value: boolean) => void;
    swapPolarity: () => void;
    hasPointBasedParent: boolean;
  }) => {
    const { master_texts } = scenario;

    const hasValueOrScore = hasPointBasedParent
      ? isDefined(scenario.point_score)
      : isDefined(scenario.translation_value);

    const defaultPolarity = hasPointBasedParent
      ? (scenario.point_score ?? 0) >= 0
        ? "positive"
        : "negative"
      : scenario.translation_value
        ? getNumberFromTranslationValue(scenario.translation_value) >= 0
          ? "positive"
          : "negative"
        : "positive";

    const altPolarity =
      defaultPolarity === "positive" ? "negative" : "positive";

    const isDefaultPositive = defaultPolarity === "positive";

    function initializeMasterText(polarity: Polarity) {
      setFieldValue(polarity, "intro", "");
    }

    if (!master_texts && scenario.has_children && scenario.logic === "or") {
      return (
        <div>
          <SectionTitle noTopMargin>Master Texts</SectionTitle>
          {scenario.has_children && scenario.logic === "or" && (
            <Comment>
              Master texts on parents are only used when children are checkboxes
              (AND or points logic)
            </Comment>
          )}
        </div>
      );
    }

    if (!master_texts) {
      return (
        <>
          <SectionTitle noTopMargin>Master Texts</SectionTitle>
          {scenario.has_children && scenario.logic === "or" && (
            <Comment>
              Master texts on parents are only used when children are checkboxes
              (AND or points logic)
            </Comment>
          )}
          <Space>
            <Button
              type="primary"
              icon={isDefaultPositive ? <LikeOutlined /> : <DislikeOutlined />}
              ghost
              size="small"
              onClick={() => initializeMasterText(defaultPolarity)}
            >
              {`Add ${hasValueOrScore ? "Default" : ""}`}
            </Button>
            <Button
              type="primary"
              icon={isDefaultPositive ? <DislikeOutlined /> : <LikeOutlined />}
              ghost
              size="small"
              onClick={() => initializeMasterText(altPolarity)}
            >
              {`Add ${hasValueOrScore ? "Alt" : ""}`}
            </Button>
          </Space>
        </>
      );
    }

    const hasDefaultPolarity = !isEmpty(master_texts[defaultPolarity]);
    const hasAltPolarity = !isEmpty(master_texts[altPolarity]);

    return (
      <>
        <SectionTitle noTopMargin>Master Texts</SectionTitle>
        <Space direction="vertical">
          <Space>
            <Button
              type="primary"
              icon={isDefaultPositive ? <LikeOutlined /> : <DislikeOutlined />}
              ghost
              size="small"
              onClick={() => initializeMasterText(defaultPolarity)}
              disabled={hasDefaultPolarity}
            >
              {`Add ${hasValueOrScore ? "Default" : ""}`}
            </Button>
            <Button
              type="primary"
              icon={isDefaultPositive ? <DislikeOutlined /> : <LikeOutlined />}
              ghost
              size="small"
              onClick={() => initializeMasterText(altPolarity)}
              disabled={hasAltPolarity}
            >
              {`Add ${hasValueOrScore ? "Alt" : ""}`}
            </Button>
            <Button
              type="primary"
              icon={<SwapOutlined />}
              ghost
              size="small"
              onClick={swapPolarity}
            >
              Swap Polarity
            </Button>
          </Space>
          {hasPointBasedParent && (
            <Checkbox
              onChange={(v) =>
                setIncludeMasterTextIfUnchecked(v.target.checked)
              }
              checked={scenario.use_master_text_if_unchecked ?? false}
            >
              Use master text if unchecked
            </Checkbox>
          )}
        </Space>

        <Tabs
          animated={false}
          defaultActiveKey={hasDefaultPolarity ? defaultPolarity : altPolarity}
          items={[
            hasDefaultPolarity
              ? {
                  label: <span>{`${capitalize(defaultPolarity)}`}</span>,
                  key: defaultPolarity,
                  children: (
                    <MasterTextsEditorFields
                      scenario={scenario}
                      polarity={defaultPolarity}
                      setFieldValue={setFieldValue}
                    />
                  ),
                }
              : undefined,

            hasAltPolarity
              ? {
                  label: (
                    <div>
                      {`${capitalize(altPolarity)} ${
                        hasValueOrScore ? "(alt)" : ""
                      }`}
                      {hasValueOrScore && !hasDefaultPolarity && (
                        <span> !!</span>
                      )}
                    </div>
                  ),
                  key: altPolarity,
                  children: (
                    <MasterTextsEditorFields
                      scenario={scenario}
                      polarity={altPolarity}
                      setFieldValue={setFieldValue}
                    />
                  ),
                }
              : undefined,
          ].filter(isDefined)}
        />
      </>
    );
  }
);
