import type { CauseOfChange, Platform } from "@gemini/common";
import type { UiState } from "instantsearch.js";
import { isCauseOfChange } from "./cause-of-change";
import type { ImpactLabel } from "./impact";
import { isImpact } from "./impact";
import type { JudgementFilterValue } from "./judgement";
import { isJudgementFilterValue } from "./judgement";
import { isPlatform } from "./platform";

export type QueryStringParameters = {
  theme?: string[];
  platform?: Platform[];
  judgement?: JudgementFilterValue[];
  impact?: ImpactLabel[];
  isLowCost?: "true" | "false";
  isSuggestion?: "true" | "false";
  isMissedOpportunity?: "true" | "false";
  sort?: "impact_asc" | "impact_desc";
  includesNotes?: "true" | "false";
  causeOfChange?: CauseOfChange[];
};

/** Default sort order for the guidelines table. */
export const indexName = "impact_asc";

export function stateToRoute(uiState: UiState) {
  const { refinementList = {}, toggle = {}, sortBy } = uiState[indexName] || {};

  const theme = refinementList.breadcrumb || [];
  const platform = (refinementList.platform || []).filter(isPlatform);
  const judgement = (refinementList.judgement || []).filter(
    isJudgementFilterValue
  );
  const impact = (refinementList.impact || []).filter(isImpact);
  const causeOfChange = (refinementList.causeOfChange || []).filter(
    isCauseOfChange
  );

  const isLowCost = toggle.isLowCost ?? false;
  const isSuggestion = toggle.isSuggestion ?? false;
  const isMissedOpportunity = toggle.isMissedOpportunity ?? false;
  const includesNotes = toggle.includesNotes ?? false;

  const sort = sortBy === "impact_asc" ? "impact_asc" : "impact_desc";

  const queryStringParameters: QueryStringParameters = {};

  if (theme.length > 0) {
    queryStringParameters.theme = theme;
  }

  if (platform.length > 0) {
    queryStringParameters.platform = platform;
  }

  if (judgement.length > 0) {
    queryStringParameters.judgement = judgement;
  }

  if (impact.length > 0) {
    queryStringParameters.impact = impact;
  }

  if (causeOfChange.length > 0) {
    queryStringParameters.causeOfChange = causeOfChange;
  }

  if (isLowCost) {
    queryStringParameters.isLowCost = "true";
  }

  if (isSuggestion) {
    queryStringParameters.isSuggestion = "true";
  }

  if (isMissedOpportunity) {
    queryStringParameters.isMissedOpportunity = "true";
  }

  if (includesNotes) {
    queryStringParameters.includesNotes = "true";
  }

  if (sort === "impact_asc") {
    queryStringParameters.sort = "impact_asc";
  }

  return queryStringParameters;
}

export function routeToState(routeState: QueryStringParameters) {
  const {
    theme = [],
    platform = [],
    judgement = [],
    impact = [],
    causeOfChange = [],
    isLowCost = "false",
    isSuggestion = "false",
    isMissedOpportunity = "false",
    includesNotes = "false",
    sort = indexName,
  } = routeState;

  const refinementList: { [key: string]: string[] } = {
    breadcrumb: theme,
    platform,
    judgement,
    impact,
    causeOfChange,
  };

  const toggle: { [key: string]: boolean } = {
    isLowCost: isLowCost === "true",
    isSuggestion: isSuggestion === "true",
    isMissedOpportunity: isMissedOpportunity === "true",
    includesNotes: includesNotes === "true",
  };

  const sortBy = sort === "impact_asc" ? "impact_asc" : "impact_desc";

  return {
    [indexName]: {
      refinementList,
      toggle,
      sortBy,
    },
  };
}
