import type { Scenario, ScenarioMap } from "../schemas";

/**
 * Get children of the scenario. That means only direct descendants, so
 * excluding any of the grand children.
 */
export function getChildScenarios(
  scenarios: ScenarioMap,
  scenarioId: string
): Scenario[] {
  return Object.values(scenarios)
    .filter((scenario) => scenario.parent_id === scenarioId)
    .sort((a, b) => a.position - b.position);
}
