import type {
  JudgementNudgeValue,
  JudgementValue,
  TranslationValue,
} from "@gemini/common";
import {
  AH_VALUE,
  AL_VALUE,
  getJudgementFromNudging,
  judgementLabels,
  NA_VALUE,
  NU_VALUE,
  VH_VALUE,
  VL_VALUE,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import React from "react";
import { Paragraph } from "~/components";

export type JudgementSummaryProps = {
  judgement: JudgementValue;
  judgementNudgeValue: JudgementNudgeValue;
  isManualJudgement: boolean;
  calculatedTranslationValue: TranslationValue | undefined;
};

export const JudgementSummary = observer((props: JudgementSummaryProps) => {
  const {
    isManualJudgement,
    judgement,
    judgementNudgeValue,
    calculatedTranslationValue,
  } = props;

  const isValueNudged = judgementNudgeValue !== 0;

  const currentLabel = judgementLabels[judgement];

  if (calculatedTranslationValue === "ir") {
    return (
      <Paragraph>
        Based on your assessment selections we have rated this guideline as
        Issue Resolved. This is considered equivalent to Adhered High.
      </Paragraph>
    );
  }

  if (calculatedTranslationValue === "na") {
    return (
      <Paragraph>
        This guideline is not applicable to the site, and can therefore be
        safely ignored. Scorecards will automatically account for this by
        excluding this guideline from consideration when calculating UX
        performances for this site.
      </Paragraph>
    );
  }

  if (isManualJudgement) {
    return (
      <Paragraph>
        You’ve chosen to manually rate this guideline as{" "}
        <strong>{currentLabel}</strong>. You can return to the assessment page
        and edit this at any point.
      </Paragraph>
    );
  }

  if (isValueNudged) {
    const originalJudgement = getJudgementFromNudging(
      calculatedTranslationValue,
      0
    );
    const originalLabel = judgementLabels[originalJudgement];
    const explanation = judgementExplanations[judgement];

    return (
      <Paragraph>
        You’ve made the decision to adjust the rating from{" "}
        <strong>{originalLabel}</strong> to <strong>{currentLabel}</strong>{" "}
        {explanation}. You can change this at any point by clicking the adjust
        rating button below.
      </Paragraph>
    );
  }

  return (
    <Paragraph>
      Based on the selected options, this site&rsquo;s implementation of the
      guideline is considered <strong>{currentLabel}</strong>. If special
      circumstances apply, you may adjust the rating up or down.
    </Paragraph>
  );
});

const judgementExplanations = {
  [NA_VALUE]:
    "which means the guideline is not applicable to this website and is thus excluded from the usability score.",
  [AH_VALUE]: "which means the guideline is implemented perfect",
  [AL_VALUE]: "which means the guideline is implemented but with minor flaws.",
  [NU_VALUE]:
    "which means the implementation is neither good nor bad - typically violates in one place but adheres in another.",
  [VL_VALUE]:
    "which means a poor implementation but with some extenuating circumstances.",
  [VH_VALUE]: "which means a direct violation of the guideline.",
};
