import type { Review } from "@gemini/common";
import { editReview } from "~/modules/api";
import type { ReviewFormData } from "./review-form";
import { ReviewForm } from "./review-form";

export function EditReviewForm({
  reviewId,
  review,
  onFinish,
  onCancel,
}: {
  reviewId: string;
  review: Review;
  onFinish: (reviewId: string) => void;
  onCancel: () => void;
}) {
  return (
    <ReviewForm
      review={review}
      submitLabel="Update Review"
      onSubmit={handleSubmit}
      onCancel={onCancel}
      disableOptions
      disableReviewType={review.type === "training"}
      deleteableReviewId={reviewId}
    />
  );

  async function handleSubmit(data: ReviewFormData) {
    await editReview({ ...data, reviewId });
    onFinish(reviewId);
  }
}
