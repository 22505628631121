import md5 from "blueimp-md5";
import { pick } from "lodash-es";
import type { Guideline } from "~/schemas";
import { cleanObject } from "../utils";
import { versionedNonTextFields } from "./constants";

export function calculateGuidelineVersion(
  guideline: Guideline,
  shouldForceNewVersion?: boolean
): string {
  const scenarios = Object.values(guideline.scenarios);
  const versioningData = scenarios.map((item) =>
    pick(item, versionedNonTextFields)
  );

  const fullString = md5(
    JSON.stringify(
      cleanObject({
        scenarios: versioningData,
        timestamp: shouldForceNewVersion ? Date.now() : undefined,
      })
    )
  );

  /**
   * The last 8 characters should be sufficient for guideline versions. The
   * chance of collision is nihil and versions do not even need to be
   * universally unique since they are not used as identifiers, but mainly to
   * track whether a review uses outdated versions.
   */
  return fullString.substr(-8, 8);
}
