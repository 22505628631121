/**
 * The part store is responsible for navigating the user to the correct
 * guideline and showing progress for the current part of the review.
 *
 * Everything related to making the actual assessment is located in the
 * assessment store.
 */
import type {
  Assessment,
  Guideline,
  JudgementValue,
  ReviewStructurePart,
} from "@gemini/common";
import {
  AH_VALUE,
  AL_VALUE,
  NA_VALUE,
  NO_REFERENCE,
  NU_VALUE,
  VH_VALUE,
  VL_VALUE,
  assert,
  convertPartToAmbrosiaFormat,
} from "@gemini/common";
import type { PerformanceChartConfiguration } from "@gemini/performance-chart";
import autoBind from "auto-bind";
import { pick } from "lodash-es";
import { action, makeAutoObservable, runInAction } from "mobx";
import Router from "next/router";
import pMemoize from "p-memoize";
import { useEffect, useState } from "react";
import { isDefined } from "ts-is-present";
import { getDocument, type Document } from "~/modules/firestore";
import { notify } from "~/modules/notifications";
import { wrapAroundLength } from "~/modules/number";
import type { AppStore } from "~/store/store";
import { isReviewStructurePart } from "~/utils/identity";
import { getAssessmentByReferenceMap } from "../../helpers";
import type { ReviewStore } from "../../store";
import { getPartByReference, getPartProgressPercentage } from "./utils";

/**
 * Guideline documents are pretty much immutable, because every guideline
 * revision results in a new document. For fetching the guidelines we can
 * therefore memoize the getDocument so that the memoize function effectively
 * creates a cache.
 */
const memGetGuidelineDocument = pMemoize((id: string) =>
  getDocument<Guideline>("guidelines", id)
);

export function useMemoizedGuideline(id: string | undefined) {
  const [guideline, setGuideline] = useState<Document<Guideline>>();

  useEffect(() => {
    if (!id) return;

    let isCancelled = false;
    memGetGuidelineDocument(id)
      .then((snapshot) => {
        if (isCancelled) return;
        setGuideline(snapshot);
      })
      .catch(notify.error);

    return () => {
      isCancelled = true;
    };
  }, [id]);

  const isLoading = id && !guideline;

  return [guideline, isLoading] as const;
}

/**
 * The part store receives a handle to the review store. For more information
 * about defining data stores see https://mobx.js.org/defining-data-stores.html
 */
export class PartStore {
  appStore;
  reviewStore;

  currentPartId = "__no_part_id";
  currentReference = NO_REFERENCE;

  guidelinesCache = new Map<string, Document<Guideline>>();

  prevReference = NO_REFERENCE;
  nextReference = NO_REFERENCE;

  isLoading = false;

  constructor(appStore: AppStore, reviewStore: ReviewStore) {
    console.log("[part store] constructor");

    this.appStore = appStore;
    this.reviewStore = reviewStore;

    makeAutoObservable(this, {
      reviewStore: false,

      /**
       * Somehow makeAutoObservable doesn't recognize that initialize needs to
       * be an action.
       */
      initialize: action,
    });
    autoBind(this);
  }

  /** In the overview page, a part can initialize without reference. */
  async initialize(partId: string, reference = NO_REFERENCE) {
    const shouldInitPart = partId !== this.currentPartId;

    if (shouldInitPart) {
      console.log(`[part store] initialize for part ${partId}`);
    }
    /**
     * Store the reference and partId so we filter out duplicate initialize
     * calls that come in short succession.
     */
    this.currentReference = reference;
    this.currentPartId = partId;

    if (shouldInitPart) {
      this.isLoading = true;

      const { page, reviewId } = Router.query as Record<string, string>;

      const part = this.reviewStore.structure.data?.sections[
        partId
      ] as ReviewStructurePart;

      if (!part && reference !== NO_REFERENCE) {
        /**
         * Part was not found, we might be handling an old part id. We'll try to
         * lookup the new partId by the guideline reference.
         */
        const newPart = getPartByReference(
          reference,
          this.reviewStore.structure.data?.sections ?? {}
        );

        if (newPart) {
          console.log(
            `[part store] Found new part id by reference, redirect to new assessment url with new part id`
          );
          await Router.replace(
            "/reviews/[reviewId]/parts/[partId]/assessments/[reference]/[page]",
            `/reviews/${reviewId}/parts/${newPart.id}/assessments/${reference}/${page}`
          );
          return;
        }
      }

      if (!part) {
        console.log(
          `[part store] Unable to locate part ${partId} in structure ${this.reviewStore.structure.data?.reference}, redirect to review page`
        );

        return Router.replace("/reviews/[reviewId]", `/reviews/${reviewId}`);
      }

      await this.fetchGuidelinesForPart(part);

      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  /** These three are just internal helpers to keep the code a little shorter */
  private get review() {
    return this.reviewStore.review.document;
  }

  private get reviewStructure() {
    return this.reviewStore.structure.document;
  }

  private get assessments() {
    return this.reviewStore.assessments.documents;
  }

  private get comparisonAssessments() {
    return this.reviewStore.comparisonAssessments.documents;
  }

  private get guidelineObservations() {
    return this.reviewStore.guidelineObservations.documents;
  }

  private get comparisonResults() {
    return this.reviewStore.comparisonResults.documents;
  }

  get guideline() {
    if (this.isLoading) return;

    assert(
      this.currentReference !== NO_REFERENCE,
      "Can not get guideline without reference"
    );

    const guideline = this.guidelinesCache.get(this.currentReference);

    if (!guideline) {
      notify.warning(
        `We cannot find the guideline for reference ${this.currentReference}`
      );
      Router.replace(
        "/reviews/[reviewId]/parts/[partId]",
        `/reviews/${this.reviewStore.review.id}/parts/${this.currentPartId}`
      ).catch((err) => notify.error(err));

      return;
    }

    return guideline;
  }

  get assessment() {
    return this.assessments.find(
      (x) => x.data.guideline_reference === this.currentReference
    );
  }

  get comparisonAssessment() {
    return this.comparisonAssessments.find(
      (x) => x.data.guideline_reference === this.currentReference
    );
  }

  get guidelineObservation() {
    return this.guidelineObservations.find(
      (x) => x.id === this.currentReference
    );
  }

  get comparisonResult() {
    return this.comparisonResults.find((x) => x.id === this.currentReference);
  }

  get sortedParts() {
    return Object.values(this.reviewStore.structure.data?.sections || {})
      .filter(isReviewStructurePart)
      .sort((a, b) => a.sequential_position - b.sequential_position);
  }

  get nextPart() {
    const currentPartIndex = this.sortedParts.findIndex(
      (part) => part.id === this.currentPartId
    );

    assert(
      currentPartIndex !== -1,
      `Failed to locate part for id ${this.currentPartId}`
    );

    return this.sortedParts[
      wrapAroundLength(currentPartIndex + 1, this.sortedParts.length)
    ];
  }

  get entryGuidelineLink() {
    const partProgress =
      this.review.data.progress.progress_by_part[this.currentPartId];

    return `/reviews/${this.review.id}/parts/${
      this.currentPartId
    }/assessments/${
      partProgress?.next_unassessed_reference ||
      "__no_next_unassessed_reference"
    }/assess`;
  }

  fetchGuidelinesForPart(part: ReviewStructurePart) {
    const { linked_guidelines } = this.review.data;

    console.log(`[part store] fetch guidelines for part ${part.id}`);

    /**
     * Technically we should take out the guidelines that are excluded from the
     * review so that they are not fetched, but these are so rare that it might
     * not even be worth the extra code.
     */
    const guidelineIdMap = pick(linked_guidelines, part.guideline_references);

    const promisedOperations = Object.entries(guidelineIdMap).map(
      async ([reference, id]) => {
        const document = await memGetGuidelineDocument(id);
        return [reference, document] as [string, Document<Guideline>];
      }
    );

    return Promise.all(promisedOperations)
      .then((items) => {
        runInAction(() => {
          for (const [reference, guideline] of items) {
            this.guidelinesCache.set(reference, guideline);
          }
        });
      })
      .then(() => console.log("[part store] fetch ready"));
  }

  get part() {
    assert(this.reviewStructure.data, "Missing review structure data");

    const part = this.reviewStructure.data.sections[
      this.currentPartId
    ] as ReviewStructurePart;

    assert(part, `Failed to locate part for ${this.currentPartId}`);

    return part;
  }

  get excludedReferences() {
    assert(this.review.data, "Missing review data");
    return Object.keys(this.review.data.excluded_guidelines);
  }

  get guidelinesForPart(): Document<Guideline>[] {
    const guidelines = this.part.guideline_references
      .filter((x) => !this.excludedReferences.includes(x))
      .map((x) => this.guidelinesCache.get(x));

    return guidelines.filter(isDefined);
  }

  get assessmentsForPart(): Document<Assessment>[] {
    return this.assessments.filter(
      (x) =>
        this.part.guideline_references.includes(x.data.guideline_reference) &&
        !this.excludedReferences.includes(x.data.guideline_reference)
    );
  }

  get progress() {
    const guidelineCount = this.guidelinesForPart.length;
    const assessmentCount = this.assessmentsForPart.length;

    const countByJudgement = this.assessmentsForPart.reduce<
      Record<JudgementValue, number>
    >(
      (result, assessment) => {
        result[assessment.data.judgement]++;
        return result;
      },
      {
        [AH_VALUE]: 0,
        [AL_VALUE]: 0,
        [NU_VALUE]: 0,
        [VL_VALUE]: 0,
        [VH_VALUE]: 0,
        [NA_VALUE]: 0,
      }
    );

    const percentage =
      /**
       * If the guidelines have not yet been loaded we will fallback on the
       * server-side calculated progress (progress_by_part).
       */
      this.isLoading
        ? getPartProgressPercentage(
            this.review.data?.progress.progress_by_part[this.currentPartId]
          )
        : Math.ceil((assessmentCount / guidelineCount) * 100);

    return {
      guidelineCount,
      assessmentCount,
      percentage,
      isCompleted: percentage === 100,
      countByJudgement,
    };
  }

  get navigation() {
    assert(this.part, `Can't get navigation if no part data is available`);
    assert(this.reviewStructure.data, "Missing review structure data");

    const isReadOnly = this.review.data.is_read_only;
    const assessmentByReference = getAssessmentByReferenceMap(this.assessments);

    const { title, parent_group_id } = this.part;

    const referencesForPart = this.guidelinesForPart
      .map((x) => x.data.reference)
      /** Filter guidelines without assessment when in read only mode */
      .filter((reference) => !isReadOnly || assessmentByReference[reference]);

    const index = referencesForPart.findIndex(
      (ref) => ref === this.currentReference
    );

    const prevIndex =
      referencesForPart.length > 0
        ? wrapAroundLength(index - 1, referencesForPart.length)
        : 0;
    const nextIndex =
      referencesForPart.length > 0
        ? wrapAroundLength(index + 1, referencesForPart.length)
        : 0;

    const prevReference = referencesForPart[prevIndex];
    const nextReference = referencesForPart[nextIndex];

    const prevAssessment = this.assessments.find(
      (doc) => doc.data.guideline_reference === prevReference
    );

    const nextPartId = this.nextPart.id;

    const nextAssessment = this.assessments.find(
      (doc) => doc.data.guideline_reference === nextReference
    );

    const nextPartReference =
      this.nextPart.guideline_references[0] || "__no_next_part_reference";

    const nextPartAssessment = this.assessments.find(
      (doc) => doc.data.guideline_reference === nextPartReference
    );

    const reviewId = this.review.id;

    const prevLink = prevAssessment
      ? `/reviews/${reviewId}/parts/${this.currentPartId}/assessments/${prevReference}/summary`
      : `/reviews/${reviewId}/parts/${this.currentPartId}/assessments/${prevReference}/assess`;

    const nextLink = nextAssessment
      ? `/reviews/${reviewId}/parts/${this.currentPartId}/assessments/${nextReference}/summary`
      : `/reviews/${reviewId}/parts/${this.currentPartId}/assessments/${nextReference}/assess`;

    const nextPartLink = nextPartAssessment
      ? `/reviews/${reviewId}/parts/${nextPartId}`
      : `/reviews/${reviewId}/parts/${nextPartId}`;

    const overviewLink = `/reviews/${reviewId}/parts/${this.currentPartId}`;

    return {
      title,
      position: index,
      total: referencesForPart.length,
      prevLink,
      nextLink,
      overviewLink,
      nextPartLink,
      groupId: parent_group_id,
      reviewId,
    };
  }

  get viewStructure() {
    const partId = this.currentPartId;

    const part = this.reviewStructure.data.sections[partId];

    assert(
      part,
      `Failed to locate part ${partId} in structure ${this.reviewStructure.id}`
    );

    if (!isReviewStructurePart(part)) {
      const errorMessage = `Section ${partId} in structure ${this.reviewStructure.id} is not a part`;
      notify.error(errorMessage);
      return;
    }

    const formattedPart = convertPartToAmbrosiaFormat(
      partId,
      part,
      this.appStore.currentGuidelines
    );

    const formattedStructure: PerformanceChartConfiguration["viewStructure"] = {
      title: `${this.reviewStructure.data.title}: ${formattedPart.title}`,
      summaries: [formattedPart],
    };

    return formattedStructure;
  }
}
