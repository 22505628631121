import type { Guideline } from "@gemini/common";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";
import { JudgementPill, Table, Text } from "~/components";
import type { Document } from "~/modules/firestore";
import { preset } from "~/theme";
import { WithCitationCodePrefix } from "../../components/with-citation-code-prefix";
import { getAssessmentByReferenceMap } from "../../helpers";
import { useReviewStore } from "../../store";

type GuidelineListProps = {
  reviewId: string;
  guidelines: Document<Guideline>[];
};

export const GuidelineList: React.FC<GuidelineListProps> = observer(
  ({ guidelines, reviewId }) => {
    const { review, assessments } = useReviewStore();
    const router = useRouter();

    const partId = router.query.partId as string;
    const assessmentByReference = getAssessmentByReferenceMap(
      assessments.documents
    );

    const showImplementationColumn = guidelines.some(
      (x) =>
        (assessmentByReference[x.data.reference]?.selected_scenario_ids || [])
          .length > 0
    );

    return (
      <Table
        data={guidelines}
        getKey={(x) => x.id}
        columns={[
          {
            name: "Guideline",
            size: showImplementationColumn ? "50%" : undefined,
            render(guideline) {
              const { reference, scenarios, citation_code } = guideline.data;
              const assessment = assessmentByReference[reference];
              return (
                <WithCitationCodePrefix citationCode={citation_code}>
                  <GuidelineTitle
                    reference={reference}
                    reviewId={reviewId}
                    partId={partId}
                    readOnly={!!review.data?.is_read_only}
                    hasAssessment={!!assessment}
                  >
                    {scenarios.__root.title}
                  </GuidelineTitle>
                </WithCitationCodePrefix>
              );
            },
          },
          showImplementationColumn
            ? {
                name: "Implementation",
                size: "50%",
                render(guideline) {
                  const { reference, scenarios } = guideline.data;
                  const assessment = assessmentByReference[reference];
                  const implementation = assessment?.selected_scenario_ids
                    ?.map((id) => scenarios[id]?.title)
                    .join(" • ");
                  return <Text size="small">{implementation}</Text>;
                },
              }
            : undefined,
          {
            name: "Judgement",
            render(guideline) {
              const assessment =
                assessmentByReference[guideline.data.reference];
              return (
                <div>
                  <JudgementPill
                    value={assessment?.judgement}
                    isIssueResolved={assessment?.is_issue_resolved}
                  />
                </div>
              );
            },
          },
        ]}
      />
    );
  }
);

type GuidelineTitleProps = {
  children: React.ReactNode;
  reviewId: string;
  partId: string;
  readOnly: boolean;
  hasAssessment: boolean;
  reference: string;
};

const GuidelineTitle = observer(
  ({
    children,
    reviewId,
    partId,
    /** @todo Check read only status */
    readOnly,
    reference,
    hasAssessment,
  }: GuidelineTitleProps) => {
    const summaryLink = (
      <StyledGuidelineTitle
        href={`/reviews/${reviewId}/parts/${partId}/assessments/${reference}/summary`}
      >
        {children}
      </StyledGuidelineTitle>
    );

    const assessLink = (
      <StyledGuidelineTitle
        href={`/reviews/${reviewId}/parts/${partId}/assessments/${reference}/assess`}
      >
        {children}
      </StyledGuidelineTitle>
    );

    if (readOnly && hasAssessment) {
      return summaryLink;
    }

    if (readOnly && !hasAssessment) {
      return <span css={preset.typography.subtitle2}>{children}</span>;
    }

    return hasAssessment ? summaryLink : assessLink;
  }
);

const StyledGuidelineTitle = styled(Link)`
  ${preset.typography.subtitle2};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
