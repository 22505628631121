import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";
import { Message, Paragraph, SpacedChildren } from "~/components";
import { useReviewStore } from "~/features/reviews/store";

export const GroupsAndPartsHeader = observer(() => {
  const { review, structure } = useReviewStore();

  assert(review.data, "Unable to render without review");
  assert(structure.data, "Unable to render without structure");

  const { confirm = 0, assess = 0 } = review.data.pending_update_counters || {};

  /**
   * @todo Add + remove types should also be part of the nonSilentUpdateCount
   *   but we don't have actions or UI on the update page for them yet.
   */
  const nonSilentUpdateCount = assess + confirm;
  const canBeUpdated = !review.data.is_read_only && nonSilentUpdateCount > 0;

  const foreignAssessmentsCount =
    review.data.structure_foreign_references.length;

  return (
    <SpacedChildren>
      {foreignAssessmentsCount > 0 && (
        <Message variant="info">
          <Paragraph noMargin>
            There's <strong>{foreignAssessmentsCount}</strong> guideline
            assessments in this site review that aren't part of the current
            selected review structure: "<em>{structure.data.title}</em>". These{" "}
            {foreignAssessmentsCount} guideline assessments are not lost, they
            just won't be displayed in the performance scorecard or in the
            assessment list. View the{" "}
            <Link href={`/reviews/${review.id}/foreign-assessments`}>
              <strong>{foreignAssessmentsCount} foreign assessments</strong>
            </Link>
            . To include them, you have to edit what you{" "}
            <Link href={`/reviews/${review.id}/edit`}>
              <strong>want to review</strong>
            </Link>
            .
          </Paragraph>
        </Message>
      )}

      {canBeUpdated && (
        <Message variant="info">
          <Paragraph noMargin>
            We've made improvements to our research catalog, which affects{" "}
            {nonSilentUpdateCount} of the guidelines assessed in this review.
            See the{" "}
            <Link href={`/reviews/${review.id}/updates`}>
              <strong>available upgrades</strong>
            </Link>
            .
          </Paragraph>
        </Message>
      )}
    </SpacedChildren>
  );
});
