import React from "react";

export function useLocalStorageNumber(
  key: string
): [number | undefined, (value: number | undefined) => void] {
  const [value, setValue] = React.useState<number | undefined>(() =>
    getNumberFromStorage(key)
  );
  React.useEffect(() => setValue(getNumberFromStorage(key)), [key]);

  return [
    value,
    (value: number | undefined) => {
      setValue(value);
      setNumberInStorage(key, value);
    },
  ];
}

function getNumberFromStorage(key: string) {
  try {
    const valueStr = window.localStorage.getItem(key);
    return valueStr ? parseFloat(valueStr) : undefined;
  } catch (err) {}
}

function setNumberInStorage(key: string, value?: number) {
  try {
    if (value === undefined) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, value.toString());
    }
  } catch (err) {}
}
