import { observer } from "mobx-react-lite";
import { useState } from "react";
import styled from "styled-components";
import { Spinner } from "~/components";
import { WithTooltip } from "~/components/with-tooltip";
import { notify } from "~/modules/notifications";
import { colors, space } from "~/theme";
import { useReviewStore } from "../store";
import { redeliverAudit } from "~/modules/api";

export const AuditDeliveryStatus = observer(() => {
  const [isDelivering, setIsDelivering] = useState(false);
  const { isDeliverableOutdated, review } = useReviewStore();

  function handleClick() {
    setIsDelivering(true);

    redeliverAudit({ review_id: review.id })
      .catch(notify.error)
      .finally(() => setIsDelivering(false));
  }

  return isDeliverableOutdated ? (
    <WithTooltip
      content={
        <div>
          <p css={{ marginBottom: 10 }}>
            The audit is out of sync with the delivered audit. Click to
            re-deliver the audit to the client.
          </p>
          <p>
            This operation can be performed up to 1 month after the audit was
            delivered. After that, the audit will be locked and no further
            changes can be made.
          </p>
        </div>
      }
    >
      <RedeliverButton onClick={handleClick} disabled={isDelivering}>
        {isDelivering && <RedeliverSpinner />}
        <span>Redeliver audit to client</span>
      </RedeliverButton>
    </WithTooltip>
  ) : (
    <RedeliverButton $latest disabled>
      audit in sync with delivered audit
    </RedeliverButton>
  );
});

function RedeliverSpinner() {
  return (
    <span
      css={{
        width: 12,
        height: 12,
        display: "grid",
        placeItems: "center",
      }}
    >
      <Spinner size={16} />
    </span>
  );
}

const RedeliverButton = styled.button`
  background-color: ${(props: { $latest?: boolean }) =>
    props.$latest ? colors.translationValue.ah : colors.translationValue.vh};
  border-radius: 2px;
  color: ${colors.whiteLilac};
  display: inline-block;
  font-family: monospace;
  font-size: 12px;
  padding: 2px 8px;
  border: none;
  line-height: 1.5;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
  display: flex;
  align-items: center;
  gap: ${space.xs};
`;
