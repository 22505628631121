import { useRouter } from "next/router";
import React from "react";
import { LoadingIndicator, PageContainer } from "~/components";

export function PageLoader({ aside }: { aside?: React.ReactNode }) {
  const router = useRouter();

  if (router.pathname.startsWith("/embedded")) {
    return null;
  }

  return (
    <PageContainer aside={aside} hideSiteFooter testingName="page-loader">
      <LoadingIndicator />
    </PageContainer>
  );
}
