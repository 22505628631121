import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";
import { Button, IconLink, Input } from "~/components";
import { attachComparison, detachComparison } from "~/modules/api";
import { useIsMountedRef } from "~/modules/hooks";
import { notify } from "~/modules/notifications";
import { fontSizes, space } from "~/theme";
import { useReviewStore } from "../reviews";

export const ReviewComparisonAttachDetachToggle = observer(
  function ReviewComparisonAttachDetachToggle() {
    const { reviewComparison } = useReviewStore();

    return reviewComparison.data ? <DetachComparison /> : <AttachComparison />;
  }
);

const DetachComparison = observer(function DetachComparison() {
  const [isPending, setIsPending] = React.useState(false);
  const { reviewComparison, comparisonReview } = useReviewStore();

  const subjectReviewId = reviewComparison.data?.subject_review_id ?? "";
  const comparisonReviewId = comparisonReview.id;
  const comparisonReviewTitle = comparisonReview.data?.title;

  async function handleDetachComparison() {
    if (window.confirm("Are you sure you want to detach the comparison?")) {
      setIsPending(true);
      await detachComparison({ subjectReviewId }).catch(notify.error);
    }
    setIsPending(false);
  }

  return (
    <div>
      <IconLink
        icon="minus"
        onClick={() => void handleDetachComparison()}
        loading={isPending}
      >
        {isPending ? "Detaching..." : "Detach comparison review"}
      </IconLink>
      <p
        css={{
          fontSize: fontSizes.sm,
          marginTop: space.xs,
          fontStyle: "italic",
        }}
      >
        Comparing against:{" "}
        <Link
          css={{ fontSize: fontSizes.xs }}
          href={`/reviews/${comparisonReviewId}`}
        >
          {comparisonReviewTitle}
        </Link>
      </p>
    </div>
  );
});

function AttachComparison() {
  const { review } = useReviewStore();
  const isMountedRef = useIsMountedRef();
  const [showForm, setShowForm] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);

  const subjectReviewId = review.id;

  async function handleAttachComparison(comparisonReviewId: string) {
    try {
      setIsPending(true);
      const comparisonReviewIds = [comparisonReviewId];
      await attachComparison({ subjectReviewId, comparisonReviewIds });
    } catch (error) {
      notify.error(error);
    } finally {
      isMountedRef.current && setIsPending(false);
    }
  }

  return (
    <div>
      {showForm ? (
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            void handleAttachComparison(
              evt.currentTarget.comparisonReviewId.value
            );
          }}
        >
          <div css={{ display: "flex", gap: 10 }}>
            <Input
              name="comparisonReviewId"
              cx={{ padding: "4px 8px" }}
              placeholder="comp. review ID"
              autoFocus
              required
              disabled={isPending}
            />
            <Button size="small" loading={isPending} disabled={isPending}>
              Attach
            </Button>
          </div>
        </form>
      ) : (
        <IconLink icon="plus" onClick={() => setShowForm(true)}>
          Attach comparison review
        </IconLink>
      )}
    </div>
  );
}
