import Joi from "@hapi/joi";
import md5 from "blueimp-md5";
import { isEmpty, set } from "lodash-es";
import type {
  Dictionary,
  ReviewStructure,
  ReviewStructureFmtAdmin,
  ReviewStructureGroup,
  ReviewStructureGroupFmtAdmin,
  ReviewStructurePart,
  ReviewStructurePartFmtAdmin,
} from "~/schemas";
import { cleanObject } from "~/utils";
import {
  formatComparableVersionString,
  isReviewStructurePartFmtAdmin,
} from "..";
import { convertSectionsFromAdminFormat } from "./convert-sections-from-admin-format";

export type PartialReviewStructure = Omit<
  ReviewStructure,
  "created_by" | "created_at" | "account_id"
>;

export function convertStructureFromAdminFormat(
  structureData: ReviewStructureFmtAdmin,
  referenceByCitationCode: Dictionary<string>,
  state: {
    sequentialPositionCounter: number;
    unresolvedCitationCodes: string[];
  }
): PartialReviewStructure {
  const {
    reference,
    version,
    title,
    description,
    grouping_tags,
    is_publicly_available,
    is_archived,
    sections: adminSections,
  } = structureData;

  const sectionsArray = convertSectionsFromAdminFormat(
    referenceByCitationCode,
    adminSections,
    state
  );

  const sectionsMap = sectionsArray.reduce(
    (acc, v) => set(acc, v.id, v),
    {} as Record<string, ReviewStructureGroup | ReviewStructurePart>
  );

  return cleanObject({
    reference,
    /** Baymard editors tend to add a v prefix because it is displayed in the UI */
    version: formatComparableVersionString(version.replace(/v/g, "")),
    title,
    description,
    grouping_tags: applyUngroupedFallback(grouping_tags),
    is_archived: is_archived ?? false,
    is_publicly_available,
    sections: sectionsMap,
    unmatched_citation_codes:
      state.unresolvedCitationCodes.length > 0
        ? state.unresolvedCitationCodes
        : undefined,
  });
}

/**
 * When tags are set we make sure that __ungrouped is not part of it. But if the
 * resulting array is empty we use __ungrouped as the fallback. This way each
 * structure has either named groups or a single group called __ungrouped, but
 * never both.
 */
function applyUngroupedFallback(groupingTags?: string[]) {
  const UNGROUPED = "__ungrouped";

  const filteredGroupingTags =
    groupingTags?.filter((x) => x !== UNGROUPED) ?? [];

  return isEmpty(filteredGroupingTags) ? [UNGROUPED] : filteredGroupingTags;
}

/**
 * Each section gets an id based on all its contained guideline citations codes.
 * This way the ids are somewhat stable when structures are edited or switched,
 * and we can apply caching to the mechanisms that fetch the guidelines for each
 * section.
 */
export function derivedSectionId(
  section: ReviewStructureGroupFmtAdmin | ReviewStructurePartFmtAdmin
): string {
  const hash = isReviewStructurePartFmtAdmin(section)
    ? getHashForPart(section)
    : getHashForGroup(section);

  /** The last 8 characters should be sufficient. */
  return hash.substring(hash.length - 8);
}

function getHashForPart(section: ReviewStructurePartFmtAdmin) {
  return md5(JSON.stringify(section.guideline_citation_codes));
}

/**
 * Groups get their id based on the ids of their children. If those are also
 * groups they will in turn get their ids based on their children until parts
 * are providing the ids based on guideline content. This way all ids are stable
 * and unique.
 */
function getHashForGroup(section: ReviewStructureGroupFmtAdmin) {
  const childIds = section.sections.map((x) => derivedSectionId(x));

  return md5(JSON.stringify(childIds));
}

export const reviewStructureFmtAdminSchema =
  Joi.object<ReviewStructureFmtAdmin>({
    reference: Joi.string().required(),
    version: Joi.string().required(),
    grouping_tags: Joi.array().items(Joi.string()),
    title: Joi.string().required(),
    /**
     * Null needs to be allowed here because this schema is also directly used
     * in the http submit edits endpoint, and undefined will be passed as null
     */
    description: [Joi.string(), Joi.allow(null)],
    is_publicly_available: Joi.boolean().required(),
    is_archived: Joi.boolean(),
    // Disable validation because it seems to give errors when structure is valid
    // sections: Joi.array().items(Joi.alternatives([groupSchema, partSchema])),
    sections: Joi.array().items(Joi.object()),
  });
