/* eslint-disable @typescript-eslint/no-explicit-any */

import { Typography } from "antd";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import type { MarkdownProps } from "~/components";
import { Markdown, MarkdownLink } from "~/components";

const { Title, Paragraph } = Typography;

const markdownComponents: MarkdownProps["components"] = {
  p: (props: any) => <Paragraph {...props} />,
  ul: (props: any) => <ul {...props} />,
  ol: (props: any) => <ol {...props} />,
  a: (props: any) => <MarkdownLink {...props} />,
  h1: (props: any) => <Title level={3} {...props} />,
  h2: (props: any) => <Title level={4} {...props} />,
  h3: (props: any) => <Title level={4} {...props} />,
  h4: (props: any) => <Title level={4} {...props} />,
  h5: (props: any) => <Title level={5} {...props} />,
  h6: (props: any) => <Title level={5} {...props} />,
};

/** A variation of MarkdownText, to stay within Antd components */
export function AdminMarkdownText(props: MarkdownProps) {
  return (
    <Typography.Paragraph>
      <Markdown
        {...props}
        components={markdownComponents}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
      />
    </Typography.Paragraph>
  );
}
