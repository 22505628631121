import { observer } from "mobx-react-lite";
import Link from "next/link";
import { AsideBox } from "~/components";
import { usePartStore, useReviewStore } from "~/features/reviews";
import { useMemoizedGuideline } from "~/features/reviews/part/store/store";
import { AssessmentSummary, LoadingCompareView } from "./assessment-summary";

export const ComparisonAssessmentSummary = observer(
  function ComparisonAssessmentSummary({
    contentOnly = false,
  }: {
    contentOnly?: boolean;
  }) {
    const { comparisonReview } = useReviewStore();
    const { comparisonAssessment } = usePartStore();

    const { guideline_id_linked } = comparisonAssessment?.data || {};
    const [guideline, isLoading] = useMemoizedGuideline(guideline_id_linked);

    if (!comparisonReview.data || !comparisonAssessment?.data) {
      return null;
    }

    if (isLoading) {
      return <LoadingCompareView />;
    }

    if (!guideline) {
      return null;
    }

    const citationCode = guideline.data.citation_code.replace("#", "");
    const titleLink = (
      <Link
        target="_blank"
        href={`/reviews/${comparisonReview.id}/guideline/${citationCode}`}
      >
        {comparisonReview.data.title}'s Assessment
      </Link>
    );

    const content = (
      <AssessmentSummary
        title={titleLink}
        assessment={comparisonAssessment?.data}
        scenarios={guideline.data.scenarios}
        showJudgement
      />
    );

    if (contentOnly) {
      return content;
    }

    return <AsideBox>{content}</AsideBox>;
  }
);
