/* eslint-disable  @typescript-eslint/no-explicit-any */

import React from "react";
import type { Options } from "react-markdown";
import ReactMarkdown from "react-markdown";
import { List } from "./list";
import { Paragraph, Title } from "./typography";

const defaultComponents: Options["components"] = {
  p: (props: any) => <Paragraph {...props} />,
  ul: (props: any) => <List {...props} />,
  ol: (props: any) => <List ordered {...props} />,
  h1: (props: any) => <Title {...props} />,
  h2: (props: any) => <Title {...props} />,
  h3: (props: any) => <Title {...props} />,
  h4: (props: any) => <Title {...props} />,
  h5: (props: any) => <Title {...props} />,
  h6: (props: any) => <Title {...props} />,
};

export type MarkdownProps = Options;

export function Markdown({ components, ...props }: MarkdownProps) {
  return (
    <ReactMarkdown
      components={{ ...defaultComponents, ...components }}
      {...props}
    />
  );
}
