import { motion } from "framer-motion";
import React from "react";
import styled, { css } from "styled-components";
import type { CropRegionsConfig } from "../logic";

export function CropLines({
  regionsConfig,
  getY,
  height,
  top,
  bottom,
  topHover,
  bottomHover,
  onTopChange,
  onBottomChange,
  onTopHover,
  onBottomHover,
}: {
  regionsConfig: CropRegionsConfig;
  getY: (y: number) => number;
  height: number;
  top: number;
  bottom: number;
  topHover: number | undefined;
  bottomHover: number | undefined;
  onTopChange: (top: number) => void;
  onBottomChange: (bottom: number) => void;
  onTopHover: (top: number | undefined) => void;
  onBottomHover: (bottom: number | undefined) => void;
}) {
  return (
    <div>
      {[...regionsConfig.topOffsets]
        .sort((a, b) => a - b)
        .map((x, i, list) => (
          <Line
            initial={false}
            key={x}
            top={getY(x)}
            onClick={() => onTopChange(x)}
            onMouseEnter={() => onTopHover(x)}
            onMouseLeave={() => onTopHover(undefined)}
            css={css`
              &::after {
                top: ${i === 0 ? -50 : 0}px;
                bottom: ${i === list.length - 1 ? -50 : -getY(list[i + 1])}px;
              }
            `}
            animate={{
              opacity: top === x || topHover === x ? 1 : 0.5,
              left: top === x || topHover === x ? -100 : -50,
              right: top === x || topHover === x ? -100 : -50,
            }}
          />
        ))}

      {[...regionsConfig.bottomOffsets]
        .sort((a, b) => b - a)
        .map((x, i, list) => (
          <Line
            initial={false}
            key={x}
            top={height - getY(x)}
            onClick={() => onBottomChange(x)}
            onMouseEnter={() => onBottomHover(x)}
            onMouseLeave={() => onBottomHover(undefined)}
            css={css`
              &::after {
                top: ${i === 0 ? -50 : getY(list[i] - list[i - 1])}px;
                bottom: ${i === list.length - 1 ? -50 : 0}px;
              }
            `}
            animate={{
              opacity: bottom === x || bottomHover === x ? 1 : 0.5,
              left: bottom === x || bottomHover === x ? -100 : -50,
              right: bottom === x || bottomHover === x ? -100 : -50,
            }}
          />
        ))}
    </div>
  );
}

const Line = styled(motion.div)<{ top: number }>`
  position: absolute;
  z-index: 10;
  top: ${(props: { top: number }) => props.top}px;
  height: 0px;
  padding: 0;
  margin: 0;
  border: 0;
  border-top: 1px dashed #fff;
  border-bottom: 1px dashed #000;
  cursor: pointer;
  background: transparent;
  transform: translateY(-50%);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
  }
`;
