import styled from "styled-components";
import { colors, fontWeights, space } from "~/theme";

export const StatusCircle = styled.span<{ active?: boolean }>`
  display: inline-block;
  font-weight: ${(x) => (x.active ? fontWeights.bold : fontWeights.regular)};
  white-space: nowrap;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: ${space.sm};
    background: ${(x) => (x.active ? colors.success : colors.ghostGray)};
  }
`;
