import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React from "react";
import { isDefined } from "ts-is-present";
import { Comment } from "./comment";
import { SectionTitle } from "./section-title";

type OptionalTextEditorProps = {
  title: string;
  value?: string;
  setValue: (value?: string) => void;
  showOnlyToRemove?: boolean;
  initialText?: string;
  comment?: string;
  noTopMargin?: boolean;
  smallHeader?: boolean;
};

export function OptionalTextEditor({
  title,
  value,
  setValue,
  showOnlyToRemove: disableAdd,
  initialText,
  comment,
  noTopMargin,
  smallHeader,
}: OptionalTextEditorProps) {
  /** @todo Move this focus stuff to an abstraction */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const inputRef = React.useRef<any>(null);
  const [shouldFocusOnInput, setShouldFocusOnInput] = React.useState(false);

  React.useEffect(() => {
    if (shouldFocusOnInput) {
      inputRef.current.focus({
        cursor: "end",
      });
    }

    setShouldFocusOnInput(false);
  }, [shouldFocusOnInput, setShouldFocusOnInput]);

  if (!isDefined(value) && disableAdd) {
    /**
     * Master texts are only used on branch nodes when the children are used
     * with logic AND. We use this option to leave out the "add" button and the
     * corresponding title from the UI when it doesn't apply.
     */
    return null;
  }

  return (
    <>
      <SectionTitle noTopMargin={noTopMargin} small={smallHeader}>
        {title}
      </SectionTitle>
      {comment && <Comment>{comment}</Comment>}

      {isDefined(value) ? (
        <>
          <Input.TextArea
            ref={inputRef}
            autoSize
            value={value}
            style={{ marginBottom: "0.5em" }}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            danger
            icon={<CloseOutlined />}
            ghost
            size="small"
            onClick={() => setValue(undefined)}
          >
            Remove
          </Button>
        </>
      ) : (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          ghost
          size="small"
          onClick={() => {
            setValue(initialText || "");
            setShouldFocusOnInput(true);
          }}
        >
          Add
        </Button>
      )}
    </>
  );
}
