import React from "react";
import styled from "styled-components";

type AspectRatioProps = {
  /** Ratio is expressed as width / height */
  ratio: number;
  children: React.ReactNode;
};

export function AspectRatio({ ratio, children }: AspectRatioProps) {
  return (
    <StyledAspectRatio ratio={ratio}>
      <Inner>{children}</Inner>
    </StyledAspectRatio>
  );
}

const StyledAspectRatio = styled.div<{ ratio: number }>`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${(props) => (1 / props.ratio) * 100}%;
`;
const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
