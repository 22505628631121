import React from "react";
import styled from "styled-components";
import { Sidebar } from "~/components";
import { colors, mediaQueries, space } from "~/theme";

export type PartProgressProps = {
  percentage: number;
};

export function PartProgress({ percentage }: PartProgressProps) {
  return (
    <Container>
      <PartProgressHeader>
        <Sidebar.Metric name="Topic progress">{percentage}%</Sidebar.Metric>
      </PartProgressHeader>
      <StyledProgressBar percentage={percentage} />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: ${space.md};
`;

const PartProgressHeader = styled.div`
  @media ${mediaQueries.sm} {
    display: flex;
    justify-content: space-between;

    ${Sidebar.Metric.MetricHeading} {
      margin-right: ${space.md};
    }
    margin-bottom: ${space.sm};
  }

  @media ${mediaQueries.md} {
    display: block;
    justify-content: initial;
    flex-wrap: initial;

    ${Sidebar.Metric.MetricHeading} {
      margin-right: initial;
    }
  }
`;

const StyledProgressBar = styled.div<{ percentage: number }>`
  background: rgba(255, 255, 255, 0.08);
  border-radius: 3px;
  height: 6px;

  &::after {
    content: "";
    display: block;
    width: ${(x) => x.percentage}%;
    height: 100%;
    border-radius: 3px;
    background: ${colors.indigo};
  }
`;
