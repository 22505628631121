import React from "react";
import { getFileArray } from "../file";

export function useOnFileDrop(
  callback: (files: File[]) => void,
  accept: (file: File) => boolean
): boolean {
  const [isDragging, setisDragging] = React.useState(false);

  useOnWindowDrop({
    accept,
    onDrag: () => setisDragging(true),
    onDragEnd: () => setisDragging(false),
    onDrop: callback,
  });

  return isDragging;
}

type UseOnWindowDropProps = {
  accept: (file: File) => boolean;
  onDrag: () => void;
  onDragEnd: () => void;
  onDrop: (files: File[]) => void;
};

function useOnWindowDrop(callbacks: UseOnWindowDropProps) {
  const [isDraggingInternal, setisDraggingInternal] = React.useState(false);
  const callbacksRef = React.useRef<UseOnWindowDropProps>(callbacks);

  React.useEffect(() => {
    callbacksRef.current = callbacks;
  }, [callbacks]);

  React.useEffect(() => {
    document.body.addEventListener("dragstart", handleDragStart, false);
    document.body.addEventListener("dragend", handleDragEnd, false);

    return () => {
      document.body.removeEventListener("dragstart", handleDragStart, false);
      document.body.removeEventListener("dragend", handleDragEnd, false);
    };

    function handleDragStart() {
      setisDraggingInternal(true);
    }

    function handleDragEnd() {
      setisDraggingInternal(false);
    }
  }, []);

  React.useEffect(() => {
    if (isDraggingInternal) return;

    let isDraggingExternal = false;
    let timeoutId = 0;

    document.body.addEventListener("dragenter", handleDragEnter, false);
    document.body.addEventListener("dragleave", handleDragLeave, false);
    document.body.addEventListener("dragover", handleDragOver, false);
    document.body.addEventListener("drop", handleDrop, false);

    return () => {
      if (isDraggingInternal) return;

      clearDragEndTimeout();
      document.body.removeEventListener("dragenter", handleDragEnter, false);
      document.body.removeEventListener("dragleave", handleDragLeave, false);
      document.body.removeEventListener("dragover", handleDragOver, false);
      document.body.removeEventListener("drop", handleDrop, false);
    };

    function handleDragEnter(e: DragEvent) {
      e.preventDefault();
      callOnDrag();
    }

    function handleDragOver(e: DragEvent) {
      e.preventDefault();
      clearDragEndTimeout();
    }

    function handleDragLeave(e: DragEvent) {
      e.preventDefault();
      callOnDragEndWithDelay();
    }

    function handleDrop(e: DragEvent) {
      e.preventDefault();
      const files = getFileArray(e.dataTransfer?.items).filter((file) =>
        callbacksRef.current.accept(file)
      );

      callOnDrop(files);
    }

    function callOnDrag() {
      clearDragEndTimeout();
      if (!isDraggingExternal) {
        isDraggingExternal = true;
        callbacksRef.current.onDrag();
      }
    }

    function callOnDragEndWithDelay() {
      clearDragEndTimeout();

      timeoutId = window.setTimeout(() => {
        if (isDraggingExternal) {
          isDraggingExternal = false;
          callbacksRef.current.onDragEnd();
        }
      }, 100);
    }

    function callOnDrop(files: File[]) {
      clearDragEndTimeout();
      callbacksRef.current.onDragEnd();
      if (files.length) {
        callbacksRef.current.onDrop(files);
      }
    }

    function clearDragEndTimeout() {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = 0;
      }
    }
  }, [isDraggingInternal]);

  return isDraggingInternal;
}
