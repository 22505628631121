import type { JudgementValue, TranslationValue } from "@gemini/common";
import {
  AH_VALUE,
  AL_VALUE,
  NA_VALUE,
  NU_VALUE,
  VH_VALUE,
  VL_VALUE,
} from "@gemini/common";

export const translationValueTagLabels: Record<TranslationValue, string> = {
  vh: "vh",
  vl: "vl",
  neutral: "nu",
  al: "al",
  ah: "ah",
  ir: "ir",
  na: "na",
} as const;

// prettier-ignore
export const judgementDescriptions: Record<JudgementValue, string> = {
  [AH_VALUE]: "Perfect implementation of the guideline.",
  [AL_VALUE]: "Guideline implemented mostly correctly but with minor flaws.",
  [NU_VALUE]: "The implementation is neither good nor bad, or violates in one place but adheres in another.",
  [VL_VALUE]: "Poor implementation of the guideline but with some extenuating circumstances.",
  [VH_VALUE]: "Direct violation of the guideline.",
  [NA_VALUE]: "The guideline is not applicable to this website and should therefore be excluded from the UX performance scorecard.",
} as const;
