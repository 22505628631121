import { causeOfChangeLabels, getImpactScore } from "@gemini/common";
import { isDefined } from "ts-is-present";
import { Button } from "~/components";
import { IconLabel } from "~/components/icon-label";
import { downloadCsv, type CsvColumn } from "~/modules/csv";
import { space } from "~/theme";
import type { GuidelineHit } from "../logic";
import { getJudgementLabel, getPlatformLabel } from "../logic";

export function DownloadGuidelinesCsvButton({
  hits,
  filename,
  hideFirstBreadcrumb,
  hasObservationColumn,
  hasSuggestionsColumn,
}: {
  hits: GuidelineHit[];
  filename: string | (() => string);
  hideFirstBreadcrumb: boolean;
  hasObservationColumn?: boolean;
  hasSuggestionsColumn?: boolean;
}) {
  const columns: (CsvColumn<GuidelineHit> | undefined)[] = [
    { label: "Code", value: (x) => x.state.citation_code },
    { label: "Title", value: (x) => x.state.title },
    {
      label: "Platform",
      value: (x) => getPlatformLabel(x.state.platform),
    },
    {
      label: "Is Low Cost",
      value: (x) => (x.isLowCost ? "Yes" : "No"),
    },
    {
      label: "Is Missed Opportunity",
      value: (x) => (x.isMissedOpportunity ? "Yes" : "No"),
    },

    hasSuggestionsColumn
      ? {
          label: "Is Suggestion",
          value: (x) => (x.isSuggestion ? "Yes" : "No"),
        }
      : undefined,

    hasObservationColumn
      ? {
          label: "Observation",
          value: (x) => x.causeOfChange && causeOfChangeLabels[x.causeOfChange],
        }
      : undefined,

    {
      label: "Judgement",
      value: (x) => getJudgementLabel(x.judgement),
    },

    { label: "Impact", value: (x) => x.impact },
    {
      label: "Impact Score",
      value: (x) => getImpactScore(x.state, x.assessment?.data),
    },
    { label: "Notes", value: (x) => x.assessment?.data.comment },
    {
      label: "Topic",
      value: (x) =>
        (hideFirstBreadcrumb ? x.breadcrumbParts.slice(1) : x.breadcrumbParts)
          .map((x) => x.title)
          .join(" > "),
    },
    {
      label: "Review Tool Link",
      value: (x) => x.link,
    },
    {
      label: "Selected scenarios",
      value: (x) => x.assessment?.data.selected_scenario_titles.join(" · "),
    },
  ];

  return (
    <Button
      size="small"
      variant="tertiary"
      onClick={() =>
        downloadCsv(
          hits,
          typeof filename === "string" ? filename : filename(),
          columns.filter(isDefined)
        )
      }
    >
      <IconLabel icon="download" label="download as .CSV" gap={space.xs} />
    </Button>
  );
}
