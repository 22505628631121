/**
 * In order to be able to query structure documents by version, we need to store
 * it as a string that can be compared using regular string comparison
 * operators. In order to achieve this we can pad the version string with zeros,
 * assuming we will never exceed 9999 major/minor/patch versions.
 *
 * The patch part is only stored and returned if it is greater than 0.
 */

import { assert } from "~/utils/assert";

export function formatComparableVersionString(semverString: string): string {
  assert(
    isSemverString(semverString),
    `Invalid semver string: ${semverString}`
  );

  const [version, remainder = ""] = semverString.split("-");
  const [majorString, minorString, patchString] = version.split(".");

  const major = parseInt(majorString, 10);
  const minor = parseInt(minorString ?? "0", 10);
  const patch = parseInt(patchString ?? "0", 10);

  assert(major < 10000, `Major version must be less than 10000, got ${major}`);
  assert(minor < 10000, `Minor version must be less than 10000, got ${minor}`);

  const majorPadded = String(major).padStart(4, "0");
  const minorPadded = String(minor).padStart(4, "0");
  const patchPadded = String(patch).padStart(4, "0");
  const remainderSuffix = remainder ? `_${remainder}` : "";

  return patch > 0
    ? `${majorPadded}_${minorPadded}_${patchPadded}${remainderSuffix}`
    : minor > 0
      ? `${majorPadded}_${minorPadded}`
      : `${majorPadded}`;
}

export function parseComparableVersionString(
  comparableVersionString: string
): string {
  const [majorString, minorString, patchString, remainder] =
    comparableVersionString.split("_");
  const major = parseInt(majorString, 10);
  const minor = parseInt(minorString ?? "0", 10);
  const patch = parseInt(patchString ?? "0", 10);

  return patch > 0
    ? remainder
      ? `${major}.${minor}.${patch}-${remainder}`
      : `${major}.${minor}.${patch}`
    : minor > 0
      ? `${major}.${minor}`
      : `${major}`;
}

export function isSemverString(value: string): boolean {
  return /^(\d+\.)?(\d+\.)?(\*|\d+)(-.+)?$/.test(value);
}

export function isComparableVersionString(value: string): boolean {
  return /^(\d{4})_(\d{4})(?:_(\d{4})(?:_(\d+\.\d+\.\d+))?)?$/.test(value);
}
