import type { ReviewStructureGroup, ReviewStructurePart } from "@gemini/common";
import { useRouter } from "next/router";
import React from "react";
import { useReviewStore } from "~/features/reviews/store";
import { isReviewStructurePart } from "~/utils/identity";
import { MasonryWrapper, RootGroup, RootPart } from "./components";

export function GroupsAndParts() {
  const partId = useRouter().query.partId as string | undefined;
  const {
    groupsFromStructure,
    partsFromStructure,
    getChildrenFromStructureGroup,
  } = useReviewStore();
  const groupChildren = getChildrenFromStructureGroup("__root");

  const isMultiColumnGroup =
    groupChildren.length === 1 &&
    !isReviewStructurePart(groupChildren[0]) &&
    hasGroupWithManyParts(
      groupChildren[0],
      groupsFromStructure,
      partsFromStructure
    );

  return (
    <div>
      {isMultiColumnGroup ? (
        <RootGroup multiColumn={isMultiColumnGroup} group={groupChildren[0]} />
      ) : (
        <MasonryWrapper
          columnGutter={24}
          items={groupChildren}
          render={({ data }) => (
            <div key={data.id}>
              {isReviewStructurePart(data) ? (
                <RootPart part={data} active={data.id === partId} />
              ) : (
                <RootGroup group={data} />
              )}
            </div>
          )}
        />
      )}
    </div>
  );
}

function hasGroupWithManyParts(
  group: ReviewStructureGroup,
  groups: ReviewStructureGroup[],
  parts: ReviewStructurePart[]
) {
  const threshold = 10;

  const groupsWithManyParts = groups
    .filter((x) => x.parent_group_id === group.id)
    .map((x) => countPartsInGroup(x, groups, parts))
    .filter((count) => count >= threshold);

  return groupsWithManyParts.length > 0;
}

function countPartsInGroup(
  group: ReviewStructureGroup,
  groups: ReviewStructureGroup[],
  parts: ReviewStructurePart[]
) {
  const partCount = parts.filter(
    (part) => part.parent_group_id === group.id
  ).length;

  const subPartCount: number = groups
    .filter((part) => part.parent_group_id === group.id)
    .map((group) => countPartsInGroup(group, groups, parts))
    .reduce((acc, x) => acc + x, 0);

  return partCount + subPartCount;
}
