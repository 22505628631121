import { PlusOutlined } from "@ant-design/icons";
import type { TagId } from "@gemini/common";
import { tags } from "@gemini/common";
import type { InputRef } from "antd";
import { Input, Popover, Tag, Tooltip } from "antd";
import React from "react";
import { notify } from "~/modules/notifications";

const allowedTagIds = Object.keys(tags);
/** Adapted from https://ant.design/components/tag */
type EditableTagGroupProps = {
  tagIds?: TagId[];
  onChange: (tagIds?: TagId[]) => void;
};

export class EditableTagGroup extends React.Component<EditableTagGroupProps> {
  state = {
    inputVisible: false,
    inputValue: "",
  };

  input: InputRef | null = null;

  handleClose = (removedTag: string) => {
    const tagIds = this.props.tagIds?.filter((tag) => tag !== removedTag);
    this.props.onChange(tagIds?.length ? tagIds : undefined);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input?.focus());
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { tagIds } = this.props;
    const { inputValue } = this.state;

    const newTagId = inputValue
      .toLowerCase()
      .trim()
      .replace(/\s/g, "_")
      .replace(/-/g, "_") as TagId;

    /**
     * Only allow the user to enter tags that are part of the list of predefined
     * tags. This should probably be done through a dropdown or something for
     * better UX.
     */
    if (!allowedTagIds.includes(newTagId)) {
      notify.warning(
        `Tag ${newTagId} is not allowed. For a full list see pop-over`
      );
      return;
    }

    if (inputValue && tagIds?.indexOf(inputValue as TagId) === -1) {
      this.props.onChange([...tagIds, newTagId]);
    }
    this.setState({
      inputVisible: false,
      inputValue: "",
    });
  };

  render() {
    const { tagIds } = this.props;
    const { inputVisible, inputValue } = this.state;

    const popoverContent = (
      <div>
        <p>Valid tags are:</p>
        <ul
          css={`
            font-style: italic;
            list-style: none;
            padding: 0 1em;
          `}
        >
          {allowedTagIds.map((id) => (
            <li key={id}>{id}</li>
          ))}
        </ul>
      </div>
    );

    return (
      <div
        css={`
          margin-bottom: 0.5em;
        `}
      >
        {tagIds?.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={`${tag}-${index}`}
              closable
              onClose={() => this.handleClose(tag)}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Popover content={popoverContent}>
            <Input
              ref={(input) => void (this.input = input)}
              type="text"
              size="small"
              style={{ width: 78 }}
              value={inputValue}
              onChange={this.handleInputChange}
              onBlur={this.handleInputConfirm}
              onPressEnter={this.handleInputConfirm}
            />
          </Popover>
        )}
        {!inputVisible && (
          <Popover content={popoverContent}>
            <Tag
              onClick={this.showInput}
              style={{ background: "#fff", borderStyle: "dashed" }}
            >
              <PlusOutlined /> Add Tag
            </Tag>
          </Popover>
        )}
      </div>
    );
  }
}
