import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useRef } from "react";
import type { IconName } from "~/components";
import {
  Icon,
  PageContainer,
  PageLoader,
  Paragraph,
  sendEvent,
  SpacedChildren,
  TabBar,
  TabButton,
  TabContent,
  TabLayout,
  Title,
} from "~/components";
import { PerformanceChartWithCompare } from "~/components/performance-chart-with-compare";
import { useReviewStore } from "~/features/reviews";
import { useStructureAmbrosiaFormat } from "~/modules/structure";
import { maybeScrollIntoView } from "~/modules/window";
import { useAppStore } from "~/store";
import { colors, fontSizes, radii, space } from "~/theme";
import {
  GroupsAndParts,
  GroupsAndPartsHeader,
  ReviewHeader,
} from "./components";
import { FilterGuidelines } from "./filter-guidelines";

export const ReviewDetailsPage: NextPage = observer(() => {
  const router = useRouter();
  const { currentGuidelines } = useAppStore();
  const { review, isLoading, structure } = useReviewStore();
  const viewStructure = useStructureAmbrosiaFormat(
    structure.document,
    currentGuidelines
  );
  const tabsRef = useRef<HTMLDivElement>(null);

  if (isLoading || !viewStructure) {
    return <PageLoader />;
  }

  const reviewId = router.query.reviewId as string;

  if (!review.data) {
    return (
      <PageContainer testingName="review-details-page-no-data">
        No data for review {reviewId}
      </PageContainer>
    );
  }

  /**
   * It's safe to read the window object here because we don't server-side
   * render the review app.
   */
  const currentUrl = window.location.origin + router.asPath;

  const assessmentListViewUrl = new URL(currentUrl);
  assessmentListViewUrl.searchParams.delete("view");

  const assessmentFilterViewUrl = new URL(currentUrl);
  assessmentFilterViewUrl.searchParams.set("view", "filter");

  return (
    <PageContainer testingName="review-details-page" hero={<ReviewHeader />}>
      <SpacedChildren size="lg">
        {review.data.type === "training" && (
          <div
            css={{
              background: colors.whiteLilac,
              padding: `${space.md} ${space.lg}`,
              borderRadius: radii.md,
            }}
          >
            <p>
              <strong>This is a UX Training-based scorecard.</strong> All users
              who use{" "}
              <a href="https://baymard.com/premium/training">
                Baymard's UX Training & Certification
              </a>{" "}
              program create a review scorecard as they complete their training.
              Below you'll find the scorecard for {review.data.title}. Use the
              scorecard for insights but please do not edit it without their
              permission.
            </p>
          </div>
        )}
        <SpacedChildren size="md">
          <Title level={3} as="h2">
            Assessment Scorecard
          </Title>
          {review.data.is_legacy && <LegacyMessage />}
          <PerformanceChartWithCompare
            review={review.document}
            viewStructure={viewStructure}
            hideAssumedRange={
              review.data.chart_options?.is_assumed_range_hidden
            }
          />
        </SpacedChildren>

        <GroupsAndPartsHeader />

        <div
          css={{
            background: colors.whiteLilac,
            padding: `${space.md} ${space.lg}`,
            borderRadius: radii.md,
          }}
        >
          <p>
            Start extracting sets of guidelines to use as a guide to help you
            improve your website. Check out the filterable guidelines table{" "}
            <Link
              href={assessmentFilterViewUrl}
              replace
              scroll={false}
              onClick={() => maybeScrollIntoView(tabsRef.current)}
            >
              here
            </Link>
            .
          </p>
        </div>

        <TabLayout ref={tabsRef}>
          <TabBar>
            <TabButton
              href={assessmentListViewUrl}
              scroll={false}
              $isActive={router.query.view !== "filter"}
              onClick={() =>
                sendEvent(
                  "Review Detail Page",
                  "Click Guidelines Tab",
                  "Assessment List"
                )
              }
            >
              <WithIcon icon="list">Assessment List</WithIcon>
            </TabButton>

            <TabButton
              href={assessmentFilterViewUrl}
              scroll={false}
              $isActive={router.query.view === "filter"}
              onClick={() =>
                sendEvent(
                  "Review Detail Page",
                  "Click Guidelines Tab",
                  "Filter Guidelines"
                )
              }
            >
              <WithIcon icon="filter">
                <span>Filter Guidelines</span>
              </WithIcon>
            </TabButton>
          </TabBar>

          <TabContent>
            {router.query.view === "filter" ? (
              <FilterGuidelines
                baseQueryParameters={{ view: router.query.view as string }}
              />
            ) : (
              <GroupsAndParts />
            )}
          </TabContent>
        </TabLayout>
      </SpacedChildren>
    </PageContainer>
  );
});

function WithIcon({
  icon,
  children,
}: {
  icon: IconName;
  children: React.ReactNode;
}) {
  return (
    <span
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: space.md,
      }}
    >
      <Icon name={icon} size={21} />
      <span>{children}</span>
    </span>
  );
}

function LegacyMessage() {
  return (
    <div css={{ fontSize: fontSizes.sm }}>
      <Paragraph noMargin>
        This review was created in Baymard's old Review Tool but is now migrated
        into Baymard's new Review Tool (on April 23, 2020). The scorecard below
        is unchanged and works as before. All ratings are kept as they were set
        before the migration.
      </Paragraph>
    </div>
  );
}
