import { getChildScenarios, getRuling } from "@gemini/common";
import React from "react";
import { useBranchContext } from "../context";

export function useChildScenarios(parentId: string) {
  const { tagIds, scenarios, industryId } = useBranchContext();

  return React.useMemo(() => {
    return getChildScenarios(scenarios, parentId).filter(
      // only render non-excluded scenarios
      (scenario) => !getRuling(scenario, industryId, tagIds).exclude
    );
  }, [scenarios, parentId, industryId, tagIds]);
}
