import type { MasonryProps } from "masonic";
import { Masonry } from "masonic";
import React from "react";
import styled from "styled-components";
import { useBreakpoints } from "~/modules/hooks";

export function MasonryWrapper<T>(props: MasonryProps<T>) {
  const breakpoints = useBreakpoints();

  const forwardedProps: MasonryProps<T> = {
    ...props,
    columnCount: breakpoints.md ? 3 : breakpoints.sm ? 2 : 1,
    /**
     * Masonic has a feature called virtual rendering, which means that items
     * that are outside of the viewport won't be rendered. With the `Infinity`-
     * value we disable this feature because we want to enable the native cmd+f
     * search feature.
     */
    overscanBy: Infinity,
  };

  return <StyledMasonry {...forwardedProps} />;
}

const StyledMasonry = styled(Masonry)`
  &:focus {
    outline: none;
  }
`;
