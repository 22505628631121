import { judgementLabels, type JudgementValue } from "@gemini/common";
import { transparentize } from "polished";
import styled, { css } from "styled-components";
import { Icon } from "~/components";
import { colors, durations, preset, space, timingFunctions } from "~/theme";

export type JudgementDisplayProps = {
  judgement: JudgementValue;
  isIssueResolved: boolean;
  extraLabel?: string;
  isNudgeable?: boolean;
  onNudgeNegative?: () => void;
  onNudgePositive?: () => void;
  short?: boolean;
};

export function JudgementDisplay({
  judgement,
  isIssueResolved,
  extraLabel,
  isNudgeable,
  onNudgeNegative,
  onNudgePositive,
  short,
}: JudgementDisplayProps) {
  return (
    <StyledJudgementDisplay
      judgement={judgement}
      short={short}
      isIssueResolved={isIssueResolved}
    >
      <div
        data-t="judgement-display"
        css={css`
          position: relative;
          width: 100%;
        `}
      >
        {isIssueResolved ? "Issue Resolved" : judgementLabels[judgement]}
        <small>{extraLabel}</small>
      </div>

      {isNudgeable && (
        <NudgeButtons>
          <NudgeButton
            disabled={!onNudgeNegative}
            onClick={onNudgeNegative}
            data-t="nudge-negative-button"
          >
            <Icon name="circleMinus" />
          </NudgeButton>
          <NudgeButton
            disabled={!onNudgePositive}
            onClick={onNudgePositive}
            data-t="nudge-positive-button"
          >
            <Icon name="circlePlus" />
          </NudgeButton>
        </NudgeButtons>
      )}
    </StyledJudgementDisplay>
  );
}

const StyledJudgementDisplay = styled.div<{
  judgement: JudgementValue;
  short?: boolean;
  isIssueResolved: boolean;
}>`
  height: ${(x) => (x.short ? 100 : 140)}px;
  position: relative;
  color: ${(x) =>
    x.isIssueResolved
      ? colors.translationValue["ir"]
      : colors.judgement[x.judgement]};
  background-color: ${(x) =>
    transparentize(
      0.9,
      x.isIssueResolved
        ? colors.translationValue["ir"]
        : colors.judgement[x.judgement]
    )};
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: ${(x) => (x.short ? "uppercase" : undefined)};
  font-size: ${(x) => (x.short ? 20 : 28)}px;
  letter-spacing: 0;
  text-align: center;

  transition-property: background-color, color;
  transition-duration: ${durations.lg};
  transition-timing-function: ${timingFunctions.out};

  & small {
    display: block;
    ${preset.typography.body2};
  }
`;

const NudgeButtons = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${space.lg};
`;

const NudgeButton = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  color: black;

  &[disabled] {
    cursor: default;
    opacity: 0.25;
  }
`;
