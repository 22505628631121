import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import NextError from "next/error";
import { useRouter } from "next/router";
import React from "react";
import {
  BackLink,
  PageContainer,
  PageLoader,
  Paragraph,
  SpacedChildren,
  Title,
} from "~/components";
import { useReviewStore } from "../store";
import { AssessTypeUpdates, ConfirmTypeUpdates } from "./components";
import { useUpdateStore } from "./store";

export const ReviewUpdatePage: NextPage = observer(() => {
  const { review } = useReviewStore();
  const router = useRouter();
  const store = useUpdateStore();
  const reviewId = router.query.reviewId as string;

  if (review.isLoading || store.isLoading) {
    return <PageLoader />;
  }

  if (review.data?.is_read_only) {
    return <NextError statusCode={404} />;
  }

  // @TODO protect update endpoints with is_read_only assertion

  return (
    <PageContainer testingName="list-page">
      <SpacedChildren size="lg">
        <SpacedChildren>
          <BackLink href={`/reviews/${reviewId}`}>Back</BackLink>
          <Title level={2} as="h1">
            Guideline Changes
          </Title>
          <Paragraph>
            In order to keep our research library as current and up to date as
            possible we often need to make enhancements to our guidelines. Most
            of the updates are automatic and won’t affect the reviews on your
            company account, but sometimes they can cause the ratings and
            assessments to change. Below is a list of upgrades available for the
            assessments made in this review. Please review at a convenient time.
          </Paragraph>
          <Paragraph bold>
            If you didn’t make the assessments yourself, please be careful
            applying any of the updates below.
          </Paragraph>
        </SpacedChildren>

        <ConfirmTypeUpdates />
        <AssessTypeUpdates />
      </SpacedChildren>
    </PageContainer>
  );
});
