import React from "react";
import { Field, TextArea } from "~/components";
import { useAuth } from "~/modules/auth";

type CommentFieldProps = {
  value: string;
  disabled: boolean;
  onChange: (comments: string) => void;
};

export function CommentField({ value, disabled, onChange }: CommentFieldProps) {
  const { claims } = useAuth();

  return (
    <Field
      label={
        claims.isAdmin
          ? "Customer-facing notes (customers can see this)"
          : "Notes for you and your team"
      }
      description={
        <React.Fragment>
          Useful for providing additional context, sharing concerns and ideas,
          etc.
        </React.Fragment>
      }
    >
      <TextArea
        value={value}
        onChange={onChange}
        placeholder="e.g. This review includes our new checkout prototype"
        disabled={disabled}
      />
    </Field>
  );
}
