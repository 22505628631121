import type {
  DerivedGuidelinesByReference,
  ReviewStructure,
  ReviewStructureFmtAdmin,
  ReviewStructureGroupFmtAdmin,
  ReviewStructurePartFmtAdmin,
} from "~/schemas";
import { isReviewStructurePart } from "~/structures";
import { cleanObject, parseComparableVersionString } from "..";

export function convertStructureToAdminFormat(
  structureData: ReviewStructure,
  currentGuidelines: DerivedGuidelinesByReference
): ReviewStructureFmtAdmin {
  const {
    reference,
    version,
    title,
    description,
    grouping_tags,
    is_archived,
    is_publicly_available,
    sections,
  } = structureData;

  /**
   * Use cleanObject here to remove undefined fields like description, because
   * otherwise the isDirty comparison will fail in the editor, as the parsed
   * YAML will not include undefined fields.
   */
  return cleanObject({
    reference,
    version: parseComparableVersionString(version),
    grouping_tags,
    title,
    description,
    is_publicly_available,
    is_archived: is_archived ?? false,
    sections: convertSectionsToAdminFormat(sections, currentGuidelines),
  });
}

export function convertSectionsToAdminFormat(
  sections: ReviewStructure["sections"],
  currentGuidelines: DerivedGuidelinesByReference,
  parentGroupId = "__root"
): (ReviewStructureGroupFmtAdmin | ReviewStructurePartFmtAdmin)[] {
  const result = Object.values(sections)
    .filter((x) => x.parent_group_id === parentGroupId)
    .sort((a, b) => a.position - b.position)
    .map((x) => {
      /** Somehow the use temporary variables was necessary to get typing to work */
      if (isReviewStructurePart(x)) {
        /**
         * Use cleanObject here to remove undefined fields like semantic_level,
         * because otherwise the isDirty comparison will fail in the editor
         */
        const part: ReviewStructurePartFmtAdmin = cleanObject({
          title: x.title,
          guideline_citation_codes: x.guideline_references.map(
            (x) =>
              currentGuidelines[x]?.citation_code ||
              `__unresolved_reference_${x}`
          ),
          semantic_level: x.semantic_level,
          sentence_reference_key: x.sentence_reference_key,
        });
        return part;
      } else {
        /**
         * Use cleanObject here to remove undefined fields like semantic_level,
         * because otherwise the isDirty comparison will fail in the editor
         */
        const group: ReviewStructureGroupFmtAdmin = cleanObject({
          title: x.title,
          sections: convertSectionsToAdminFormat(
            sections,
            currentGuidelines,
            x.id
          ),
          semantic_level: x.semantic_level,
          sentence_reference_key: x.sentence_reference_key,
        });
        return group;
      }
    });

  return result;
}
