import { ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "~/modules/firebase";
import { notify } from "~/modules/notifications";
import { confirmUnload } from "./window";

type UploadError = {
  code: "ERROR_FILE_TOO_BIG" | "ERROR_SERVER" | "ERROR_UNKNOWN";
  message: string;
};

type OnFinishFileCallback = (file: File, uploadError?: UploadError) => void;

const NUM_BYTES_IN_MB = 1000 * 1024;

export async function uploadFiles(
  files: File[],
  uploadDirectory: string,
  maxFileSizeMb: number,
  onFinishFile: OnFinishFileCallback
) {
  const cancelConfirmUnload = confirmUnload();

  await Promise.all(
    files.map((x) => {
      if (x.size / NUM_BYTES_IN_MB > maxFileSizeMb) {
        const error: UploadError = {
          code: "ERROR_FILE_TOO_BIG",
          message: `File size is too large (max ${maxFileSizeMb}MB)`,
        };

        notify.error(error.message);

        return onFinishFile(x, error);
      }

      return uploadFile(x, uploadDirectory, onFinishFile);
    })
  )
    .catch((err) => notify.error(err))
    .finally(cancelConfirmUnload);
}

async function uploadFile(
  file: File,
  uploadDirectory: string,
  onFinish: OnFinishFileCallback
) {
  const filePath = `${uploadDirectory}/${file.name}`;
  const storageRef = ref(storage, filePath);
  const metadata = {
    contentDisposition: `attachment; filename=${file.name};`,
  };

  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  let showProgress = true;

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progressPercentage = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );

      /**
       * Multiple notifications, including different types can overwrite each
       * other as long as the message field is not used. This might be a bug in
       * Ant.
       */
      if (showProgress) {
        notify.info({
          key: filePath,
          duration: 0,
          description: `${file.name}: ${progressPercentage}%`,
          onClose: () => (showProgress = false),
        });
      }
    },
    (error) => {
      notify.info({
        key: filePath,
        description: `${file.name}: failed`,
      });

      notify.error(error.message);

      onFinish(file, {
        code: "ERROR_SERVER",
        message: error.message,
      });
    },
    () => {
      notify.success({
        key: filePath,
        description: `${file.name}: completed`,
      });

      onFinish(file);
    }
  );

  /**
   * We also await the upload task promise-like object, so that Promise.all()
   * can facilitate the confirmUnload logic.
   */
  await uploadTask;
}
