import type { Guideline, JudgementValue } from "@gemini/common";
import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import {
  Button,
  FatLink,
  JudgementPill,
  SpacedChildren,
  Title,
} from "~/components";
import { ForwardLink } from "~/components/forward-link";
import { useReviewStore } from "~/features/reviews";
import { usePartStore } from "~/features/reviews/part";
import { useAuth, useUserId } from "~/modules/auth";
import type { SubmitAction } from "~/modules/hooks";
import { useSubmitAction } from "~/modules/hooks";
import { notify } from "~/modules/notifications";
import { useAssessmentStore } from "../store";
import { Footer } from "./footer";

export const AssessFooter = observer(() => {
  const router = useRouter();
  const { claims } = useAuth();
  const userId = useUserId();
  const { review } = useReviewStore();
  const { navigation, guideline } = usePartStore();
  const assessmentStore = useAssessmentStore();

  const { isSubmitDisabled, judgement, isManualJudgement, isIssueResolved } =
    assessmentStore;

  const reference = router.query.reference as string;
  const reviewId = router.query.reviewId as string;
  const partId = router.query.partId as string;

  assert(guideline?.data, "Cannot render assess footer without guideline");

  const manualJudgementAction: SubmitAction = {
    label: "Continue to manual rating",
    onSubmit: () => {
      if (isSubmitDisabled) return;
      /**
       * Where in the update flow we can't submit when moving to manual
       * judgement for technical reasons, we probably shouldn't submit in the
       * normal flow either.
       */
      void router.push(
        `/reviews/[reviewId]/parts/[partId]/assessments/[reference]/[page]`,
        `/reviews/${reviewId}/parts/${partId}/assessments/${reference}/manual-judgement`
      );
    },
  };

  const nextGuidelineAction: SubmitAction = {
    label: "Continue to next guideline",
    onSubmit: () => {
      if (isSubmitDisabled) return;

      assessmentStore
        .submitAssessment(userId)
        .then(
          (x) =>
            x.success &&
            notifySubmit(guideline.data, judgement, isIssueResolved)
        )
        .catch((err) => notify.error(err));

      void router.push(
        `/reviews/[reviewId]/parts/[partId]/assessments/[reference]/[page]`,
        navigation.nextLink
      );
    },
  };

  const summaryAction: SubmitAction = {
    label: "Continue to summary",
    onSubmit: () => {
      if (isSubmitDisabled) return;

      assessmentStore
        .submitAssessment(userId)
        .catch((err) => notify.error(err));

      void router.push(
        `/reviews/[reviewId]/parts/[partId]/assessments/[reference]/[page]`,
        `/reviews/${reviewId}/parts/${partId}/assessments/${reference}/summary`
      );
    },
  };

  const nextPartAction: SubmitAction = {
    label: "Continue to next topic",
    onSubmit: () => {
      if (isSubmitDisabled) return;

      assessmentStore
        .submitAssessment(userId)
        .then(
          (x) =>
            x.success &&
            notifySubmit(guideline.data, judgement, isIssueResolved)
        )
        .catch((err) => notify.error(err));

      void router.push(
        `/reviews/[reviewId]/parts/[partId]`,
        navigation.nextPartLink
      );
    },
  };

  const isCaseStudy = review.data?.type === "case-study";

  const { label, handleSubmit } = useSubmitAction(
    isCaseStudy
      ? {
          primary: summaryAction,
          secondary: nextGuidelineAction,
          tertiary: nextPartAction,
        }
      : claims.isAdmin
        ? {
            primary: nextGuidelineAction,
            secondary: summaryAction,
            tertiary: nextPartAction,
          }
        : isManualJudgement
          ? {
              primary: manualJudgementAction,
              secondary: undefined,
              tertiary: undefined,
            }
          : {
              primary: summaryAction,
              secondary: nextGuidelineAction,
              tertiary: nextPartAction,
            }
  );

  if (review.data?.is_read_only) {
    return (
      <Footer
        right={
          <SpacedChildren horizontal size="lg" css={{ padding: "11px 0" }}>
            <ForwardLink
              href={`/reviews/${reviewId}/parts/${partId}/assessments/${reference}/summary`}
            >
              View Summary
            </ForwardLink>
          </SpacedChildren>
        }
      />
    );
  }

  return (
    <Footer
      right={
        <SpacedChildren horizontal size="lg">
          <FatLink href={navigation.nextLink}>Skip assessment</FatLink>
          <Button
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
            testingName="submit-button"
          >
            {label}
          </Button>
        </SpacedChildren>
      }
    />
  );
});

function notifySubmit(
  guideline: Guideline,
  judgement: JudgementValue,
  isIssueResolved: boolean
) {
  notify.success({
    key: `save-assessment-${guideline.citation_code}`,
    message: "Your assessment has been saved",
    description: (
      <SpacedChildren size="sm">
        <div>
          {guideline.scenarios.__root.title}{" "}
          <strong>({guideline.citation_code})</strong>
        </div>
        <Title level={5} variant="overline">
          Judgement
        </Title>
        <JudgementPill value={judgement} isIssueResolved={isIssueResolved} />
      </SpacedChildren>
    ),
  });
}
