import type { CropRegionsConfig } from "./features/assessment/components";

/** If not set, we let the app proxy forward things to production */
const baymardBaseUrl = process.env.BAYMARD_BASE_URL ?? "";

export const firebaseConfig = {
  projectId: process.env.FIREBASE_PROJECT_ID,
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: `${process.env.FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.FIREBASE_PROJECT_ID}.firebaseio.com`,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  storageBucket: `gs://${process.env.FIREBASE_PROJECT_ID}.appspot.com`,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

/**
 * We could place every config variable in a secret but for non-sensitive
 * information this is a bit easier probably if we only ever have two
 * environments.
 */
export const isProduction =
  process.env.FIREBASE_PROJECT_ID === "baymard-gemini-prd";

export const phase = process.env.PHASE;

export const baymard = {
  baseUrl: baymardBaseUrl,
  guidelinesEndpoint: `${baymardBaseUrl}/gemini-api/guideline-by-mysql-id`,
  authTokenEndpoint: `${baymardBaseUrl}/firebase/create_custom_token`,
} as const;

export const algolia = {
  apiKey: process.env.ALGOLIA_API_KEY ?? "__missing_ALGOLIA_API_KEY",
  appId: process.env.ALGOLIA_APP_ID ?? "__missing_ALGOLIA_APP_ID",
  indexName: {
    guidelines: "guidelines",
  },
};

export const cropRegions: CropRegionsConfig[] = [
  {
    /** IPhone XS with GUI offsets belonging to iOS 14.2. */
    width: 1125,
    height: 2436,

    /**
     * Offsets as defined in:
     * https://docs.google.com/document/d/1_l0ONARgZOKctFu1GzaWIsFgf1wbyuuhbLD9m3gsLMc/edit#heading=h.ufyeyd6h1f6b
     */
    topOffsets: [0, 132],
    bottomOffsets: [0, 63, 155, 199, 288],

    defaultTopOffset: 132,
    defaultBottomOffset: 63,
  },
];
