import React from "react";
import styled, { css } from "styled-components";

export type PartProps = {
  area: string;
  children: React.ReactNode;
  centerY?: boolean;
};

export function Part({ area, centerY, children }: PartProps) {
  return (
    <StyledPart area={area} centerY={centerY}>
      {children}
    </StyledPart>
  );
}

const StyledPart = styled.div<PartProps>`
  grid-area: ${(x) => x.area};
  ${(x) =>
    x.centerY &&
    css`
      height: 100%;
      display: flex;
      align-items: center;
    `}
`;
