import {
  getJudgementFromTranslationValue,
  type CauseOfChange,
  type Platform,
  type TranslationValue,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import { useMemo, useRef } from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { JudgementPill, LoadingIndicator, SpacedChildren } from "~/components";
import { fontSizes, space } from "~/theme";
import { useReviewStore } from "../store";
import { CurrentRefinements } from "./components/current-refinements";
import { HighlightsDropDown } from "./components/highlights-dropdown";
import { Hits } from "./components/hits";
import { RefinementListDropDown } from "./components/refinement-list-dropdown";
import { TopicDropDown } from "./components/topic-dropdown";
import { itemsJsOptions } from "./config";
import {
  algoliaNextJsHistoryRouter,
  getCauseOfChangeLabel,
  getPlatformLabel,
  indexName,
  routeToState,
  sortCauseOfChange,
  sortImpacts,
  sortJudgements,
  sortPlatforms,
  stateToRoute,
  useGuidelineTableSearchClient,
  type QueryStringParameters,
} from "./logic";

export const FilterGuidelines = observer(function FilterGuidelines({
  baseQueryParameters,
}: {
  baseQueryParameters: Record<string, string>;
}) {
  const { guidelineSearchHits, isFetchingGuidelineStates } = useReviewStore();

  const searchClient = useGuidelineTableSearchClient(
    itemsJsOptions,
    guidelineSearchHits
  );

  const baseQueryParametersRef = useRef(baseQueryParameters);
  baseQueryParametersRef.current = baseQueryParameters;

  const routing = useMemo(
    () => ({
      router: algoliaNextJsHistoryRouter<QueryStringParameters>({
        createURL: ({ qsModule, routeState, location }) => {
          const { pathname, hash, search } = location;
          const queryString = qsModule.stringify({
            ...qsModule.parse(search.replace(/^\?/, "")),
            ...(baseQueryParametersRef.current || {}),
            ...routeState,
          });
          return `${pathname}?${queryString}${hash}`;
        },
      }),
      stateMapping: { stateToRoute, routeToState },
    }),
    []
  );

  if (isFetchingGuidelineStates || !searchClient) return <LoadingIndicator />;

  /** We'll only want to show the suggestions toggle if there are suggestions */
  const hasSuggestions = guidelineSearchHits.some((x) => x.isSuggestion);

  return (
    <InstantSearch
      // @ts-expect-error types compatible
      searchClient={searchClient}
      indexName={indexName}
      routing={routing}
    >
      <Configure hitsPerPage={9999} />
      <Filters enableSuggestionsFilter={hasSuggestions} />
      <Hits enableSuggestionsFilter={hasSuggestions} />
    </InstantSearch>
  );
});

const Filters = observer(function Filters({
  enableSuggestionsFilter,
}: {
  enableSuggestionsFilter: boolean;
}) {
  const { review } = useReviewStore();
  return (
    <SpacedChildren
      size="md"
      css={{
        background: "white",
        position: "sticky",
        top: 0,
        zIndex: 5,
        padding: `${space.md} 0`,
        marginTop: `calc(${space.md} * -1)`,
      }}
    >
      <SpacedChildren
        horizontal
        size="md"
        css={{
          fontSize: fontSizes.sm,
          display: "flex",
          "& > *": { flex: 1 },
        }}
      >
        <RefinementListDropDown
          label="Platform"
          attribute="platform"
          sortBy={sortPlatforms}
          getLabel={(x) =>
            `${getPlatformLabel(x.value as Platform)} (${x.count})`
          }
        />

        <RefinementListDropDown
          label="Impact"
          attribute="impact"
          sortBy={sortImpacts}
        />

        <RefinementListDropDown
          label="Judgement"
          attribute="judgement"
          sortBy={sortJudgements}
          getLabel={(x) => (
            <span
              css={{
                display: "flex",
                gap: space.sm,
                position: "relative",
                top: -2,
              }}
            >
              <span>
                <JudgementPill
                  value={
                    x.value === "not_yet_rated"
                      ? undefined
                      : getJudgementFromTranslationValue(
                          x.value as TranslationValue
                        )
                  }
                  /**
                   * There is no easy way to get to is_issue_resolved here. It
                   * is not that important but @todo when we have moved to
                   * shortcodes because then the fix is easy.
                   */
                  isIssueResolved={false}
                />
              </span>
              <span>({x.count})</span>
            </span>
          )}
        />

        <HighlightsDropDown enableSuggestionsFilter={enableSuggestionsFilter} />

        <TopicDropDown
          label="Topic"
          attribute="breadcrumb"
          alignContent="right"
        />

        {
          /**
           * Only show the Observation column if the guideline has a comparison
           * document attached.
           */
          review.data?.review_comparison_id && (
            <RefinementListDropDown
              label="Observation"
              attribute="causeOfChange"
              sortBy={sortCauseOfChange}
              getLabel={(x) =>
                `${getCauseOfChangeLabel(x.value as CauseOfChange)} (${x.count})`
              }
            />
          )
        }
      </SpacedChildren>
      <CurrentRefinements />
    </SpacedChildren>
  );
});
