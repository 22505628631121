/* eslint-disable @typescript-eslint/no-explicit-any */

import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { colors, fontWeights, space } from "~/theme";
import type { MarkdownProps } from "./markdown";
import { Markdown } from "./markdown";
import {
  isImageUrl,
  isVimeoUrl,
  useAdaptiveCarouselEventHandlers,
} from "./media-carousel-modal";
import type { TitleProps } from "./typography";
import { Title } from "./typography";

import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

const levelMap = {
  1: 5,
  2: 5,
  3: 5,
  4: 6,
  5: 6,
  6: 6,
} as const;

const markdownComponents: MarkdownProps["components"] = {
  a: (props: any) => <MarkdownLink {...props} />,
  h1: (props: any) => <MarkdownTitle {...props} />,
  h2: (props: any) => <MarkdownTitle {...props} />,
  h3: (props: any) => <MarkdownTitle {...props} />,
  h4: (props: any) => <MarkdownTitle {...props} />,
  h5: (props: any) => <MarkdownTitle {...props} />,
  h6: (props: any) => <MarkdownTitle {...props} />,
};

/** 🚨 NOTE: This markdown renderer will also parse HTML. Handle with care. */
export function MarkdownText(props: MarkdownProps) {
  return (
    <StyledMarkdownText>
      <Markdown
        {...props}
        components={markdownComponents}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
      />
    </StyledMarkdownText>
  );
}

function MarkdownTitle(props: TitleProps) {
  const level = levelMap[props.level];
  return <Title {...props} level={level} cx={{ color: colors.charcoal }} />;
}

type MarkdownLinkProps = {
  href: string;
  children: React.ReactNode;
};

export function MarkdownLink({ href, children }: MarkdownLinkProps) {
  const isCarouselLink = isImageUrl(href) || isVimeoUrl(href);

  if (isCarouselLink) {
    return <MarkdownCarouselLink href={href}>{children}</MarkdownCarouselLink>;
  }

  return (
    <StyledMarkdownLink href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </StyledMarkdownLink>
  );
}

function MarkdownCarouselLink({ href, children }: MarkdownLinkProps) {
  const carouselProps = useAdaptiveCarouselEventHandlers(href);

  return (
    <StyledMarkdownLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...carouselProps}
    >
      {children}
    </StyledMarkdownLink>
  );
}

const StyledMarkdownLink = styled(Link)`
  font-weight: ${fontWeights.semibold};
  color: ${colors.slateGray};
`;

const StyledMarkdownText = styled.div`
  overflow-wrap: break-word;

  p,
  ul,
  ol {
    margin-bottom: 1.2rem;
  }

  ul,
  ol {
    font-size: 0.888889em;
  }

  li {
    margin-bottom: 0.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: ${space.lg};
  }
`;
