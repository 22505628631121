import { Typography } from "antd";

export function SectionTitle({
  children,
  small,
  noTopMargin,
}: {
  children: React.ReactNode;
  small?: boolean;
  noTopMargin?: boolean;
}) {
  return (
    <Typography.Title
      level={small ? 5 : 4}
      style={{ marginTop: noTopMargin ? 0 : "1em" }}
    >
      {children}
    </Typography.Title>
  );
}
