import type {
  ReviewPartProgress,
  ReviewStructureGroup,
  ReviewStructurePart,
} from "@gemini/common";
import { isReviewStructurePart } from "~/utils/identity";

export function getPartProgressPercentage(
  progress: ReviewPartProgress | undefined
) {
  if (!progress) return 0;
  const { completed_count, total_count } = progress;
  return Math.ceil((completed_count / total_count) * 100);
}

export function getPartByReference(
  reference: string,
  sections: Record<string, ReviewStructureGroup | ReviewStructurePart>
) {
  const match = Object.values(sections).find(
    (part) =>
      isReviewStructurePart(part) &&
      part.guideline_references.includes(reference)
  );

  return match as ReviewStructurePart;
}
