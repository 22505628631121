import React from "react";
import styled from "styled-components";
import { colors, mediaQueries, space } from "~/theme";

export type SeparatorProps = {
  area: string;
};

export function Separator({ area }: SeparatorProps) {
  return <StyledSeparator area={area} />;
}

const StyledSeparator = styled.div<SeparatorProps>`
  grid-area: ${(x) => x.area};
  margin: ${space.md} 0;
  width: 100%;
  height: 1px;
  background: ${colors.athensGray};
  opacity: 0.1;

  @media ${mediaQueries.sm} {
    width: 1px;
    height: auto;
    margin: 0;
  }

  @media ${mediaQueries.md} {
    width: 100%;
    height: 1px;
    margin: ${space.md} 0;
  }
`;
