import type { Condition, IndustryId } from "@gemini/common";
import { cleanObject } from "@gemini/common";
import { uniq } from "lodash-es";
import React from "react";
import { EditableTagGroup } from "../../../components/editable-tag-group";
import { IndustrySelector } from "../../../components/industry-selector";

type ConditionEditorProps = {
  condition: Condition;
  onChange: (value: Condition) => void;
};

type IndustrySelection = IndustryId | "__no_selection";

export function ConditionEditor(props: ConditionEditorProps) {
  const { industry_ids, tag_ids } = props.condition;

  const selections: IndustrySelection[] = industry_ids
    ? [...industry_ids, "__no_selection"]
    : ["__no_selection"];

  function handleChange(industryIds: IndustryId[]) {
    props.onChange(
      cleanObject<Condition>({
        industry_ids: industryIds.length > 0 ? uniq(industryIds) : undefined,
        tag_ids,
      })
    );
  }

  return (
    <div>
      <div
        css={`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {selections.map((selection, index) => (
          <IndustrySelector
            key={index}
            industryId={selection}
            onChange={(oldValue, newValue) => {
              const newIndustryIds = [
                ...(industry_ids?.filter((id) => id !== oldValue) || []),
                newValue,
              ];

              handleChange(newIndustryIds);
            }}
            onRemove={(removedValue) => {
              const newIndustryIds =
                industry_ids?.filter((id) => id !== removedValue) || [];

              handleChange(newIndustryIds);
            }}
          />
        ))}
      </div>
      <EditableTagGroup
        tagIds={tag_ids || []}
        onChange={(tags) =>
          props.onChange(
            cleanObject<Condition>({
              industry_ids: props.condition.industry_ids,
              tag_ids: tags,
            })
          )
        }
      />
    </div>
  );
}
