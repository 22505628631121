import useFocusTrap from "@charlietango/use-focus-trap";
import type { ReactNode } from "react";
import React, { useRef } from "react";
import styled from "styled-components";
import { useHotkey } from "~/modules/hooks";
import { useOnClickOutside } from "~/modules/hooks/use-on-click-outside";
import { space } from "~/theme";
import { Icon } from "./icon";
import { inputStyle } from "./input";

type DropDownButtonProps = {
  label: ReactNode;
  children: ReactNode;
  alignContent?: "left" | "right";
  isInitiallyOpen?: boolean;
  isDisabled?: boolean;
  onChange?: (isOpen: boolean) => void;
};

export function DropDownButton({
  children,
  label,
  alignContent = "left",
  isInitiallyOpen = false,
  isDisabled = false,
  onChange,
}: DropDownButtonProps) {
  const [isOpen, setIsOpen] = React.useState(isInitiallyOpen);
  const ref = useRef<HTMLDivElement>(null);
  const focusTrapRef = useFocusTrap(isOpen);
  const changeOpenState = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onChange?.(isOpen);
  };

  useOnClickOutside(ref, () => changeOpenState(false));
  useHotkey("esc", () => changeOpenState(false), { disabled: !isOpen });

  return (
    <div css={{ position: "relative" }} ref={ref}>
      <ToggleButton
        onClick={() => changeOpenState(!isOpen)}
        disabled={isDisabled}
      >
        <span
          css={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span css={{ whiteSpace: "nowrap" }}>{label}</span>
          <Icon
            name={isOpen ? "chevronUp" : "chevronDown"}
            size={20}
            cx={{ marginRight: -2 }}
          />
        </span>
      </ToggleButton>
      <Dropdown
        hidden={!isOpen}
        ref={focusTrapRef}
        $alignContent={alignContent}
      >
        <MaxHeightContainer>{children}</MaxHeightContainer>
      </Dropdown>
    </div>
  );
}

function MaxHeightContainer({ children }: { children: ReactNode }) {
  return (
    <div
      css={{
        padding: space.md,
        maxHeight: "75vh",
        overflow: "scroll",
        position: "relative",
      }}
    >
      {children}
    </div>
  );
}

const ToggleButton = styled.button`
  ${inputStyle};
  padding: 1.2rem 1.6rem;
  text-align: left;
  font-style: normal;
  cursor: pointer;
  &[disabled] {
    cursor: default;
  }
`;

const Dropdown = styled.div<{ $alignContent: "left" | "right" }>`
  position: absolute;
  top: calc(100% + ${space.sm});
  left: ${({ $alignContent }) => ($alignContent === "left" ? "0" : "auto")};
  right: ${({ $alignContent }) => ($alignContent === "right" ? "0" : "auto")};
  min-width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${space.sm};

  &[hidden] {
    display: none;
  }
`;
