import { assert } from "~/utils/assert";

export function setPathParams(
  template: string,
  params: Record<string, string>,
  fileName = ""
) {
  assert(!template.endsWith("/"), "Template string must not end with a slash.");

  const directory = Object.keys(params).reduce(
    (x, key) => x.replace(`{${key}}`, params[key]),
    template
  );

  return `${directory}/${fileName}`;
}

export function getPathParams<T extends string>(
  template: string,
  requiredParams: readonly T[],
  path: string
) {
  const regexStr = template
    /** Remove leading & trailing slash for regex start/end matching. */
    .replace(/^(\/)/, "")
    .replace(/(\/)$/, "")

    /** Map path variables to regex named groups. */
    .replace(/\{.*?\}/g, (x) => {
      const segment = x.replace("{", "").replace("}", "");
      return String.raw`(?<${segment}>[^\/]+)`;
    });

  /** Create regex with start/end matching. */
  const regex = new RegExp(`^${regexStr}$`);

  const params = regex.exec(path)?.groups;

  /** Return params when the regex matches and all params have a value. */
  if (params && requiredParams.every((x) => params[x])) {
    return params as Record<T, string>;
  }
}
