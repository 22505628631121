import {
  type CauseOfChange,
  causeOfChangeLabels,
  causesOfChange,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import { AsideBox, Field, Icon, TextArea } from "~/components";
import { WithTooltip } from "~/components/with-tooltip";
import { useAssessmentStore } from "~/features/assessment";
import { usePartStore } from "~/features/reviews";
import { useAuth } from "~/modules/auth";
import { colors, fonts, fontSizes } from "~/theme";
import { SectionTitle } from "./assessment-summary";

export const AuditorObservationForm = observer(() => {
  const auth = useAuth();
  const { observation, storedObservation, setObservation, deleteObservation } =
    useAssessmentStore();

  const { comparisonAssessment } = usePartStore();

  if (!comparisonAssessment) {
    /**
     * To keep the UI consistent in compare mode, and to be clear on why there
     * is no observation possible.
     */
    return (
      <ObservationFormBox>
        There is no comparison assessment available
      </ObservationFormBox>
    );
  }

  const showContent = auth.claims?.isAdmin;

  const currentObservation = observation ?? storedObservation;

  return (
    <ObservationFormBox>
      {showContent && (
        <>
          <div css={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
            {causesOfChange.map((x) => (
              <CauseOfChangeButton
                key={x}
                causeOfChange={x}
                onClick={() => setObservation(x, currentObservation?.text)}
                selected={x === currentObservation?.cause_of_change}
              />
            ))}
          </div>

          {currentObservation && (
            <div
              css={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Field label="Additional observation comment">
                <TextArea
                  value={currentObservation.text}
                  cx={{ padding: "1rem" }}
                  onChange={(newText) =>
                    setObservation(currentObservation.cause_of_change, newText)
                  }
                />
              </Field>

              <TextButton onClick={deleteObservation}>
                <span onClick={deleteObservation}>delete observation</span>
              </TextButton>
            </div>
          )}
        </>
      )}
    </ObservationFormBox>
  );
});

function TextButton({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <button
      onClick={onClick}
      css={{
        all: "unset",
        cursor: "pointer",
        textDecoration: "underline",
        textDecorationColor: colors.grayMedium,
        "&:hover": {
          color: colors.translationValue.vh,
          textDecorationColor: colors.translationValue.vh,
        },
      }}
    >
      {children}
    </button>
  );
}

function CauseOfChangeButton({
  onClick,
  causeOfChange,
  selected,
}: {
  onClick: () => void;
  causeOfChange: CauseOfChange;
  selected: boolean;
}) {
  return (
    <button
      onClick={onClick}
      css={{
        all: "unset",
        fontFamily: fonts.monospace,
        fontSize: fontSizes.xxs,
        border: `1px solid ${colors.ghostGray}`,
        borderRadius: 4,
        padding: `2px 4px`,
        "&:hover": {
          backgroundColor: colors.ghostGray,
          cursor: "pointer",
        },
        backgroundColor: selected ? colors.ghostGray : "transparent",
      }}
    >
      {causeOfChangeLabels[causeOfChange]}
    </button>
  );
}

function ObservationFormBox({ children }: { children: React.ReactNode }) {
  return (
    <AsideBox>
      {children && (
        <>
          <SectionTitle>
            <span css={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                Observation
                <WithTooltip content="The observed difference between this assessment and the one we are comparing to">
                  <span tabIndex={-1}>
                    <Icon
                      name="info"
                      size={16}
                      cx={{ marginLeft: 5, position: "relative", top: 2 }}
                    />
                  </span>
                </WithTooltip>
              </span>
            </span>
          </SectionTitle>

          {children}
        </>
      )}
    </AsideBox>
  );
}
