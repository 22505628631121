import type { DeliverableMasterTexts } from "@gemini/common";
import { isEmpty } from "lodash-es";
import { observer } from "mobx-react-lite";
import { AdminMarkdownText } from "./admin-markdown-text";
import { SectionTitle } from "./section-title";

export const DeliverablePreview = observer(
  ({ deliverableTexts }: { deliverableTexts: DeliverableMasterTexts[] }) => {
    if (isEmpty(deliverableTexts)) {
      return <span>(There are no deliverable texts)</span>;
    }

    return (
      <>
        {deliverableTexts.map((x, index) => (
          <section key={index}>
            <OptionalSection
              title="Custom Guideline Title"
              value={x.custom_guideline_title}
              noTopMargin
            />

            <OptionalSection
              title="Company Text Template"
              value={x.company_text_template}
            />

            <OptionalSection
              title="Presenter Notes"
              value={x.presenter_notes}
            />

            <OptionalSection title="Intro" value={x.intro} />

            <OptionalSection title="Recommendation" value={x.recommendation} />
          </section>
        ))}
      </>
    );
  }
);

function OptionalSection({
  title,
  value,
  noTopMargin,
}: {
  title: string;
  value?: string;
  noTopMargin?: boolean;
}) {
  if (!value) {
    return null;
  }

  return (
    <>
      <SectionTitle small noTopMargin={noTopMargin}>
        {title}
      </SectionTitle>
      <AdminMarkdownText>{value}</AdminMarkdownText>
    </>
  );
}
