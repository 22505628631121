import { observer } from "mobx-react-lite";
import { Sidebar } from "~/components";
import { useAuth } from "~/modules/auth";
import { AuditDeliveryStatus } from "../components/audit-delivery-status";
import { useReviewStore } from "../store";
import { SidebarFinished, SidebarUnfinished } from "./components";
import { usePartStore } from "./store";

export const PartSidebar = observer(() => {
  const { review, isDelivered } = useReviewStore();
  const { part, progress } = usePartStore();
  const { claims } = useAuth();
  const showAuditDeliveryInfo = claims.isAdmin && isDelivered;

  const breadcrumbs = [
    { href: "/reviews", name: "Reviews" },
    {
      href: `/reviews/${review.id}`,
      name: review.data?.title || "__no_review_title",
    },
    { name: part?.title || "__no_part_title" },
  ];

  return (
    <Sidebar
      variant={review.data?.type === "case-study" ? "secondary" : "primary"}
      breadcrumbs={breadcrumbs}
    >
      {showAuditDeliveryInfo && <AuditDeliveryStatus />}
      {progress.isCompleted ? <SidebarFinished /> : <SidebarUnfinished />}
    </Sidebar>
  );
});
