import { clamp, isEmpty, isNumber } from "lodash-es";
import { isDefined } from "ts-is-present";
import type { TranslationValue } from "./constants";
import { AH_VALUE, AL_VALUE, NU_VALUE, VH_VALUE, VL_VALUE } from "./constants";
import { getTranslationValueFromNumericValue } from "./judgement";
import { getRuling } from "./rules";
import type {
  PointsToValueMapping,
  ReviewContext,
  Scenario,
  ScenarioMap,
} from "./schemas";
import { assert } from "./utils";

export type ValidationError = {
  scenarioId: string;
  errorType:
    | "SELECTION_REQUIRED"
    | "OPTION_WITHOUT_VALUE"
    | "OPTION_WITHOUT_POINT_SCORE"
    | "INCOMPATIBLE_SELECTION";
};

export type CalculationResult = {
  value?: TranslationValue;
  validationErrors?: ValidationError[];
};

export const DEFAULT_POINTS_MAPPING_MAX = 10;
export const DEFAULT_POINTS_MAPPING_MIN = -10;

export const DEFAULT_POINTS_MAPPING: PointsToValueMapping[] = [
  { from: DEFAULT_POINTS_MAPPING_MIN, to: -3, value: "vh" },
  { from: -2, to: -1, value: "vl" },
  { from: 0, to: 0, value: "neutral" },
  { from: 1, to: 2, value: "al" },
  { from: 3, to: DEFAULT_POINTS_MAPPING_MAX, value: "ah" },
];

/**
 * This recursive function returns the calculated translation value for the
 * branch based on selected scenarios. This value can be IR. The final judgement
 * value is created and stored outside this function, translating IR to AH and
 * flagging the assessment with is_issue_resolved.
 *
 * The following pseudo code is used to traverse the tree:
 *
 * - Find all selected child scenarios where parentId == branchId
 * - If no selection is available: return parent translation value
 * - Else: collect all translation values from selected children
 *
 *   - If scenario is a leaf return its translation value
 *   - Else: return calculateTranslationValueForBranch(scenarioId)
 * - Sum all values, giving precedence for non-numeric value NA and convert IR to
 *   AH
 * - If logic is AND: calculate value using accumulate or average.
 *
 *   - If mode is accumulate: clip value to range -2 2
 * - Return value
 */
export function calculateTranslationValueRecursively(args: {
  scenarioId?: string;
  allScenarios: ScenarioMap;
  allSelectedScenarioIds: string[];
  reviewContext: ReviewContext;
}): CalculationResult {
  const {
    scenarioId = "__root",
    allScenarios,
    allSelectedScenarioIds,
    reviewContext,
  } = args;

  const scenario = allScenarios[scenarioId];

  assert(scenario, `Failed to find scenario for id ${scenarioId}`);

  const selectedChildScenarios = Object.values(allScenarios).filter(
    (scenario) =>
      scenario.parent_id === scenarioId &&
      allSelectedScenarioIds.includes(scenario.id)
  );

  /**
   * It could be that some children are hidden for this review context. We do
   * not want to count them.
   */
  const numChildrenInContext = countChildrenInContext(
    allScenarios,
    scenarioId,
    reviewContext
  );

  /**
   * # ====================================================================
   *
   * No children are selected
   */
  if (isEmpty(selectedChildScenarios)) {
    if (scenarioId === "__root") {
      /**
       * Having the __root without any selected children is the initial state of
       * assessment. We could return a validation error if we want, but it's
       * probably more convenient to return undefined values and not start with
       * an error state.
       */
      return {};
    }

    if (scenario.logic === "or" && numChildrenInContext > 0) {
      /**
       * If an OR logic scenario has children that are in context scope we
       * enforce that a selection is made. It doesn't matter if the OR parent
       * has a TV, because we always need to enforce child selection (you can
       * not unselect a radio button one you select one)
       */
      return {
        validationErrors: [
          {
            scenarioId,
            errorType: "SELECTION_REQUIRED",
          },
        ],
      };
    }

    /**
     * If no children were selected, or a child selection was not possible for
     * this context, we return the translation value of the current scenario. If
     * there is no translation value for this node, we return a validation error
     * to enforce selection.
     */
    const value = getTranslationValueForContext(scenario, reviewContext);

    return {
      value,
      validationErrors:
        value === undefined
          ? [
              {
                scenarioId,
                errorType: "SELECTION_REQUIRED",
              },
            ]
          : undefined,
    };
  }

  /**
   * # ====================================================================
   *
   * Some children are selected. In the case of points we use a different
   * algorithm to come to a translation value.
   */
  if (scenario.logic === "points") {
    const values = selectedChildScenarios.map((scenario) =>
      getPointScoreForContext(scenario, reviewContext)
    );

    const isAllNumbers = values.every(isNumber);

    if (isAllNumbers) {
      return {
        value: calculateTranslationValueFromPointScores(scenario, values),
      };
    } else {
      return {
        validationErrors: [
          {
            scenarioId,
            errorType: "OPTION_WITHOUT_POINT_SCORE",
          },
        ],
      };
    }
  }

  /** Recursively collect a list of translation values for the selected children. */
  const results = selectedChildScenarios.map((childScenario) =>
    calculateTranslationValueRecursively({
      scenarioId: childScenario.id,
      allScenarios,
      allSelectedScenarioIds,
      reviewContext,
    })
  );

  /**
   * See if there were any validation errors when collecting the results, and
   * return them if there were.
   */
  const capturedErrors = results
    .flatMap((result) => result.validationErrors)
    .filter(isDefined);

  const validationErrors = isEmpty(capturedErrors) ? undefined : capturedErrors;

  if (validationErrors) {
    return { validationErrors };
  }

  /**
   * Collect the values from the results. Not every scenario is guaranteed to
   * have a defined translation value, so we filter out undefined.
   */
  const values = results.map((result) => result.value).filter(isDefined);

  if (isEmpty(values)) {
    /**
     * It is possible that none of the selected scenarios had a translation
     * value attached to them, but they also didn't have children. In that case
     * we do not have values but we also do not have validation errors.
     */
    return {
      validationErrors: [
        {
          /**
           * It could be that multiple of the selected children should be
           * enforced to have a selection, but the error always focuses on one
           * scenario so we select the first.
           */
          scenarioId,
          errorType: "SELECTION_REQUIRED",
        },
      ],
    };
  }

  if (scenario.logic === "or" && values.length > 1) {
    /** With OR logic we only ever allow one child value to be selected */
    return {
      validationErrors: [
        {
          scenarioId,
          errorType: "INCOMPATIBLE_SELECTION",
        },
      ],
    };
  }

  return {
    value: calculateTranslationValueFromClassicSelection(scenario, values),
  };
}

export function isPointScore(
  value: TranslationValue | number
): value is number {
  return isNumber(value);
}

function calculateTranslationValueFromClassicSelection(
  scenario: Scenario,
  values: TranslationValue[]
) {
  const { logic, logic_and_mode } = scenario;

  switch (logic) {
    case "or":
      assert(
        values.length <= 1,
        /**
         * This should have been caught earlier in the calling context, but
         * leaving this in just to be sure.
         */
        `Logic OR can not have more then one child selected. Values: ${values}, scenario id: ${scenario.id}`
      );

      return values[0];
    case "and":
      return combineTranslationValues(values, logic_and_mode);
    default:
      throw new Error(`Invalid logic type: ${logic}`);
  }
}

/** Sum the points from the selected children and map that to a translation value */
export function calculateTranslationValueFromPointScores(
  scenario: Scenario,
  values: number[]
): TranslationValue {
  const sum = values.reduce((acc, value) => acc + value, 0);
  const mapping = scenario.points_mapping;

  if (!mapping) {
    throw new Error(
      `No mapping defined for point-based scenario ${scenario.id}`
    );
  }

  const range = mapping.find(({ to, from }) => sum >= from && sum <= to);

  /**
   * During editing in the admin it could be that you change the range while
   * options are selected and no translation value can be mapped
   */
  if (!range) {
    return "na";
  }

  return range.value;
}

/**
 * Get the translation value for this scenario taking the review context into
 * account for a possible rule-based override.
 */
export function getTranslationValueForContext(
  scenario: Scenario,
  reviewContext: ReviewContext
): TranslationValue | undefined {
  const { overrideValue } = getRuling(
    scenario,
    reviewContext.industryId,
    reviewContext.tagIds
  );

  return overrideValue ?? scenario.translation_value;
}

/**
 * Get the point score for this scenario taking the review context into account
 * for a possible rule-based override.
 */
export function getPointScoreForContext(
  scenario: Scenario,
  reviewContext: ReviewContext
): number | undefined {
  const { overridePointScore } = getRuling(
    scenario,
    reviewContext.industryId,
    reviewContext.tagIds
  );

  return overridePointScore ?? scenario.point_score;
}

/**
 * Count the scenario children that fall within the scope of this review context
 * (and are thus visible in the UI). For each of the scenarios we can query a
 * ruling based on tag and industry id settings, and only count the children
 * that are not excluded.
 */
function countChildrenInContext(
  scenarios: ScenarioMap,
  scenarioId: string,
  reviewContext: ReviewContext
) {
  const nonExcludedRulings = Object.values(scenarios)
    .filter((x) => x.parent_id === scenarioId)
    .map((x) => getRuling(x, reviewContext.industryId, reviewContext.tagIds))
    .filter((x) => x.exclude === false);

  return nonExcludedRulings.length;
}

type NumericJudgementValue = number | "na";

/**
 * @todo Discuss: Als IR gekozen wordt in een lijst met OR logic dan is de
 *   uitkomst van de calculateValueForNode ook "ir". Maar als "ir" opgeteld
 *   wordt bij AND logic, dan wordt de waarde 2 gebruikt voor rekenen ("AH").
 *   Het probleem is dat we dan in de output niet meer weten dat IR onderdeel
 *   was. En de flag is_issue_resolved wordt dan ook niet gezet.
 */
export function combineTranslationValues(
  values: TranslationValue[],
  mode: "acc" | "avg" = "avg"
): TranslationValue | undefined {
  if (isEmpty(values)) return;

  const sum = values.reduce<NumericJudgementValue>((result, value) => {
    /** Once the result is NA, we can ignore all other values */
    if (result === "na") {
      return result;
    }

    /** If the value is NA, we can ignore all previous numerical values */
    if (value === "na") {
      return value;
    }

    /** If the value is IR, treat is as AH (2) */
    if (value === "ir") {
      return Number(result) + 2;
    }

    return Number(result) + getNumberFromTranslationValue(value);
  }, 0);

  /** If sum is not a number it should be "na" and we return it as is */
  if (sum === "na") return sum;

  switch (mode) {
    case "acc":
      return getTranslationValueFromNumericValue(
        clamp(sum, VH_VALUE, AH_VALUE)
      );

    case "avg":
      return getTranslationValueFromNumericValue(
        clamp(Math.round(sum / values.length), VH_VALUE, AH_VALUE)
      );
  }
}

export function getNumberFromTranslationValue(value: TranslationValue): number {
  switch (value) {
    case "vh":
      return VH_VALUE;
    case "vl":
      return VL_VALUE;
    case "neutral":
      return NU_VALUE;
    case "al":
      return AL_VALUE;
    case "ah":
      return AH_VALUE;
    case "na":
      /**
       * Return AH on NA. NA should already be handled in
       * combineTranslationValues before we call this function. The reason we
       * want to return a positive number here is to make the master texts
       * polarity default to positive for NA translation values, just like we do
       * when a scenario has no translation value.
       */
      return AH_VALUE;
    // throw new Error("Unable to convert NA to number");
    case "ir":
      return AH_VALUE;
    default:
      throw new Error(`Invalid translation value ${value}`);
  }
}
