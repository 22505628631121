import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import React from "react";
import { Scenario, ValidationMessage } from ".";
import { useBranchContext } from "../context";
import { useChildScenarios } from "../hooks";

type BranchProps = {
  parentId: string;
  level: number;
  onScenarioChange?: (checked: boolean, scenarioId: string) => void;
};

export const Branch = observer(
  ({ parentId, level, onScenarioChange }: BranchProps) => {
    const parent = useScenario(parentId);
    const childScenarios = useChildScenarios(parentId);
    const selectedIds = useSelectedIds(parentId);
    const handleScenarioChange = useChangeHandler(parentId, onScenarioChange);

    if (!childScenarios.length) return null;

    return (
      <React.Fragment>
        <ValidationMessage scenarioId={parentId} />
        {childScenarios.map((scenario, index) => (
          <Scenario
            logic={parent.logic}
            testingName={`scenario-level-${level}-index-${index}`}
            onChange={handleScenarioChange}
            key={scenario.id}
            level={level}
            parentId={parentId}
            scenario={scenario}
            selectedIds={selectedIds}
          >
            <Branch
              parentId={scenario.id}
              level={level + 1}
              onScenarioChange={handleScenarioChange}
            />
          </Scenario>
        ))}
      </React.Fragment>
    );
  }
);

function useSelectedIds(parentId: string): string[] {
  const { selectedIdsMap } = useBranchContext();
  return selectedIdsMap[parentId] || [];
}

function useChangeHandler(
  parentId: string,
  onScenarioChange?: (checked: boolean, scenarioId: string) => void
) {
  const { onSelectionChange } = useBranchContext();
  const parent = useScenario(parentId);
  const selectedIds = useSelectedIds(parentId);

  return React.useCallback(
    (checked: boolean, scenarioId: string) => {
      /** Trigger onScenarioChange to recursively select all parent scenarios */
      if (onScenarioChange) onScenarioChange(checked, parentId);

      const values =
        parent.logic === "and" || parent.logic === "points"
          ? checked
            ? selectedIds.concat(scenarioId)
            : selectedIds.filter((x) => x !== scenarioId)
          : [scenarioId];

      onSelectionChange(parentId, values);
    },
    [parent.logic, onScenarioChange, onSelectionChange, parentId, selectedIds]
  );
}

function useScenario(scenarioId: string) {
  const { scenarios } = useBranchContext();
  const scenario = scenarios[scenarioId];
  assert(scenario, `Failed to find scenario ${scenarioId}`);
  return scenario;
}
