import React from "react";
import styled from "styled-components";
import { colors, preset } from "~/theme";

export type MetricProps = {
  name: string;
  children: React.ReactNode;
};

export function Metric({ name, children }: MetricProps) {
  return (
    <div>
      <MetricHeading>{name}</MetricHeading>
      <MetricValue>{children}</MetricValue>
    </div>
  );
}

const MetricHeading = styled.span`
  ${preset.typography.overline}
  display: block;
  color: ${colors.ghostGray};
  white-space: nowrap;
`;

const MetricValue = styled.span`
  ${preset.typography.h5}
`;

Metric.MetricHeading = MetricHeading;
Metric.MetricValue = MetricValue;
