import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import React from "react";
import { BackLink, Button, PageContainer } from "~/components";
import { useUserId } from "~/modules/auth";
import { notify } from "~/modules/notifications";
import { usePartStore } from "../reviews";
import { AssessmentHeader, Footer, WithSummaryPageAside } from "./components";
import { ManualJudgementForm } from "./components/manual-judgement-form";
import { AssessmentSidebar } from "./sidebar";
import { useAssessmentStore } from "./store";

export const AssessmentManualJudgementPage: NextPage = observer(() => {
  const router = useRouter();
  const { guideline, sortedParts } = usePartStore();
  const assessmentStore = useAssessmentStore();
  const userId = useUserId();

  const aside = <AssessmentSidebar />;

  const reference = router.query.reference as string;
  const reviewId = router.query.reviewId as string;
  const partId = router.query.partId as string;

  assert(guideline, "Missing guideline data");

  const { citation_code, sql_id } = guideline.data;

  const {
    isManualJudgement,
    calculatedTranslationValue,
    isSubmitting,
    selectedIds,
    comment,
    scenarios,
    images,
    files,
    pendingFileUploads,
    observation,
  } = assessmentStore;

  React.useEffect(() => {
    /**
     * As user should not have access to the manual judgement page if
     * `isManualJudgement` is false
     */
    if (!isManualJudgement) {
      router
        .replace(
          `/reviews/[reviewId]/parts/[partId]/assessments/[reference]/[page]`,
          `/reviews/${reviewId}/parts/${partId}/assessments/${reference}/assess`
        )
        .catch((err) => notify.error(err));
    }
  }, [isManualJudgement, router, reviewId, partId, reference]);

  const guidelineData = guideline.data;

  const rootScenario = guidelineData.scenarios.__root;

  const isSaveDisabled =
    isSubmitting ||
    (!isManualJudgement &&
      (calculatedTranslationValue?.value === undefined ||
        !!calculatedTranslationValue?.validationErrors));

  const footer = (
    <Footer
      left={
        <BackLink
          href={`/reviews/${reviewId}/parts/${partId}/assessments/${guidelineData.reference}/assess`}
        >
          Back to assessment options
        </BackLink>
      }
      right={
        <Button onClick={submitAssessment} disabled={isSaveDisabled}>
          Continue
        </Button>
      }
    />
  );

  return (
    <PageContainer
      aside={aside}
      footerBar={footer}
      testingName="manual-judgement-page"
    >
      <AssessmentHeader
        title={rootScenario.title}
        citationCode={citation_code}
        sqlId={sql_id}
      />

      <WithSummaryPageAside
        isUpdateFlow={false}
        selectedIds={selectedIds}
        comment={comment}
        scenarios={scenarios}
        isManualJudgement={isManualJudgement}
        images={images}
        files={files}
        pendingFileUploads={pendingFileUploads}
        sortedParts={sortedParts}
        observation={observation}
      >
        {(aside) => (
          <React.Fragment>
            <ManualJudgementForm guideline={guideline} />
            {aside}
          </React.Fragment>
        )}
      </WithSummaryPageAside>
    </PageContainer>
  );

  async function submitAssessment() {
    assessmentStore.submitAssessment(userId).catch((err) => notify.error(err));

    return router.push(
      `/reviews/[reviewId]/parts/[partId]/assessments/[reference]/[page]`,
      `/reviews/${reviewId}/parts/${partId}/assessments/${reference}/summary`
    );
  }
});
