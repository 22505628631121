import type { Guideline, JudgementValue, ReviewUpdate } from "@gemini/common";
import {
  assert,
  calculateTranslationValueRecursively,
  getJudgementFromNudging,
  NO_REFERENCE,
} from "@gemini/common";
import autoBind from "auto-bind";
import { collection, doc, getDoc } from "firebase/firestore";
import { pick, set } from "lodash-es";
import { action, makeAutoObservable, runInAction } from "mobx";
import pMemoize from "p-memoize";
import { isDefined } from "ts-is-present";
import { applyConfirmUpdates } from "~/modules/api";
import { db } from "~/modules/firebase";
import type { Document } from "~/modules/firestore";
import { ObservableCollection } from "~/modules/firestore-mobx";
import { wrapAroundLength } from "~/modules/number";
import type { ReviewStore } from "../../store";

/**
 * Guideline documents are pretty much immutable, because every guideline
 * revision results in a new document. For fetching the guidelines we can
 * therefore memoize the getDocument so that the memoize function effectively
 * creates a cache.
 */
const memGetGuideline = pMemoize((id?: string) =>
  getDoc(doc(db, "guidelines", id || "__no_document_id"))
);

export class UpdateStore {
  reviewStore: ReviewStore;

  /**
   * [Note] We don't need the review structure document as part of this store,
   * because we can filter any pending updates based on the
   * structure_foreign_keys field that is already part of the review document.
   */

  updates: ObservableCollection<ReviewUpdate>;

  /**
   * The new guideline versions based on the references in the pending updates
   * documents.
   */
  newGuidelinesCache = new Map<string, Document<Guideline>>();

  /**
   * All guidelines currently linked to the review that are referenced in the
   * updates collection. We use this data to show the titles of the selected
   * scenarios based on the correct (old) guideline version.
   */
  oldGuidelinesCache = new Map<string, Document<Guideline>>();

  /**
   * By first setting a unique reference value, we can filter out all duplicate
   * initialize calls. Do not set it to NO_REFERENCE initially because the store
   * might be constructed on an overview page where there is no reference and
   * then the init would be skipped.
   */
  currentReference = "__not_initialized_yet";

  prevReference = NO_REFERENCE;
  nextReference = NO_REFERENCE;

  constructor(reviewStore: ReviewStore) {
    console.log("[update store] create for review", reviewStore.review.id);

    this.reviewStore = reviewStore;

    this.updates = new ObservableCollection<ReviewUpdate>(
      collection(db, "reviews", reviewStore.review.id, "updates")
    );

    makeAutoObservable(this, {
      updates: false,

      /**
       * Somehow makeAutoObservable doesn't recognize that initialize needs to
       * be an action.
       */
      initialize: action,
    });

    autoBind(this);
  }

  /**
   * Just like the part store the update store can get initialized landing on
   * the overview page (without reference) or on one of the assessment pages
   * where the reference is known.
   */
  async initialize(reference = NO_REFERENCE) {
    if (this.currentReference === reference) {
      console.log("[update store] skip initialize for reference", reference);
      return;
    }

    console.log("[update store] initialize for reference", reference);

    /**
     * Already store the reference as current so that we can ignore subsequent
     * init calls when things are already fetching maybe.
     */
    this.currentReference = reference;

    /**
     * We need the update documents to load first, otherwise we can't know what
     * guideline data to fetch.
     */
    return this.updates
      .ready()
      .then((updates) =>
        Promise.all([
          this.fetchOldGuidelines(updates),
          this.fetchNewGuidelines(updates),
        ])
      );
  }

  private get review() {
    return this.reviewStore.review.document;
  }

  private get assessments() {
    return this.reviewStore.assessments.documents;
  }

  get isLoading() {
    return this.updates.isLoading;
  }

  get guideline() {
    assert(
      this.currentReference,
      "Can not get new guideline without reference"
    );
    return this.newGuidelinesCache.get(this.currentReference);
  }

  get oldGuideline() {
    assert(
      this.currentReference,
      "Can not get old guideline without reference"
    );
    return this.oldGuidelinesCache.get(this.currentReference);
  }

  get assessment() {
    return this.assessments.find(
      (x) => x.data.guideline_reference === this.currentReference
    );
  }

  get confirmTypeUpdates() {
    return (
      this.updates.documents
        .filter((doc) => doc.data.type === "confirm")
        /**
         * After switching structures it is possible that there are pending
         * update documents that are no longer part of the linked guidelines. We
         * want to ignore but preserve those updates in case the user switches
         * back.
         */
        .filter((doc) =>
          this.review.data.linked_guideline_references.includes(
            doc.data.guideline_reference
          )
        )
    );
  }

  get confirmTypeGuidelines() {
    return this.confirmTypeReferences
      .map((reference) => this.oldGuidelinesCache.get(reference))
      .filter(isDefined);
  }

  get confirmTypeAssessments() {
    return this.assessments.filter(
      (doc: { data: { guideline_reference: string } }) =>
        this.confirmTypeReferences.includes(doc.data.guideline_reference)
    );
  }

  get assessTypeUpdates() {
    return (
      this.updates.documents
        .filter((doc) => doc.data.type === "assess")
        /**
         * After switching structures it is possible that there are pending
         * update documents that are no longer part of the linked guidelines. We
         * want to ignore but preserve those updates in case the user switches
         * back.
         */
        .filter((doc) =>
          this.review.data.linked_guideline_references.includes(
            doc.data.guideline_reference
          )
        )
    );
  }

  get assessTypeGuidelines() {
    return this.assessTypeReferences
      .map((reference) => this.oldGuidelinesCache.get(reference))
      .filter(isDefined);
  }

  get assessTypeAssessments() {
    return this.assessments.filter(
      (doc: { data: { guideline_reference: string } }) =>
        this.assessTypeReferences.includes(doc.data.guideline_reference)
    );
  }

  get silentTypeUpdates() {
    return this.updates.documents.filter((doc) => doc.data.type === "silent");
  }

  get addTypeUpdates() {
    return this.updates.documents.filter((doc) => doc.data.type === "add");
  }

  get removeTypeUpdates() {
    return this.updates.documents.filter((doc) => doc.data.type === "remove");
  }

  applyConfirmUpdates() {
    return applyConfirmUpdates({ reviewId: this.review.id });
  }

  get entryGuidelineLink() {
    if (this.assessTypeUpdates.length > 0) {
      return `/reviews/${this.review.id}/updates/assessments/${this.assessTypeUpdates[0].data.guideline_reference}/assess`;
    }
  }

  /**
   * Fetch any of the guidelines from the current review data that appear in the
   * list of updates. The updates need to be fetched first of course.
   */
  fetchOldGuidelines(updates: Document<ReviewUpdate>[]) {
    const referencesToUpdate = updates.map((x) => x.data.guideline_reference);

    const guidelineIdMap = pick(
      this.review.data.linked_guidelines,
      referencesToUpdate
    );

    console.log("[update store] Fetch old guidelines", guidelineIdMap);

    const promisedOperations = Object.entries(guidelineIdMap).map(
      async ([reference, id]) => {
        const snapshot = await memGetGuideline(id);
        return [
          reference,
          { id: snapshot.id, data: snapshot.data() as Guideline },
        ] as [string, Document<Guideline>];
      }
    );

    return Promise.all(promisedOperations).then(
      (items: [string, Document<Guideline>][]) => {
        runInAction(() => {
          for (const [reference, guideline] of items) {
            this.oldGuidelinesCache.set(reference, guideline);
          }
        });
      }
    );
  }

  /**
   * Fetching the new guidelines for each of the updates is more complicated
   * then in the part store. This is because for the part store all guidelines
   * are already known upfront when the store initializes.
   *
   * In this case the update documents can change at any time and they are also
   * not known right away, because their query needs to complete first.
   *
   * They way this is solved is by calling fetchGuidelines on every redraw and
   * compare the already cached guideline document ids (not references) with
   * what is already in cache. If there is a difference we fetch those.
   */
  fetchNewGuidelines(updates: Document<ReviewUpdate>[]) {
    const guidelineIdMap = updates.reduce<Record<string, string>>(
      (acc, v) => set(acc, v.data.guideline_reference, v.data.guideline_id),
      {}
    );

    console.log("[update store] Fetch new guidelines", guidelineIdMap);

    const promisedOperations = Object.entries(guidelineIdMap).map(
      async ([reference, id]) => {
        const snapshot = await memGetGuideline(id);
        return [
          reference,
          { id: snapshot.id, data: snapshot.data() as Guideline },
        ] as [string, Document<Guideline>];
      }
    );

    return Promise.all(promisedOperations).then(
      (items: [string, Document<Guideline>][]) => {
        runInAction(() => {
          for (const [reference, guideline] of items) {
            this.newGuidelinesCache.set(reference, guideline);
          }
        });
      }
    );
  }

  get progress() {
    const pendingCount = this.assessTypeUpdates.length;
    return {
      guidelineCount: pendingCount,
      assessmentCount: pendingCount,
      percentage: (1 / (pendingCount + 1)) * 100,
      isCompleted: pendingCount === 0,
    };
  }

  get allUpdateReferences() {
    return this.updates.documents.map((x) => x.data.guideline_reference);
  }

  get confirmTypeReferences() {
    return this.confirmTypeUpdates.map((x) => x.data.guideline_reference);
  }

  get confirmTypeJudgements() {
    const newGuidelinesCache = this.newGuidelinesCache;

    return this.confirmTypeAssessments
      .map((assessment) => {
        const {
          guideline_reference,
          selected_scenario_ids,
          judgement_nudge_value,
        } = assessment.data;

        const guideline = newGuidelinesCache.get(guideline_reference);

        /**
         * It could happen that the new guideline version is not fetched yet,
         * because update documents can change at any time.
         */
        if (!guideline) {
          return [guideline_reference, undefined] as [string, undefined];
        }

        const { value: calculatedTranslationValue, validationErrors } =
          calculateTranslationValueRecursively({
            allScenarios: guideline.data.scenarios,
            allSelectedScenarioIds: selected_scenario_ids,
            reviewContext: {
              industryId: this.review.data.industry_id,
              tagIds: this.review.data.tag_ids,
            },
          });

        assert(
          !validationErrors,
          `Some validation errors occurred in assessment of guideline ${
            guideline.data.reference
          } in review: ${this.review.id}: ${JSON.stringify(
            validationErrors,
            null,
            2
          )}`
        );

        /**
         * Nudged assessments should be treated as re-assessment so I don't
         * think this part has any effect here, but we'll leave it in for
         * robustness since it can't hurt.
         */
        const judgement = getJudgementFromNudging(
          calculatedTranslationValue,
          judgement_nudge_value
        );

        return [guideline_reference, judgement];
      })
      .reduce<Record<string, JudgementValue>>(
        (acc, [reference, judgement]) => set(acc, reference, judgement),
        {}
      );
  }

  get assessTypeReferences() {
    return this.assessTypeUpdates.map((x) => x.data.guideline_reference);
  }

  get navigation() {
    const index = this.assessTypeReferences.findIndex(
      (ref) => ref === this.currentReference
    );

    const remainingAssessmentCount = this.assessTypeReferences.length;
    let prevLink: string | undefined;
    let nextLink: string | undefined;

    const isCompleted = remainingAssessmentCount === 0;

    if (remainingAssessmentCount > 0) {
      const prevIndex = wrapAroundLength(index - 1, remainingAssessmentCount);
      const nextIndex = wrapAroundLength(index + 1, remainingAssessmentCount);

      const prevReference = this.assessTypeReferences[prevIndex];
      const nextReference = this.assessTypeReferences[nextIndex];

      /**
       * We do not navigate to guidelines that have already been re-assessed
       * because once the assessment gets submitted, the guideline (update) is
       * removed from the re-assess list. So basically this means that you can
       * only navigate to guidelines that have not been re-assessed yet.
       */
      prevLink = `/reviews/${this.review.id}/updates/assessments/${prevReference}/assess`;

      nextLink = `/reviews/${this.review.id}/updates/assessments/${nextReference}/assess`;
    }

    const overviewLink = `/reviews/${this.review.id}/updates`;

    return {
      position: index,
      total: remainingAssessmentCount,
      prevLink,
      nextLink,
      overviewLink,
      isCompleted,
    };
  }
}
