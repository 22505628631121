import deepmerge from "deepmerge";
import type { ScenarioIdsByParent, ScenarioMap } from "../schemas";
import { assert } from "../utils";

/**
 * For each of the passed in ids, return a map describing what scenarios are
 * involved in selecting those. The map describes for each (parent) scenario
 * which of its (direct) children should be selected.
 */
export function getAllScenarioIdsInvolvedInSelection(
  scenarios: ScenarioMap,
  targetSelectionIds: string[]
): ScenarioIdsByParent {
  const targetSelectionMaps = targetSelectionIds.map((id) =>
    getScenarioIdsLeadingUpTo(scenarios, id)
  );

  return targetSelectionMaps.reduce<ScenarioIdsByParent>((acc, v) => {
    /**
     * Merge the two objects to that the arrays for each property are
     * concatenated. lodash-es merge will replace the value of one property with
     * the other.
     */
    return deepmerge(acc, v);
  }, {});
}

/**
 * Recursively climb up the tree to find what (parent) scenarios are involved
 * when selecting the target.
 */
export function getScenarioIdsLeadingUpTo(
  scenarios: ScenarioMap,
  targetSelectionId: string
): ScenarioIdsByParent {
  const scenario = scenarios[targetSelectionId];

  assert(scenario, `Failed to find scenario ${targetSelectionId}`);

  const { parent_id } = scenario;

  return parent_id === "__no_parent"
    ? {}
    : {
        [parent_id]: [targetSelectionId],
        ...getScenarioIdsLeadingUpTo(scenarios, parent_id),
      };
}
