import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import type { IconProps } from "~/components";
import { IconInline, Spinner } from "~/components";
import { colors, fontSizes, fontWeights, theme } from "~/theme";

type IconLinkProps = {
  children: React.ReactNode;
  icon: IconProps["name"];
  href?: string;
  testingName?: string;
  onClick?: () => void;
  loading?: boolean;
};
export const IconLink = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  IconLinkProps
>(({ children, icon, href, testingName, onClick, loading }, ref) => {
  const inner = loading ? (
    <LoadingWithText>{children}</LoadingWithText>
  ) : (
    <IconWithText icon={icon}>{children}</IconWithText>
  );

  return href ? (
    <StyledIconLink
      ref={ref as React.Ref<HTMLAnchorElement>}
      href={href}
      onClick={onClick}
      data-t={testingName}
    >
      {inner}
    </StyledIconLink>
  ) : (
    <StyledIconButton
      ref={ref as React.Ref<HTMLButtonElement>}
      onClick={onClick}
      data-t={testingName}
    >
      {inner}
    </StyledIconButton>
  );
});

IconLink.displayName = "IconLink";

const linkStyles = css`
  text-decoration: none;
  font-weight: ${fontWeights.semibold};
  font-size: ${fontSizes.md};
  white-space: nowrap;

  &:hover {
    color: ${colors.charcoal};
  }
`;

const StyledIconLink = styled(Link)`
  ${linkStyles}
`;

const StyledIconButton = styled.button`
  ${linkStyles}

  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  display: inline;
  color: inherit;
  cursor: pointer;
`;

function IconWithText({
  icon,
  children,
}: {
  icon: IconProps["name"];
  children: React.ReactNode;
}) {
  return (
    <span>
      <IconInline
        cx={{ transform: "translateY(1px)", marginRight: theme.space.sm }}
        name={icon}
      />

      <span>{children}</span>
    </span>
  );
}

function LoadingWithText({ children }: { children: React.ReactNode }) {
  return (
    <span>
      <span
        css={{
          width: 20,
          height: 20,
          display: "inline-block",
          transform: "translateY(4px)",
          marginRight: theme.space.sm,
        }}
      >
        <Spinner size={20} />
      </span>
      <span>{children}</span>
    </span>
  );
}
