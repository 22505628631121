import type { IndustryId, Review, ReviewType, TagId } from "@gemini/common";
import {
  assert,
  industrySelectOptions,
  reviewTypes,
  tags,
} from "@gemini/common";
import Link from "next/link";
import React from "react";
import { css } from "styled-components";
import {
  AdminFieldWrapper,
  Button,
  CheckboxWithLabel,
  Field,
  InlineSelect,
  Input,
  Paragraph,
  Select,
  SpacedChildren,
  TextArea,
} from "~/components";
import { useAuth } from "~/modules/auth";
import {
  useFormBooleanValue,
  useFormValue,
  useFormValueArray,
  useIsMountedRef,
} from "~/modules/hooks";
import { notify } from "~/modules/notifications";
import { useAppStore } from "~/store";
import { colors, fontSizes, fontWeights, preset, space } from "~/theme";
import { useReviewStructureOptions } from "./logic/use-review-structure-options";
import { wrapBackgroundTask } from "~/utils/wrap-background-task";

const tagIdList = Object.keys(tags) as TagId[];

const typeOptions = reviewTypes
  /**
   * Trainings are always created by the training platform via the API, so we
   * exclude it from the options here.
   */
  .filter((x) => x !== "training")
  .map((value) => ({ value, label: value }));

const industryOptions = industrySelectOptions
  .map(([value, label]) => {
    return value === "__no_industry"
      ? { value: undefined, label: "[None selected]" }
      : { value: value as IndustryId, label };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

export type ReviewFormData = {
  title: string;
  description: string;
  industryId: IndustryId;
  tagIds: TagId[];
  type: ReviewType;
  structureId?: string;
  isAssumedRangeHidden?: boolean;
};

export function ReviewForm({
  onSubmit,
  onCancel,
  submitLabel,
  review = {},
  disableOptions,
  disableReviewType,
  deleteableReviewId,
}: {
  onSubmit: (data: ReviewFormData) => Promise<void>;
  onCancel: () => void;
  submitLabel: string;
  review?: Partial<Review>;
  disableOptions?: boolean;
  disableReviewType?: boolean;
  deleteableReviewId?: string;
}) {
  const { claims } = useAuth();
  const { currentReviewStructures } = useAppStore();

  const [isSaving, setIsSaving] = React.useState(false);
  const [error, setError] = React.useState<Error | undefined>();
  const isMountedRef = useIsMountedRef();

  const typeField = useFormValue<ReviewType | undefined>(
    review.type ?? (claims.isAdmin ? undefined : "self-review")
  );

  const reviewStructureField = useFormValue(review.structure_id);

  const reviewStructureGroupField = useFormValue<string | undefined>(undefined); // maybe choose different default

  const { structureOptions, groupOptions, defaultStructureId } =
    useReviewStructureOptions({
      accountId: claims.accountId,
      structures: currentReviewStructures,
      selectedStructureId: reviewStructureField.value,
      selectedGroup: reviewStructureGroupField.value,
    });

  const titleField = useFormValue(review.title ?? "");
  const isAssumedRangeHiddenField = useFormBooleanValue(
    review.chart_options?.is_assumed_range_hidden ?? false
  );
  const descriptionField = useFormValue(review.description || "");
  const industryField = useFormValue(
    noIndustryToEmptyValue(review.industry_id),
    {
      disabled: disableOptions,
    }
  );
  const tagIdFields = useFormValueArray(review.tag_ids ?? [], tagIdList, {
    disabled: disableOptions,
  });

  return (
    <form
      data-t="review-form"
      onSubmit={(evt) => wrapBackgroundTask(handleSubmit(evt))}
    >
      <SpacedChildren size="lg">
        <Paragraph cx={preset.typography.body2}>
          Note: all Baymard Premium users at <em>{claims.accountName}</em> can
          access and edit your review.
        </Paragraph>

        <Field label="Title" required>
          <Input
            {...titleField.inputProps}
            required
            placeholder="e.g. ABC Widget 2020"
            testingName="review-form-title"
          />
        </Field>

        {claims.isAdmin && !disableReviewType && (
          <AdminFieldWrapper>
            <Field label="Type">
              <Select
                {...typeField.inputProps}
                required
                placeholder="Choose a review type"
                options={typeOptions}
                testingName="review-form-type"
              />
            </Field>
          </AdminFieldWrapper>
        )}

        <Field
          as="div"
          label="What do you want to review?"
          description="By default you will review the 700+ guidelines used in the benchmark. If you&rsquo;re only interested in a subset of guidelines, click &lsquo;edit&rsquo; to choose another guideline collection."
        >
          <SpacedChildren>
            {claims.isAdmin && (
              <InlineSelect
                {...reviewStructureGroupField.inputProps}
                placeholder="Choose a structure group"
                options={groupOptions}
                testingName="review-form-structure-group-select"
                closeOnSelect
              />
            )}
            <InlineSelect
              {...reviewStructureField.inputProps}
              placeholder="Choose a review structure"
              options={structureOptions}
              testingName="review-form-structure-select"
              /** Keep original behavior for normal users */
              closeOnSelect={claims.isAdmin}
              value={reviewStructureField.value ?? defaultStructureId}
            />
          </SpacedChildren>
        </Field>

        {claims.isAdmin && (
          <AdminFieldWrapper>
            <Field label="“Assumed Range” Visualizations">
              <CheckboxWithLabel {...isAssumedRangeHiddenField.inputProps}>
                Hide the “assumed range” visualizations
              </CheckboxWithLabel>
            </Field>
          </AdminFieldWrapper>
        )}

        <Field
          label="Notes"
          labelExtra="(optional)"
          description={
            <React.Fragment>
              Will be displayed at the top of the site review page
            </React.Fragment>
          }
        >
          <TextArea
            {...descriptionField.inputProps}
            placeholder="e.g. This review includes our new checkout prototype"
            testingName="review-form-description"
          />
        </Field>

        {disableOptions ? (
          <Paragraph cx={preset.typography.body2}>
            Industry and site characteristics cannot be changed.
          </Paragraph>
        ) : (
          <React.Fragment>
            <Field
              label="Industry"
              labelExtra="(optional but strongly recommended)"
              description={
                <React.Fragment>
                  Your industry-selection will tailor the review options and
                  automatically exclude irrelevant guidelines. This cannot be
                  changed later.
                </React.Fragment>
              }
            >
              <Select
                {...industryField.inputProps}
                options={industryOptions}
                testingName="review-form-industry"
              />
            </Field>

            <Field
              as="div"
              label="Site Characteristics"
              labelExtra="(optional)"
              description={
                <React.Fragment>
                  Your selections will tailor the review options and
                  automatically exclude irrelevant guidelines. This cannot be
                  changed later.
                </React.Fragment>
              }
            >
              <SpacedChildren>
                {tagIdFields.inputs.map((tag) => (
                  <CheckboxWithLabel
                    {...tag}
                    key={tag.value}
                    testingName={`review-form-tag-${tag.value}`}
                  >
                    <strong
                      css={css`
                        font-weight: ${fontWeights.semibold};
                        display: block;
                        margin-bottom: ${space.xs};
                      `}
                    >
                      {tags[tag.value].name}
                    </strong>
                    <span
                      css={css`
                        font-size: ${fontSizes.sm};
                      `}
                    >
                      {tags[tag.value].description}
                    </span>
                  </CheckboxWithLabel>
                ))}
              </SpacedChildren>
            </Field>
          </React.Fragment>
        )}

        {deleteableReviewId && (
          <div>
            <Paragraph cx={preset.typography.body2}>
              If you no longer want this review, you can{" "}
              <Link
                href={`/reviews/${deleteableReviewId}/delete`}
                data-t="delete-link"
                css={css`
                  ${preset.typography.body2};
                  color: ${colors.danger};
                `}
              >
                delete
              </Link>{" "}
              it.
            </Paragraph>
          </div>
        )}

        <SpacedChildren
          horizontal
          css={css`
            border-top: 1px solid ${colors.gray};
            display: flex;
            justify-content: flex-end;
            padding: ${space.lg} 0;
          `}
        >
          <Button
            type="button"
            variant="secondary"
            onClick={() => onCancel()}
            disabled={isSaving}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            loading={isSaving}
            testingName="review-form-submit"
          >
            {submitLabel}
          </Button>
        </SpacedChildren>
      </SpacedChildren>
    </form>
  );

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    setError(undefined);
    setIsSaving(true);

    try {
      assert(typeField.value, 'The "Type"-field is required');
      assert(titleField.value.trim(), 'The "title"-field is required');

      await onSubmit({
        type: typeField.value,
        title: titleField.value.trim(),
        description: descriptionField.value.trim(),
        industryId: emptyValueToNoIndustry(industryField.value),
        tagIds: tagIdFields.value,
        structureId: reviewStructureField.value,
        isAssumedRangeHidden: isAssumedRangeHiddenField.value,
      });
    } catch (err) {
      notify.error(err);
      isMountedRef.current && setError(error);
    } finally {
      isMountedRef.current && setIsSaving(false);
    }
  }
}

// Because an IndustryId is never empty (none === '__no_industry') we need
// to map the existing value to an empty string for a correct
// representation in the UI (rendering as an "empty" select-input)
function noIndustryToEmptyValue(
  industryId: IndustryId | undefined
): IndustryId | undefined {
  if (!industryId || industryId === "__no_industry") {
    return undefined;
  } else {
    return industryId;
  }
}

function emptyValueToNoIndustry(value: IndustryId | undefined): IndustryId {
  if (value === undefined) {
    return "__no_industry";
  } else {
    return value;
  }
}
