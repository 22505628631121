import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { preset } from "~/theme";

type GuidelineTitleProps = {
  title: string;
  pathname?: string;
};

export const GuidelineTitle = observer(
  ({ title, pathname }: GuidelineTitleProps) => {
    return pathname ? (
      <StyledGuidelineTitle href={pathname}>{title}</StyledGuidelineTitle>
    ) : (
      /**
       * @todo It would probably be good to have additional styling here to
       *   indicate that this guideline has no link / is foreign to the current
       *   structure. But in the near future we might not show these guidelines
       *   at all so maybe wait until we know we need it.
       */
      <StyledGuidelineTitle as="span">{title}</StyledGuidelineTitle>
    );
  }
);

export const StyledGuidelineTitle = styled(Link)`
  ${preset.typography.subtitle2};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
