import type { IndustryId, TagId } from "@gemini/common";
import { useRouter } from "next/router";

export function useAssessmentQueryParameters() {
  const { query } = useRouter();
  const reference = query.reference as string;
  const tagIds = (query.tagIds || []) as TagId[];
  const industryId: IndustryId = (query.industryId ||
    "__no_industry") as IndustryId;

  return {
    reference,
    industryId,
    tagIds,
  };
}
