import { cleanObject } from "@gemini/common";
import { createReview } from "~/modules/api";
import { useAuth } from "~/modules/auth";
import type { ReviewFormData } from "./review-form";
import { ReviewForm } from "./review-form";

export function CreateReviewForm({
  onFinish,
  onCancel,
}: {
  onFinish: (reviewId: string) => void;
  onCancel: () => void;
}) {
  const { claims } = useAuth();

  return (
    <ReviewForm
      submitLabel="Create Review"
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );

  async function handleSubmit(data: ReviewFormData) {
    const response = await createReview({
      /**
       * Sending undefined properties in a payload to https callable functions
       * results in them being received as null. The structureId can be
       * undefined here.
       */
      ...cleanObject(data),
      accountId: claims.accountId,
    });
    onFinish(response.data.reviewId);
  }
}
