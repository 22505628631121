import { assert } from "@gemini/common";
import React from "react";
import { authContext } from "./context";

export function useAuth() {
  const auth = React.useContext(authContext);
  assert(auth, "Missing AuthProvider in component tree");

  return auth;
}

export const useUserId = () => {
  const { user } = useAuth();
  assert(
    user,
    "The useUserId hook can only be used on components in auth protected routes"
  );

  return user.uid;
};
