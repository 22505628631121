import type { DerivedReview } from "@gemini/common";
import moment from "moment";
import Link from "next/link";
import React from "react";
import Moment from "react-moment";
import styled, { css } from "styled-components";
import {
  RenderOnceInViewport,
  StyledTable,
  Table,
  tableHeadStyle,
  Text,
  Username,
} from "~/components";
import type { Document } from "~/modules/firestore";
import { useBreakpoints } from "~/modules/hooks";
import { colors, fontSizes, fontWeights, preset, space } from "~/theme";
import { NextReviewAction } from "./next-review-action";

type ReviewListProps<T> = {
  reviews: Document<T>[];
  showUsernames?: boolean;
};

export function ReviewList<T extends DerivedReview>({
  reviews,
  showUsernames,
}: ReviewListProps<T>) {
  const breakpoints = useBreakpoints();

  return breakpoints.md ? (
    <ReviewWideList reviews={reviews} showUsernames={showUsernames} />
  ) : (
    <ReviewNarrowList reviews={reviews} showUsernames={showUsernames} />
  );
}

function ReviewWideList<T extends DerivedReview>({
  reviews,
  showUsernames,
}: ReviewListProps<T>) {
  return (
    <Table
      data={reviews}
      getKey={(review) => review.id}
      columns={[
        {
          name: "Review title",
          size: "27%",
          render(review) {
            return (
              <Link
                href={`/reviews/${review.id}`}
                css={css`
                  font-weight: ${fontWeights.bold};
                  text-decoration: none;
                  &:hover {
                    text-decoration: underline;
                  }
                `}
              >
                {review.data.title}
              </Link>
            );
          },
        },
        {
          name: "Created",
          size: "23%",
          render(review) {
            return (
              <span
                css={{ fontSize: fontSizes.sm }}
                title={moment(review.data.created_at.toDate()).format(
                  "Do MMMM YYYY HH:mm"
                )}
              >
                <Moment
                  date={review.data.created_at.toDate()}
                  format="MMM D, YYYY"
                />
                {showUsernames && (
                  <React.Fragment>
                    {" | "}
                    <Username id={review.data.created_by} />
                  </React.Fragment>
                )}
              </span>
            );
          },
        },
        {
          name: "Last edited",
          size: "13%",
          render(review) {
            return review.data.modified_at.isEqual(review.data.created_at) ? (
              <Text size="small">&ndash;</Text>
            ) : (
              <span
                css={{ fontSize: fontSizes.sm }}
                title={moment(review.data.modified_at.toDate()).format(
                  "Do MMMM YYYY HH:mm"
                )}
              >
                <Moment
                  date={review.data.modified_at.toDate()}
                  format="MMM D, YYYY"
                />
              </span>
            );
          },
        },
        {
          name: "Progress",
          size: "15%",
          render(review) {
            return (
              <span css={{ fontSize: fontSizes.sm }}>
                {[
                  review.data.progress.completed_count,
                  review.data.progress.total_count,
                ].join("/")}{" "}
                topics rated
              </span>
            );
          },
        },
        {
          name: "Shortcut",
          size: "21%",
          render(review) {
            return (
              <RenderOnceInViewport>
                <NextReviewAction review={review} asLink />
              </RenderOnceInViewport>
            );
          },
        },
      ]}
    />
  );
}

function ReviewNarrowList<T extends DerivedReview>({
  reviews,
  showUsernames,
}: ReviewListProps<T>) {
  return (
    <ol>
      {reviews.map((review) => (
        <ReviewListItem key={review.id}>
          <Link
            href={`/reviews/${review.id}`}
            css={css`
              ${preset.typography.h5};
              color: ${colors.charcoal};
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            {review.data.title}
          </Link>
          <ReviewDetailsTable
            rows={[
              {
                title: "Created at",
                value: (
                  <Text
                    size="small"
                    title={moment(review.data.created_at.toDate()).format(
                      "Do MMMM YYYY HH:mm"
                    )}
                  >
                    <Moment
                      date={review.data.created_at.toDate()}
                      format="MMM D, YYYY"
                    />
                    {showUsernames && (
                      <React.Fragment>
                        {" | "}
                        <Username id={review.data.created_by} />
                      </React.Fragment>
                    )}
                  </Text>
                ),
              },
              {
                title: "Last edited",
                value: review.data.modified_at.isEqual(
                  review.data.created_at
                ) ? (
                  <Text size="small">&ndash;</Text>
                ) : (
                  <Text
                    size="small"
                    title={moment(review.data.modified_at.toDate()).format(
                      "Do MMMM YYYY HH:mm"
                    )}
                  >
                    <Moment
                      date={review.data.modified_at.toDate()}
                      format="MMM D, YYYY"
                    />
                  </Text>
                ),
              },
              {
                title: "Progress",
                value: (
                  <Text size="small">
                    {[
                      review.data.progress.completed_count,
                      review.data.progress.total_count,
                    ].join("/")}{" "}
                    topics rated
                  </Text>
                ),
              },

              {
                title: "Shortcut",
                value: (
                  <RenderOnceInViewport>
                    <NextReviewAction review={review} asLink />
                  </RenderOnceInViewport>
                ),
              },
            ]}
          />
        </ReviewListItem>
      ))}
    </ol>
  );
}

function ReviewDetailsTable({
  rows,
}: {
  rows: { title: string; value: React.ReactNode }[];
}) {
  return (
    <StyledTable>
      <tbody>
        {rows.map(({ title, value }, i) => (
          <tr key={title + i} css={{ marginBottom: space.sm }}>
            <td
              css={css`
                ${tableHeadStyle};
                width: 100px;
              `}
            >
              {title}
            </td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
}

const ReviewListItem = styled.li`
  border-top: 1px solid ${colors.ghostGray};
  padding: ${space.md} 0;
  :last-child {
    padding-bottom: 0;
  }
`;
