import { DeleteOutlined } from "@ant-design/icons";
import type { GuidelineRevision } from "@gemini/common";
import { Button } from "antd";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import React from "react";
import { LoadingIndicator, RelativeTime, Username } from "~/components";
import { revertToVersion } from "~/modules/api";
import { db } from "~/modules/firebase";
import type { Document } from "~/modules/firestore";
import { useTypedCollection } from "~/modules/firestore";
import { useIsMountedRef } from "~/modules/hooks/use-is-mounted";
import { notify } from "~/modules/notifications";
import { Comment } from "./comment";
import { SectionTitle } from "./section-title";

type RevisionHistoryProps = {
  guidelineReference: string;
  allowRevert: boolean;
  onNewDocument: (documentId: string) => void;
};

export const RevisionHistory: React.FC<RevisionHistoryProps> = (props) => {
  const [revisions, isLoading] = useTypedCollection<GuidelineRevision>(
    query(
      collection(db, "revisions"),
      where("guideline_reference", "==", props.guidelineReference),
      where("is_reverted", "==", false),
      orderBy("created_at", "desc"),
      limit(10)
    )
  );

  const isMounted = useIsMountedRef();

  if (isLoading) return <LoadingIndicator />;
  if (!revisions) return <div>no data</div>;

  function renderVersion(revision: GuidelineRevision) {
    return (
      <>
        <pre
          css={`
            display: inline;
            margin-right: 0.5em;
          `}
        >
          {revision.version}
        </pre>
        <span
          css={`
            color: #888;
            margin-right: 0.5em;
            font-style: italic;
          `}
        >
          <RelativeTime date={revision.created_at.toDate()} />
          {" | "}
          <Username id={revision.created_by} />
        </span>
      </>
    );
  }

  function renderListItem(doc: Document<GuidelineRevision>) {
    return <li key={doc.id}>{renderVersion(doc.data)}</li>;
  }

  const currentRevision = revisions.shift();
  const previousRevision = revisions[0] as
    | Document<GuidelineRevision>
    | undefined;

  const listItems = revisions.map((doc) => renderListItem(doc));

  return (
    <>
      {currentRevision && previousRevision && (
        <>
          <SectionTitle noTopMargin>This Version</SectionTitle>
          {renderVersion(currentRevision.data)}{" "}
          <Button
            danger
            icon={<DeleteOutlined />}
            ghost
            size="small"
            onClick={() => {
              revertToVersion({
                fromRevisionId: currentRevision.id,
                // Button is disabled if no previousRevision exists
                toRevisionId: previousRevision.id,
              })
                .then((result) => {
                  if (isMounted.current) {
                    props.onNewDocument(result.data.guidelineId);
                  }
                })
                .catch((err) => notify.error(err));
            }}
            disabled={!props.allowRevert || !previousRevision}
          >
            Revert
          </Button>
          {props.allowRevert ? (
            <Comment>
              Revert deletes the current version and makes the previous revision
              current again. This can not be undone!
            </Comment>
          ) : (
            <Comment>
              Revert is disabled for published guidelines. You will have to
              un-publish them first.
            </Comment>
          )}
        </>
      )}
      <SectionTitle>Previous Versions</SectionTitle>
      <ol
        css={`
          list-style-type: none;
          padding: 0;
        `}
      >
        {listItems}
      </ol>
    </>
  );
};
