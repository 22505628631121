import { observer } from "mobx-react-lite";
import { AsideBox } from "~/components";
import type { useAssessmentAsideData } from "../hooks/use-assessment-aside-data";
import { AssessmentSummary } from "./assessment-summary";

type CounterpartAssessmentSummaryProps = {
  assessmentAsideData: ReturnType<typeof useAssessmentAsideData>;
  showJudgement: boolean;
  contentOnly?: boolean;
};

export const CounterpartAssessmentSummary = observer(
  ({
    assessmentAsideData,
    showJudgement,
    contentOnly,
  }: CounterpartAssessmentSummaryProps) => {
    if (!assessmentAsideData) return null;

    const { assessment, scenarios, title, titleTooltipContent } =
      assessmentAsideData;

    const content = (
      <AssessmentSummary
        title={title}
        titleTooltipContent={titleTooltipContent}
        assessment={assessment.data}
        scenarios={scenarios}
        showJudgement={showJudgement}
      />
    );

    if (contentOnly) {
      return content;
    }

    return <AsideBox>{content}</AsideBox>;
  }
);
