import type { IndustryId, Platform, Scenario, TagId } from "@gemini/common";
import { getConditionalInstructionTexts } from "@gemini/common";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import {
  AdaptiveCarouselContextProvider,
  MarkdownText,
  SpacedChildren,
  useAdaptiveCarouselEventHandlers,
} from "~/components";
import { colors, fontSizes, fontWeights } from "~/theme";
import { Title } from "./typography";

type ScenarioInstructionTextProps = {
  scenario: Scenario;
  industryId: IndustryId;
  tagIds: TagId[];
  title?: string;
};

export function getPlatformText(platform: Platform) {
  switch (platform) {
    case "app":
      return "app";
    case "desktop":
      return "desktop website";
    case "mobile":
      return "mobile website";
    default:
      throw new Error(`Unknown platform ${platform}`);
  }
}

export const ScenarioInstructionText = observer(
  ({ scenario, industryId, tagIds, title }: ScenarioInstructionTextProps) => {
    return (
      <SpacedChildren size="sm" style={{ fontSize: fontSizes.mdLg }}>
        {title && (
          <Title level={3} variant="h4">
            {title}
          </Title>
        )}

        <AdaptiveCarouselContextProvider>
          {scenario.description && (
            <MarkdownText>{scenario.description}</MarkdownText>
          )}
          {scenario.instruction_text && (
            <MarkdownText>{scenario.instruction_text}</MarkdownText>
          )}
          {(
            getConditionalInstructionTexts(scenario, industryId, tagIds) || []
          ).map((text, index) => (
            <MarkdownText key={index}>{text}</MarkdownText>
          ))}

          {scenario.instruction_video_url && (
            <InstructionVideoLink url={scenario.instruction_video_url} />
          )}
        </AdaptiveCarouselContextProvider>
      </SpacedChildren>
    );
  }
);

function InstructionVideoLink({ url }: { url: string }) {
  const carouselProps = useAdaptiveCarouselEventHandlers(url);
  const videoLink = (
    <StyledLink href={url} {...carouselProps}>
      video
    </StyledLink>
  );

  return <>(see {videoLink} instruction)</>;
}

const StyledLink = styled(Link)`
  font-weight: ${fontWeights.semibold};
  color: ${colors.slateGray};
`;
