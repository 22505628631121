import type { FirestoreTimestamp } from "@gemini/common";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import type { DocumentReference, LogLevel } from "firebase/firestore";
import {
  serverTimestamp as _serverTimestamp,
  doc,
  getFirestore,
  initializeFirestore,
  setLogLevel,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import type { Maintenance } from "~/components";
import { firebaseConfig, isProduction } from "~/config";

export const firebaseApp = initializeApp(firebaseConfig);

export const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
  experimentalAutoDetectLongPolling: true,
});

export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export const functions = isProduction
  ? getFunctions(firebaseApp, "us-east4")
  : getFunctions(firebaseApp, "europe-west2");

export type {
  User as FirebaseUser,
  UserInfo as FirebaseUserInfo,
} from "firebase/auth";
export { Timestamp } from "firebase/firestore";
export type { FieldValue, Firestore } from "firebase/firestore";

export const serverTimestamp = () => _serverTimestamp() as FirestoreTimestamp;

/**
 * We need a secondary firebase app because the default app will be put into
 * offline mode when the maintenance lock is activated.
 */
const maintenanceFirebaseApp = initializeApp(firebaseConfig, "maintenance");
const maintenanceFirestore = getFirestore(maintenanceFirebaseApp);

/** By exporting just the ref we can keep the rest of maintenance private */
export const maintenanceDocumentRef = doc(
  maintenanceFirestore,
  "__system",
  "maintenance"
) as DocumentReference<Maintenance>;

/** Optionally set firebase log level stored in localStorage */
try {
  const logLevel = window.localStorage.getItem("gemini-firebase-log-level");

  if (logLevel && isLogLevel(logLevel)) {
    log(`Setting firebase log level to:`, logLevel);
    setLogLevel(logLevel);
  }
} catch (err) {}

function isLogLevel(level: string): level is LogLevel {
  return ["debug", "verbose", "info", "warn", "error", "silent"].includes(
    level
  );
}

function log(msg: string, ...args: unknown[]) {
  console.log(`%c[gemini]: ${msg}`, `color: #4D7CC7`, ...args);
}
