import { isDeepEqual } from "remeda";
import type { Assessment } from "~/schemas";
import type { DetectedAssessmentChange } from "./types";

type AssessmentDataForComparison = Pick<
  Assessment,
  "selected_scenario_ids" | "judgement" | "judgement_nudge_value"
>;

/**
 * For each of the comparison assessments, compare it to the subject assessment
 * and return the change type or undefined if no change was detected.
 */
export function detectChange(
  subjectAssessment: AssessmentDataForComparison,
  comparisonAssessment: AssessmentDataForComparison | undefined
): DetectedAssessmentChange | undefined {
  if (!comparisonAssessment) {
    return "addition";
  }

  if (
    !isDeepEqual(
      subjectAssessment.selected_scenario_ids.toSorted(),
      comparisonAssessment.selected_scenario_ids.toSorted()
    )
  ) {
    return "selection";
  }

  if (comparisonAssessment.judgement !== subjectAssessment.judgement) {
    return "judgement";
  }

  if (
    comparisonAssessment.judgement_nudge_value !==
    subjectAssessment.judgement_nudge_value
  ) {
    return "nudge";
  }

  return;
}
