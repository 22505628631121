import type {
  IndustryId,
  Scenario,
  ScenarioIdsByParent,
  TagId,
  ValidationError,
} from "@gemini/common";
import { assert } from "@gemini/common";
import React from "react";

export type BranchContextStateProps = {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

export type BranchContextProps = {
  industryId: IndustryId;
  tagIds: TagId[];
  scenarios: Record<string, Scenario>;
  onSelectionChange: (parentId: string, values?: string[]) => void;
  selectedIdsMap: ScenarioIdsByParent;
  isDisabled: boolean;
  validationErrors?: ValidationError[];

  /**
   * As a shortcut read-only mode will render the entire tree in an expanded
   * state, because otherwise we'd have to implement a way to expand branches
   * without being able to select scenarios.
   */
  readOnly: boolean;
};

const branchContext = React.createContext<
  (BranchContextProps & BranchContextStateProps) | undefined
>(undefined);

export function useBranchContext() {
  const context = React.useContext(branchContext);
  assert(context, "Missing BranchContextProvider in component tree");

  return context;
}

export function BranchContextProvider({
  value,
  children,
}: {
  value: BranchContextProps;
  children: React.ReactNode;
}) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  React.useEffect(() => {
    /** Expand the branch when it's read-only */
    if (value.readOnly) {
      setIsExpanded(true);
      return;
    }

    /** Disable expand when new scenario's are rendered */
    setIsExpanded(false);
  }, [value.scenarios, value.readOnly]);

  return (
    <branchContext.Provider value={{ ...value, isExpanded, setIsExpanded }}>
      {children}
    </branchContext.Provider>
  );
}
