import { AnimatePresence } from "framer-motion";
import React from "react";
import { css } from "styled-components";
import { space, zIndices } from "~/theme";
import { IconButton } from "../icon";
import { ModalBare } from "../modal";
import { MediaCarouselSlide } from "./media-carousel-slide";
import type { CarouselNavigation } from "./use-carousel-navigation";

export function MediaCarouselModal({
  src,
  navigation,
}: {
  src: string | undefined;
  navigation: CarouselNavigation;
}) {
  return (
    <ModalBare isOpen={!!src} onClose={navigation.hide}>
      <AnimatePresence initial={false} custom={navigation.direction}>
        {src && (
          <MediaCarouselSlide
            key={navigation.page}
            src={src}
            direction={navigation.direction}
            onSwipeLeft={navigation.next}
            onSwipeRight={navigation.prev}
          />
        )}
      </AnimatePresence>

      {navigation.hasMultipleItems && (
        <React.Fragment>
          <ChevronButton
            direction="right"
            onClick={(evt) => {
              evt.stopPropagation();
              navigation.next();
            }}
          />
          <ChevronButton
            direction="left"
            onClick={(evt) => {
              evt.stopPropagation();
              navigation.prev();
            }}
          />
        </React.Fragment>
      )}
    </ModalBare>
  );
}

function ChevronButton({
  onClick,
  direction,
}: {
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  direction: "left" | "right";
}) {
  return (
    <IconButton
      onClick={onClick}
      size={40}
      icon={direction === "left" ? "chevronLeft" : "chevronRight"}
      cx={css`
        position: absolute;
        z-index: ${zIndices.modal + 10};
        top: 50%;
        transform: translateY(-20px);
        color: white;
        border: 1px solid white;
        border-radius: 50%;
        filter: drop-shadow(0 0 0.75rem rgba(11, 35, 60, 0.87));
        ${{
          left: direction === "left" ? space.md : undefined,
          right: direction === "right" ? space.md : undefined,
        }}
      `}
    />
  );
}
