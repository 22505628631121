import { assert } from "@gemini/common";
import type { PerformanceChartProps } from "@gemini/performance-chart";
import "@gemini/performance-chart/index.css";
import type { User } from "firebase/auth";
import dynamic from "next/dynamic";
import { useCallback, useEffect, useState } from "react";
import { auth } from "~/modules/firebase";
import { ErrorBoundary } from "./error-boundary";
import { SelectorObserverTooltip } from "./selector-observer-tooltip";

const PerformanceChartCore = dynamic<PerformanceChartProps>(async () => {
  return (await import("@gemini/performance-chart")).PerformanceChartCore;
});

/** Chart core wrapped in tooltip observer */
export function PerformanceChart({
  chartProps,
  renderGuidelineLink,
}: {
  chartProps: PerformanceChartProps;
  isEmbeddedInPart?: boolean;
  renderGuidelineLink?: (
    guidelineReference: string,
    title: string
  ) => JSX.Element;
}) {
  const getFirebaseIdToken = useGetFirebaseIdToken();

  /**
   * Users in the review tool should always be authenticated when rendering a
   * chart. `getFirebaseIdToken` is undefined if the user is not authenticated.
   */
  if (!getFirebaseIdToken) return null;

  return (
    <SelectorObserverTooltip selector=".js-tippy-iqy8ha">
      <ErrorBoundary>
        <PerformanceChartCore
          {...chartProps}
          componentEm="2rem"
          getFirebaseIdToken={getFirebaseIdToken}
          renderGuidelineLink={renderGuidelineLink}
        />
      </ErrorBoundary>
    </SelectorObserverTooltip>
  );
}

function useGetFirebaseIdToken() {
  const [user, setUser] = useState<User | null>(null);
  useEffect(() => auth.onAuthStateChanged((user) => setUser(user)), []);

  const getFirebaseIdToken = useCallback(async () => {
    assert(user, "User is not authenticated");
    /**
     * User.getIdToken() returns the current token if it has not expired or if
     * it will not expire in the next five minutes. Otherwise, this will refresh
     * the token and return a new one.
     */
    return user.getIdToken();
  }, [user]);

  return user ? getFirebaseIdToken : undefined;
}
