import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import styled from "styled-components";
import { Button, List, Sidebar, Text } from "~/components";
import { PartProgress } from "~/features/assessment/components";
import { JumpToGuideline } from "~/features/reviews/components";
import { mediaQueries, space } from "~/theme";
import { useReviewStore } from "../../store";
import { usePartStore } from "../store";

export const SidebarUnfinished = observer(() => {
  const { review } = useReviewStore();
  const router = useRouter();
  const store = usePartStore();

  if (store.isLoading) return <PartsContainer />;

  const { progress, entryGuidelineLink } = store;
  const reviewId = router.query.reviewId as string;

  return (
    <PartsContainer>
      <Sidebar.Part area="guidelines">
        <Sidebar.Metric name="Guidelines">
          {progress.guidelineCount}
        </Sidebar.Metric>
      </Sidebar.Part>

      <Sidebar.Part area="part-progress">
        <PartProgress percentage={progress.percentage} />
      </Sidebar.Part>

      {!review.data?.is_read_only && (
        <Sidebar.Part area="button">
          <Button fullWidth href={entryGuidelineLink}>
            {progress.percentage === 0 ? "Start topic" : "Continue topic"}
          </Button>
        </Sidebar.Part>
      )}

      <Sidebar.Part area="overview-link">
        <Sidebar.Anchor href={`/reviews/${reviewId}`}>
          See all topics
        </Sidebar.Anchor>

        <br />
        <JumpToGuideline>
          {(onClick) => (
            <Sidebar.AnchorButton onClick={onClick}>
              Jump to guideline or topic&hellip;
            </Sidebar.AnchorButton>
          )}
        </JumpToGuideline>
      </Sidebar.Part>

      <Sidebar.Separator area="separator" />

      <Sidebar.TextPart area="incentive" title="Assess this topic to:">
        <div css={{ color: "white" }}>
          <List noMargin>
            <li>
              <Text size="small">Identify and document UX issues</Text>
            </li>
            <li>
              <Text size="small">Get instructions for how to improve</Text>
            </li>
            <li>
              <Text size="small">Get a topic-level UX scorecard</Text>
            </li>
          </List>
        </div>
      </Sidebar.TextPart>
    </PartsContainer>
  );
});

const PartsContainer = styled.div`
  display: grid;
  grid-template-areas:
    "guidelines"
    "part-progress"
    "button"
    "overview-link"
    "separator"
    "incentive";

  grid-column-gap: ${space.lg};
  grid-row-gap: ${space.md};

  @media ${mediaQueries.sm} {
    grid-template-columns: 1fr 1fr 1px 2fr;
    grid-template-areas:
      "guidelines part-progress separator incentive"
      "button     overview-link separator incentive";
  }

  @media ${mediaQueries.md} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "guidelines"
      "part-progress"
      "button"
      "overview-link"
      "separator"
      "incentive";
  }
`;
