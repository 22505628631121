import React from "react";
import type { CSSProp } from "styled-components";
import styled, { css } from "styled-components";
import { space } from "~/theme";

export type ListProps = {
  children?: React.ReactNode;
  ordered?: boolean;
  noMargin?: boolean;
  cx?: CSSProp;
};

export function List({ children, ordered, ...props }: ListProps) {
  return ordered ? (
    <StyledListOrdered {...props}>{children}</StyledListOrdered>
  ) : (
    <StyledList {...props}>{children}</StyledList>
  );
}

const listStyles = ({ noMargin }: ListProps) => css`
  padding-left: ${space.md};
  margin-left: 1px;
  margin-bottom: ${noMargin ? 0 : space.md};
`;

type StyledListProps = Pick<ListProps, "noMargin" | "cx">;

const StyledList = styled.ul<StyledListProps>`
  ${listStyles};
  list-style: disc;
  ${(x) => x.cx};
`;

const StyledListOrdered = styled.ol<StyledListProps>`
  ${listStyles};
  list-style: decimal;
  ${(x) => x.cx};
`;
