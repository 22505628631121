import { getDownloadURL, ref } from "firebase/storage";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Text } from "~/components";
import { storage } from "~/modules/firebase";
import { theme } from "~/theme";

type FileUploadLink = {
  name: string;
  path?: string;
  isUploading?: boolean;
  isFlaggedForDeletion?: boolean;
  onRemove?: () => void;
};

export const FileUploadLink = observer(
  ({
    name,
    path,
    isUploading,
    isFlaggedForDeletion,
    onRemove,
  }: FileUploadLink) => {
    const [isHovering, setIsHovering] = useState(false);
    const url = useStorageDownloadUrl(isUploading ? undefined : path);

    if (isUploading) {
      return (
        <Text cx={css({ color: theme.colors.grayMedium })}>
          {name}&nbsp;&nbsp;
          <SmallText>
            {isUploading ? "uploading" : "deleting"}&hellip;
          </SmallText>
        </Text>
      );
    }

    return (
      <span
        onMouseEnter={() => setIsHovering(true)}
        onMouseMove={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        css={css({
          position: "relative",
          display: "inline-block",
          paddingRight: 30,
        })}
      >
        {isFlaggedForDeletion ? (
          <StyledLink as="span" css={{ textDecoration: "line-through" }}>
            {name}
          </StyledLink>
        ) : url ? (
          <StyledLink href={url} download={name}>
            {name}
          </StyledLink>
        ) : (
          <StyledLink as="span">{name}</StyledLink>
        )}

        {onRemove && (
          <span style={{ opacity: isHovering ? 1 : 0 }}>
            &nbsp;&nbsp;
            <TextButton onClick={onRemove}>
              {isFlaggedForDeletion ? "undo delete file" : "delete file"}
            </TextButton>
          </span>
        )}
      </span>
    );
  }
);

const StyledLink = styled(Link)`
  text-decoration: none;
  &[href]:hover {
    text-decoration: underline;
  }
`;

function SmallText({ children }: { children: ReactNode }) {
  return (
    <span
      css={css`
        font-size: ${theme.fontSizes.xs};
      `}
    >
      {children}
    </span>
  );
}

const TextButton = styled.button<{ color?: string }>`
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  color: currentColor;
  &:hover {
    text-decoration: underline;
  }
`;

function useStorageDownloadUrl(path?: string) {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (!path) return setUrl(undefined);

    let isCancelled = false;

    const storageRef = ref(storage, path);

    getDownloadURL(storageRef)
      .then((x) => !isCancelled && setUrl(x))
      .catch(() => {
        /**
         * Unable to get download url, this is not a critical error so catch it
         * and do nothing.
         */
      });

    return () => {
      isCancelled = true;
    };
  }, [path]);

  return url;
}
