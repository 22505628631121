import React from "react";
import styled from "styled-components";
import { createVimeoUrl, getVimeoId } from "~/modules/vimeo";
import { AspectRatio } from "./aspect-ratio";

type VimeoPlayerProps = {
  /** Ratio is expressed as width / height */
  ratio: number;
  /** We'll extract the vimeo ID from this string. */
  src: string;
  autoPlay?: boolean;
};

export function VimeoPlayer({ src, ratio, autoPlay }: VimeoPlayerProps) {
  const id = getVimeoId(src);

  if (!id) {
    return null;
  }

  const vimeoUrl = createVimeoUrl(id, { autoPlay });

  return (
    <AspectRatio ratio={ratio}>
      <ResponsiveIframe
        src={vimeoUrl}
        frameBorder="0"
        allowFullScreen
        allow="autoplay; fullscreen"
        css={{ maxHeight: "80vh" }}
      />
    </AspectRatio>
  );
}

const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;
