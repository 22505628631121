import type { PlatformCounterparts } from "@gemini/common";
import { assert } from "@gemini/common";
import { Button, Modal } from "antd";
import { isEmpty, uniq } from "lodash-es";
import Link from "next/link";
import { useState } from "react";
import { CheckboxWithLabel, SpacedChildren } from "~/components";
import { callCloneGuideline } from "~/modules/callable";
import { notify } from "~/modules/notifications";
import { useAppStore } from "~/store";

export function CloneGuidelineForm({
  srcGuidelineId,
  counterpartReferences,
}: {
  srcGuidelineId: string;
  counterpartReferences: PlatformCounterparts;
}) {
  const { currentGuidelines } = useAppStore();

  const [selectedReferences, setSelectedReferences] = useState<string[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsSubmitting(true);
    setIsModalVisible(false);

    Promise.all(
      selectedReferences.map((reference) => {
        const dstGuideline = currentGuidelines[reference];

        if (!dstGuideline) {
          return Promise.reject(
            `Missing current guideline data for reference ${reference}`
          );
        }

        return callCloneGuideline(srcGuidelineId, dstGuideline.id);
      })
    )
      .then(() => {
        notify.success("Clone successful");
        setSelectedReferences([]);
      })
      .catch((err) => notify.error(err))
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const items = Object.entries(counterpartReferences)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([platform, reference]) => {
      const currentGuideline = currentGuidelines[reference];

      assert(
        currentGuideline,
        `Missing current guideline for reference ${reference}`
      );

      return (
        <li key={reference}>
          <CheckboxWithLabel
            name={platform}
            checked={selectedReferences.includes(reference)}
            onChange={(checked) =>
              setSelectedReferences((x) =>
                checked
                  ? uniq([...x, reference])
                  : x.filter((x) => x !== reference)
              )
            }
          >
            <Link href={`/admin/guidelines/${reference}/edit`}>
              {currentGuideline.citation_code}
            </Link>
          </CheckboxWithLabel>
        </li>
      );
    });

  if (isEmpty(items)) {
    return (
      <p>
        There are currently no known counterparts to this guideline. They need
        to exist and be linked to this data before you are allowed to clone
        scenarios.
      </p>
    );
  }

  const cloneCitationCodesString = selectedReferences
    .map((x) => currentGuidelines[x].citation_code)
    .join(", ");

  return (
    <SpacedChildren size="lg">
      <p>
        Clone the current guideline/scenarios to one or more of the counterpart
        guidelines:
      </p>
      <ol css={{ padding: 0, listStyle: "none", lineHeight: "2" }}>{items}</ol>
      <Button
        type="primary"
        onClick={showModal}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        Clone Scenarios
      </Button>
      <Modal
        title={`Clone to ${cloneCitationCodesString}`}
        open={isModalVisible}
        okText="Yes, go ahead"
        cancelText="No"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {`Are you sure you want to clone the current scenarios to guideline(s) ${cloneCitationCodesString}? The resulting clones will get published immediately.`}
      </Modal>
    </SpacedChildren>
  );
}
