import React from "react";
import { getVimeoId } from "~/modules/vimeo";

export function isVimeoUrl(src: string) {
  return !!getVimeoId(src);
}

export function isImageUrl(src: string) {
  return /\.(jpe?g|gif|png|webp)/.test(src);
}

export function useSources() {
  const [sources, setSources] = React.useState<string[]>([]);

  const addSource = React.useCallback((src: string) => {
    setSources((sources) => [...sources, src]);
  }, []);

  const removeSource = React.useCallback(
    (src: string) =>
      setSources((sources) => {
        const indexToRemove = sources.indexOf(src);
        if (indexToRemove > -1) {
          sources.splice(indexToRemove, 1);
          return [...sources];
        } else {
          return sources;
        }
      }),
    []
  );

  return [sources, addSource, removeSource] as const;
}

const imgCache: Record<string, boolean> = {};
export function preloadImage(src: string) {
  if (isImageUrl(src) && !imgCache[src]) {
    imgCache[src] = true;
    new Image().src = src;
  }
}
