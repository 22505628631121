import type { AuthClaims, BaymardAuthClaims } from "@gemini/common";
import { assert } from "@gemini/common";
import * as Sentry from "@sentry/browser";
import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { isDefined } from "ts-is-present";
import { isProduction } from "~/config";
import { signInWithBaymard, storeUserData } from "../api";
import type { FirebaseUser } from "../firebase";
import { auth } from "../firebase";
import { useIsMountedRef } from "../hooks";
import { notify } from "../notifications";
import { identifyUser, optOutCapturing } from "../posthog";
import { getBaymardUserToken, loadScript, redirectToSignIn } from "./helpers";

export type AuthContext = {
  user: FirebaseUser | null;
  claims: AuthClaims;
  signInWithBaymard: typeof signInWithBaymard;
  signOut: typeof auth.signOut;
};

export const authContext = React.createContext<AuthContext | null>(null);

export function AuthProvider({
  children,
  loadingAuthElement,
}: {
  children: React.ReactNode;
  loadingAuthElement?: React.ReactNode;
}) {
  const [user, setUser] = React.useState<FirebaseUser | null>();
  const [claims, setClaims] = useState<AuthClaims>();
  const isMountedRef = useIsMountedRef();

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => setUser(user));

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    if (window.Cypress) {
      /**
       * Cypress signs in to Firebase directly using its own created auth token
       * with service credentials + firebase-admin.
       */
      return;
    }

    if (isDefined(user) && user !== null) {
      /**
       * Only proceed to fetch token if auth is initialized (not undefined) and
       * there is no user available yet.
       */
      return;
    }

    getBaymardUserToken()
      .then((token) => {
        return signInWithCustomToken(auth, token);
      })
      .then(async ({ user }) => {
        assert(user, "signInWithCustomToken did not return user?");
        Sentry.setUser({ id: user.uid });

        /**
         * CallStoreUserData requires its own catch, because we don't want to
         * redirect the user if things go wrong in processing that request.
         */
        storeUserData().catch((err) => notify.error(err));

        const authClaims = await getAuthClaimsForUser(user);
        setClaims(authClaims);

        if (authClaims.isAdmin) {
          optOutCapturing();
        } else if (user.uid) {
          identifyUser(user.uid);
        } else {
          console.error("No user_id claim found in token claims");
          Sentry.captureException(
            new Error("No user_id claim found in token claims")
          );
        }
      })
      .catch(() => {
        /** Not necessary to log errors here, they are not useful */
        isMountedRef.current && redirectToSignIn();
      });
  }, [user, isMountedRef]);

  return claims ? (
    <authContext.Provider
      value={{
        user: user ?? null,
        claims,
        signInWithBaymard,
        signOut: auth.signOut,
      }}
    >
      {children}
      <BaymardEngagementTracker />
    </authContext.Provider>
  ) : (
    loadingAuthElement
  );
}

function BaymardEngagementTracker() {
  useEffect(() => {
    if (isProduction && !window.heartbeatConfig) {
      try {
        window.heartbeatConfig = {
          init: new Date(),
          apiEndpoint: "https://baymard.com/api/heartbeats",
          // Exclude any path except the /reviews paths
          pathExclusionRegex: /^\/reviews\/|^\/reviews$/,
        };
        loadScript(
          "https://baymard.com/heartbeats/v4-min-2d7ba2f604b09321bd412f54be5fdf43.js"
        );
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  return null;
}

async function getAuthClaimsForUser(user: FirebaseUser) {
  const idTokenResult = await user.getIdTokenResult();
  const baymardAuthClaims =
    idTokenResult.claims as unknown as BaymardAuthClaims;

  const authClaims: AuthClaims = {
    accountId: baymardAuthClaims.premium_account_id,
    accountName:
      baymardAuthClaims.account_title ||
      `Account ${baymardAuthClaims.premium_account_id}`,
    name: baymardAuthClaims.name,
    email: baymardAuthClaims.email,
    isSuspended: baymardAuthClaims.is_suspended,
    isAdmin: baymardAuthClaims.is_admin,
    isEditor: baymardAuthClaims.is_admin || baymardAuthClaims.is_editor,
    engagementUserToken: baymardAuthClaims.engagement_user,
    engagementDeviceId: baymardAuthClaims.engagement_device,
  };

  return authClaims;
}
