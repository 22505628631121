import type { JudgementNudgeValue } from "@gemini/common";
import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import React from "react";
import { css } from "styled-components";
import {
  AdaptiveCarouselContextProvider,
  BackLink,
  Button,
  MarkdownText,
  Modal,
  PageContainer,
  Paragraph,
  SpacedChildren,
  Text,
  Title,
} from "~/components";
import { useAuth, useUserId } from "~/modules/auth";
import { notify } from "~/modules/notifications";
import { colors, fontSizes, mediaQueries, space } from "~/theme";
import { usePartStore, useReviewStore } from "../reviews";
import {
  AssessmentHeader,
  Footer,
  JudgementDisplay,
  JudgementSummary,
  NudgeForm,
  WithSummaryPageAside,
} from "./components";
import { AssessmentSidebar } from "./sidebar";
import { useAssessmentStore } from "./store";

export const AssessmentSummaryPage: NextPage = observer(() => {
  const { claims } = useAuth();
  const router = useRouter();
  const { review } = useReviewStore();
  const partStore = usePartStore();
  const assessmentStore = useAssessmentStore();
  const userId = useUserId();

  const reviewId = router.query.reviewId as string;
  const reference = router.query.reference as string;

  assert(review.data, "Cannot render without review");

  const [showNudgeForm, toggleNudgeForm] = React.useState(false);

  const partId = router.query.partId;

  const { is_read_only } = review.data;

  const {
    isSubmitting,
    masterText,
    hasAssessment,
    submittedAssessmentData,
    calculatedTranslationValue,
    pendingFileUploads,
  } = assessmentStore;

  React.useEffect(() => {
    /**
     * A user should not have access to the summary page if there's no
     * assessment
     */
    if (!hasAssessment) {
      const url = is_read_only
        ? `/reviews/${reviewId}/parts/${partId}`
        : `/reviews/${reviewId}/parts/${partId}/assessments/${reference}/assess`;

      router.replace(url).catch((err) => notify.error(err));
    }
  }, [hasAssessment, router, partId, reviewId, reference, is_read_only]);

  const aside = <AssessmentSidebar />;

  const { progress, navigation, guideline, sortedParts } = partStore;

  assert(guideline, "Missing guideline data");

  const { citation_code, sql_id, scenarios } = guideline.data;

  /**
   * The hasAssessment check returns true if there is local assessment data
   * available. This way we don't have to wait for submit to happen.
   */
  if (!hasAssessment || !submittedAssessmentData) {
    return (
      <PageContainer aside={aside} testingName="summary-page">
        {/**
         * @todo Style this a bit better. This occurs when a users lands on a summary
         *   page but there is no assessment data. It should be an edge case but it can
         *   happen.
         */}
        <Title level={2}>No summary data available</Title>
        <Paragraph>
          You are attempting to view the summary of an assessment that does not
          exist (anymore).
        </Paragraph>
      </PageContainer>
    );
  }

  /**
   * The summary page gets its data from the submitted assessment data and not
   * the temporary variables. This is so that we can move to the previous step,
   * change something and hit the backbutton without the real/stored data (which
   * the user should see on the summary page) getting screwed up by the edits.
   */

  const {
    selected_scenario_ids,
    comment,
    judgement,
    is_manual_judgement,
    is_issue_resolved,
    judgement_nudge_value,
    images,
    files,
    submitted_at,
    submitted_by,
    internal_comment,
    is_marked_for_discussion,
    is_marked_for_suggestion,
    observation,
  } = submittedAssessmentData;

  const isNudgeable =
    !is_manual_judgement && !is_issue_resolved && judgement !== "na";
  const isValueNudged = judgement_nudge_value !== 0;

  const rootScenario = scenarios.__root;

  /**
   * The backLink will take a user back to either the assessment page or the
   * manual judgement page. Since admins/auditors will set the manual judgement
   * on the assessment page, we will always point them to the assessment page.
   */
  const backLink =
    is_manual_judgement && !claims.isAdmin ? (
      <BackLink
        href={`/reviews/${review.id}/parts/${partId}/assessments/${reference}/manual-judgement`}
      >
        Back to manual judgement
      </BackLink>
    ) : (
      <BackLink
        href={`/reviews/${review.id}/parts/${partId}/assessments/${reference}/assess`}
      >
        {review.data.is_read_only ? "View Assessment" : "Back to assessment"}
      </BackLink>
    );

  const footer = (
    <Footer
      left={<div css={{ padding: "11px" }}>{backLink}</div>}
      right={
        !review.data.is_read_only && (
          <SpacedChildren
            horizontal
            size="lg"
            css={{ display: "flex", alignItems: "center" }}
          >
            {progress.isCompleted && (
              <Text
                semibold
                cx={css`
                  color: ${colors.charcoal};
                  font-size: ${fontSizes.sm};
                  display: none;
                  @media ${mediaQueries.md} {
                    display: inline;
                  }
                `}
              >
                Great work, you've completed this topic
              </Text>
            )}

            <Button
              href={
                progress.isCompleted
                  ? `/reviews/${reviewId}/parts/${partId}`
                  : navigation.nextLink
              }
            >
              {progress.isCompleted ? "View Topic Results" : "Next Guideline"}
            </Button>
          </SpacedChildren>
        )
      }
    />
  );

  function handleNudgeSubmit(nudgeValue: JudgementNudgeValue) {
    assessmentStore.setJudgementNudgeValue(nudgeValue);
    assessmentStore.submitAssessment(userId).catch(notify.error);

    toggleNudgeForm(false);
  }

  const isReadOnly = review.data.is_read_only && !claims.isAdmin;

  return (
    <PageContainer aside={aside} footerBar={isReadOnly ? undefined : footer}>
      <AssessmentHeader
        title={rootScenario.title}
        citationCode={citation_code}
        sqlId={sql_id}
      />

      {hasAssessment && (
        <WithSummaryPageAside
          isUpdateFlow={false}
          selectedIds={selected_scenario_ids}
          comment={comment ?? ""}
          scenarios={scenarios}
          isManualJudgement={is_manual_judgement}
          images={images}
          files={files}
          pendingFileUploads={pendingFileUploads}
          submittedAt={submitted_at}
          submittedBy={submitted_by}
          internalComment={internal_comment}
          isMarkedForDiscussion={is_marked_for_discussion}
          isMarkedForSuggestion={is_marked_for_suggestion}
          sortedParts={sortedParts}
          observation={observation}
        >
          {(aside) => (
            <React.Fragment>
              <div css={{ marginBottom: space.lg }}>
                <JudgementDisplay
                  judgement={judgement}
                  isIssueResolved={is_issue_resolved}
                  extraLabel={
                    is_manual_judgement
                      ? "(manually set judgement)"
                      : isValueNudged
                        ? "(adjusted)"
                        : undefined
                  }
                />
              </div>

              <SpacedChildren css={{ marginBottom: space.lg }}>
                <div>
                  <Title level={5}>Assessment output</Title>
                  <JudgementSummary
                    calculatedTranslationValue={
                      calculatedTranslationValue.value
                    }
                    judgement={judgement}
                    judgementNudgeValue={judgement_nudge_value}
                    isManualJudgement={is_manual_judgement}
                  />
                </div>
                {!review.data?.is_read_only && isNudgeable && (
                  <React.Fragment>
                    <Button
                      size="small"
                      variant={showNudgeForm ? "secondary" : "tertiary"}
                      onClick={() => toggleNudgeForm(!showNudgeForm)}
                      testingName="adjust-rating-button"
                    >
                      Adjust rating
                    </Button>

                    <Modal
                      isOpen={showNudgeForm}
                      onClose={() => toggleNudgeForm(false)}
                    >
                      <NudgeForm
                        calculatedTranslationValue={
                          calculatedTranslationValue.value || "neutral"
                        }
                        judgementNudgeValue={judgement_nudge_value}
                        onCancel={() => toggleNudgeForm(false)}
                        onSubmit={handleNudgeSubmit}
                        disabled={isSubmitting}
                      />
                    </Modal>
                  </React.Fragment>
                )}
              </SpacedChildren>

              {aside}

              <AdaptiveCarouselContextProvider>
                <MarkdownText>{masterText}</MarkdownText>
              </AdaptiveCarouselContextProvider>
            </React.Fragment>
          )}
        </WithSummaryPageAside>
      )}
    </PageContainer>
  );
});
