import React from "react";
import { baymard } from "~/config";

type GuidelineLinkProps = {
  sqlId?: string;
  children?: React.ReactNode;
};

export function GuidelineLink({ sqlId, children }: GuidelineLinkProps) {
  if (!sqlId) {
    return <span>{children}</span>;
  }

  const url = `${baymard.baseUrl}/premium/guidelines/${sqlId}`;

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}
