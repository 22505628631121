import type { ScenarioMap } from "@gemini/common";
import { assert, getChildScenarios } from "@gemini/common";
import { isDefined } from "ts-is-present";

/**
 * Validate scenario structure. Currently this only applies to point-based
 * scenarios
 *
 * Test the children points mapping for a few things:
 *
 * 1. All children have points assigned to them
 * 2. The possible minimum and maximum outcome values are covered by mapping. The
 *    min and max should take any rule based overrides into account as well.
 * 3. Child ranges have no overlap
 * 4. Child ranges have no gaps
 */

export function validateScenario(
  scenarioId: string,
  scenarios: ScenarioMap
): {
  isValid: boolean;
  validationError?: string;
} {
  const scenario = scenarios[scenarioId];

  assert(scenario, `Missing scenario ${scenarioId}`);

  if (scenario.logic !== "points") {
    return {
      isValid: true,
    };
  }

  if (!scenario.points_mapping) {
    return {
      isValid: false,
      validationError: "No mapping defined",
    };
  }

  const childScenarios = getChildScenarios(scenarios, scenarioId);

  /** All children have points assigned to them */
  const hasPointsOnAllChildren = childScenarios.every((x) =>
    isDefined(x.point_score)
  );

  if (!hasPointsOnAllChildren) {
    return {
      isValid: false,
      validationError: "Not all children have points assigned to them",
    };
  }

  /**
   * The possible minimum and maximum outcome values are covered by mapping.
   * Also include any possible rule based overrides.
   */
  const [scoreMinimum, scoreMaximum] = childScenarios.reduce(
    (acc, x) => {
      assert(isDefined(x.point_score), "Child scenario has no point score");

      const score = x.point_score;
      const overruleScore = x.rule?.point_score;

      /**
       * Take the minimum and maximum of the scenario score, taking overruled
       * point_score values into account
       */
      const maybeNegative = Math.min(score, overruleScore ?? Infinity);
      const maybePositive = Math.max(score, overruleScore ?? -Infinity);

      /** Only subtract negative numbers to get to the possible minimum */
      if (maybeNegative < 0) {
        acc[0] = acc[0] + maybeNegative;
      }

      /** Only add positive numbers to get to the possible maximum */
      if (maybePositive > 0) {
        acc[1] = acc[1] + maybePositive;
      }

      return acc;
    },
    [0, 0]
  );

  const mapping = scenario.points_mapping;

  const [mapMinimum, mapMaximum] = mapping.reduce(
    (acc, x) => {
      acc[0] = Math.min(acc[0], x.from);
      acc[1] = Math.max(acc[1], x.to);

      return acc;
    },
    [Infinity, -Infinity]
  );

  if (scoreMinimum < mapMinimum) {
    return {
      isValid: false,
      validationError: `Score minimum is ${scoreMinimum}, but mapping starts at ${mapMinimum}`,
    };
  }

  if (scoreMaximum > mapMaximum) {
    return {
      isValid: false,
      validationError: `Score maximum is ${scoreMaximum}, but mapping ends at ${mapMaximum}`,
    };
  }

  const sortedMappings = mapping.slice().sort((a, b) => a.from - b.from);

  const hasOverlap = sortedMappings.reduce((hasOverlap, value, index) => {
    if (index === 0) {
      return false;
    }

    if (hasOverlap === true) {
      return true;
    }

    const previousToValue = sortedMappings[index - 1].to;

    return previousToValue >= value.from;
  }, false);

  if (hasOverlap) {
    return {
      isValid: false,
      validationError: `Some ranges seem to overlap / share the same values`,
    };
  }

  const hasGaps = sortedMappings.reduce((hasGaps, value, index) => {
    if (index === 0) {
      return false;
    }

    if (hasGaps === true) {
      return true;
    }

    const previousToValue = sortedMappings[index - 1].to;

    return value.from - previousToValue > 1;
  }, false);

  if (hasGaps) {
    return {
      isValid: false,
      validationError: `There are gaps between the ranges`,
    };
  }

  return {
    isValid: true,
  };
}
