import { set } from "lodash-es";
import { createUniqueId } from "../ids";
import type { Scenario } from "../schemas";

const ROOT_ID = "__root";
const NO_PARENT_ID = "__no_parent";
/**
 * Return the same scenario data but all with newly generated ids. We always
 * want to keep the id of __root in tact. In case of cloning a branch it would
 * be the id of the root node of the branch.
 */
export function replaceScenarioIds(
  scenarios: Scenario[],
  rootScenarioId = ROOT_ID
) {
  /**
   * Make sure the cloned scenarios get their own unique id. The only exception
   * is the __root id which should always be there.
   *
   * First we create a map of all existing ids and their replacements, so that
   * we can iterate the array of scenarios in an arbitrary order. It helps
   * because scenarios reference each other.
   */
  const replacementScenarioIds = scenarios.reduce<Record<string, string>>(
    (acc, x) => (x.id === ROOT_ID ? acc : set(acc, x.id, createUniqueId())),
    {
      [ROOT_ID]: ROOT_ID,
      [NO_PARENT_ID]: NO_PARENT_ID,
    }
  );

  const clonedScenarios: Scenario[] = scenarios.map((x) => ({
    ...x,
    id: replacementScenarioIds[x.id],
    parent_id: replacementScenarioIds[x.parent_id],
  }));

  return {
    newScenarios: clonedScenarios,
    newRootId: replacementScenarioIds[rootScenarioId],
  };
}
