import type { DerivedReview, ReviewStructure } from "@gemini/common";
import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import { ActionLink } from "~/components";
import { InlineLoadingIndicator } from "~/features/admin/components";
import { useUserId } from "~/modules/auth";
import type { Document } from "~/modules/firestore";
import { useTypedDocument } from "~/modules/firestore";
import { colors, fontSizes, fontWeights, preset } from "~/theme";
import { getLastSubmittedPart } from "../helpers";

type NextReviewActionProps<T extends DerivedReview> = {
  review: Document<T>;
  showReviewTitle?: boolean;
  asLink?: boolean;
  ignoreUpdates?: boolean;
};

export function NextReviewAction<T extends DerivedReview>({
  review,
  showReviewTitle,
  asLink,
  ignoreUpdates,
}: NextReviewActionProps<T>) {
  const userId = useUserId();
  const [structure, isLoading] = useTypedDocument<ReviewStructure>(
    "review_structures",
    review.data.structure_id
  );

  if (isLoading) {
    return <InlineLoadingIndicator />;
  }

  if (!structure) {
    return (
      <span>{`Failed to load structure: ${review.data.structure_id}`}</span>
    );
  }

  const { created_by, type, progress, pending_update_counters, title } =
    review.data;

  const assessCount = pending_update_counters.assess;
  const confirmCount = pending_update_counters.confirm;
  const nonSilentUpdateCount = assessCount + confirmCount;

  const lastPart = getLastSubmittedPart(review.data, structure.data);
  const isReviewOwner = userId === created_by;
  const isAudit = type === "audit";

  if (!isReviewOwner || progress.is_review_completed) {
    return (
      <LinkOrButton
        asLink={asLink}
        href={`/reviews/${review.id}`}
        title="View review results"
      >
        <strong>View {isAudit ? "audit" : "review"} results</strong>
      </LinkOrButton>
    );
  }

  if (nonSilentUpdateCount > 0 && !ignoreUpdates) {
    const showAssessCount = assessCount > 0;
    const title = showAssessCount
      ? "Review guideline changes"
      : "Update guidelines";

    return (
      <LinkOrButton
        asLink={asLink}
        href={`/reviews/${review.id}/updates`}
        title={title}
      >
        <strong>{title}</strong>
        <Count>{showAssessCount ? assessCount : confirmCount}</Count>
      </LinkOrButton>
    );
  }

  if (!lastPart) {
    return (
      <LinkOrButton
        asLink={asLink}
        href={`/reviews/${review.id}`}
        title={`Start reviewing ${title}`}
      >
        {showReviewTitle ? (
          <span>
            Start reviewing <strong>{title}</strong>
          </span>
        ) : (
          <strong>Start reviewing</strong>
        )}
      </LinkOrButton>
    );
  }

  return (
    <LinkOrButton
      asLink={asLink}
      href={`/reviews/${review.id}/parts/${lastPart.id}`}
      title={`Resume rating ${title} - ${lastPart.title}`}
    >
      {showReviewTitle ? (
        <React.Fragment>
          Resume rating{" "}
          <strong>
            {title} &ndash; {lastPart.title}
          </strong>
        </React.Fragment>
      ) : (
        <React.Fragment>
          Resume rating <strong>{lastPart.title}</strong>
        </React.Fragment>
      )}
    </LinkOrButton>
  );
}

function LinkOrButton(args: {
  children: React.ReactNode;
  href: string;
  title?: string;
  asLink?: boolean;
}) {
  const { asLink, href, title, children } = args;

  if (!asLink) {
    return <ActionLink variant="secondary" {...args} />;
  }

  return (
    <Link
      href={href}
      title={title}
      css={css`
        ${preset.typography.body2}
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      `}
    >
      {children}
    </Link>
  );
}

const Count = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${colors.indigo};
  color: white;
  font-weight: ${fontWeights.semibold};
  border-radius: 0.666em;
  min-width: 1.333em;
  height: 1.333em;
  font-size: ${fontSizes.xs};
  padding: 0.333em;
  margin: 0 0.333em;
`;
