import type {
  Bipolar,
  ConditionalText,
  DeliverableMasterTexts,
  Guideline,
  GuidelineEditBuffer,
  GuidelineState,
  IndustryId,
  PointsToValueMapping,
  Polarity,
  Rule,
  Scenario,
  ScenarioIdsByParent,
  ScenarioLogic,
  TagId,
  TranslationValue,
  ValidationError,
} from "@gemini/common";
import {
  DEFAULT_POINTS_MAPPING,
  assert,
  calculateChanges,
  calculateTranslationValueRecursively,
  cleanObject,
  compileMasterTextMarkdown,
  createUniqueId,
  getAllScenarioIdsInvolvedInSelection,
  getDeliverableTextSegmentsRecursively,
  getJudgementFromTranslationValue,
  getPreAssessedScenarioIds,
  hasTextualChanges,
  makeDeliverableMasterTexts,
  replaceScenarioIds,
  updateScenarioSelectionInPlace,
} from "@gemini/common";
import autoBind from "auto-bind";
import { collection } from "firebase/firestore";
import { forEach, get, isEmpty, mapValues, set, unset } from "lodash-es";
import { makeAutoObservable, runInAction } from "mobx";
import outdent from "outdent";
import { isDefined, isFilled } from "ts-is-present";
import { submitGuidelineEdits } from "~/modules/api";
import { db } from "~/modules/firebase";
import type { Document } from "~/modules/firestore";
import { getDocument } from "~/modules/firestore";
import { ObservableDocument } from "~/modules/firestore-mobx";
import { notify } from "~/modules/notifications";
import { validateScenario } from "../logic/validate-scenario";

export type OptionalTextFieldName =
  | "description"
  | "instruction_text"
  | "instruction_video_url"
  | "master_text";

/**
 * Using a dummy data buffer as fallback greatly simplifies application logic
 * because we don't need to check if it exists everywhere we use it.
 */
const dummyEditBuffer: GuidelineEditBuffer = {
  scenarios: {},
  citation_code: "__no_code",
};

export class EditStore {
  /**
   * @todo See if the edit buffer can be removed completely if we use the
   *   guideline data directly
   */
  comparisonGuideline: Document<Guideline> | undefined;
  guidelineState = new ObservableDocument<GuidelineState>(
    collection(db, "guideline_states")
  );

  editBuffer = dummyEditBuffer;

  /**
   * For every scenario (parent) describe what (direct) children are selected.
   * So for radio buttons the array would only contain maximum 1 value always.
   */
  selectedIdsMap: ScenarioIdsByParent = {};
  focusedScenarioId = "__root";

  isLoading = false;
  isDirty = false;
  isSubmitting = false;
  validationErrors: ValidationError[] | undefined;

  isManualJudgement = false;
  shouldUseTextualChanges = false;
  lastSelectedScenarioTabKey = "common";

  industryId: IndustryId = "__no_industry";
  tagIds: TagId[] = [];

  constructor(reference: string) {
    makeAutoObservable(this, {
      guidelineState: false,
    });

    /**
     * Bind class methods so that we can pass them as functions down to other
     * components without loosing "this".
     */
    autoBind(this);

    this.resetEdits();

    this.isLoading = true;

    /**
     * We could instead inject using the state onData callback, but that would
     * mean that if another admin is editing the same guideline simultaneously
     * it would instantly overwrite the other's edits on submit, so that is
     * probably not feasible.
     */
    this.guidelineState
      .attachTo(reference)
      .ready()
      .then((state) => {
        assert(
          state,
          `Failed to find guideline state for reference ${reference}`
        );
        return this.injectNewGuidelineData(state.last_edited_id);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      })
      .catch((err) => console.error(err));
  }

  private populateEditBuffer(data: Guideline) {
    /**
     * No need to run this in an action, because we create a completely new
     * observable instance here.
     *
     * @todo See if we can refactor this to a plain object with observable
     *   properties. The editBuffer by itself is never observed, and passing in
     *   the object like this will convert scenarios into an ObservableMap
     *   without Typescript knowing about it. Therefore the toJS conversions are
     *   used in this file. Would be nice to make this explicit and less magic
     *   by declaring scenarios as an ObservableMap and call .values() on it.
     */
    this.editBuffer = {
      scenarios: data.scenarios,
      citation_code: data.citation_code,
    };
  }

  /**
   * Whenever a new revision becomes available, call this method to initialize
   * the edit buffer with the new guideline data.
   */
  injectNewGuidelineData(documentId: string) {
    this.isLoading = true;

    return getDocument<Guideline>("guidelines", documentId).then(
      (guideline) => {
        this.comparisonGuideline = guideline;

        this.populateEditBuffer(guideline.data);

        runInAction(() => {
          this.isLoading = false;
        });
      }
    );
  }

  applyPreAssessment() {
    const scenariosArray = Object.values(this.editBuffer.scenarios);

    const preAssessedScenarioIds = getPreAssessedScenarioIds(scenariosArray, {
      industryId: this.industryId,
      tagIds: this.tagIds,
    });

    if (preAssessedScenarioIds.length === 0) {
      notify.warning("No rules matched pre-assess for the current context");
      return;
    }

    if (preAssessedScenarioIds.length > 1) {
      notify.warning(
        "There are 2 or more scenarios with pre-assess rules that match the current review context condition. This could potentially create conflicts."
      );
    }

    this.selectedIdsMap = getAllScenarioIdsInvolvedInSelection(
      this.editBuffer.scenarios,
      preAssessedScenarioIds
    );
  }

  get hasTextualChanges() {
    return hasTextualChanges(this.changes);
  }

  get changes() {
    assert(
      this.comparisonGuideline?.data,
      "Unable to diff without guideline data"
    );

    return calculateChanges(
      this.comparisonGuideline.data.scenarios,
      this.editBuffer.scenarios
    );
  }

  get editScenario() {
    return this.editBuffer.scenarios[this.focusedScenarioId];
  }

  get scenarios() {
    return this.editBuffer.scenarios;
  }

  get editScenarioOrThrow() {
    const scenario = this.editScenario;

    if (!scenario) {
      throw new Error("No edit scenario data available");
    }

    return scenario;
  }

  get validation() {
    return validateScenario(this.editScenarioOrThrow.id, this.scenarios);
  }

  setIsManualJudgement(value: boolean) {
    this.isManualJudgement = value;

    if (value == true) {
      this.selectedIdsMap = {};
    }
  }

  setScenarioTitle(title: string) {
    const scenario = this.editScenarioOrThrow;

    scenario.title = title ?? "(no title)";
    this.isDirty = true;
  }

  setScenarioConditionalText(value?: ConditionalText) {
    const scenario = this.editScenarioOrThrow;

    scenario.conditional_instruction_text = value;
    this.isDirty = true;
  }

  setScenarioOptionalTextField(
    fieldPath: OptionalTextFieldName,
    text?: string
  ) {
    const scenario = this.editScenarioOrThrow;

    set(scenario, fieldPath, text);
    this.isDirty = true;
  }

  setMasterTextsField(
    polarity: Polarity,
    field: keyof DeliverableMasterTexts,
    value?: string
  ) {
    const scenario = this.editScenarioOrThrow;

    isDefined(value)
      ? set(scenario, ["master_texts", polarity, field], value)
      : unset(scenario, ["master_texts", polarity, field]);

    /** When the last field removed, remove the whole polarity container */
    if (isEmpty(get(scenario, ["master_texts", polarity]))) {
      unset(scenario, ["master_texts", polarity]);
    }

    this.isDirty = true;
  }

  setIncludeMasterTextIfUnchecked(value: boolean) {
    const scenario = this.editScenarioOrThrow;

    scenario.use_master_text_if_unchecked = value;
    this.isDirty = true;
  }

  swapMasterTextsPolarity() {
    const scenario = this.editScenarioOrThrow;

    const swapped: Bipolar<DeliverableMasterTexts> = {
      positive: scenario.master_texts?.negative,
      negative: scenario.master_texts?.positive,
    };

    scenario.master_texts = swapped;

    this.isDirty = true;
  }

  setScenarioTranslationValue(value?: TranslationValue) {
    const scenario = this.editScenarioOrThrow;

    scenario.translation_value = value;
    this.isDirty = true;
  }

  setScenarioPointScore(value: number | null) {
    const scenario = this.editScenarioOrThrow;

    if (isFilled(value)) {
      scenario.point_score = value;
    } else {
      scenario.point_score = undefined;
    }
    this.isDirty = true;
  }

  setScenarioLogic(value: ScenarioLogic) {
    const scenario = this.editScenarioOrThrow;

    scenario.logic = value;

    /** Clear or reset some properties that are dependent on the logic type */
    switch (value) {
      case "or":
        scenario.logic_and_mode = undefined;
        scenario.points_mapping = undefined;
        break;

      case "and":
        scenario.logic_and_mode = "acc";
        scenario.points_mapping = undefined;
        break;

      case "points":
        scenario.logic_and_mode = undefined;
        scenario.points_mapping = DEFAULT_POINTS_MAPPING;
    }

    this.isDirty = true;
  }

  setScenarioLogicAndMode(value: "acc" | "avg") {
    const scenario = this.editScenarioOrThrow;

    scenario.logic_and_mode = value;
    this.isDirty = true;
  }

  setChildrenPointsMapping(value: PointsToValueMapping[]) {
    const scenario = this.editScenarioOrThrow;

    scenario.points_mapping = value;
    this.isDirty = true;
  }

  setScenarioRule(rule?: Rule) {
    const scenario = this.editScenarioOrThrow;

    scenario.rule = rule;
    this.isDirty = true;
  }

  setFocus(scenarioId: string) {
    this.focusedScenarioId = scenarioId;
  }

  setIndustryId(industryId: IndustryId) {
    this.industryId = industryId;
  }

  setTagIds(tagIds: TagId[]) {
    this.tagIds = tagIds;
  }

  resetEdits() {
    this.isDirty = false;
    this.shouldUseTextualChanges = false;
    this.selectedIdsMap = {};
    this.focusedScenarioId = "__root";
  }

  get selectedIds() {
    return Object.values(this.selectedIdsMap).flat();
  }

  updateSelection(parentId: string, values?: string[]) {
    updateScenarioSelectionInPlace(this.selectedIdsMap, parentId, values);
  }

  get calculatedTranslationValue() {
    return calculateTranslationValueRecursively({
      allScenarios: this.editBuffer.scenarios,
      allSelectedScenarioIds: this.selectedIds,
      reviewContext: { industryId: this.industryId, tagIds: this.tagIds },
    });
  }

  get masterText() {
    if (!this.calculatedTranslationValue.value) {
      return outdent`
          (There is no valid scenario selection)
        `;
    }

    const segments = getDeliverableTextSegmentsRecursively({
      scenarioId: "__root",
      allScenarios: this.editBuffer.scenarios,
      allSelectedScenarioIds: this.selectedIds,
      reviewContext: {
        industryId: this.industryId,
        tagIds: this.tagIds,
      },
    });

    if (isEmpty(segments)) {
      return outdent`
        (No master text segments available)
      `;
    }

    const judgement = getJudgementFromTranslationValue(
      this.calculatedTranslationValue.value
    );

    return compileMasterTextMarkdown({
      segments,
      judgement,
      isManualJudgement: this.isManualJudgement,
      judgementNudgeValue: 0,
    });
  }

  async submitChanges() {
    this.isSubmitting = true;

    assert(this.comparisonGuideline, "Called submit without guideline data");

    try {
      /**
       * Submit returns a document id if the guideline was stored as a new
       * version / document.
       */
      const response = await submitGuidelineEdits({
        guidelineId: this.comparisonGuideline.id,
        editBuffer: {
          ...this.editBuffer,
          // Make sure no translation_value: undefined is sent to API because
          // httpsCallable converts undefined to null. Need to report bug.
          scenarios: mapValues(this.editBuffer.scenarios, (scenario) =>
            cleanObject(scenario)
          ),
        },
        shouldUseTextualChanges: this.shouldUseTextualChanges,
      });

      if (response.data.newGuidelineId) {
        /**
         * Guideline id must match with latest document guideline.id in the
         * GuidelineStatusView, otherwise it will show a warning. So we update
         * the guideline data with it. No need to fetch the new document since
         * the buffer should be up to date right after a submit.
         */
        this.comparisonGuideline.id = response.data.newGuidelineId;
      }

      /**
       * But we do need to copy the edit buffer back to the guideline because
       * otherwise changes are not diff-ed properly.
       */
      this.comparisonGuideline.data.scenarios = this.editBuffer.scenarios;
      this.comparisonGuideline.data.citation_code =
        this.editBuffer.citation_code;

      /**
       * This is a hack to make changes respond to the new guideline data,
       * because it is a computed property but this.guideline is not data.
       *
       * @todo Maybe use an observable collection to fetch the guideline and
       *   then have a mobx "reaction" respond to the document id change that
       *   happens as a result of storing a new revision.
       */
      console.log("Changes length", this.changes.length);
      // @TODO test if this works also or something similar
      // this.changes.forEach(_ => _);

      this.isDirty = false;
      this.shouldUseTextualChanges = false;
      this.isSubmitting = false;
    } catch (err) {
      this.isSubmitting = false;
      throw err;
    }
  }

  addChildScenario() {
    const parentScenarioId = this.focusedScenarioId;
    const scenarios = this.editBuffer.scenarios;

    const parentScenario = scenarios[parentScenarioId];
    const { logic, logic_and_mode } = parentScenario;

    const childScenarioId = createUniqueId();
    const scenario: Scenario = {
      id: childScenarioId,
      parent_id: parentScenarioId,
      has_children: false,
      title: `Scenario ${childScenarioId}`,
      position: this.childCount,
    };

    scenarios[parentScenarioId] = cleanObject<Scenario>({
      ...parentScenario,
      has_children: true,
      logic: logic || "or",
      logic_and_mode: logic === "and" ? logic_and_mode || "avg" : undefined,
    });

    scenarios[childScenarioId] = scenario;
    this.isDirty = true;
  }

  deleteScenario() {
    const scenario = this.editBuffer.scenarios[this.focusedScenarioId];
    const parentId = scenario.parent_id;

    const previousSiblingCount = this.siblingScenarios.length;

    if (!parentId) {
      throw new Error(`Missing scenario data for ${this.focusedScenarioId}`);
    }

    /** We need to reorder the siblings to keep positions consistent. */
    this.siblingScenarios
      .filter(
        ({ position }) =>
          // @TODO position used to be optional so we add this extra check.
          // Remove later if not required anymore
          typeof position === undefined || position > scenario.position
      )
      .forEach((scenario) => (scenario.position -= 1));

    delete this.editBuffer.scenarios[this.focusedScenarioId];

    /**
     * Check if parent still has children and un-flag if not. Otherwise we can
     * never delete the parent after it having had children.
     */
    if (previousSiblingCount === 1) {
      this.editBuffer.scenarios[parentId].has_children = false;
    }

    /**
     * Because the deleted scenario will not exist anymore it's important to
     * shift the focus otherwise we get undefined data. The parent is probably
     * the most practical to turn to.
     */
    this.focusedScenarioId = parentId;

    this.isDirty = true;
  }

  get parentScenario(): Scenario {
    const { scenarios } = this.editBuffer;

    const parentId = scenarios[this.focusedScenarioId].parent_id;

    return scenarios[parentId];
  }

  get siblingScenarios(): Scenario[] {
    const { scenarios } = this.editBuffer;

    const parentId = scenarios[this.focusedScenarioId].parent_id;

    const scenariosArray = Object.values(scenarios);

    return scenariosArray.filter((scenario) => scenario.parent_id === parentId);
  }

  get siblingCount() {
    return this.siblingScenarios.length;
  }

  get childScenarios(): Scenario[] {
    const { scenarios } = this.editBuffer;

    const scenariosArray = Object.values(scenarios);

    return scenariosArray.filter(
      (scenario) => scenario.parent_id === this.focusedScenarioId
    );
  }

  get childCount() {
    return this.childScenarios.length;
  }

  moveScenario(direction: "up" | "down") {
    const amount = direction === "up" ? -1 : 1;
    const editScenario = this.editScenarioOrThrow;

    const currentPosition = editScenario.position;

    if (direction === "up" && currentPosition === 0) {
      return;
    }

    const scenarios = this.editBuffer.scenarios;
    const siblings = this.siblingScenarios;

    const affectedSibling = siblings.find(
      ({ position }) => position === currentPosition + amount
    );

    if (!affectedSibling) {
      notify.warning(
        "Position data seems to be corrupted. Please reorder siblings and try again"
      );
      return;
    }

    scenarios[this.focusedScenarioId].position = currentPosition + amount;

    // The affected sibling ends up on the spot where the focused scenario was
    // before.
    affectedSibling.position = currentPosition;

    this.isDirty = true;
  }

  reorderSiblings() {
    const maxPosition = this.siblingCount - 1;

    this.siblingScenarios
      .map((scenario) => {
        if (
          typeof scenario.position === undefined ||
          scenario.position > maxPosition
        ) {
          /**
           * Place all scenario's with "invalid" position data right at the end
           * of the list. If a scenario with corrupt data already has a large
           * position it would not end up in succession if we don't clip the
           * range first.
           */
          scenario.position = maxPosition;
        }

        return scenario;
      })
      .sort((a, b) => a.position - b.position)
      .forEach((scenario, index) => {
        this.editBuffer.scenarios[scenario.id].position = index;
      });
    this.isDirty = true;
  }

  get deliverableTexts() {
    const scenarios = this.editBuffer.scenarios;

    const segments = getDeliverableTextSegmentsRecursively({
      scenarioId: "__root",
      allScenarios: scenarios,
      allSelectedScenarioIds: this.selectedIds,
      reviewContext: {
        industryId: this.industryId,
        tagIds: this.tagIds,
      },
    });

    return makeDeliverableMasterTexts(this.editBuffer, segments);
  }

  get counterpartReferences() {
    return this.comparisonGuideline?.data.platform_counterpart_references;
  }

  /** Mount a copied scenario branch into the edit buffer. */
  mountScenarioBranch(scenarios: Scenario[], branchRootId: string) {
    const scenario = this.editScenarioOrThrow;

    console.log("Mounting to editScenario id", scenario.id);

    /**
     * Every time we paste from the clipboard, we generate new ids for the
     * scenarios that are being pasted. This way you can paste the same
     * structure multiple times within the same guideline if you want.
     */
    const { newScenarios, newRootId } = replaceScenarioIds(
      scenarios,
      branchRootId
    );

    /**
     * Copy the scenarios and attach the one representing the root to the
     * mounting scenario id.
     */
    const mountedScenarios = [...newScenarios].map((x) =>
      x.id === newRootId ? { ...x, parent_id: scenario.id } : x
    );

    /** Inject the scenarios into the map */
    forEach(mountedScenarios, (x) => (this.editBuffer.scenarios[x.id] = x));

    /** The mounting scenario now has children for sure. */
    this.editBuffer.scenarios[scenario.id].has_children = true;
    /**
     * And since "or" is by far the most common logic mode, set that as a
     * default. @TODO maybe this default could be set in a better place but I
     * can't find it ATM.
     */
    this.editBuffer.scenarios[scenario.id].logic = "or";

    this.isDirty = true;

    /** Return a function that can be used to remove the scenarios again. */
    return () => {
      runInAction(() => {
        forEach(newScenarios, (scenario) => {
          delete this.editBuffer.scenarios[scenario.id];
        });
      });
    };
  }
}
