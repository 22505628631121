import type {
  ArchiveAllReviewStructureVersionsPayload,
  ArchiveReviewPayload,
  AttachComparisonPayload,
  AttachComparisonResult,
  CreateGuidelinePayload,
  CreateReviewPayload,
  DetachComparisonPayload,
  DetachComparisonResult,
  DuplicateReviewPayload,
  EditReviewPayload,
  GetExtractForComparisonPayload,
  GetExtractForComparisonResult,
  GetReviewStructureGuidelineStatesPayload,
  GetReviewStructureGuidelineStatesResult,
  GetReviewStructureListResult,
  PublishReviewComparisonPayload,
  PublishReviewComparisonResult,
  RedeliverAuditPayload,
  RedeliverAuditResult,
  RemoveGuidelineObservationPayload,
  RemoveGuidelineObservationResult,
  ReplaceGuidelineInReviewStructuresPayload,
  ReplaceGuidelineInReviewStructuresResult,
  SetReviewStructureArchivedStatePayload,
  SubmitAssessmentPayload,
  SubmitGuidelineEditsPayload,
  SubmitGuidelineObservationPayload,
  SubmitGuidelineObservationResult,
  SubmitReviewStructureEditsPayload,
  SubmitSectionObservationPayload,
  SubmitSectionObservationResult,
} from "@gemini/common";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

export const signInWithBaymard = httpsCallable<
  { email: string; password: string },
  { token?: string }
>(functions, "signInWithBaymard");

export const publishVersion = httpsCallable<{ guidelineId: string }, void>(
  functions,
  "publishVersion"
);

export const unPublishVersion = httpsCallable<{ guidelineId: string }, void>(
  functions,
  "unPublishVersion"
);

export const revertToVersion = httpsCallable<
  { fromRevisionId: string; toRevisionId: string },
  { guidelineId: string }
>(functions, "revertToVersion");

export const applyConfirmUpdates = httpsCallable<{ reviewId: string }, void>(
  functions,
  "applyConfirmUpdates"
);

export const submitGuidelineEdits = httpsCallable<
  SubmitGuidelineEditsPayload,
  { newGuidelineId?: string }
>(functions, "submitGuidelineEdits");

export const createGuideline = httpsCallable<
  CreateGuidelinePayload,
  { reference: string }
>(functions, "createGuideline");

export const createReview = httpsCallable<
  CreateReviewPayload,
  { reviewId: string }
>(functions, "createReview");

export const editReview = httpsCallable<EditReviewPayload, void>(
  functions,
  "editReview"
);

export const duplicateReview = httpsCallable<
  DuplicateReviewPayload,
  { reviewId: string }
>(functions, "duplicate_review");

export const archiveReview = httpsCallable<ArchiveReviewPayload, void>(
  functions,
  "archiveReview"
);

export const submitAssessment = httpsCallable<SubmitAssessmentPayload, void>(
  functions,
  "submit_assessment"
);

export const storeUserData = httpsCallable<void, void>(
  functions,
  "storeUserData"
);

export const submitReviewStructureEdits = httpsCallable<
  SubmitReviewStructureEditsPayload,
  { structure_id: string }
>(functions, "submitReviewStructureEdits");

export const getReviewStructureList = httpsCallable<
  void,
  GetReviewStructureListResult
>(functions, "getReviewStructureList");

export const getReviewStructureGuidelineStates = httpsCallable<
  GetReviewStructureGuidelineStatesPayload,
  GetReviewStructureGuidelineStatesResult
>(functions, "getReviewStructureGuidelineStates");

export const replaceGuidelineInReviewStructures = httpsCallable<
  ReplaceGuidelineInReviewStructuresPayload,
  ReplaceGuidelineInReviewStructuresResult
>(functions, "replaceGuidelineInReviewStructures");

export const setReviewStructureArchivedState = httpsCallable<
  SetReviewStructureArchivedStatePayload,
  void
>(functions, "setReviewStructureArchivedState");

export const archiveAllReviewStructureVersions = httpsCallable<
  ArchiveAllReviewStructureVersionsPayload,
  void
>(functions, "archiveAllReviewStructureVersions");

export const attachComparison = httpsCallable<
  AttachComparisonPayload,
  AttachComparisonResult
>(functions, "attach_comparison");

export const detachComparison = httpsCallable<
  DetachComparisonPayload,
  DetachComparisonResult
>(functions, "detach_comparison");

export const publishReviewComparison = httpsCallable<
  PublishReviewComparisonPayload,
  PublishReviewComparisonResult
>(functions, "publish_review_comparison");

export const submitGuidelineObservation = httpsCallable<
  SubmitGuidelineObservationPayload,
  SubmitGuidelineObservationResult
>(functions, "submit_guideline_observation");

export const submitSectionObservation = httpsCallable<
  SubmitSectionObservationPayload,
  SubmitSectionObservationResult
>(functions, "submit_section_observation");

export const removeGuidelineObservation = httpsCallable<
  RemoveGuidelineObservationPayload,
  RemoveGuidelineObservationResult
>(functions, "remove_guideline_observation");

export const getExtractForComparison = httpsCallable<
  GetExtractForComparisonPayload,
  GetExtractForComparisonResult
>(functions, "get_extract_for_comparison");

export const redeliverAudit = httpsCallable<
  RedeliverAuditPayload,
  RedeliverAuditResult
>(functions, "redeliver_audit");
