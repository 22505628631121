import React from "react";
import styled from "styled-components";
import { mediaQueries, sizes, space } from "~/theme";

type MaxWidthContainerProps = {
  children: React.ReactNode;
  narrow?: boolean;
};

export function MaxWidthContainer({
  children,
  narrow,
}: MaxWidthContainerProps) {
  return (
    <StyledMaxWidthContainer narrow={narrow}>
      {children}
    </StyledMaxWidthContainer>
  );
}

const StyledMaxWidthContainer = styled.div<MaxWidthContainerProps>`
  width: 100%;
  margin: 0 auto;
  max-width: ${(x) => (x.narrow ? sizes.pageWidthNarrow : sizes.pageWidth)};
  padding-left: ${space.md};
  padding-right: ${space.md};

  @media ${mediaQueries.md} {
    padding-left: ${space.lg};
    padding-right: ${space.lg};
  }
`;
