import React from "react";
import styled, { css } from "styled-components";
import { MaxWidthContainer } from "~/components";
import { baymard } from "~/config";
import { fontWeights, space } from "~/theme";

type Section = {
  title: string;
  items: Array<{
    url?: string;
    label?: string;
    extraLabel?: string;
    isActive?: boolean;
  }>;
};

const sections: Section[] = [
  {
    title: "Research Content & Tools",
    items: [
      {
        url: "/premium/guideline-collections",
        label: "Guidelines",
        extraLabel: "660+ research findings",
      },
      {
        url: "/premium/benchmarks",
        label: "Benchmarks",
        extraLabel: "190+ case studies",
      },
      {
        url: "/premium/page-designs",
        label: "Page Designs",
        extraLabel: "13,000+ examples",
      },
    ],
  },

  {
    title: "Recent Research Updates",
    items: [
      {
        url: "/premium/pages/roadmap-and-changelog",
        label: "Roadmap & Changelog",
      },
      {
        url: "/premium/content-updates",
        label: "Your Content Updates",
      },
    ],
  },

  {
    title: "Your Content & Services",
    items: [
      {
        url: "/premium/pages/on-boarding",
        label: "On-Boarding Session",
      },
      {
        url: "/premium/pages/live-qa-sessions",
        label: "Live Q&A Sessions",
      },
      {
        url: "/premium/pages/your-audits",
        label: "Your Audits",
      },
      {
        url: "/premium/site-reviews",
        label: "Review Tool",
        isActive: true,
      },
    ],
  },

  {
    title: "Additional Services",
    items: [
      {
        url: "/premium/pages/get-an-audit",
        label: "UX Audit",
        extraLabel: "Get Baymard to audit your site",
      },
      {
        url: "/premium/training",
        label: "UX Training",
        extraLabel: "Remote and self-paced",
      },
    ],
  },

  {
    title: "Account & Support",
    items: [
      {
        url: "/premium",
        label: "Dashboard",
      },
      {
        url: "/premium/account",
        label: "Manage Account & Users",
      },
      {
        url: "/premium/pages/help-and-faq",
        label: "Help & FAQ",
      },
    ],
  },

  {
    title: "Getting Started",
    items: [
      {
        url: "/premium/pages/getting-started",
        label: "Video Introduction",
      },
      {
        url: "/premium/pages/on-boarding",
        label: "Schedule On-Boarding Session",
      },
      {
        url: "/premium/pages/research-methodology",
        label: "Read Research Methodology",
      },
    ],
  },
];

export function SiteFooter() {
  return (
    <div
      css={css`
        -webkit-font-smoothing: subpixel-antialiased;
        border-top: 2px solid #d5d5d5;
        background: #eee;
      `}
    >
      <MaxWidthContainer>
        <div css={{ paddingTop: "4.4rem", paddingBottom: "4.4rem" }}>
          <Sections>
            {sections.map(({ title, items }, i) => (
              <Section key={i} title={title}>
                <ul>
                  {items.map((x, i) => (
                    <li key={i}>
                      <FooterLink
                        href={baymard.baseUrl + x.url}
                        label={x.label}
                        extraLabel={x.extraLabel}
                        active={x.isActive}
                      />
                    </li>
                  ))}
                </ul>
              </Section>
            ))}
          </Sections>
        </div>
      </MaxWidthContainer>
    </div>
  );
}

const Sections = styled.div`
  column-width: 264px;
  column-gap: 22px;
  width: 100%;
`;

type FooterLinkProps = {
  label?: string;
  extraLabel?: string;
  href?: string;
  active?: boolean;
};

function FooterLink({ label, extraLabel, href, active }: FooterLinkProps) {
  return (
    <span
      css={css`
        font-weight: ${fontWeights.light};
        font-size: 1.54rem;
        color: #333;
      `}
    >
      {href && (
        <a
          css={css`
            display: inline-block;
            margin: ${space.xs} 0;
            color: ${active ? "#777" : "#111"};
            text-decoration-color: #bbb;
            cursor: ${active ? "default" : "pointer"};

            &:hover {
              color: ${active ? undefined : "#B60B00"};
              text-decoration: ${active ? "underline" : "none"};
            }
          `}
          href={href}
        >
          {label}
        </a>
      )}
      {label && extraLabel && ": "}
      {extraLabel && <span>{extraLabel}</span>}
    </span>
  );
}

type SectionProps = {
  title: string;
  children: React.ReactNode;
};

function Section({ title, children }: SectionProps) {
  return (
    <StyledSection>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </StyledSection>
  );
}

const SectionTitle = styled.h3`
  font-weight: ${fontWeights.medium};
  font-size: 1.694rem;
  text-decoration: none;
  color: black;
  margin-bottom: 1.1rem;
`;

const StyledSection = styled.div`
  break-inside: avoid-column;
  margin-bottom: 4.4rem;
`;
