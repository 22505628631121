import type { ToggleRefinementRenderState } from "instantsearch.js/es/connectors/toggle-refinement/connectToggleRefinement";
import type { ReactNode } from "react";
import React from "react";
import { useToggleRefinement } from "react-instantsearch";
import { CheckboxWithLabel } from "~/components";

export type ToggleRefinementLabel =
  | ReactNode
  | ((value: ToggleRefinementRenderState["value"]) => ReactNode);

type ToggleRefinementProps = {
  label: ToggleRefinementLabel;
  attribute: string;
  isVirtual?: boolean;
};

export function ToggleRefinement({
  label,
  attribute,
  isVirtual,
}: ToggleRefinementProps) {
  const { refine, value } = useToggleRefinement({ attribute });

  if (isVirtual) return null;

  return (
    <CheckboxWithLabel
      disabled={(value.onFacetValue.count || 0) === 0 && !value.isRefined}
      checked={value.isRefined}
      onChange={(isChecked) => refine({ isRefined: !isChecked })}
    >
      <span css={{ whiteSpace: "nowrap" }}>
        {typeof label === "function" ? (
          label(value)
        ) : (
          <>
            {label} ({value.onFacetValue.count || 0})
          </>
        )}
      </span>
    </CheckboxWithLabel>
  );
}
