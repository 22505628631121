import moment from "moment";
import React from "react";
import Moment from "react-moment";

type RelativeTimeProps = {
  date: Date | string;
  referenceDate?: Date | string;
  pastPrefix?: string;
  pastPostfix?: string;
  futurePrefix?: string;
  futurePostfix?: string;
};

export class RelativeTime extends React.Component<RelativeTimeProps> {
  public render() {
    const {
      date,
      referenceDate,
      pastPrefix,
      pastPostfix = "ago",
      futurePrefix,
      futurePostfix,
    } = this.props;

    const passedDate = moment(date);
    const compareToDate = referenceDate ? moment(referenceDate) : moment();
    const isFuture = passedDate.isAfter(compareToDate);

    const prefix = isFuture ? futurePrefix : pastPrefix;
    const postfix = isFuture ? futurePostfix : pastPostfix;
    const tooltipFormat = "Do MMMM YYYY HH:mm";

    const text = (
      <span title={moment(date).format(tooltipFormat)}>
        {prefix && `${prefix} `}
        <Moment ago to={passedDate}>
          {compareToDate.toISOString()}
        </Moment>
        {postfix && ` ${postfix}`}
      </span>
    );

    return text;
  }
}
