import {
  assert,
  assessmentFileUploadDirectoryTemplate,
  setPathParams,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import React from "react";
import styled, { css } from "styled-components";
import {
  PageContainer,
  Paragraph,
  ScenarioBranch,
  ScenarioInstructionText,
  SpacedChildren,
  getPlatformText,
} from "~/components";
import { useReviewStore } from "~/features/reviews";
import { usePartStore } from "~/features/reviews/part";
import { useAuth } from "~/modules/auth";
import { notify } from "~/modules/notifications";
import { colors, mediaQueries, preset, radii, space } from "~/theme";
import { wrapBackgroundTask } from "~/utils/wrap-background-task";
import {
  AssessFooter,
  AssessmentContent,
  AssessmentHeader,
  AssessmentSelection,
  CommentField,
  ComparisonAssessmentSummary,
  AuditorObservationForm,
  AuditorObservationResult,
  CounterpartAssessmentSummary,
  ImageField,
  InternalNotes,
  ManualJudgement,
  useAssessmentAsideData,
} from "./components";
import { FileField } from "./components/file-field";
import { FileList } from "./components/file-list";
import { AssessmentSidebar } from "./sidebar";
import { useAssessmentStore } from "./store";
import { ComputedDifference } from "./components/assessment-aside/components/computed-difference";

export const AssessmentIndexPage: NextPage = observer(() => {
  const router = useRouter();
  const { claims } = useAuth();
  const partStore = usePartStore();
  const assessmentStore = useAssessmentStore();
  const reviewStore = useReviewStore();

  const reference = router.query.reference as string;

  const aside = <AssessmentSidebar />;

  const { review } = reviewStore;

  assert(review.data, "Missing review data");

  const { guideline, sortedParts } = partStore;

  assert(guideline, "Missing guideline data");

  const { citation_code, sql_id, scenarios, platform } = guideline.data;

  const {
    calculatedTranslationValue,
    comment,
    imageUploads,
    isManualJudgement,
    manualJudgement,
    selectedIdsMap,
    setComment,
    setIsManualJudgement,
    setManualJudgementValue,
    updateSelection,
    addImages,
    removeImage,
    reorderImages,
    files,
    pendingFileUploads,
    filesToDelete,
    addFiles,
    removeFile,
    observation,
  } = assessmentStore;

  assert(scenarios, "Missing scenario data");

  const { industry_id, tag_ids } = review.data;

  const rootScenario = scenarios.__root;

  const validationErrors = calculatedTranslationValue?.validationErrors;

  // there's always a __root scenario, therefore check length > 1
  const hasScenarios = Object.keys(scenarios).length > 1;

  const [asideComparisonContent, setAsideComparisonContent] = React.useState<
    "counterpart" | "comparison"
  >();

  if (review.data && asideComparisonContent === undefined) {
    /**
     * Set the default aside comparison content based on the comparison id
     * existence.
     */
    setAsideComparisonContent(
      review.data.review_comparison_id ? "comparison" : "counterpart"
    );
  }

  const desktopCounterpartData = useAssessmentAsideData("desktop", sortedParts);
  const mobileCounterpartData = useAssessmentAsideData("mobile", sortedParts);
  const appCounterpartData = useAssessmentAsideData("app", sortedParts);

  const hasCounterpartData =
    !!desktopCounterpartData || !!mobileCounterpartData || !!appCounterpartData;

  return (
    <PageContainer
      testingName="page-assess"
      aside={aside}
      footerBar={<AssessFooter key={partStore.currentReference} />}
    >
      <AssessmentHeader
        title={rootScenario.title}
        citationCode={citation_code}
        sqlId={sql_id}
      />
      <AssessmentContent
        /**
         * Remount the assessment content to prevent recycling of ui- and
         * component-state. For example the adaptive carousel context was kept
         * alive between pages where it would make more sense to unmount the old
         * carousel instance when navigating to a new page.
         */
        key={partStore.currentReference}
      >
        <div
          css={css`
            @media ${mediaQueries.lg} {
              display: grid;
              grid-column-gap: ${space.lg};
              grid-template-areas: "intro ." "assessment summary";
              grid-template-columns: 7fr 3fr;
            }
          `}
        >
          <div
            css={css`
              grid-area: intro;
              margin-bottom: ${space.lg};
            `}
          >
            <ScenarioInstructionText
              scenario={rootScenario}
              industryId={industry_id}
              tagIds={tag_ids}
              title={`Test your ${getPlatformText(platform)} for the following`}
            />
          </div>

          <div
            css={{ gridArea: "assessment" }}
            /* reset focus by remounting this element */
            key={guideline.data.reference}
          >
            <SpacedChildren size="lg" css={{ marginBottom: space.xl }}>
              <ScenarioBranch
                readOnly={review.data.is_read_only}
                industryId={industry_id}
                isDisabled={isManualJudgement}
                onSelectionChange={updateSelection}
                scenarios={scenarios}
                selectedIdsMap={selectedIdsMap}
                tagIds={tag_ids}
                validationErrors={validationErrors}
              />
              <ManualJudgement
                disabled={review.data.is_read_only}
                manualJudgement={manualJudgement}
                isManualJudgement={isManualJudgement}
                setIsManualJudgement={setIsManualJudgement}
                setManualJudgementValue={setManualJudgementValue}
                hasScenarios={hasScenarios}
                feedbackLinkHref={feedbackLinkHref(citation_code)}
              />
            </SpacedChildren>

            <SpacedChildren size="lg" css={{ marginBottom: space.lg }}>
              <div id="comment">
                <CommentField
                  disabled={review.data.is_read_only}
                  value={comment}
                  onChange={setComment}
                />
              </div>

              <ImageField
                disabled={review.data.is_read_only}
                imageUploads={imageUploads}
                onAdd={(images, cropConfig) => {
                  addImages(images, cropConfig).catch(notify.error);
                }}
                onRemove={removeImage}
                onReorder={reorderImages}
                label="Add a screenshot of your site"
                extraLabel="You can also drag and drop images or directly paste a screenshot from your clipboard"
                allowScreenshotCropping={review.data.type === "case-study"}
              />

              <FileField
                disabled={review.data.is_read_only}
                label="Upload files"
                extraLabel={`Upload files like a video recording, prototype design files, etc. Max ${
                  claims.isAdmin ? "2GB" : "90MB"
                } each. Files will continue to upload in the background when you submit the assessment.`}
                onAdd={(files) => {
                  wrapBackgroundTask(
                    addFiles(
                      files,
                      setPathParams(assessmentFileUploadDirectoryTemplate, {
                        accountId: claims.accountId,
                        reviewId: review.id,
                        reference,
                      }),
                      claims.isAdmin ? 2048 : 90
                    )
                  );
                }}
              >
                <FileList
                  accountId={review.data.account_id}
                  files={files}
                  reference={reference}
                  reviewId={review.id}
                  pendingFileUploads={pendingFileUploads}
                  filesToDelete={filesToDelete}
                  onRemove={review.data.is_read_only ? undefined : removeFile}
                />
              </FileField>

              {claims.isAdmin && <InternalNotes />}
            </SpacedChildren>
            <SpacedChildren>
              <Paragraph
                cx={css`
                  ${preset.typography.body2}
                `}
              >
                <a
                  css={{ color: colors.slateGray }}
                  href={feedbackLinkHref(citation_code)}
                >
                  Send Baymard feedback
                </a>{" "}
                on the options available for this guideline
              </Paragraph>
            </SpacedChildren>
          </div>

          {/** @todo Move this to its own component AssessmentAside, like SummaryAside */}
          <StickyAside>
            <SpacedChildren>
              <AssessmentSelection />

              {review.data.review_comparison_id && hasCounterpartData && (
                <div>
                  <strong>Compare:</strong>{" "}
                  <TextButton
                    $selected={asideComparisonContent === "comparison"}
                    onClick={() => setAsideComparisonContent("comparison")}
                  >
                    comparison-review
                  </TextButton>{" "}
                  <TextButton
                    $selected={asideComparisonContent === "counterpart"}
                    onClick={() => setAsideComparisonContent("counterpart")}
                  >
                    counterparts
                  </TextButton>
                </div>
              )}

              {asideComparisonContent === "comparison" ? (
                <>
                  {review.data.is_read_only && observation ? (
                    <AuditorObservationResult observation={observation} />
                  ) : (
                    <>
                      <ComputedDifference />
                      <AuditorObservationForm />
                      <ComparisonAssessmentSummary />
                    </>
                  )}
                </>
              ) : (
                <>
                  {desktopCounterpartData && (
                    <CounterpartAssessmentSummary
                      assessmentAsideData={desktopCounterpartData}
                      showJudgement={claims.isAdmin}
                    />
                  )}
                  {mobileCounterpartData && (
                    <CounterpartAssessmentSummary
                      assessmentAsideData={mobileCounterpartData}
                      showJudgement={claims.isAdmin}
                    />
                  )}
                  {appCounterpartData && (
                    <CounterpartAssessmentSummary
                      assessmentAsideData={appCounterpartData}
                      showJudgement={claims.isAdmin}
                    />
                  )}
                </>
              )}
            </SpacedChildren>
          </StickyAside>
        </div>
      </AssessmentContent>
    </PageContainer>
  );
});

const TextButton = styled.button<{ $selected?: boolean }>`
  all: unset;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  margin: 0 ${space.xs};
  padding: 0.1rem 1rem;

  border-radius: ${radii.lg};

  color: ${colors.charcoal};
  background: ${(x) => (x.$selected ? colors.whiteLilac : undefined)};

  &:hover {
    background: ${colors.whiteLilac};
  }
`;

function StickyAside({ children }: { children: React.ReactNode }) {
  return (
    <Aside>
      <div
        css={css`
          position: sticky;
          top: ${space.lg};
        `}
      >
        {children}
      </div>
    </Aside>
  );
}

const Aside = styled.div`
  height: 100%;
  grid-area: summary;

  display: none;

  @media ${mediaQueries.lg} {
    display: block;
  }
`;

function feedbackLinkHref(citationCode: string) {
  return `mailto:support+scenario-feedback@baymard.com?subject=${encodeURIComponent(
    `Review Tool: Feedback on Guideline ${citationCode}`
  )}`;
}
