import { notify } from "~/modules/notifications";

/**
 * Wrap a background task with default error handler. This can be used in cases
 * where you are not interested in handling a promise but still the user or
 * system should be aware of any errors occurring.
 */
export function wrapBackgroundTask<T>(
  promise: Promise<T>,
  errorHandler: (error: Error) => void = notify.error
) {
  promise.catch(errorHandler);
}
