import * as Sentry from "@sentry/nextjs";
import React from "react";
import { fonts, fontSizes, fontWeights, space } from "~/theme";
import { BoxWithBorder } from "./box-with-border";
import { Logo } from "./logo";

type ErrorBoundaryProps = {
  fallback?: (error: Error, errorId: string) => React.ReactNode;
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  error?: Error;
  errorId?: string;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    fallback: <DefaultErrorMessage />,
  };

  state = {
    error: undefined,
    errorId: undefined,
  } as ErrorBoundaryState;

  componentDidCatch(error: Error) {
    if (error) {
      const errorId = Sentry.captureException(error);
      this.setState({ error, errorId });
    }
  }

  render() {
    return this.state.error ? (
      this.props.fallback ? (
        this.props.fallback(this.state.error, this.state.errorId ?? "__no_id")
      ) : null
    ) : (
      <React.Fragment>{this.props.children}</React.Fragment>
    );
  }
}

function DefaultErrorMessage() {
  return (
    <BoxWithBorder>
      <span
        css={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Logo cx={{ width: 150, marginRight: space.md }} />
        <span
          css={{
            fontFamily: fonts.monospace,
            fontWeight: fontWeights.semibold,
            fontSize: fontSizes.xs,
          }}
        >
          An error occurred. We apologize for any inconvenience caused. Baymard
          has been notified about the issue.
        </span>
      </span>
    </BoxWithBorder>
  );
}
