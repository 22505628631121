import type { ReviewStructureGroup } from "@gemini/common";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";
import { useReviewStore } from "~/features/reviews/store";
import { colors, fontSizes, preset, space } from "~/theme";
import { isReviewStructurePart } from "~/utils/identity";
import { MasonryWrapper } from "./masonry-wrapper";
import { PartLink } from "./part-link";

const GUTTER = 48;

export function NestedGroup({
  group,
  level,
  isMultiColumn,
  isParentMultiColumn,
  index = 0,
}: {
  group: ReviewStructureGroup;
  level: number;
  isMultiColumn?: boolean;
  isParentMultiColumn?: boolean;
  index?: number;
}) {
  const partId = useRouter().query.partId as string | undefined;
  const { getChildrenFromStructureGroup } = useReviewStore();
  const groupChildren = getChildrenFromStructureGroup(group.id);
  const needsBorderBottom = isParentMultiColumn;
  const isGroupWithinGroup =
    (!isParentMultiColumn && level >= 1) || (isParentMultiColumn && level >= 2);

  return (
    <div
      css={css`
        font-size: ${fontSizes.sm};
        padding-bottom: ${needsBorderBottom ? `${GUTTER / 2}px` : undefined};

        border-bottom: ${needsBorderBottom
          ? `1px solid ${colors.athensGray}`
          : undefined};

        margin-top: ${needsBorderBottom ? `${GUTTER / -2}px` : ""};
        margin-bottom: ${isGroupWithinGroup ? space.sm : undefined};
      `}
    >
      {level > 0 && <GroupTitle level={level}>{group.title}</GroupTitle>}

      {isMultiColumn ? (
        <MasonryWrapper
          items={groupChildren}
          columnGutter={GUTTER}
          render={({ data, index }) =>
            isReviewStructurePart(data) ? (
              <PartLink
                key={data.id}
                part={data}
                level={level}
                active={data.id === partId}
              />
            ) : (
              <NestedGroup
                key={data.id}
                group={data}
                level={level + 1}
                isParentMultiColumn={isMultiColumn}
                index={index}
              />
            )
          }
        />
      ) : (
        <div>
          {groupChildren.map((x) =>
            isReviewStructurePart(x) ? (
              <PartLink
                key={x.id}
                part={x}
                level={level}
                active={x.id === partId}
              />
            ) : (
              <NestedGroup
                key={x.id}
                group={x}
                level={level + 1}
                index={index}
              />
            )
          )}
        </div>
      )}
    </div>
  );
}

const GroupTitle = styled.span<{ level: number }>`
  ${preset.typography.subtitle2};
  color: ${colors.slateGray};
  display: block;
  padding: 5px 8px 5px ${(x) => Math.max(x.level - 1, 0) * 20}px;
`;
