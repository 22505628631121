import type { Platform, ReviewStructurePart } from "@gemini/common";
import Link from "next/link";
import { useAssessmentStore } from "~/features/assessment";
import { useReviewStore } from "~/features/reviews";
import { fontWeights } from "~/theme";

/**
 * @todo This hook isn't the most wonderful idea and is slowly becoming messy.
 *   Candidate for cleanup.
 */
export function useAssessmentAsideData(
  platform: Platform,
  parts?: ReviewStructurePart[],
  options: { openInNewTab: boolean } = { openInNewTab: true }
) {
  const { review } = useReviewStore();
  const { counterpartAssessments, counterpartGuidelines } =
    useAssessmentStore();
  const { openInNewTab } = options;

  const counterpartGuideline = counterpartGuidelines[platform];
  const counterpartAssessment = counterpartAssessments[platform];

  if (counterpartAssessment.isLoading || counterpartGuideline.isLoading) {
    return;
  }

  if (!counterpartGuideline.data) {
    return;
  }

  const hasAssessment = counterpartAssessment.data;

  const { scenarios, citation_code, reference } = counterpartGuideline.data;

  const counterpartPartId = parts?.find((x) =>
    x.guideline_references.includes(counterpartGuideline.data?.reference ?? "")
  )?.id;

  const linkPlaceholder = (
    <span css={{ fontWeight: fontWeights.bold }}>{citation_code}</span>
  );

  /**
   * Hide the link when:
   *
   * - The review is read only and has no counterpart assessment, there is nothing
   *   to show on the counterpart side. We can not navigate to the assess page.
   * - The counterpart guideline falls outside of the review structure, navigating
   *   to it would break the app.
   */
  const shouldHideLink =
    (review.data?.is_read_only && !hasAssessment) || !counterpartPartId;

  const linkComponent = shouldHideLink ? (
    linkPlaceholder
  ) : (
    <Link
      href={`/reviews/${
        review.id
      }/parts/${counterpartPartId}/assessments/${reference}/${
        hasAssessment ? "summary" : "assess"
      }`}
      css={{ fontWeight: fontWeights.bold }}
      target={openInNewTab ? "_blank" : undefined}
      rel={openInNewTab ? "noopener noreferrer" : undefined}
    >
      {citation_code}
    </Link>
  );

  const title = (
    <>
      {linkComponent} {platform} variant assessment
    </>
  );

  const titleTooltipContent = `Your assessment for the ${platform} variant of this guideline may be a useful reference here.`;

  return {
    assessment: counterpartAssessment,
    platform,
    title,
    titleTooltipContent,
    scenarios,
  };
}
