import styled, { css } from "styled-components";
import { colors, fontSizes, radii, space } from "~/theme";

export function PointScoreTag({ value }: { value: number }) {
  return <StyledPointScoreTag $value={value}>{value}</StyledPointScoreTag>;
}

const StyledPointScoreTag = styled.span<{
  $value: number;
}>`
  display: block;
  padding: 0 ${space.xs};
  border-radius: ${radii.sm};
  white-space: nowrap;
  text-transform: uppercase;
  font-size: ${fontSizes.xxs};
  line-height: 1.6;
  border: 1px solid;
  text-align: center;

  ${(x) => css`
    color: white;
    border-color: ${getColorForValue(x.$value)};
    background-color: ${getColorForValue(x.$value)};
  `}
`;

function getColorForValue(score: number) {
  if (score === 0) {
    return colors.pointScores.neutral;
  } else {
    return score < 0
      ? colors.pointScores.negative
      : colors.pointScores.positive;
  }
}
