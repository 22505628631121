import {
  allIndustries,
  assert,
  parseComparableVersionString,
  tags,
} from "@gemini/common";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import Moment from "react-moment";
import styled, { css } from "styled-components";
import {
  ActionLink,
  BackLink,
  IconLink,
  MaxWidthContainer,
  Paragraph,
  SpacedChildren,
  Text,
  Title,
  Truncate,
  Username,
} from "~/components";
import { ReviewComparisonAttachDetachToggle } from "~/features/comparison";
import { useReviewStore } from "~/features/reviews";
import { useAuth } from "~/modules/auth";
import { colors, fontSizes, mediaQueries, space } from "~/theme";
import { getLastSubmittedPart } from "../helpers";
import { AuditDeliveryStatus } from "./audit-delivery-status";
import { JumpToGuideline } from "./jump-to-guideline";

export const ReviewHeader = observer(() => {
  const { review, structure, partsFromStructure } = useReviewStore();
  const { claims } = useAuth();
  assert(review.data, "Unable to render header without review data");
  assert(structure.data, "Unable to render header without structure data");

  const partCount = partsFromStructure.length;
  const guidelineCount = partsFromStructure
    .map((x) => x.guideline_references.length)
    .reduce((acc, x) => acc + x, 0);

  const lastPart = getLastSubmittedPart(review.data, structure.data);

  const showNextAction =
    !review.data.is_read_only &&
    !review.data.progress.is_review_completed &&
    !!lastPart;

  return (
    <div>
      <div
        css={{ padding: `${space.xl} 0`, backgroundColor: colors.whiteLilac }}
      >
        <MaxWidthContainer>
          <SpacedChildren size="xs">
            <BackLink hideIcon href="/reviews">
              Review Tool
            </BackLink>

            <div
              css={css`
                display: grid;
                grid-template-areas: "meta" "intro";
                grid-gap: ${space.lg};

                @media ${mediaQueries.md} {
                  grid-template-areas: "intro meta";
                  grid-template-columns: 1fr min-content;
                }
              `}
            >
              <SpacedChildren
                size="md"
                css={{ gridArea: "intro", maxWidth: 800 }}
              >
                <Title level={2} as="h1" noMargin>
                  {review.data.title}
                </Title>
                <Paragraph size="large">
                  There are {guidelineCount} guidelines in this review split
                  across {partCount} topics. As you assess guidelines the Review
                  Tool will automatically calculate your UX performance and
                  update your scorecard.
                </Paragraph>
                <Paragraph size="large">
                  Select a topic from the list below or expand the scorecard to
                  select a specific guideline.
                  {showNextAction && (
                    <>
                      {" "}
                      Alternatively use the button below to pick up where you
                      left off.
                    </>
                  )}
                </Paragraph>

                {showNextAction && (
                  <div css={{ paddingTop: space.md }}>
                    <ActionLink
                      variant="primary"
                      href={`/reviews/${review.id}/parts/${lastPart.id}`}
                    >
                      Continue rating <strong>{lastPart.title}</strong> topic
                    </ActionLink>
                  </div>
                )}
              </SpacedChildren>

              <div css={{ gridArea: "meta" }}>
                <SpacedChildren
                  size="sm"
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    [`@media ${mediaQueries.md}`]: {
                      paddingTop: 5,
                      flexDirection: "column",
                    },
                  }}
                >
                  <JumpToGuideline>
                    {(onClick) => (
                      <IconLink icon="search" onClick={onClick}>
                        Jump to guideline or topic
                      </IconLink>
                    )}
                  </JumpToGuideline>

                  <Divider />

                  <IconLink
                    href={`/reviews/${review.id}/duplicate`}
                    icon="duplicate"
                    testingName="duplicate-link"
                  >
                    Duplicate review
                  </IconLink>

                  {!review.data.is_read_only && (
                    <>
                      <Divider />
                      <IconLink
                        href={`/reviews/${review.id}/edit`}
                        icon="editOutline"
                        testingName="edit-or-delete-link"
                      >
                        Edit review
                      </IconLink>
                    </>
                  )}

                  {claims.isAdmin && (
                    <>
                      <Divider />
                      <ReviewComparisonAttachDetachToggle />
                    </>
                  )}
                </SpacedChildren>
              </div>
            </div>
          </SpacedChildren>
        </MaxWidthContainer>
      </div>
      <div css={{ borderBottom: `1px solid ${colors.gray}` }}>
        <MaxWidthContainer>
          <ReviewMetaInfo />
        </MaxWidthContainer>
      </div>
    </div>
  );
});

const ReviewMetaInfo = observer(() => {
  const { review, structure, isDelivered } = useReviewStore();
  const { claims } = useAuth();
  assert(review.data, "Unable to render without review");
  assert(structure.data, "Unable to render without structure");

  const {
    created_at,
    industry_id,
    description,
    tag_ids,
    last_released_at,
    last_released_by,
  } = review.data;

  const showAuditDeliveryInfo = claims.isAdmin && isDelivered;

  return (
    <div
      css={{
        fontSize: fontSizes.mdLg,
        padding: `${space.md} 0`,
        maxWidth: "100%",
      }}
    >
      <SpacedChildren size="sm">
        <MetaItemRow>
          <MetaItem label="Created" noWrap>
            {moment(created_at.toDate()).format("MMM D, YYYY")} {" | "}
            <div css={{ display: "inline-block" }}>
              <Username id={review.data.created_by} />
            </div>
          </MetaItem>

          <MetaItem label="Industry">{allIndustries[industry_id]}</MetaItem>

          <div css={{ flexShrink: 2 }}>
            <MetaItem label="Characteristics">
              {tag_ids.length
                ? tag_ids.map((id) => tags[id].name).join(", ")
                : "None"}
            </MetaItem>
          </div>

          <MetaItem label="Review Structure">
            {structure.data.title} v
            {parseComparableVersionString(structure.data.version)}
          </MetaItem>
        </MetaItemRow>

        <MetaItemRow>
          {showAuditDeliveryInfo && (
            <div css={{ maxWidth: "25%" }}>
              <MetaItem label="Audit delivery">
                <Moment
                  date={last_released_at?.toDate()}
                  format="MMM D, YYYY"
                />
                {last_released_by && (
                  <>
                    {" | "}
                    <Username id={last_released_by} />
                  </>
                )}
                <br />
                <AuditDeliveryStatus />
              </MetaItem>
            </div>
          )}

          {description && (
            <div css={{ flex: 1 }}>
              <MetaItem label="Notes">
                <Truncate lines={2}>{description}</Truncate>
              </MetaItem>
            </div>
          )}
        </MetaItemRow>
      </SpacedChildren>
    </div>
  );
});

const MetaItemRow = styled.div`
  display: flex;
  gap: ${space.sm} ${space.md};
  flex-wrap: wrap;

  @media ${mediaQueries.md} {
    gap: ${space.sm} ${space.lg};
    flex-wrap: nowrap;
  }
`;

function MetaItem({
  label,
  children,
  noWrap,
}: {
  label: string;
  children: React.ReactNode;
  noWrap?: boolean;
}) {
  return (
    <div
      css={{ fontSize: fontSizes.md, whiteSpace: noWrap ? "nowrap" : "normal" }}
    >
      <div css={{ color: colors.slateGray }}>
        <Text variant="overline" cx={{ whiteSpace: "nowrap" }}>
          {label}
        </Text>
      </div>
      <div>{children}</div>
    </div>
  );
}

const Divider = styled.hr`
  border: none;
  background: ${colors.gray};
  height: 1px;
  width: 100%;
`;
