import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import styled from "styled-components";
import {
  BackLink,
  List,
  PageContainer,
  PageLoader,
  Paragraph,
  SpacedChildren,
  Title,
} from "~/components";
import { useReviewStore } from "~/features/reviews";
import { notify } from "~/modules/notifications";
import { colors, mediaQueries, radii, space } from "~/theme";
import { DuplicateReviewForm } from "./components";

export const ReviewDuplicatePage: NextPage = observer(() => {
  const router = useRouter();
  const store = useReviewStore();

  if (store.isLoading) {
    return <PageLoader />;
  }
  const { review } = store;
  const reviewId = router.query.reviewId as string;

  if (!review.data) {
    return null;
  }

  return (
    <PageContainer testingName="duplicate-page">
      <SpacedChildren>
        <BackLink href={`/reviews/${reviewId}`}>Back</BackLink>
        <Title level={2} as="h1">
          Duplicate Review
        </Title>
      </SpacedChildren>

      <TwoColumns>
        <div css={{ gridArea: "form" }}>
          <DuplicateReviewForm
            reviewId={reviewId}
            review={review.data}
            onFinish={goToReview}
            onCancel={() => goToReview(reviewId)}
          />
        </div>
        <div css={{ gridArea: "info" }}>
          <GrayBlock>
            <SpacedChildren>
              <Title level={4}>Not sure what duplicating does?</Title>
              <List noMargin>
                <ListItem>
                  <Paragraph>
                    The original review and its duplicated counterpart are fully
                    separate copies so you can safely edit one without affecting
                    the other.
                  </Paragraph>
                </ListItem>
                <ListItem>
                  <Paragraph noMargin>
                    Duplicating a review is helpful if you want to make a
                    before/after comparison.
                  </Paragraph>
                </ListItem>
              </List>
            </SpacedChildren>
          </GrayBlock>
        </div>
      </TwoColumns>
    </PageContainer>
  );

  function goToReview(reviewId: string) {
    assert(reviewId, "Missing review id in goToReview");

    router
      .push(`/reviews/[reviewId]`, `/reviews/${reviewId}`)
      .catch(notify.error);
  }
});

const TwoColumns = styled.div`
  display: grid;
  grid-template-areas: "info" "form";
  grid-column-gap: ${space.lg};
  grid-row-gap: ${space.lg};

  @media ${mediaQueries.md} {
    grid-template-areas: "form info";
  }
`;

const GrayBlock = styled.div`
  padding: ${space.lg};
  background: ${colors.whiteLilac};
  border-radius: ${radii.lg};
  color: ${colors.charcoal};
`;

const ListItem = styled.li`
  margin-bottom: ${space.md};
  &:last-child {
    margin-bottom: 0;
  }
`;
