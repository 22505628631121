import type { PanInfo } from "framer-motion";
import { motion } from "framer-motion";
import React from "react";
import { css } from "styled-components";
import { VimeoPlayer } from "~/components/vimeo-player";
import { radii, space } from "~/theme";
import { isVimeoUrl } from "./logic";
import type { Direction } from "./use-carousel-navigation";

type MediaCarouselSlideProps = {
  src: string;
  direction: Direction;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
};

/**
 * Animation + logic is copy pasted from
 * https://codesandbox.io/s/framer-motion-image-gallery-pqvx3
 */

const variants = {
  enter: (direction: Direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: Direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

export function MediaCarouselSlide({
  src,
  direction,
  onSwipeLeft,
  onSwipeRight,
}: MediaCarouselSlideProps) {
  const motionProps = React.useMemo(
    () => ({
      custom: direction,
      variants: variants,
      initial: "enter",
      animate: "center",
      exit: "exit",
      transition: {
        x: { duration: 0.25 },
        opacity: { duration: 0.25 },
      },
      drag: "x" as const,
      dragConstraints: { left: 0, right: 0 },
      dragElastic: 1,
      onClick: (evt: React.MouseEvent<HTMLDivElement>) => evt.stopPropagation(),
      onDragEnd: (evt: DragEvent, { offset, velocity }: PanInfo) => {
        const swipe = swipePower(offset.x, velocity.x);

        if (swipe < -swipeConfidenceThreshold) {
          onSwipeLeft();
        } else if (swipe > swipeConfidenceThreshold) {
          onSwipeRight();
        }
      },
    }),
    [direction, onSwipeLeft, onSwipeRight]
  );

  return (
    <div
      css={css`
        position: absolute;
        top: ${space.lg};
        left: ${space.xl};
        right: ${space.xl};
        bottom: ${space.lg};
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      {isVimeoUrl(src) ? (
        <motion.div
          style={{
            width: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            borderRadius: radii.lg,
          }}
          {...motionProps}
        >
          <VimeoPlayer src={src} ratio={16 / 9} />
        </motion.div>
      ) : (
        <motion.img
          src={src}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            borderRadius: radii.lg,
          }}
          {...motionProps}
        />
      )}
    </div>
  );
}

/**
 * Experimenting with distilling swipe offset and velocity into a single
 * variable, so the less distance a user has swiped, the more velocity they need
 * to register as a swipe. Should accomodate longer swipes and short flicks
 * without having binary checks on just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
