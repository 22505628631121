import { Tag } from "antd";
import React from "react";
import { isDefined } from "ts-is-present";

type PointScoreTagProps = {
  value?: number;
  style?: React.CSSProperties;
};

export const PointScoreTag: React.FC<PointScoreTagProps> = ({
  value,
  style,
}) => {
  if (!isDefined(value)) return null;

  if (value === 0) {
    return (
      <Tag color="blue" style={style}>
        {value}
      </Tag>
    );
  }
  if (value < 0) {
    return (
      <Tag color="orange" style={style}>
        {value}
      </Tag>
    );
  } else {
    return (
      <Tag color="lime" style={style}>
        {value}
      </Tag>
    );
  }
};
