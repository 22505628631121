import Link from "next/link";
import styled, { css } from "styled-components";
import { fontSizes, fontWeights } from "~/theme";

export const anchorStyles = css`
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeights.semibold};
  color: white;
  &:hover {
    opacity: 0.9;
  }
`;

export const SidebarAnchor = styled(Link)`
  ${anchorStyles}
`;

export const SidebarAnchorButton = styled.button`
  ${anchorStyles};
  display: inline;
  padding: 0;
  border: 0;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  text-align: inherit;
`;
