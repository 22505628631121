import React from "react";
import { isOpenInNewTabEvent } from "~/modules/event";
import { preloadImage, useSources } from "./logic";
import { MediaCarouselModal } from "./media-carousel-modal";
import { useCarouselNavigation } from "./use-carousel-navigation";

type Fn<T, P> = (src: T) => P;
type ShowSourceFn = (src: string, onClose?: () => void) => void;

const adaptiveCarouselContext = React.createContext<{
  sources: string[];
  addSource: Fn<string, void>;
  removeSource: Fn<string, void>;
  hasSource: Fn<string, boolean>;
  showSource: ShowSourceFn;
}>({
  sources: [],
  addSource: () => undefined,
  removeSource: () => undefined,
  hasSource: () => false,
  showSource: () => undefined,
});

/**
 * The `AdaptiveCarouselContextProvider` allows you to create a modal carousel
 * without knowing beforehand which media will be part of the carousel. They
 * will be injected in the carousel when a child component calls the
 * `useAdaptiveCarouselClickHandler`-hook which in turn returns a click handler
 * in order to show the media as part of the context-carousel.
 *
 * Links to images and vimeo-videos are supported.
 *
 * Usage:
 *
 * Wrap the part of the component tree where the links to media will be rendered
 * with the `AdaptiveCarouselContextProvider`:
 *
 *     <AdaptiveCarouselContextProvider>
 *       <SomeDeepComponentTree />
 *     </AdaptiveCarouselContextProvider>;
 *
 * Somewhere inside this component you call the
 * `useAdaptiveCarouselClickHandler` in order to add the media to the
 * context-carousel and get a click handler:
 *
 *     function SomeComponentDeepInsideTheTree({ href }) {
 *       const showMediaInCarousel = useAdaptiveCarouselClickHandler(href);
 *       return (
 *         <div>
 *           <a href={href} onClick={showMediaInCarousel}>
 *             link to an image/video
 *           </a>
 *         </div>
 *       );
 *     }
 */
export function AdaptiveCarouselContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [onCloseCallback, setOnCloseCallback] = React.useState<
    (() => void) | undefined
  >();
  const [sources, addSource, removeSource] = useSources();
  const { src, navigation } = useCarouselNavigation(sources, onCloseCallback);
  const { showIndex } = navigation;

  const hasSource = React.useCallback(
    (src: string) => {
      return sources.includes(src);
    },
    [sources]
  );

  const showSource = React.useCallback(
    (src: string, onClose?: () => void) => {
      showIndex(sources.indexOf(src));
      setOnCloseCallback(() => onClose);
    },
    [sources, showIndex]
  );

  const contextValue = React.useMemo(
    () => ({
      sources,
      addSource,
      removeSource,
      hasSource,
      showSource,
    }),
    [sources, addSource, removeSource, hasSource, showSource]
  );

  return (
    <adaptiveCarouselContext.Provider value={contextValue}>
      <MediaCarouselModal src={src} navigation={navigation} />
      {children}
    </adaptiveCarouselContext.Provider>
  );
}

export function useAdaptiveCarouselEventHandlers<T extends React.MouseEvent>(
  href: string
) {
  const { hasSource, addSource, removeSource, showSource } = React.useContext(
    adaptiveCarouselContext
  );

  React.useEffect(() => {
    addSource(href);
    return () => removeSource(href);
  }, [href, addSource, removeSource]);

  const showSourceWrapper = React.useCallback(
    (evt: T) => {
      const target = evt.target as HTMLElement | undefined;

      if (!hasSource(href)) {
        return;
      }

      evt.preventDefault();

      if (isOpenInNewTabEvent(evt)) {
        return window.open(href);
      }

      showSource(href, () => {
        if (target && typeof target.focus === "function") {
          target.focus();
        }
      });
    },
    [showSource, hasSource, href]
  );

  const preloadSource = React.useCallback(() => {
    preloadImage(href);
  }, [href]);

  return { onClick: showSourceWrapper, onMouseEnter: preloadSource };
}
