import type { Scenario, ScenarioMap } from "../schemas";
import { assert } from "../utils";

export function getAllBranchScenarios(
  scenarios: ScenarioMap,
  scenarioId: string
): Scenario[] {
  const branchRoot = scenarios[scenarioId];

  assert(branchRoot, `Missing scenario ${scenarioId}`);

  const children = getChildrenRecursively(Object.values(scenarios), scenarioId);

  return [branchRoot, ...children];
}

function getChildrenRecursively(
  scenarios: Scenario[],
  scenarioId: string
): Scenario[] {
  const children = scenarios.filter((x) => x.parent_id === scenarioId);

  return [
    ...children,
    ...children.flatMap((x) => getChildrenRecursively(scenarios, x.id)),
  ];
}
