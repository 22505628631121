import type { TippyProps } from "@tippyjs/react";
import Tippy from "@tippyjs/react";
import type { Instance } from "tippy.js";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import { fontSizes } from "~/theme";

let handleMount: undefined | ((tippyInstance: Instance) => void);

/**
 * Usage: <WithTooltip content={<p>message</p>}> <button>foo</button>
 * </WithTooltip>
 */

export function WithTooltip({ content, ...props }: TippyProps) {
  if (!content) {
    return <>{props.children}</>;
  }

  return (
    <Tippy
      {...(handleMount ? { onMount: handleMount } : {})}
      appendTo={getBody}
      maxWidth="320px"
      animation="shift-away"
      theme="light-border"
      content={
        typeof content === "string" ? (
          <span css={{ fontSize: fontSizes.sm }}>{content}</span>
        ) : (
          content
        )
      }
      {...props}
    />
  );
}

function getBody() {
  return document.body;
}

if (process.env.NODE_ENV !== "production") {
  /** This entire block won't be part of a production build */
  const isFocusableSelector =
    "button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]), details:not([disabled]), summary:not(:disabled)";

  handleMount = function reportIsFocusable(tippyInstance: Instance) {
    const { reference } = tippyInstance;

    if (!reference.matches(isFocusableSelector)) {
      console.error({ tippyInstance, reference });
      throw new Error(
        `A tooltip has been wrapped around an element which cannot receive focus state. Please make it focusable for a11y by setting tabIndex={0} or explicitly set tabIndex={-1}.`
      );
    }
  };
}
