import { cloneDeep, every, isEmpty, some } from "lodash-es";
import { isDefined } from "ts-is-present";
import type { JsonObject } from "type-fest";
import type { ScenarioMap } from "~/schemas";
import type { Changeset, LeafChange } from "../changesets";
import { applyChanges, diff, isLeafChange } from "../changesets";
import type { VersionedScenarioTextField } from "./constants";
import { versionedTextFields } from "./constants";

export function calculateChanges(
  oldScenarios: ScenarioMap,
  newScenarios: ScenarioMap
) {
  return diff(
    oldScenarios as unknown as JsonObject,
    newScenarios as unknown as JsonObject
  );
}

export function hasOnlyTextualChanges(changes: Changeset): boolean {
  return every(changes, (change) =>
    isLeafChange(change)
      ? versionedTextFields.includes(change.key as VersionedScenarioTextField)
      : hasOnlyTextualChanges(change.changes)
  );
}

export function hasTextualChanges(changes: Changeset): boolean {
  /**
   * Using the native Array.some function broke the functionality. @TODO figure
   * out why that is
   */
  return some(changes, (change) =>
    isLeafChange(change)
      ? versionedTextFields.includes(change.key as VersionedScenarioTextField)
      : hasTextualChanges(change.changes)
  );
}

/**
 * Changes have the following shape:
 *
 * [ { changes: [ { type: 'update', value: 'vh', oldValue: 'ir', key:
 * 'translation_value' } ], key: 'P6MmqD2W', type: 'update' } ],
 *
 * This means that the first level of keys must be ignored for textual changes.
 * The first level will only describe update / add / remove actions on the
 * scenario level. Then the nested changes array contains the changes to the
 * scenario properties. It's these we want to target.
 */

export function stripTextualChanges(revisionChanges: Changeset): Changeset {
  return revisionChanges.map((scenarioChange) => {
    if (isLeafChange(scenarioChange)) {
      /**
       * The change to the scenario could be something else then a nested
       * property change. For example add/remove scenario. Pass these changes
       * unaltered.
       */
      return scenarioChange;
    }

    return {
      key: scenarioChange.key,
      type: scenarioChange.type,
      changes: scenarioChange.changes
        .map((propertyChange) =>
          isLeafChange(propertyChange) && isTextualChange(propertyChange)
            ? undefined
            : propertyChange
        )
        .filter(isDefined),
    };
  });
}

function isTextualChange(change: LeafChange) {
  return versionedTextFields.includes(change.key as VersionedScenarioTextField);
}

/**
 * The original applyChanges from diff-json mutates the data in place. We export
 * an immutable version here, with the added benefit that we do not have to
 * include that library outside of the common module.
 */
export function applyScenarioChanges(
  scenarios: ScenarioMap,
  changes: Changeset
): [ScenarioMap, string[]] {
  if (isEmpty(changes)) {
    return [scenarios, []];
  }

  const updatedScenarios = cloneDeep(scenarios);

  const failedChanges = applyChanges(
    updatedScenarios as unknown as JsonObject,
    changes
  );

  const failedScenarioIds = failedChanges.map((x) => {
    return x.key;
  });

  return [updatedScenarios, failedScenarioIds];
}
