import { getErrorMessage } from "@gemini/common";
import { usePubsubChannel } from "@klaasman/pubsub-channel";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import { MeasureHeight, PageLoader } from "~/components";
import { useUserId } from "~/modules/auth";
import { notify } from "~/modules/notifications";
import { useAssessmentStore } from "../assessment";
import { Assess, Summary } from "./components";
import { embeddedPubsubChannels } from "./pubsub";

export const EmbeddedAssessmentPage = observer(
  function EmbeddedAssessmentPage() {
    const router = useRouter();
    const userId = useUserId();
    const { submitAssessment, isSubmitDisabled, hasAssessment } =
      useAssessmentStore();

    usePubsubChannel(embeddedPubsubChannels.navigate, undefined, (url) => {
      router.replace(url).catch(() => (window.location.href = url));
    });

    const [, setIsDisabled] = usePubsubChannel(
      embeddedPubsubChannels.isSubmitDisabled,
      true
    );
    setIsDisabled(isSubmitDisabled, window.parent);

    const [page, setPage] = usePubsubChannel(
      embeddedPubsubChannels.pageType,
      undefined,
      (page) => {
        if (page === "submit") {
          submitAssessment(userId)
            .then(() => setPage("summary", window.parent))
            .catch((err) => notify.error(getErrorMessage(err)));
        }
      }
    );

    if (page === undefined) {
      setPage(hasAssessment ? "summary" : "assess", window.parent);
    }

    if (page === undefined) {
      return <PageLoader />;
    }

    return (
      <MeasureHeight
        onChange={(height) =>
          embeddedPubsubChannels.height.publish(height, window.parent)
        }
      >
        {page === "summary" ? <Summary /> : <Assess />}
      </MeasureHeight>
    );
  }
);
