import type {
  DerivedGuidelinesByReference,
  ReviewStructure,
  ReviewStructureFmtAmbrosia,
} from "@gemini/common";
import {
  convertPartToAmbrosiaFormat,
  convertSectionsToAdminFormat,
  convertStructureToAdminFormat,
  convertStructureToAmbrosiaFormat,
} from "@gemini/common";
import { useMemo } from "react";
import type { Document } from "~/modules/firestore";
import { notify } from "~/modules/notifications";
import { isReviewStructurePart } from "~/utils/identity";

export function useStructureAdminFormat(
  structure: Document<ReviewStructure>,
  currentGuidelines: DerivedGuidelinesByReference
) {
  return useMemo(
    () => convertStructureToAdminFormat(structure.data, currentGuidelines),
    [structure, currentGuidelines]
  );
}

export function useSectionsAdminFormat(
  sections: ReviewStructure["sections"],
  currentGuidelines: DerivedGuidelinesByReference
) {
  return useMemo(
    () => convertSectionsToAdminFormat(sections, currentGuidelines),
    [sections, currentGuidelines]
  );
}

export function useStructureAmbrosiaFormat(
  structure: Document<ReviewStructure>,
  currentGuidelines: DerivedGuidelinesByReference,
  partId?: string
) {
  if (!partId) {
    return convertStructureToAmbrosiaFormat(
      structure.id,
      structure.data,
      currentGuidelines
    );
  }

  const part = structure.data.sections[partId];

  if (!part) {
    const errorMessage = `Failed to locate part ${partId} in structure ${structure.id}`;
    notify.error(errorMessage);
    return;
  }

  if (!isReviewStructurePart(part)) {
    const errorMessage = `Section ${partId} in structure ${structure.id} is not a part`;
    notify.error(errorMessage);
    return;
  }

  const formattedPart = convertPartToAmbrosiaFormat(
    partId,
    part,
    currentGuidelines
  );

  const formattedStructure: ReviewStructureFmtAmbrosia = {
    id: `gemini-${structure.id}@${partId}`,
    title: `${structure.data.title}: ${formattedPart.title}`,
    summaries: [formattedPart],
  };

  return formattedStructure;
}
