import React from "react";
import styled, { css } from "styled-components";
import { colors, fontSizes, fontWeights, space } from "~/theme";
import { Paragraph } from "./typography";

type FieldProps = {
  label: React.ReactNode;
  children: React.ReactNode;
  description?: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  required?: boolean;
  labelExtra?: React.ReactNode;
  htmlFor?: string;
};

export function Field({
  label,
  description,
  children,
  as,
  required,
  labelExtra,
  htmlFor,
}: FieldProps) {
  const Tag = as || "label";

  return (
    <Tag style={{ display: "block" }} htmlFor={htmlFor}>
      <span
        css={css`
          display: inline-block;
          margin-bottom: ${space.xs};
          font-weight: ${fontWeights.semibold};
          color: ${colors.charcoal};
        `}
      >
        {required && <Asterisk />} {label}{" "}
        {labelExtra && <StyledLabelExtra>{labelExtra}</StyledLabelExtra>}
      </span>
      {description && (
        <Paragraph cx={{ marginBottom: space.sm }}>{description}</Paragraph>
      )}
      {children}
    </Tag>
  );
}

const StyledLabelExtra = styled.span`
  font-weight: ${fontWeights.regular};
  font-size: ${fontSizes.sm};
`;

function Asterisk() {
  return (
    <span
      css={css`
        position: absolute;
        transform: translateX(-150%);
      `}
    >
      *
    </span>
  );
}
