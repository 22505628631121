import React from "react";
import styled, { css } from "styled-components";
import { useOnClickOutside } from "~/modules/hooks/use-on-click-outside";
import { colors, fontSizes, fontWeights, space } from "~/theme";
import { inputStyle } from "./input";
import { RadioWithLabelAndDescription } from "./radio";

export type InlineSelectOption<T> = {
  label: string;
  value: T | undefined;
  description?: string;
  isHidden?: boolean;
};
export type InlineSelectProps<T> = {
  options: InlineSelectOption<T>[];
  value: T | undefined;
  onChange: (value: T | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  testingName?: string;
  closeOnSelect?: boolean;
};

export function InlineSelect<T extends string | undefined>({
  options,
  value,
  onChange,
  placeholder,
  disabled,
  testingName,
  closeOnSelect,
}: InlineSelectProps<T>) {
  const [isOpen, setIsOpen] = React.useState(false);
  const selectedOption = options.find((x) => x.value === value);
  const visibleOptions = options.filter((x) => !x.isHidden);
  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => closeOnSelect && setIsOpen(false));

  return (
    <Wrapper
      ref={ref}
      value={value}
      isOpen={!disabled && isOpen}
      data-t={testingName}
      disabled={disabled}
    >
      <SelectedOption
        value={value}
        isOpen={!disabled && isOpen}
        option={selectedOption}
        placeholder={placeholder}
        disabled={disabled}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <div css={{ fontStyle: "normal" }}>
          {visibleOptions.map((x) => (
            <div
              key={x.value}
              css={css`
                &:not(:last-child) {
                  margin-bottom: 1.6rem;
                  padding-bottom: 0.8rem;
                  border-bottom: 1px solid ${colors.athensGray};
                }
              `}
            >
              <RadioWithLabelAndDescription
                onChange={(checked) => checked && onChange(x.value)}
                onClick={() => closeOnSelect && setIsOpen(false)}
                checked={x.value === value}
                label={x.label}
                description={x.description}
              />
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (x) => !["value", "isOpen", "disabled"].includes(x),
})<{
  value?: string;
  isOpen?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  ${inputStyle};
  color: inherit;
  padding: ${(x) => (x.isOpen ? `${space.md} 2rem` : 0)};
`;

function SelectedOption<T>({
  value,
  option,
  placeholder,
  onClick,
  isOpen,
  disabled,
}: {
  value: string | undefined;
  option?: InlineSelectOption<T>;
  placeholder?: string;
  onClick?: () => void;
  isOpen?: boolean;
  disabled?: boolean;
}) {
  const buttonProps = isOpen
    ? {}
    : {
        type: "button",
        onClick: disabled ? undefined : onClick,
      };

  return (
    <StyledValue
      disabled={disabled}
      as={isOpen ? "div" : "button"}
      value={value}
      {...buttonProps}
      css={css`
        margin-bottom: ${isOpen ? space.md : 0};
        padding: ${isOpen ? 0 : `${space.md} 2rem`};
      `}
    >
      <span>{option?.label || placeholder}</span>
      <div
        css={css`
          pointer-events: none;
          position: absolute;
          right: ${isOpen ? "0" : "2rem"};
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: ${fontWeights.semibold};
          font-size: ${fontSizes.sm};
          color: ${colors.slateGray};
        `}
      >
        edit
      </div>
    </StyledValue>
  );
}

const StyledValue = styled.button`
  ${inputStyle};
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  text-align: left;
  display: inline-block;
  background: transparent;
`;
