import type { ReactNode } from "react";
import React, { useEffect } from "react";
import useDimensions from "react-cool-dimensions";

export function MeasureHeight({
  onChange,
  children,
}: {
  onChange: (height: number) => void;
  children: ReactNode;
}) {
  const heightRef = React.useRef<number>();
  const { observe } = useDimensions<HTMLDivElement>({
    onResize: ({ height }) => {
      const roundedHeight = Math.ceil(height);

      if (heightRef.current !== roundedHeight) {
        heightRef.current = roundedHeight;
        onChange(roundedHeight);
      }
    },
  });

  useEffect(() => {
    /**
     * For some reason the observe must be called in the next event loop. It
     * works and I don't think it's worth the effort to figure out why.
     */
    const id = window.setTimeout(observe, 0);
    return () => window.clearTimeout(id);
  }, [observe]);

  return <div ref={observe}>{children}</div>;
}
