import { isEmpty } from "lodash-es";
import { z } from "zod";
import type {
  Dictionary,
  ReviewStructureFmtAdmin,
  ReviewStructureGroupFmtAdmin,
  ReviewStructurePartFmtAdmin,
} from "..";
import { isReviewStructurePartFmtAdmin } from "./identity";

const sectionBaseSchema = z.object({
  title: z.string(),
  semantic_level: z.string().optional(),
  sentence_reference_key: z.string().optional(),
});

type PartSection = z.infer<typeof sectionBaseSchema> & {
  guideline_citation_codes: Array<PartSection | GroupSection>;
};

const partSectionSchema = sectionBaseSchema.extend({
  guideline_citation_codes: z.string().array(),
});

type GroupSection = z.infer<typeof sectionBaseSchema> & {
  sections: Section[];
};

// @ts-expect-error @TODO don't know how to type this yet
const groupSectionSchema: z.ZodType<GroupSection> = sectionBaseSchema.extend({
  sections: z.lazy(() => sectionSchema.array()),
});

type Section = PartSection | GroupSection;

const sectionSchema = partSectionSchema.or(groupSectionSchema);

const rootSchema = z.lazy(() => sectionSchema.array());

export function validateSectionsStructure(
  data: ReviewStructureFmtAdmin["sections"]
): [boolean, string?] {
  const result = rootSchema.safeParse(data);

  if (!result.success) {
    return [false, "The YAML structure seems to be invalid"];
  } else {
    return [true];
  }
}

/** See if all citation codes in the sections can be resolved to a guideline */
export function validateSectionsCitationCodes(
  sections: ReviewStructureFmtAdmin["sections"],
  referenceByCitationCode: Dictionary<string>
) {
  const citationCodes = sections.flatMap(getCitationCodesRecursive);

  const unresolvedCitationCodes = citationCodes.filter(
    (x) => !referenceByCitationCode[x]
  );

  return [isEmpty(unresolvedCitationCodes), unresolvedCitationCodes] as const;
}

function getCitationCodesRecursive(
  partOrGroup: ReviewStructurePartFmtAdmin | ReviewStructureGroupFmtAdmin
): string[] {
  if (isReviewStructurePartFmtAdmin(partOrGroup)) {
    return partOrGroup.guideline_citation_codes;
  } else {
    return partOrGroup.sections.flatMap(getCitationCodesRecursive);
  }
}
