import { transparentize } from "polished";
import React from "react";
import type { CSSProp } from "styled-components";
import styled, { css } from "styled-components";
import {
  colors,
  durations,
  fontSizes,
  lineHeights,
  radii,
  space,
  timingFunctions,
} from "~/theme";

export type InputProps = {
  value?: string | undefined;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  required?: boolean;
  type?: "text" | "email" | "password";
  disabled?: boolean;
  testingName?: string;
  autoFocus?: boolean;
  cx?: CSSProp;
  id?: string;
  name?: string;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ onChange, testingName, ...props }, ref) => {
    const handleChange = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) =>
        onChange?.(evt.target.value),
      [onChange]
    );
    return (
      <StyledInput
        ref={ref}
        onChange={handleChange}
        data-t={testingName}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

/**
 * Note that changes to this `inputStyle` are also applied on other components
 * like TextArea and Select.
 */
export const inputStyle = css<{ value?: string; disabled?: boolean }>`
  padding: ${space.md} 2rem;
  text-indent: ${(x) => (x.value ? undefined : "1px")};
  border: 1px solid ${transparentize(0.25, colors.ghostGray)};
  border-radius: ${radii.md};
  outline: none;
  width: 100%;
  font-size: ${fontSizes.md};
  font-family: inherit;
  line-height: ${lineHeights.lg};
  font-style: ${(x) => (x.value ? undefined : "italic")};
  appearance: none;
  background: white;

  color: ${(x) => (x.value ? colors.charcoal : colors.placeholder)};
  ::placeholder {
    color: ${colors.placeholder};
  }

  transition-property: border-color, color, box-shadow;
  transition-duration: ${durations.sm};
  transition-timing-function: ${timingFunctions.out};

  opacity: ${(x) => (x.disabled ? 0.5 : 1)};

  &:hover:not(:disabled) {
    border-color: ${colors.slateGray};
  }

  &:focus {
    border-color: ${colors.indigo};
    box-shadow: 0 0 0px 1px ${colors.indigo}; // mimic an outline with radius
  }
`;

const StyledInput = styled.input.withConfig<{ cx?: CSSProp }>({
  shouldForwardProp: (x) => !["cx"].includes(x),
})`
  ${inputStyle}
  ${(x) => x.cx};
`;
