import type { JudgementValue, TranslationValue } from "@gemini/common";
import {
  AH_VALUE,
  AL_VALUE,
  NA_VALUE,
  NU_VALUE,
  VH_VALUE,
  VL_VALUE,
} from "@gemini/common";

export const breakpoints = {
  /** Equals 600px */
  sm: "37.5em",
  /** Equals 960px */
  md: "60em",
  /** Equals 1280px */
  lg: "80em",
  /** Equals 1920px */
  xl: "120em",
} as const;

export const mediaQueries = {
  /** 600px and up */
  sm: `all and (min-width: 37.5em)`,
  /** 960px and up */
  md: `all and (min-width: 60em)`,
  /** 1280px and up */
  lg: `all and (min-width: 80em)`,
  /** 1920px and up */
  xl: `all and (min-width: 120em)`,
} as const;

export const colors = {
  charcoal: "#303030",
  gray: "#dddddd",
  grayLight: "#f2f2f2",
  grayMedium: "#a2a2a2",
  grayDark: "#686767",

  blueZodiac: "#0b233c",
  blueGray: "#545963",
  slateGray: "#738196",
  ghostGray: "#caccda",
  athensGray: "#ebecf3",
  whiteLilac: "#f5f7fb",
  indigo: "#4D7CC7",

  success: "#a1b145",
  danger: "#B42625",

  placeholder: "#6E6E6E",

  judgement: {
    [AH_VALUE]: "#769b37",
    [AL_VALUE]: "#a1b145",
    [NU_VALUE]: "#ffc302",
    [VL_VALUE]: "#ea7a0d",
    [VH_VALUE]: "#b42625",
    [NA_VALUE]: "#a2a2a2",
  } as Record<JudgementValue, string>,

  translationValue: {
    vh: "#b42625",
    vl: "#ea7a0d",
    neutral: "#ffc302",
    al: "#a1b145",
    ah: "#769b37",
    ir: "#4D7CC7",
    na: "#a2a2a2",
  } as Record<TranslationValue, string>,

  pointScores: {
    negative: "#ea7a0d",
    neutral: "#ffc302",
    positive: "#a1b145",
  },
} as const;

export const fonts = {
  body: "proxima-nova, -apple-system, BlinkMacSystemFont, Avenir, Helvetica Neue, Noto Sans, Roboto, Arial, Lucida Sans, Verdana, sans-serif",
  heading: "inherit",
  monospace: "Menlo, monospace",
} as const;

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const;

export const lineHeights = {
  xs: 1.1,
  sm: 1.2,
  md: 1.333,
  lg: 1.5,
  xl: 1.666,
  xxl: 2,
} as const;

export const fontSizes = {
  xxxs: "1.0rem",
  xxs: "1.1rem",
  xs: "1.3rem",
  sm: "1.5rem",
  md: "1.6rem",
  mdLg: "1.8rem",
  lg: "2.0rem",
  xl: "2.4rem",
  xxl: "3.2rem",
  xxxl: "4.4rem",
} as const;

export const radii = {
  sm: "2px",
  md: "4px",
  lg: "8px",
  xl: "12px",
} as const;

export const space = {
  xs: "0.4rem",
  sm: "0.8rem",
  md: "1.6rem",
  lg: "3.2rem",
  xl: "6.4rem",
} as const;

export const durations = {
  sm: "100ms",
  md: "200ms",
  lg: "300ms",
} as const;

export const timingFunctions = {
  in_out: "cubic-bezier(0.4, 0, 0.2, 1)",
  in: "cubic-bezier(0.4, 0, 1, 1)",
  out: "cubic-bezier(0, 0, 0.2, 1)",
} as const;

export const sizes = {
  pageWidth: "1179px",
  pageWidthNarrow: "564px",
};

export const zIndices = {
  modal: 100,
};

export const theme = {
  breakpoints,
  mediaQueries,
  colors,
  fonts,
  fontWeights,
  lineHeights,
  fontSizes,
  radii,
  space,
  durations,
  timingFunctions,
} as const;

export type Theme = typeof theme;
