import { PlusOutlined } from "@ant-design/icons";
import { industrySelectOptions, type IndustryId } from "@gemini/common";
import { Select, Tag } from "antd";
import React from "react";

type IndustrySelection = IndustryId | "__no_selection";

type IndustrySelectorProps = {
  industryId?: IndustrySelection;
  onChange: (oldValue: IndustryId, newValue: IndustryId) => void;
  onRemove: (removedValue?: IndustryId) => void;
};

export function IndustrySelector(props: IndustrySelectorProps) {
  const [showSelector, setShowSelector] = React.useState(
    props.industryId !== "__no_selection"
  );

  function toggleShowSelector() {
    setShowSelector(!showSelector);
  }

  return (
    <div
      css={`
        margin-bottom: 0.5em;
        margin-right: 1em;
      `}
    >
      {showSelector ? (
        <>
          <Select<IndustrySelection>
            style={{ minWidth: "12em", marginBottom: "0.5em" }}
            defaultValue={props.industryId || "__no_selection"}
            onChange={(value) => {
              if (value === "__no_selection") {
                props.onRemove(props.industryId as IndustryId | undefined);
                toggleShowSelector();
              } else {
                props.onChange(
                  props.industryId as IndustryId,
                  value as IndustryId
                );
              }
            }}
            size="small"
          >
            <Select.Option key="__no_selection" value="__no_selection">
              (do not match industry)
            </Select.Option>
            {industrySelectOptions.map(([id, name]) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </>
      ) : (
        <Tag
          onClick={() => toggleShowSelector()}
          style={{ background: "#fff", borderStyle: "dashed" }}
        >
          <PlusOutlined /> Add Industry
        </Tag>
      )}
    </div>
  );
}
