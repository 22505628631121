import { createIndex, getSearchClient } from "instantsearch-itemsjs-adapter";
import type {
  ItemsJsOptions,
  SearchClient,
} from "instantsearch-itemsjs-adapter/lib/itemsjsInterface";
import { isEqual } from "lodash-es";
import { useRef } from "react";
import type { GuidelineHit } from "./create-guideline-hits";

export function useGuidelineTableSearchClient(
  itemsJsOptions: ItemsJsOptions,
  hits: GuidelineHit[]
) {
  const previousData = useRef<unknown>();
  const searchClientRef = useRef<SearchClient>();

  if (!isEqual(hits, previousData.current)) {
    previousData.current = hits;

    searchClientRef.current = getSearchClient(
      createIndex(hits, itemsJsOptions)
    );
  }

  return searchClientRef.current;
}
