import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import type { Rule } from "@gemini/common";
import { cleanObject, DEFAULT_POINTS_MAPPING } from "@gemini/common";
import { Button, Radio } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { VimeoPlayer } from "~/components";
import { RuleEditor } from "~/features/admin/components";
import { getVimeoId } from "~/modules/vimeo";
import type { EditStore } from "../store";
import { Comment } from "./comment";
import { ConditionalTextEditor } from "./conditional-text-editor";
import { OptionalTextEditor } from "./optional-text-editor";
import { PointsToValueMapping } from "./points-to-value-mapping";
import { SectionTitle } from "./section-title";

type RootScenarioEditViewProps = {
  store: EditStore;
};

/**
 * A simplified edit view based on ScenarioEditView but specifically for the
 * root / guideline-level. Some things do not apply here.
 */
export const RootScenarioEditView: React.FC<RootScenarioEditViewProps> =
  observer(({ store }) => {
    const scenario = store.editScenario;

    if (!scenario) return null;

    const {
      logic,
      logic_and_mode,
      points_mapping: children_points_mapping,
      description,
      instruction_text,
      conditional_instruction_text,
      rule,
    } = scenario;

    return (
      <div>
        <OptionalTextEditor
          title="Description"
          value={description}
          setValue={(v) => store.setScenarioOptionalTextField("description", v)}
        />

        <OptionalTextEditor
          title="Instruction Video URL"
          value={scenario.instruction_video_url}
          setValue={(v) =>
            store.setScenarioOptionalTextField("instruction_video_url", v)
          }
          comment="We only accept Vimeo videos. For example
          https://vimeo.com/58444378"
        />
        {scenario.instruction_video_url &&
          getVimeoId(scenario.instruction_video_url) && (
            <div
              css={{
                flexGrow: 0,
                width: 250,
              }}
            >
              <VimeoPlayer
                src={scenario.instruction_video_url}
                ratio={800 / 450}
              />
            </div>
          )}

        <OptionalTextEditor
          title="Instruction Text"
          value={instruction_text}
          setValue={(x) =>
            store.setScenarioOptionalTextField("instruction_text", x)
          }
        />
        <SectionTitle>Conditional Instruction Text</SectionTitle>
        <Comment>
          Conditional instruction text is visible below the regular instructions
          when the review matches the tag and industry settings.
        </Comment>
        {conditional_instruction_text ? (
          <ConditionalTextEditor
            conditionalText={conditional_instruction_text}
            onChange={store.setScenarioConditionalText}
          />
        ) : (
          <Button
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            ghost
            onClick={() =>
              store.setScenarioConditionalText({
                conditionals: [
                  {
                    text: "Some conditional text",
                    condition: {
                      tag_ids: [],
                    },
                  },
                ],
              })
            }
          >
            Add
          </Button>
        )}

        <SectionTitle>Logic Mode</SectionTitle>
        <Radio.Group
          value={logic}
          onChange={(e) => store.setScenarioLogic(e.target.value)}
          size="small"
        >
          <Radio.Button value={"and"}>AND</Radio.Button>
          <Radio.Button value={"or"}>OR</Radio.Button>
          <Radio.Button value={"points"}>Points</Radio.Button>
        </Radio.Group>

        {logic === "and" ? (
          <>
            <Radio.Group
              value={logic_and_mode}
              onChange={(e) => store.setScenarioLogicAndMode(e.target.value)}
              size="small"
            >
              <Radio.Button value={"acc"}>Accumulate</Radio.Button>
              <Radio.Button value={"avg"}>Average</Radio.Button>
            </Radio.Group>
          </>
        ) : null}

        {logic === "points" ? (
          <>
            <SectionTitle>Points to Translation Value Mapping</SectionTitle>

            {children_points_mapping ? (
              <PointsToValueMapping
                mapping={children_points_mapping}
                validation={store.validation}
                onChange={(x) => store.setChildrenPointsMapping(x)}
              />
            ) : (
              <Button
                onClick={() =>
                  store.setChildrenPointsMapping(DEFAULT_POINTS_MAPPING)
                }
              >
                Add Mapping
              </Button>
            )}
          </>
        ) : null}

        <SectionTitle>Guideline Rule</SectionTitle>
        <Comment>
          A rule can be used to exclude, include or pre-assess a guideline when
          the particular review matches the industry and/or tags.
        </Comment>
        {rule ? (
          <>
            <RuleEditor
              rule={rule}
              onChange={store.setScenarioRule}
              isRootScenario={true}
            />
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              ghost
              onClick={() => store.setScenarioRule()}
            >
              Remove Rule
            </Button>
          </>
        ) : (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            ghost
            size="small"
            onClick={() =>
              store.setScenarioRule(
                cleanObject<Rule>({
                  action: "include",
                  condition: {},
                })
              )
            }
          >
            Add Rule
          </Button>
        )}

        <SectionTitle>Scenarios</SectionTitle>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="small"
          ghost
          onClick={() => store.addChildScenario()}
        >
          Add Scenario
        </Button>
      </div>
    );
  });
