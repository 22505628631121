import { observer } from "mobx-react-lite";
import React from "react";
import { Branch, ExpandButton } from "./components";
import type { BranchContextProps } from "./context";
import { BranchContextProvider, useBranchContext } from "./context";
import { useChildScenarios } from "./hooks";

export const ScenarioBranch = observer((props: BranchContextProps) => {
  return (
    <BranchContextProvider value={props}>
      <RootBranch />
    </BranchContextProvider>
  );
});

function RootBranch() {
  const [ref, setFocusOnRef] = useFocusOnFirstInputInRef<HTMLDivElement>();
  const { isExpanded, setIsExpanded, readOnly } = useBranchContext();
  const parentId = "__root";
  const hasSiblingScenarios = useChildScenarios(parentId).length > 0;

  const handleExpandToggle = React.useCallback(() => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) setFocusOnRef();
  }, [setFocusOnRef, setIsExpanded, isExpanded]);

  if (!hasSiblingScenarios) return null;

  return (
    <div css={{ position: "relative" }} ref={ref}>
      {!readOnly && (
        <ExpandButton isExpanded={isExpanded} onClick={handleExpandToggle} />
      )}
      <Branch parentId={parentId} level={0} />
    </div>
  );
}

export function useFocusOnFirstInputInRef<T extends HTMLElement>() {
  const containerRef = React.useRef<T>(null);
  const setFocus = React.useCallback(() => {
    containerRef.current?.querySelector("input")?.focus();
  }, []);

  return [containerRef, setFocus] as const;
}
