import { cropRegions } from "~/config";
import { getImageDimensions } from "~/modules/image";

export type CropRegionsConfig = {
  width: number;
  height: number;
  topOffsets: Readonly<number[]>;
  bottomOffsets: Readonly<number[]>;
  defaultTopOffset: number;
  defaultBottomOffset: number;
};

export async function getCropRegionsConfigForFile(file: File) {
  const dimensions = await getImageDimensions(file);

  const { width, height } = dimensions;

  return cropRegions.find((x) => x.width === width && x.height === height);
}
