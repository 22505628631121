import type { Scenario, ScenarioMap } from "../schemas";

export function getSortedSelectedScenarios(
  scenarios: ScenarioMap,
  selectedIds: string[],
  parentId: string
): Scenario[] {
  return Object.values(scenarios)
    .filter(
      (scenario) =>
        scenario.parent_id === parentId && selectedIds.includes(scenario.id)
    )
    .sort((a, b) => a.position - b.position)
    .reduce<Scenario[]>((result, scenario) => {
      result.push(scenario);
      if (scenario.has_children) {
        return result.concat(
          getSortedSelectedScenarios(scenarios, selectedIds, scenario.id)
        );
      }
      return result;
    }, []);
}
