import { observer } from "mobx-react-lite";
import { AsideBox, Title } from "~/components";
import { useReviewStore } from "~/features/reviews";
import { useUserId } from "~/modules/auth";
import { space } from "~/theme";
import { useAssessmentStore } from "../store";
import { AssessmentSelectionList } from "./assessment-selection-list";

export const AssessmentSelection = observer(() => {
  const userId = useUserId();
  const { review } = useReviewStore();
  const { isManualJudgement, selectedIds, scenarios } = useAssessmentStore();

  const isReviewOwner = userId === review.data?.created_by;
  const hasAssessmentSelection = isManualJudgement || selectedIds.length > 0;

  return (
    <AsideBox>
      {hasAssessmentSelection && (
        <>
          <Title level={5} variant="overline" cx={{ marginBottom: space.md }}>
            {isReviewOwner
              ? "Your Assessment selection"
              : "Assessment selection"}
          </Title>
          <AssessmentSelectionList
            scenarios={scenarios}
            selectedIds={selectedIds}
            isManualJudgement={isManualJudgement}
          />
        </>
      )}
    </AsideBox>
  );
});
