import { transparentize } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import { colors, fontSizes, radii, space } from "~/theme";
import type { IconName } from "./icon";
import { Icon } from "./icon";

type Variant = "info" | "danger";

type MessageProps = {
  children: React.ReactNode;
  variant: Variant;
  icon?: IconName;
};

const messageColors: Record<Variant, string> = {
  info: colors.indigo,
  danger: colors.danger,
};

export function Message({ children, variant, icon }: MessageProps) {
  const color = messageColors[variant];
  return (
    <StyledMessage color={color}>
      {icon && (
        <span
          css={css`
            flex-basis: 24px;
            color: ${color};
            margin-right: ${space.md};
            display: block;
            width: 24px;
            height: 24px;
          `}
        >
          <Icon name={icon} size={24} />
        </span>
      )}
      <div css={{ width: "100%" }}>{children}</div>
    </StyledMessage>
  );
}

const StyledMessage = styled.div.withConfig({
  shouldForwardProp: (x) => !["color"].includes(x),
})<{ color: string }>`
  padding: ${space.md} ${space.lg};
  background: ${(x) => transparentize(0.9, x.color)};
  border: 1px solid ${(x) => x.color};
  font-size: ${fontSizes.sm};
  color: black;
  border-radius: ${radii.md};
  display: flex;
`;
