import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import {
  BackLink,
  PageContainer,
  PageLoader,
  SpacedChildren,
  Title,
} from "~/components";
import { useReviewStore } from "~/features/reviews";
import { notify } from "~/modules/notifications";
import { EditReviewForm } from "./components";

export const ReviewEditPage: NextPage = observer(() => {
  const router = useRouter();
  const store = useReviewStore();

  if (store.isLoading) {
    return <PageLoader />;
  }
  const { review } = store;
  const reviewId = router.query.reviewId as string;

  if (!review.data) {
    return <PageContainer>No data or id for review {reviewId}</PageContainer>;
  }

  return (
    <PageContainer narrowContent>
      <SpacedChildren size="xs">
        <SpacedChildren size="xs">
          <BackLink href={`/reviews/${reviewId}`}>Back</BackLink>
          <Title level={2} as="h1" noMargin>
            Edit Review
          </Title>
        </SpacedChildren>
        <EditReviewForm
          review={review.data}
          reviewId={reviewId}
          onFinish={() => goToReview(reviewId)}
          onCancel={() => goToReview(reviewId)}
        />
      </SpacedChildren>
    </PageContainer>
  );

  function goToReview(reviewId: string) {
    router
      .push(`/reviews/[reviewId]`, `/reviews/${reviewId}`)
      .catch(notify.error);
  }
});
