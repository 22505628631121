import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { PageLoader } from "~/components";
import { notify } from "~/modules/notifications";
import { useAppStore } from "~/store";
import { useReviewStore } from "../../store";
import { PartStore } from "./store";

const PartStoreContext = React.createContext<PartStore | null>(null);

export function PartStoreProvider({ children }: { children: React.ReactNode }) {
  const appStore = useAppStore();
  const reviewStore = useReviewStore();

  return (
    <PartStoreContext.Provider
      value={useMemo(
        () => new PartStore(appStore, reviewStore),
        [appStore, reviewStore]
      )}
    >
      <PartLoader>{children}</PartLoader>
    </PartStoreContext.Provider>
  );
}

export const usePartStore = () => {
  const store = React.useContext(PartStoreContext);
  assert(store, "Missing PartStoreProvider in component tree");
  return store;
};

/**
 * The loader initializes the store based on the route if necessary, and waits
 * for the data to load.
 */
const PartLoader = observer(({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const store = usePartStore();

  store
    .initialize(
      router.query.partId as string,
      router.query.reference as string | undefined
    )
    .catch((err) => notify.error(err));

  if (store.isLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
});
