import styled from "styled-components";
import { space } from "~/theme";

export type SpacedChildrenProps = {
  size?: keyof typeof space;
  horizontal?: boolean;
};

export const SpacedChildren = styled.div<SpacedChildrenProps>`
  & > *:not(:last-child) {
    ${({ horizontal, size = "md" }) =>
      horizontal ? { marginRight: space[size] } : { marginBottom: space[size] }}
  }
`;
