import type { ReviewStructurePart } from "@gemini/common";
import Link from "next/link";
import React from "react";
import { useReviewStore } from "~/features/reviews";
import { PartRow } from "./part-row";

type PartProps = {
  part: ReviewStructurePart;
  level: number;
  active?: boolean;
};

export function PartLink({ part, level, active }: PartProps) {
  const { review } = useReviewStore();

  return (
    <Link
      href={`/reviews/${review.id}/parts/${part.id}`}
      css={{ textDecoration: "none" }}
    >
      <PartRow
        active={active}
        level={level}
        progress={review.data?.progress.progress_by_part[part.id]}
      >
        {part.title}
      </PartRow>
    </Link>
  );
}
