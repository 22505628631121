import { assert, getErrorMessage } from "@gemini/common";
import type {
  CollectionReference,
  DocumentReference,
  Query,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import {
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  query,
} from "firebase/firestore";
import { first } from "lodash-es";
import {
  useCollection,
  useDocument,
  useDocumentOnce,
} from "react-firebase-hooks/firestore";
import { db } from "./firebase";

export type Document<T> = {
  id: string;
  data: T;
  ref: DocumentReference;
};

export async function getDocument<T>(
  path: string,
  ...pathSegments: string[]
): Promise<Document<T>> {
  try {
    const ref = doc(db, path, ...pathSegments);
    const snapshot = await getDoc(ref);

    assert(
      snapshot.exists(),
      `No document ${documentId} available at /${[path, ...pathSegments].join(
        "/"
      )}`
    );

    return { id: snapshot.id, data: snapshot.data() as T, ref };
  } catch (err) {
    console.log("Failed getDocument", path, pathSegments, getErrorMessage(err));
    throw err;
  }
}

export async function getFirstDocument<T>(
  queryOrCollectionRef: Query | CollectionReference
): Promise<Document<T>> {
  try {
    const snapshot = await getDocs(query(queryOrCollectionRef, limit(1)));

    assert(!snapshot.empty, `Query resulted in no documents`);

    const doc = first(snapshot.docs) as QueryDocumentSnapshot;

    return { id: doc.id, data: doc.data() as T, ref: doc.ref };
  } catch (err) {
    console.log(
      "Failed getFirstDocument",
      queryOrCollectionRef,
      getErrorMessage(err)
    );
    throw err;
  }
}
export function useTypedDocument<T>(
  path?: string,
  ...pathSegments: string[]
): [Document<T> | undefined, boolean] {
  try {
    const [snapshot, isLoading, error] = useDocument(
      path ? doc(db, path, ...pathSegments) : undefined
    );

    if (error) {
      throw error;
    }

    const document = snapshot?.exists()
      ? { id: snapshot.id, data: snapshot.data() as T, ref: snapshot.ref }
      : undefined;

    return [document, isLoading];
  } catch (err) {
    console.log(
      "Failed useTypedDocument",
      path,
      pathSegments,
      getErrorMessage(err)
    );
    throw err;
  }
}

export function useTypedDocumentOnce<T>(
  path?: string,
  ...pathSegments: string[]
): [Document<T> | undefined, boolean] {
  try {
    const [snapshot, isLoading, error] = useDocumentOnce(
      path ? doc(db, path, ...pathSegments) : undefined
    );

    if (error) {
      throw error;
    }

    const document = snapshot?.exists()
      ? { id: snapshot.id, data: snapshot.data() as T, ref: snapshot.ref }
      : undefined;

    return [document, isLoading];
  } catch (err) {
    console.log(
      "Failed useTypedDocument",
      path,
      pathSegments,
      getErrorMessage(err)
    );
    throw err;
  }
}

export function useTypedCollection<T>(
  ref?: Query | CollectionReference
): [Document<T>[], boolean] {
  const [snapshot, isLoading, error] = useCollection(ref);

  if (error) {
    throw error;
  }

  const docs = snapshot
    ? snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data() as T,
        ref: doc.ref,
      }))
    : [];

  return [docs, isLoading];
}
