import { assert } from "@sindresorhus/is";
import { difference, intersection, isEqual, isUndefined } from "lodash-es";
import { isDefined } from "ts-is-present";
import type {
  JsonArray,
  JsonObject,
  JsonPrimitive,
  JsonValue,
} from "type-fest";
import { assert as assertMsg, getErrorMessage } from "./utils";

type ChangeType = "remove" | "add" | "update";

/**
 * @todo I found https://www.npmjs.com/package/json8-patch and it looks like
 *   that would have been a better choice entirely. So if we spend more time on
 *   this we should also consider migrating to a JsonPatch format.
 */

export type BranchChange = {
  type: ChangeType;
  key: string;
  changes: (BranchChange | LeafChange)[];
};

type LeafChangeTypeRemove = {
  type: "remove";
  key: string;
  value: JsonValue;
};

type LeafChangeTypeAdd = {
  type: "add";
  key: string;
  value: JsonValue;
};

type LeafChangeTypeUpdate = {
  type: "update";
  key: string;
  value: JsonValue;
  oldValue: JsonValue;
};

export type LeafChange =
  | LeafChangeTypeRemove
  | LeafChangeTypeAdd
  | LeafChangeTypeUpdate;

export type Changeset = (BranchChange | LeafChange)[];

export function isLeafChange(
  value: LeafChange | BranchChange
): value is LeafChange {
  return (value as LeafChange).value !== undefined;
}

/**
 * Return a string that can make a distinction between different kinds of
 * objects (Array, Object, Function, Date) and primitive values
 */
function getTypeOfValue(value: JsonValue) {
  if (value === null) {
    /**
     * Typeof null is "object" and that's a little confusing so a different
     * string is better.
     */
    return "null";
  }

  /**
   * Note that we need to use the Object.prototype.toString function here,
   * calling value.toString() does not work.
   */
  const matches = Object.prototype.toString
    .call(value)
    .match(/^\[object\s(.*)\]$/);

  if (matches) {
    return matches[1];
  } else {
    return typeof value;
  }
}

function getKey(path: string[]) {
  return path[path.length - 1] ?? "$root";
}

function compare(
  oldValue: JsonValue,
  newValue: JsonValue,
  path: string[] = [],
  keyPath: string[] = []
): Changeset {
  const typeOfOldValue = getTypeOfValue(oldValue);
  const typeOfNewValue = getTypeOfValue(newValue);

  if (typeOfOldValue !== typeOfNewValue) {
    return [
      {
        type: "remove",
        key: getKey(path),
        value: oldValue,
      },
      {
        type: "add",
        key: getKey(path),
        value: newValue,
      },
    ];
  }

  switch (typeOfOldValue) {
    case "Object": {
      const diffs = compareObject(
        oldValue as JsonObject,
        newValue as JsonObject,
        path,
        keyPath
      );
      if (diffs.length) {
        if (path.length) {
          return [
            {
              type: "update",
              key: getKey(path),
              changes: diffs,
            },
          ];
        } else {
          return diffs;
        }
      }
      return [];
    }
    case "Array":
      return compareArray(
        oldValue as JsonArray,
        newValue as JsonArray,
        path,
        keyPath
      );
    case "Date":
      throw new Error(`Date is not supported`);
    case "Function":
      throw new Error(`Functions are not supported`);
    default:
      return comparePrimitives(
        oldValue as JsonPrimitive,
        newValue as JsonPrimitive,
        path
      );
  }
}
function compareObject(
  oldObj: JsonObject,
  newObj: JsonObject,
  path: string[],
  keyPath: string[],
  skipPath = false
): Changeset {
  const changes: Changeset = [];
  const oldObjKeys = Object.keys(oldObj);
  const newObjKeys = Object.keys(newObj);
  const intersectionKeys = intersection(oldObjKeys, newObjKeys);

  for (const intersectionKey of intersectionKeys) {
    const newPath = path.concat(intersectionKey);
    const newKeyPath = skipPath ? keyPath : keyPath.concat(intersectionKey);

    const diffs = compare(
      /**
       * Because the intersection keys are extracted from the objects, I think
       * it is safe to assume they return a value.
       */
      oldObj[intersectionKey],
      newObj[intersectionKey],
      newPath,
      newKeyPath
    );

    if (diffs.length) {
      changes.push(...diffs);
    }
  }

  const addedKeys = difference(newObjKeys, oldObjKeys);

  for (const addedKey of addedKeys) {
    const newPath = path.concat(addedKey);

    const value = newObj[addedKey];
    if (isUndefined(value)) {
      /**
       * If the value is undefined, do not consider it a new key/value pair.
       * This allows us to pass in an object with fields set to undefined as a
       * means to delete them.
       */
      continue;
    }

    changes.push({
      type: "add",
      key: getKey(newPath),
      value,
    });
  }
  const deletedKeys = difference(oldObjKeys, newObjKeys);

  for (const deletedKey of deletedKeys) {
    const newPath = path.concat(deletedKey);
    const value = oldObj[deletedKey];

    assertMsg(
      isDefined(value),
      `Failed to get value for deleted key ${deletedKey}`
    );

    changes.push({
      type: "remove",
      key: getKey(newPath),
      value,
    });
  }

  return changes;
}

function compareArray(
  oldObj: JsonArray,
  newObj: JsonArray,
  path: string[],
  keyPath: string[]
): Changeset {
  const indexedOldObj = convertArrayToObj(oldObj);
  const indexedNewObj = convertArrayToObj(newObj);

  const diffs = compareObject(
    indexedOldObj,
    indexedNewObj,
    path,
    keyPath,
    true
  );

  if (diffs.length > 0) {
    return [
      {
        type: "update",
        key: getKey(path),
        changes: diffs,
      },
    ];
  } else {
    return [];
  }
}

function convertArrayToObj(arr: JsonArray): JsonObject {
  return Object.assign({} as JsonObject, arr);
}

function comparePrimitives(
  oldObj: JsonPrimitive,
  newObj: JsonPrimitive,
  path: string[]
): Changeset {
  if (oldObj !== newObj) {
    return [
      {
        type: "update",
        key: getKey(path),
        value: newObj,
        oldValue: oldObj,
      },
    ];
  } else {
    return [];
  }
}

function removeKey(
  obj: JsonObject | JsonValue[],
  key: string,
  value: JsonValue
) {
  if (Array.isArray(obj)) {
    /**
     * This part was plain wrong in the original implementation. Here we work
     * around it by looking up the element from the array. This is one reason to
     * switch to something json-patch based I think, but luckily we don't use
     * array operations when diffing scenarios AFAIK.
     */
    if (!isEqual(obj[Number(key)], value)) {
      const index = obj.findIndex((x) => isEqual(x, value));
      assertMsg(
        index !== -1,
        `Failed to find value ${value} to remove from array ${JSON.stringify(
          obj
        )}`
      );
      return obj.splice(index, 1);
    } else {
      return obj.splice(Number(key), 1);
    }
  } else {
    return delete obj[key];
  }
}

function modifyKeyValue(
  obj: JsonObject | JsonValue[],
  key: string,
  value: JsonValue
) {
  if (Array.isArray(obj)) {
    obj[Number(key)] = value;
  } else {
    obj[key] = value;
  }
}

function addKeyValue(
  obj: JsonObject | JsonValue[],
  key: string,
  value: JsonValue
) {
  if (Array.isArray(obj)) {
    obj.push(value);
  } else {
    obj[key] = value;
  }
}

function applyLeafChange(obj: JsonObject | JsonValue[], change: LeafChange) {
  const { type, key, value } = change;

  switch (type) {
    case "add":
      addKeyValue(obj, key, value);
      break;
    case "update":
      modifyKeyValue(obj, key, value);
      break;
    case "remove":
      removeKey(obj, key, value);
      break;
  }
}

function applyArrayChange(array: JsonValue[], { changes }: BranchChange) {
  for (const subChange of changes) {
    if (isLeafChange(subChange)) {
      applyLeafChange(array, subChange);
    } else {
      const element = array[Number(subChange.key)];
      assertMsg(element, `Missing element on index ${subChange.key}`);
      assert.plainObject(element);
      applyChangesOrThrow(element, subChange.changes);
    }
  }
}

function applyBranchChange(
  obj: JsonObject | JsonValue[],
  change: BranchChange
) {
  if (Array.isArray(obj)) {
    applyArrayChange(obj, change);
  } else {
    applyChangesOrThrow(obj, change.changes);
  }
}

function revertLeafChange(obj: JsonObject | JsonValue[], change: LeafChange) {
  const { type, key, value } = change;

  switch (type) {
    case "add":
      removeKey(obj, key, value);
      break;
    case "update":
      modifyKeyValue(obj, key, change.oldValue);
      break;
    case "remove":
      addKeyValue(obj, key, value);
      break;
  }
}

function revertArrayChange(array: JsonValue[], change: BranchChange) {
  for (const subChange of change.changes) {
    if (isLeafChange(subChange)) {
      revertLeafChange(array, subChange);
    } else {
      const element = array[Number(subChange.key)];
      assert.plainObject(element);
      revertChanges(element, subChange.changes);
    }
  }
}

function revertBranchChange(
  obj: JsonValue[] | JsonObject,
  change: BranchChange
) {
  if (Array.isArray(obj)) {
    revertArrayChange(obj, change);
  } else {
    revertChanges(obj, change.changes);
  }
}

export function diff(
  oldObj: JsonValue[] | JsonObject,
  newObj: JsonValue[] | JsonObject
) {
  return compare(oldObj, newObj);
}

/** Same as api entry but recursively used. Throw because errors need to surface. */
function applyChangesOrThrow(inputValue: JsonObject, changes: Changeset) {
  for (const change of changes) {
    if (isLeafChange(change)) {
      applyLeafChange(inputValue, change);
    } else {
      const value = inputValue[change.key] as JsonObject | JsonValue[];
      applyBranchChange(value, change);
    }
  }
}

/**
 * This is the API entry point function. Internally the recursion uses the
 * applyChangesOrThrow so that errors surface here and can be returned for each
 * change
 */
export function applyChanges(inputValue: JsonObject, changes: Changeset) {
  const failedChanges: Changeset = [];

  for (const change of changes) {
    try {
      if (isLeafChange(change)) {
        applyLeafChange(inputValue, change);
      } else {
        const value = inputValue[change.key] as JsonObject | JsonValue[];
        applyBranchChange(value, change);
      }
    } catch (err) {
      failedChanges.push(change);
    }
  }
  return failedChanges;
}

export function revertChanges(inputValue: JsonObject, changes: Changeset) {
  const reverseChanges = changes.reverse();
  const failedChanges: Changeset = [];

  for (const change of reverseChanges) {
    try {
      if (isLeafChange(change)) {
        revertLeafChange(inputValue, change);
      } else {
        const value = inputValue[change.key] as JsonObject | JsonValue[];
        revertBranchChange(value, change);
      }
    } catch (err) {
      console.error(
        new Error(
          `Failed to revert change: ${getErrorMessage(
            err
          )} Change: ${JSON.stringify(change)}`
        )
      );
      failedChanges.push(change);
    }
  }
  return failedChanges;
}
