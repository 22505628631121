export type CsvColumn<T> = {
  label: string;
  value: keyof T | ((item: T) => string | number | undefined);
};

export function downloadCsv<T>(
  data: T[],
  filename: string,
  columns: CsvColumn<T>[]
) {
  const csvContent = [
    columns.map(({ label }) => `"${label}"`).join(","),
    ...data.map((x) =>
      columns
        .map(({ value: valueOrKey }) => {
          const value =
            typeof valueOrKey === "function" ? valueOrKey(x) : x[valueOrKey];
          return typeof value === "string"
            ? // In a csv, a quote must be escaped with a double quote: " => ""
              `"${value.replace(/"/g, '""')}"`
            : value ?? "";
        })
        .join(",")
    ),
  ].join("\n");

  const url = URL.createObjectURL(
    new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
  );

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
