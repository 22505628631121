import type {
  Assessment,
  CauseOfChange,
  GetReviewStructureGuidelineStatesResult,
  GuidelineObservation,
  ReviewStructure,
  ReviewStructurePart,
} from "@gemini/common";
import { assert, getImpactScore } from "@gemini/common";
import { isDefined } from "ts-is-present";
import type { Document } from "~/modules/firestore";
import { isReviewStructurePart } from "~/utils/identity";
import { getImpactLabel } from "./impact";
import { getJudgementFilterValueFromJudgement } from "./judgement";

export type GuidelineHit = ReturnType<typeof createGuidelineHits>[number];

export function createGuidelineHits({
  stateByReference,
  assessments,
  structure,
  reviewId,
  observations,
}: {
  stateByReference: GetReviewStructureGuidelineStatesResult;
  assessments: Document<Assessment>[];
  structure: Document<ReviewStructure>;
  reviewId: string;
  observations?: Document<GuidelineObservation>[];
}) {
  return Object.entries(stateByReference).map(
    ([reference, { state, partId }]) => {
      /**
       * Construct the position of the guideline by looking up the part position
       * and append the guideline reference index as decimals.
       */
      const part = structure.data.sections[partId] as ReviewStructurePart;
      const positionSort = parseFloat(
        `${part.sequential_position}.${part.guideline_references
          .indexOf(reference)
          .toString()
          .padStart(4, "0")}`
      );

      const assessment = assessments.find(
        (assessment) => assessment.data.guideline_reference === reference
      );

      const impact = getImpactScore(state, assessment?.data);
      const breadcrumb = getBreadcrumb(reference, structure.data);
      const breadcrumbParts = breadcrumb.map(
        (id) => structure.data.sections[id] as ReviewStructurePart
      );

      const lastPartId = breadcrumbParts[breadcrumbParts.length - 1].id;

      return {
        reference,
        state,
        assessment,

        /** Derived values used for rendering */
        breadcrumbParts,
        link: `https://review-tool.baymard.com/reviews/${reviewId}/parts/${lastPartId}/assessments/${reference}`,

        /** Values for filtering */
        platform: state.platform,
        isLowCost: state.is_low_cost,
        isMissedOpportunity: state.is_missed_opportunity,
        includesNotes: (assessment?.data.comment?.length || 0) > 0,
        isSuggestion: assessment?.data.is_marked_for_suggestion ?? false,
        breadcrumb,
        judgement: getJudgementFilterValueFromJudgement(
          assessment?.data.judgement
        ),
        impact: isDefined(impact) ? getImpactLabel(impact) : undefined,
        causeOfChange: observations?.find((x) => x.id === reference)?.data
          .cause_of_change as CauseOfChange | undefined,

        /** Values for sorting */
        impactSort: isDefined(impact) ? impact : -Infinity,
        positionSort,
      };
    }
  );
}

function getBreadcrumb(reference: string, structure: ReviewStructure) {
  const partWithGuideline = Object.values(structure.sections)
    .filter(isReviewStructurePart)
    .find((x) => x.guideline_references.includes(reference));

  assert(partWithGuideline, `Unable to find part for guideline ${reference}`);

  const breadcrumbIds = [];
  let partId = partWithGuideline.id;

  while (partId !== "__root") {
    breadcrumbIds.unshift(partId);

    partId = structure.sections[breadcrumbIds[0]].parent_group_id;
  }

  return breadcrumbIds;
}
