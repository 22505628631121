import React from "react";
import { useCurrentRefinements } from "react-instantsearch";
import { DropDownButton } from "~/components";
import type { ToggleRefinementLabel } from "./toggle-refinement";
import { ToggleRefinement } from "./toggle-refinement";

export type ToggleRefinementListDropDownToggle = {
  label: ToggleRefinementLabel;
  attribute: string;
};

export function ToggleRefinementListDropDown({
  label,
  toggles,
  alignContent,
  isInitiallyOpen,
}: {
  label: string;
  toggles: ToggleRefinementListDropDownToggle[];
  alignContent?: "left" | "right";
  isInitiallyOpen?: boolean;
}) {
  const { items } = useCurrentRefinements();
  const nbSelected = items.filter((item) =>
    toggles.find((x) => x.attribute === item.attribute)
  ).length;

  return (
    <DropDownButton
      isInitiallyOpen={isInitiallyOpen}
      alignContent={alignContent}
      label={
        <>
          {label} {nbSelected ? `(${nbSelected})` : ""}
        </>
      }
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {toggles.map((x) => (
          <ToggleRefinement
            key={x.attribute}
            label={x.label}
            attribute={x.attribute}
          />
        ))}
      </div>
    </DropDownButton>
  );
}
