import type { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { observer } from "mobx-react-lite";
import type { CSSProperties, ReactNode } from "react";
import React from "react";

export const Reorderable = observer(function Reorderable<T>({
  items,
  onReorder,
  getId,
  renderItem,
}: {
  items: T[];
  onReorder: (items: T[]) => void;
  getId: (item: T) => UniqueIdentifier;
  renderItem: (item: T) => ReactNode;
}) {
  const itemIds = items.map((x) => getId(x));
  const sensors = useSensors(
    useSensor(PointerSensor, {
      /** The `activationConstraint` allows triggering click events. */
      activationConstraint: { distance: 10 },
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = itemIds.indexOf(active.id);
      const newIndex = itemIds.indexOf(over.id);
      onReorder([...arrayMove(items, oldIndex, newIndex)]);
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={itemIds} strategy={rectSortingStrategy}>
        {itemIds.map((x, i) => (
          <SortableItem key={x} id={x} data-k={x}>
            {renderItem(items[i])}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
});

function SortableItem({
  id,
  children,
}: {
  id: UniqueIdentifier;
  children: ReactNode;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, transition: { easing: "ease-out", duration: 100 } });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div style={{ pointerEvents: isDragging ? "none" : "auto" }}>
        {children}
      </div>
    </div>
  );
}
