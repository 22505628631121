import React from "react";
import type { CSSProp } from "styled-components";
import styled, { css } from "styled-components";
import { fontWeights, preset } from "~/theme";

/** Reusable styles */

const sizeMap = {
  small: "body2",
  default: "body1",
  large: "body3",
} as const;

const variantStyle = (x: TextProps) => css`
  ${x.variant &&
  (x.variant === "default"
    ? preset.typography[sizeMap[x.size ?? "default"]]
    : preset.typography[x.variant])}
`;

const fontWeightStyle = css`
  font-weight: ${(x: TextProps) =>
    x.bold ? fontWeights.bold : x.semibold ? fontWeights.semibold : undefined};
`;

/** Text */

type TextVariants = "caption" | "overline" | "button";

export type TextProps = {
  children?: React.ReactNode;
  size?: "small" | "default" | "large";
  noMargin?: boolean;
  variant?: "default" | TextVariants;
  bold?: boolean;
  semibold?: boolean;
  cx?: CSSProp;
  title?: string;
};
const customTextProps = [
  "cx",
  "small",
  "noMargin",
  "variant",
  "bold",
  "semibold",
];

export const Text = React.forwardRef<HTMLSpanElement, TextProps>(
  ({ variant = "default", ...props }, ref) => (
    <StyledText ref={ref} variant={variant} {...props} />
  )
);

Text.displayName = "Paragraph";

const StyledText = styled.span.withConfig<TextProps>({
  shouldForwardProp: (prop) => !customTextProps.includes(prop),
})`
  ${variantStyle};
  ${fontWeightStyle};
  ${(x) => x.cx};
`;

/** Paragraph */

export const Paragraph = React.forwardRef<HTMLParagraphElement, TextProps>(
  ({ variant = "default", ...props }, ref) => (
    <StyledParagraph ref={ref} variant={variant} {...props} />
  )
);

Paragraph.displayName = "Paragraph";

const StyledParagraph = styled("p").withConfig<TextProps>({
  shouldForwardProp: (prop) => !customTextProps.includes(prop),
})`
  ${variantStyle};
  ${fontWeightStyle};
  margin-bottom: ${(x) => (x.noMargin ? 0 : "0.5em")};

  ${(x) => x.cx};
`;

/** Title */

type Level = 1 | 2 | 3 | 4 | 5 | 6;
type TitleVariants = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const titleVariantMap: Record<Level, TitleVariants> = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
};

export type TitleProps = {
  level: Level;
  variant?: TitleVariants | TextVariants;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  children?: React.ReactNode;
  noMargin?: boolean;
  cx?: CSSProp;
};

const customTitleProps = ["level", "variant", "noMargin", "cx"];

export const Title = React.forwardRef<HTMLHeadingElement, TitleProps>(
  ({ level, variant, ...props }, ref) => {
    const tag = titleVariantMap[level];
    return (
      <StyledTitle ref={ref} variant={variant || tag} as={tag} {...props} />
    );
  }
);

Title.displayName = "Title";

export type SubtitleProps = TitleProps & {
  small?: boolean;
};

export const Subtitle = React.forwardRef<HTMLHeadElement, SubtitleProps>(
  ({ level, ...props }: SubtitleProps, ref) => {
    const tag = titleVariantMap[level];
    return <StyledTitle ref={ref} as={tag} {...props} />;
  }
);

Subtitle.displayName = "Subtitle";

const StyledTitle = styled.h1.withConfig<TitleProps>({
  shouldForwardProp: (prop) => !customTitleProps.includes(prop),
})`
  ${(x) => x.variant && preset.typography[x.variant]}
  margin-bottom: ${(x) => (x.noMargin ? 0 : "0.5em")};
  ${(x) => x.cx};
`;
