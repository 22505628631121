import type {
  CauseOfChange,
  ReviewStructurePart,
  ScenarioMap,
} from "@gemini/common";
import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { useRouter } from "next/router";
import { darken } from "polished";
import React from "react";
import Moment from "react-moment";
import styled, { css } from "styled-components";
import {
  AdaptiveCarouselContextProvider,
  Icon,
  LineBreak,
  Paragraph,
  SpacedChildren,
  Spinner,
  Text,
  Title,
  Username,
  WordBreak,
  useAdaptiveCarouselEventHandlers,
} from "~/components";
import { StatusCircle } from "~/components/status-circle";
import { useReviewStore } from "~/features/reviews";
import { useAuth, useUserId } from "~/modules/auth";
import type { Timestamp } from "~/modules/firebase";
import { useBreakpoints } from "~/modules/hooks";
import { getImageSourceFromPath, imageSourcePresets } from "~/modules/image";
import { colors, fontSizes, mediaQueries, space } from "~/theme";
import { useAssessmentStore } from "../..";
import { AssessmentContent } from "../assessment-content";
import { AssessmentSelectionList } from "../assessment-selection-list";
import { FileList } from "../file-list";
import { InternalNotes } from "../internal-notes";
import {
  AuditorObservationResult,
  CounterpartAssessmentSummary,
} from "./components";
import { ComputedDifference } from "./components/computed-difference";
import { useAssessmentAsideData } from "./hooks/use-assessment-aside-data";

type WithSummaryPageAsideProps = {
  children: (aside: React.ReactNode) => React.ReactNode;
} & SummaryPageAsideProps;

export const WithSummaryPageAside = observer(
  ({ children, ...asideProps }: WithSummaryPageAsideProps) => {
    const breakpoints = useBreakpoints();
    return (
      <div
        css={css`
          flex-grow: 1;
          grid-template-areas: "main";
          @media ${mediaQueries.lg} {
            display: grid;
            grid-template-areas: "main aside";
            grid-template-columns: 6fr 4fr;
          }
        `}
      >
        <div
          css={css`
            grid-area: main;
          `}
        >
          <AssessmentContent>
            {children(
              breakpoints.lg ? undefined : <SummaryPageAside {...asideProps} />
            )}
          </AssessmentContent>
        </div>

        {breakpoints.lg && (
          <div
            css={css`
              grid-area: aside;
              border-left: 1px solid ${colors.gray};
            `}
          >
            <AssessmentContent>
              <SummaryPageAside {...asideProps} />
            </AssessmentContent>
          </div>
        )}
      </div>
    );
  }
);

type SummaryPageAsideProps = {
  selectedIds: string[];
  comment: string;
  scenarios: ScenarioMap;
  isManualJudgement: boolean;
  isUpdateFlow: boolean;
  images?: string[];
  files?: string[];
  pendingFileUploads: string[];
  submittedBy?: string;
  submittedAt?: Timestamp;
  internalComment?: string;
  isMarkedForDiscussion?: boolean;
  isMarkedForSuggestion?: boolean;
  /**
   * Sorted parts are only available in the partStore. This store can not be
   * used in the update flow where this component is being re-used, so we pass
   * the parts via props to avoid a dependency on usePartStore.
   */
  sortedParts?: ReviewStructurePart[];

  observation: { cause_of_change: CauseOfChange; text?: string } | undefined;
};

const SummaryPageAside = observer((props: SummaryPageAsideProps) => {
  const router = useRouter();
  const { review } = useReviewStore();
  const { storedObservation } = useAssessmentStore();
  const { claims } = useAuth();
  const userId = useUserId();

  const [isEditingInternalNotes, setIsEditingInternalNotes] =
    React.useState(false);
  const {
    selectedIds,
    comment,
    scenarios,
    isManualJudgement,
    images = [],
    files = [],
    pendingFileUploads,
    submittedBy,
    submittedAt,
    internalComment,
    isMarkedForDiscussion,
    isMarkedForSuggestion,
    isUpdateFlow,
    sortedParts,
    observation,
  } = props;

  const reference = router.query.reference as string;
  const partId = router.query.partId as string;

  assert(review.data, "Missing review data");

  const isReviewOwner = userId === review.data.created_by;

  const desktopCounterpartData = useAssessmentAsideData("desktop", sortedParts);
  const mobileCounterpartData = useAssessmentAsideData("mobile", sortedParts);
  const appCounterpartData = useAssessmentAsideData("app", sortedParts);

  return (
    <SpacedChildren
      size="lg"
      css={`
        margin-bottom: ${space.md};
      `}
    >
      <SideBlock
        testingName="assessment-selection"
        readOnly={review.data.is_read_only}
        title={
          isReviewOwner ? "Your Assessment selection" : "Assessment selection"
        }
        subtitle={
          <React.Fragment>
            {submittedBy && (
              <>
                by <Username id={submittedBy} />
              </>
            )}
            {submittedAt && (
              <>
                {" on "}
                <Moment date={submittedAt.toDate()} format="MMMM D, YYYY" />
              </>
            )}
          </React.Fragment>
        }
        editLink={
          isUpdateFlow
            ? `/reviews/${review.id}/updates/assessments/${reference}/assess`
            : `/reviews/${review.id}/parts/${partId}/assessments/${reference}/assess`
        }
      >
        <SpacedChildren size="sm">
          <AssessmentSelectionList
            scenarios={scenarios}
            selectedIds={selectedIds}
            isManualJudgement={isManualJudgement}
            showValues
          />
          {selectedIds.length > 1 && !isManualJudgement && (
            <span css={{ fontSize: fontSizes.xs }}>
              The judgement is based on the full combination of selections.
            </span>
          )}
        </SpacedChildren>
      </SideBlock>
      <SideBlock
        readOnly={review.data.is_read_only}
        title={
          claims.isAdmin
            ? "Customer-facing notes"
            : "Notes for you and your team"
        }
        editLink={
          isUpdateFlow
            ? `/reviews/${review.id}/updates/assessments/${reference}/assess#comment`
            : `/reviews/${review.id}/parts/${partId}/assessments/${reference}/assess#comment`
        }
      >
        <SpacedChildren>
          <Paragraph noMargin>
            {comment ? (
              <WordBreak>
                <LineBreak>{comment}</LineBreak>
              </WordBreak>
            ) : (
              <em>There are no notes</em>
            )}
          </Paragraph>
          {images.length > 0 && (
            <AdaptiveCarouselContextProvider>
              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  margin: 0 -${space.xs};
                `}
              >
                {images.map((src) => (
                  <Thumbnail
                    isUploading={src.startsWith("upload-temp")}
                    src={getImageSourceFromPath(src)}
                    srcThumb={getImageSourceFromPath(
                      src,
                      imageSourcePresets.thumbnail
                    )}
                    key={src}
                  />
                ))}
              </div>
            </AdaptiveCarouselContextProvider>
          )}

          {files.length > 0 && (
            <FileList
              files={files}
              pendingFileUploads={pendingFileUploads}
              reference={reference}
              reviewId={review.id}
              accountId={review.data.account_id}
            />
          )}
        </SpacedChildren>
      </SideBlock>

      {claims.isAdmin && (
        <React.Fragment>
          {isEditingInternalNotes ? (
            <InternalNotes
              saveable
              onFinish={() => setIsEditingInternalNotes(false)}
            />
          ) : (
            <SideBlock
              readOnly={review.data.is_read_only}
              title="Internal notes (For Baymard only)"
              onEditClick={() => setIsEditingInternalNotes(true)}
            >
              <SpacedChildren horizontal css={{ marginBottom: space.md }}>
                <StatusCircle active={isMarkedForSuggestion}>
                  suggestion
                </StatusCircle>

                <StatusCircle active={isMarkedForDiscussion}>
                  discussion
                </StatusCircle>
              </SpacedChildren>
              <Paragraph noMargin>
                {internalComment ? (
                  <WordBreak>
                    <LineBreak>{internalComment}</LineBreak>
                  </WordBreak>
                ) : (
                  <em>There are no internal notes</em>
                )}
              </Paragraph>
            </SideBlock>
          )}
        </React.Fragment>
      )}

      {
        /**
         * The `ComputedDifference` and `AuditorObservationResult` components
         * depend on the `PartStore`, which is not available during the update
         * flow.
         *
         * Assuming those components don't add a lot of value in the update
         * flow, we can safely not render them.
         */
        !isUpdateFlow && (
          <>
            <ComputedDifference />
            <AuditorObservationResult
              observation={observation ?? storedObservation}
            />
          </>
        )
      }

      {sortedParts && (
        <>
          <CounterpartAssessmentSummary
            assessmentAsideData={desktopCounterpartData}
            showJudgement
          />
          <CounterpartAssessmentSummary
            assessmentAsideData={mobileCounterpartData}
            showJudgement
          />
          <CounterpartAssessmentSummary
            assessmentAsideData={appCounterpartData}
            showJudgement
          />
        </>
      )}
    </SpacedChildren>
  );
});

function Thumbnail({
  src,
  srcThumb,
  isUploading,
}: {
  src: string;
  srcThumb: string;
  isUploading: boolean;
}) {
  const carouselProps = useAdaptiveCarouselEventHandlers(src);
  return (
    <StyledThumbnail src={srcThumb} {...carouselProps} disabled={isUploading}>
      {isUploading && (
        <SpinnerContainer>
          <Spinner size={26} />
        </SpinnerContainer>
      )}
    </StyledThumbnail>
  );
}

const StyledThumbnail = styled.button<{ src: string }>`
  position: relative;
  width: 6rem;
  height: 4.5rem;
  background: url(${(x) => x.src});
  background-size: cover;
  background-position: center;
  border: 0;
  padding: 0;
  margin: ${space.xs};
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
`;

type SideBlockProps = {
  title: string;
  children: React.ReactNode;
  readOnly: boolean;
  editLink?: string;
  onEditClick?: () => void;
  subtitle?: React.ReactNode;
  testingName?: string;
};

function SideBlock({
  title,
  subtitle,
  children,
  editLink,
  onEditClick,
  readOnly,
  testingName,
}: SideBlockProps) {
  const breakpoints = useBreakpoints();
  return (
    <div data-t={testingName}>
      <div
        css={css`
          margin-bottom: ${space.sm};
          position: relative;
          padding-right: 30px;
        `}
      >
        <div>
          <Title
            level={5}
            variant={breakpoints.lg ? "overline" : undefined}
            noMargin
          >
            {title}
          </Title>
          {subtitle && <Text variant="caption">{subtitle}</Text>}
        </div>
        {!readOnly && (
          <EditButton
            href={editLink}
            onClick={onEditClick}
            title={`Edit ${title}`}
          />
        )}
      </div>
      <div css={{ fontSize: fontSizes.sm }}>{children}</div>
    </div>
  );
}

const editButtonStyles = css`
  cursor: pointer;
  background: none;
  border: 0;
  position: absolute;
  top: -4px;
  right: 0;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  color: ${colors.slateGray};
  &:hover {
    color: ${darken(0.1, colors.slateGray)};
  }
`;

function EditButton({
  href,
  onClick,
  title,
}: {
  href?: string;
  onClick?: () => void;
  title: string;
}) {
  return href ? (
    <Link href={href} css={editButtonStyles} onClick={onClick} title={title}>
      <Icon name="editOutline" size={22} />
    </Link>
  ) : (
    <button css={editButtonStyles} onClick={onClick} title={title}>
      <Icon name="editOutline" size={22} />
    </button>
  );
}
