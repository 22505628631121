import type {
  JudgementNudgeValue,
  JudgementValue,
  TranslationValue,
} from "@gemini/common";
import {
  AH_VALUE,
  VH_VALUE,
  assert,
  getJudgementFromTranslationValue,
  judgementLabels,
  judgementValues,
} from "@gemini/common";
import { darken } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import { Button, Paragraph, SpacedChildren, Title } from "~/components";
import { judgementDescriptions } from "~/constants";
import { colors, fontSizes, fontWeights, preset, space } from "~/theme";
import { JudgementDisplay } from "./judgement-display";

type NudgeFormProps = {
  calculatedTranslationValue: TranslationValue;
  judgementNudgeValue: JudgementNudgeValue;
  onSubmit: (nudgeValue: JudgementNudgeValue) => void;
  onCancel: () => void;
  disabled: boolean;
};

export function NudgeForm({
  calculatedTranslationValue,
  judgementNudgeValue,
  onSubmit,
  onCancel,
  disabled,
}: NudgeFormProps) {
  const originalJudgement = getJudgementFromTranslationValue(
    calculatedTranslationValue
  );

  const { judgement, nudgeValue, nudgeNegative, nudgePositive } =
    useNudgeControls(originalJudgement, judgementNudgeValue);

  return (
    <React.Fragment>
      <div
        css={css`
          padding: ${space.lg};
        `}
      >
        <SpacedChildren>
          <SpacedChildren size="xs">
            <Title level={3}>Adjust the rating</Title>
            <Paragraph size="small">
              Use the plus and minus buttons to make your selection
            </Paragraph>
          </SpacedChildren>

          <JudgementDisplay
            short
            judgement={judgement as JudgementValue}
            isIssueResolved={calculatedTranslationValue === "ir"}
            isNudgeable
            onNudgeNegative={disabled ? undefined : nudgeNegative}
            onNudgePositive={disabled ? undefined : nudgePositive}
          />

          <SpacedChildren>
            <Paragraph>
              The given judgement is based on exhaustive usability testing and
              is consistent with the UX benchmarks. Adjusting this judgement
              should therefore be done sparsely and only when there are truly
              special circumstances which warrant a more positive or negative
              judgement.
            </Paragraph>

            <div>
              {judgementValues.map((x) => (
                <JudgementInfo key={x} value={x} />
              ))}
            </div>
          </SpacedChildren>
        </SpacedChildren>
      </div>
      <NudgeFormFooter judgement={originalJudgement}>
        <CancelButton disabled={disabled} onClick={() => onCancel()}>
          Cancel
        </CancelButton>
        <Button
          disabled={disabled}
          variant="tertiary"
          onClick={() => onSubmit(nudgeValue)}
          testingName="submit-nudge-button"
        >
          Save
        </Button>
      </NudgeFormFooter>
    </React.Fragment>
  );
}

function JudgementInfo({ value }: { value: JudgementValue }) {
  return (
    <div css={{ fontSize: fontSizes.sm }}>
      <strong
        css={{ fontWeight: fontWeights.semibold, color: colors.charcoal }}
      >
        {judgementLabels[value]}
      </strong>
      <Paragraph>{judgementDescriptions[value]}</Paragraph>
    </div>
  );
}

type NudgeFormFooterProps = {
  children: React.ReactNode;
  judgement: JudgementValue;
};

function NudgeFormFooter({ judgement, children }: NudgeFormFooterProps) {
  return (
    <div
      css={css`
        position: sticky;
        bottom: 0px;
        background: white;
        border-top: 1px solid ${colors.gray};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${space.md} ${space.lg};
      `}
    >
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        <OurRating>
          <span
            css={{
              color: colors.charcoal,
              marginRight: space.sm,
            }}
          >
            Our rating
          </span>
          <span css={{ fontWeight: fontWeights.regular }}>
            {judgementLabels[judgement]}
          </span>
        </OurRating>
      </div>

      <div
        css={css`
          flex-shrink: 1;
        `}
      >
        <SpacedChildren
          horizontal
          css={css`
            display: flex;
          `}
        >
          {children}
        </SpacedChildren>
      </div>
    </div>
  );
}

const OurRating = styled.div`
  ${preset.typography.overline};
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CancelButton = styled.button`
  ${preset.typography.button};
  border: 0;
  cursor: pointer;
  padding: ${space.md};
  background: none;
  color: ${colors.slateGray};

  &:not(:disabled):hover {
    color: ${darken(0.1, colors.blueGray)};
  }
`;

function useNudgeControls(
  judgement: JudgementValue,
  initialJudgementNudgeValue: JudgementNudgeValue
) {
  /**
   * We only show the nudge form when the judgement is actually nudgeable, so NA
   * is not a value that this form should have to deal with.
   */
  assert(judgement !== "na", 'Unable to nudge "NA"');

  const [nudgeValue, setNudgeValue] = React.useState<number>(
    initialJudgementNudgeValue
  );

  const judgementNudged = judgement + nudgeValue;

  const canNudgeMinus = judgementNudged - 1 >= VH_VALUE && nudgeValue > -2;
  const canNudgePlus = judgementNudged + 1 <= AH_VALUE && nudgeValue < +2;

  const nudgeNegative = canNudgeMinus
    ? () => setNudgeValue((x) => x - 1)
    : undefined;

  const nudgePositive = canNudgePlus
    ? () => setNudgeValue((x) => x + 1)
    : undefined;

  return {
    judgement: judgementNudged,
    nudgeValue: nudgeValue as JudgementNudgeValue,
    nudgeNegative,
    nudgePositive,
  };
}
