import React from "react";

export function LineBreak({ children }: { children: string }) {
  return (
    <React.Fragment>
      {children.split(/\n/g).map((x, i) => (
        <React.Fragment key={i}>
          {x}
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
