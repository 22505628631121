import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { darken } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import { useIsMountedRef } from "~/modules/hooks";
import { colors, durations, radii, space, timingFunctions } from "~/theme";
import { preset } from "~/theme/preset";
import { Spinner } from "./spinner";

export type ButtonProps = {
  children?: React.ReactNode;
  variant?: "primary" | "secondary" | "tertiary";
  size?: "x-small" | "small" | "medium";
  inverted?: boolean;
  fullWidth?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  type?: "submit" | "reset" | "button";
  onClick?:
    | ((evt: React.MouseEvent<HTMLButtonElement>) => void)
    | ((evt: React.MouseEvent<HTMLButtonElement>) => Promise<void>);
  disabled?: boolean;
  loading?: boolean;
  onMouseEnter?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseMove?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  testingName?: string;
};

/**
 * The Button component accepts an `href`-prop which results in rendering an
 * anchor instead of a button. e.g.
 *
 *     <Button href="/foo">foo</Button>;
 *
 * Or
 *
 *     <Link to="/bar">
 *       <Button>bar</Button>
 *     </Link>;
 */

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, loading, disabled, onClick, testingName, ...props }, ref) => {
    const [isLoadingPromise, setIsLoadingPromise] = React.useState(false);
    const isMountedRef = useIsMountedRef();
    const isLoading = loading || isLoadingPromise;

    const handleClick = React.useCallback(
      async (evt: React.MouseEvent<HTMLButtonElement>) => {
        const promisedClick = onClick ? onClick(evt) : undefined;
        if (promisedClick instanceof Promise) {
          setIsLoadingPromise(true);
          promisedClick
            .catch((err) => console.error(err))
            .finally(() => isMountedRef.current && setIsLoadingPromise(false));
        }
      },
      [onClick, isMountedRef]
    );

    return (
      <StyledButton
        ref={ref}
        as={props.href ? Link : "button"}
        {...props}
        disabled={isLoading || disabled}
        loading={isLoading}
        variant={props.variant || "primary"}
        onClick={handleClick}
        data-t={testingName}
      >
        <ButtonLabel loading={isLoading}>{children}</ButtonLabel>
        <ButtonSpinner loading={isLoading} />
      </StyledButton>
    );
  }
);

Button.displayName = "Button";

function ButtonSpinner({ loading }: { loading: boolean }) {
  return (
    <span
      css={css`
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `}
    >
      <AnimatePresence>
        {loading && (
          <motion.span
            transition={{
              type: "spring",
              damping: 26,
              stiffness: 233,
              mass: 0.6,
              delay: loading ? 0.1 : 0,
            }}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner size="2rem" />
          </motion.span>
        )}
      </AnimatePresence>
    </span>
  );
}

function ButtonLabel({
  children,
  loading,
}: {
  children: React.ReactNode;
  loading: boolean;
}) {
  return (
    <motion.span
      style={{ display: "block" }}
      transition={{
        type: "spring",
        damping: 26,
        stiffness: 233,
        mass: 0.6,
        delay: loading ? 0 : 0.1,
      }}
      animate={{
        y: loading ? -10 : 0,
        opacity: loading ? 0 : 1,
      }}
    >
      {children}
    </motion.span>
  );
}

const StyledButton = styled("button").withConfig<ButtonProps>({
  shouldForwardProp: (prop, defaultValidator) =>
    defaultValidator(prop) && prop !== "loading",
})`
  ${preset.typography.buttonXs}
  position: relative;
  padding: ${(x) =>
    x.size === "x-small"
      ? `${space.xs} ${space.xs}`
      : x.size === "small"
        ? `${space.xs} ${space.sm}`
        : `${space.md} 2.7rem`};

  background-color: transparent;
  color: currentColor;
  border: none;
  border-radius: ${radii.md};
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  transition-property: background-color, border-color, color, opacity;
  transition-duration: ${durations.sm};
  transition-timing-function: ${timingFunctions.out};
  width: ${(x) => x.fullWidth && "100%"};

  &:disabled {
    opacity: ${(x) => (x.loading ? 1 : 0.5)};
    cursor: default;
  }

  ${(x) =>
    x.variant === "primary" &&
    css`
      background: ${colors.indigo};
      border: 1px solid ${colors.indigo};
      color: white;

      &:not(:disabled):hover {
        background: ${darken(0.1, colors.indigo)};
        border-color: ${darken(0.1, colors.indigo)};
      }
    `}

  ${(x) =>
    x.variant === "secondary" &&
    (x.inverted
      ? css`
          color: white;
          border: 1px solid #314a64;

          &:not(:disabled):hover {
            color: white;
            border: 1px solid white;
          }
        `
      : css`
          color: ${colors.indigo};
          border: 1px solid ${colors.indigo};

          &:not(:disabled):hover {
            color: ${darken(0.1, colors.indigo)};
            border: 1px solid ${darken(0.1, colors.indigo)};
          }
        `)}

    ${(x) =>
    x.variant === "tertiary" &&
    (x.inverted
      ? css`
          color: white;
          border: 1px solid #314a64;

          &:not(:disabled):hover {
            color: white;
            border-color: white;
          }
        `
      : css`
          color: ${colors.slateGray};
          border: 1px solid ${colors.slateGray};

          &:not(:disabled):hover {
            color: ${darken(0.1, colors.blueGray)};
            border-color: ${darken(0.1, colors.blueGray)};
          }
        `)}
`;
