import type { MouseEvent, PointerEvent } from "react";
import React from "react";
import { css } from "styled-components";
import { space } from "~/theme";

export type InputWithLabelProps = {
  input: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  onPointerEnter?: (evt: PointerEvent) => void;
  onPointerLeave?: (evt: PointerEvent) => void;
  onClick?: (evt: MouseEvent) => void;
};

export function InputWithLabel({
  input,
  children,
  disabled,
  onPointerEnter,
  onPointerLeave,
  onClick,
}: InputWithLabelProps) {
  return (
    <label
      onClick={onClick}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      css={css`
        display: flex;
        align-items: baseline;
        cursor: ${disabled ? "default" : "pointer"};
      `}
    >
      <span
        css={css`
          display: flex;
          align-items: center;
          margin-right: ${space.md};
          top: -2px;
          position: relative;
        `}
      >
        {input}
      </span>

      {children && (
        <span
          css={css`
            opacity: ${disabled ? 0.5 : 1};
          `}
        >
          {children}
        </span>
      )}
    </label>
  );
}
