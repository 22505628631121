import React from "react";
import styled, { css } from "styled-components";
import { Icon, SpacedChildren } from "~/components";
import { colors, fontWeights } from "~/theme";

export function UpdateComplete({ message }: { message: string }) {
  return (
    <SpacedChildren
      horizontal
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <Icon size={24} name="circleTick" cx={{ color: colors.success }} />
      <Message>{message}</Message>
    </SpacedChildren>
  );
}

const Message = styled.span`
  font-weight: ${fontWeights.semibold};
  color: ${colors.blueGray};
`;
