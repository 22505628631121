import { Breadcrumb, ConfigProvider, Layout, Menu } from "antd";
import Link from "next/link";
import React from "react";
import { Spacer } from "~/components/spacer";
import { isProduction } from "~/config";
import { fonts } from "~/theme";

export type AdminPageContainerProps = {
  children: React.ReactNode;
  breadcrumbs?: Array<{ href?: string; name: string }>;
  activeMenuItem: "admin" | "guidelines" | "structures" | "performance-chart";
};

export function AdminPageContainer({
  children,
  breadcrumbs = [],
  activeMenuItem,
}: AdminPageContainerProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: fonts.body,
          fontSizeHeading3: 22,
          fontSizeHeading4: 18,
          fontSizeHeading5: 14,
        },
      }}
    >
      <Layout id="antd" style={{ minHeight: "100vh" }}>
        <Menu
          mode="horizontal"
          selectedKeys={[activeMenuItem]}
          theme={isProduction ? "dark" : "light"}
          items={[
            {
              key: "review-tool",
              label: <Link href="/reviews">Review Tool</Link>,
            },
            {
              key: "guidelines",
              label: <Link href="/admin/guidelines">Guidelines</Link>,
            },

            {
              key: "structures",
              label: <Link href="/admin/structures">Structures</Link>,
            },

            {
              key: "performance-chart",
              label: (
                <Link href="/admin/performance-chart">
                  Performance Chart Test
                </Link>
              ),
            },
          ]}
        ></Menu>

        <Layout>
          <Layout
            style={{
              padding: "0 24px 24px",
            }}
          >
            {breadcrumbs.length > 0 ? (
              <Breadcrumb
                style={{ margin: "8px 0", fontSize: 12 }}
                items={breadcrumbs.map((x) =>
                  x.href
                    ? {
                        title: <Link href={x.href}>{x.name}</Link>,
                      }
                    : { title: x.name }
                )}
              />
            ) : (
              <Spacer size="md" />
            )}

            {children}
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}
