import Link from "next/link";
import { darken } from "polished";
import type { MouseEvent } from "react";
import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { Icon } from "~/components";
import {
  colors,
  durations,
  fontSizes,
  radii,
  space,
  timingFunctions,
} from "~/theme";

type ActionLinkProps = {
  children: React.ReactNode;
  href?: string;

  title?: string;
  variant?: "primary" | "secondary" | "tertiary";
  onClick?: (evt: MouseEvent) => void;
};

export const ActionLink = forwardRef(function ActionLink(
  { href, title, variant = "secondary", children, onClick }: ActionLinkProps,
  ref
) {
  return (
    <StyledActionLink
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      ref={ref as any}
      {...(href ? { href } : { as: "button" })}
      variant={variant}
      title={title}
      onClick={onClick}
    >
      <span css={{ marginRight: space.sm }}>{children}</span>{" "}
      <span css={{ position: "relative", top: "1px" }}>
        <Icon name="chevronRight" size={20} />
      </span>
    </StyledActionLink>
  );
});

const StyledActionLink = styled(Link)<{ variant?: string }>`
  border-radius: calc(${radii.xl} * 2);

  padding: 1.1rem 1.5rem 1.1rem 2rem;
  font-size: ${fontSizes.md};
  line-height: 1;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  transition-property: background-color;
  transition-duration: ${durations.sm};
  transition-timing-function: ${timingFunctions.out};

  cursor: pointer;

  ${(x) =>
    x.variant === "primary" &&
    css`
      background: ${colors.indigo};
      border: 1px solid ${colors.indigo};
      color: white;

      &:not(:disabled):hover {
        background: ${darken(0.1, colors.indigo)};
        border-color: ${darken(0.1, colors.indigo)};
      }
    `}

  ${(x) =>
    x.variant === "secondary" &&
    css`
      background-color: ${colors.whiteLilac};
      border: 1px solid ${colors.whiteLilac};
      color: ${colors.charcoal};

      &:not(:disabled):hover {
        background-color: ${colors.athensGray};
        border-color: ${colors.athensGray};
      }
    `}

    ${(x) =>
    x.variant === "tertiary" &&
    css`
      color: ${colors.slateGray};
      border: 1px solid ${colors.slateGray};

      &:not(:disabled):hover {
        color: ${darken(0.1, colors.blueGray)};
        border-color: ${darken(0.1, colors.blueGray)};
      }
    `}
`;
