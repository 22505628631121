import { IconLabel } from "~/components/icon-label";
import { itemsJsOptions } from "../config";
import type { ToggleRefinementListDropDownToggle } from "./toggle-refinement-list-dropdown";
import { ToggleRefinementListDropDown } from "./toggle-refinement-list-dropdown";

export function HighlightsDropDown({ enableSuggestionsFilter = false }) {
  const toggles: ToggleRefinementListDropDownToggle[] = [
    {
      attribute: "isLowCost",
      label: (x) => (
        <IconLabel
          icon="dollar"
          label={`${itemsJsOptions.aggregations.isLowCost.title} (${
            x.onFacetValue.count ?? 0
          })`}
        />
      ),
    },
    {
      attribute: "isMissedOpportunity",
      label: (x) => (
        <IconLabel
          icon="star"
          label={`${itemsJsOptions.aggregations.isMissedOpportunity.title} (${
            x.onFacetValue.count ?? 0
          })`}
        />
      ),
    },
    {
      attribute: "includesNotes",
      label: (x) => (
        <IconLabel
          icon="comment"
          label={`${itemsJsOptions.aggregations.includesNotes.title} (${
            x.onFacetValue.count ?? 0
          })`}
        />
      ),
    },
  ];

  if (enableSuggestionsFilter) {
    toggles.push({
      attribute: "isSuggestion",
      label: (x) => (
        <IconLabel
          icon="circleTick"
          label={`${itemsJsOptions.aggregations.isSuggestion.title} (${
            x.onFacetValue.count ?? 0
          })`}
        />
      ),
    });
  }

  return <ToggleRefinementListDropDown label="Highlights" toggles={toggles} />;
}
