import { css } from "styled-components";
import { fontWeights } from "../theme";

const tags =
  "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video";
const tagsHtml5 =
  "article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section";

const meyerReset = css`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
  ${tags} {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  ${tagsHtml5} {
    /* HTML5 display-role reset for older browsers */
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  strong {
    font-weight: ${fontWeights.semibold};
  }
  em {
    font-style: italic;
  }
`;

export const reset = css`
  ${meyerReset}

  /** own resets */

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  a {
    color: currentColor;
  }
`;
