import { motion } from "framer-motion";
import { transparentize } from "polished";
import React from "react";
import styled from "styled-components";
import { ActionLink } from "~/components";
import { colors, radii, theme } from "~/theme";

export function SelectButton({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) {
  return (
    <ButtonContainer onClick={onClick} initial={false} whileHover="hover">
      <MotionActionLink
        variants={{
          hover: { translateX: 10, backgroundColor: colors.athensGray },
        }}
      >
        <strong>{label}</strong>
      </MotionActionLink>
      <HitArea />
    </ButtonContainer>
  );
}

const MotionActionLink = motion(ActionLink);

const ButtonContainer = styled(motion.div)`
  cursor: pointer;
  will-change: transform;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  filter: drop-shadow(0px 0px 30px ${transparentize(0.13, colors.blueZodiac)});
`;

const HitArea = styled.div`
  position: absolute;
  top: -50px;
  bottom: -50px;
  left: -50px;
  right: -50px;
  background: ${transparentize(0.6, theme.colors.indigo)};
  z-index: -1;
  border-radius: ${radii.xl};
`;
