import { isDefined } from "remeda";
import type { JudgementValue } from "~/constants";
import { getNumericValueFromJudgement } from "~/judgement";
import type { DetectedAssessmentChange } from "./types";

/**
 * Figure out if the change is positive or negative. Only return a value if we
 * can make a comparison.
 */
export function getJudgementDelta(
  changeType: DetectedAssessmentChange,
  judgementA?: JudgementValue,
  judgementB?: JudgementValue
): number | undefined {
  if (!isDefined(judgementA) || !isDefined(judgementB)) {
    return;
  }

  switch (changeType) {
    case "selection":
    case "judgement":
    case "nudge":
      if (judgementA === "na" || judgementB === "na") {
        return;
      }

      return (
        getNumericValueFromJudgement(judgementA) -
        getNumericValueFromJudgement(judgementB)
      );

    default:
      return;
  }
}
