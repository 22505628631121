import { createPubsubChannel } from "@klaasman/pubsub-channel";

export const embeddedPubsubChannels = {
  navigate: createPubsubChannel<string>("gemini-embedded-navigate"),
  height: createPubsubChannel<number>("gemini-embedded-height"),
  isSubmitDisabled: createPubsubChannel<boolean>(
    "gemini-embedded-is-submit-disabled"
  ),
  pageType: createPubsubChannel<"assess" | "submit" | "summary">(
    "gemini-embedded-page-type"
  ),
};
