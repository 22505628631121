import React from "react";
import { getFileArray } from "../file";

export function useOnClipboardPaste(
  callback: (images: File[]) => void,
  accept: (file: File) => boolean
) {
  React.useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const files = getFileArray(e.clipboardData?.items).filter((file) =>
        accept(file)
      );

      files.length && callback(files);
    };

    document.addEventListener("paste", handlePaste);
    return () => document.removeEventListener("paste", handlePaste);
  }, [callback, accept]);
}
