import { assert } from "@gemini/common";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { DocumentHead, PageLoader } from "~/components";
import { ReviewStore } from "./store";

const ReviewContext = React.createContext<ReviewStore | null>(null);

export function ReviewStoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const reviewId = router.query.reviewId as string;

  return (
    <ReviewContext.Provider
      value={useMemo(() => new ReviewStore(reviewId), [reviewId])}
    >
      <ReviewLoader>{children}</ReviewLoader>
    </ReviewContext.Provider>
  );
}

export function useReviewStore() {
  const store = React.useContext(ReviewContext);
  assert(store, "Missing ReviewStoreProvider in component tree");

  return store;
}

/**
 * The loader initializes the store based on the route if necessary, and waits
 * for the data to load before children rendered.
 */
const ReviewLoader = observer(({ children }: { children: React.ReactNode }) => {
  const store = useReviewStore();

  if (store.isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <DocumentHead title={store.title} />
      {children}
    </>
  );
});
