import { matchesCondition } from "../conditionals";
import type { IndustryId, Scenario, TagId } from "../schemas";

export function getPreAssessedScenarioIds(
  scenarios: Scenario[],
  reviewContext: { industryId: IndustryId; tagIds: TagId[] }
): string[] {
  return scenarios
    .filter(
      (scenario) =>
        scenario.rule?.action === "preAssess" &&
        matchesCondition(
          scenario.rule.condition,
          reviewContext.industryId,
          reviewContext.tagIds
        )
    )
    .map((scenario) => scenario.id);
}
