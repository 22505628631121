import { AH_VALUE } from "@gemini/common";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React from "react";
import styled, { css } from "styled-components";
import { Button, Paragraph, Sidebar } from "~/components";
import { useReviewStore } from "~/features/reviews";
import { JumpToGuideline } from "~/features/reviews/components";
import { useBreakpoints } from "~/modules/hooks";
import { colors, mediaQueries, space } from "~/theme";
import { usePartStore } from "../store";

export const SidebarFinished = observer(() => {
  const router = useRouter();
  const store = usePartStore();
  const breakpoints = useBreakpoints();
  const { review } = useReviewStore();

  if (store.isLoading) return <PartsContainer />;

  const { progress, navigation } = store;
  const reviewId = router.query.reviewId as string;

  return (
    <PartsContainer>
      <Sidebar.TextPart
        area="assessed"
        title={<WithAssessedIcon>100% Assessed</WithAssessedIcon>}
      >
        {!review.data?.is_read_only && (
          <Paragraph size="small" noMargin>
            Great work! You’ve reviewed all {progress.guidelineCount}{" "}
            guidelines. You have {progress.countByJudgement[AH_VALUE]}{" "}
            guidelines which adhered high.
          </Paragraph>
        )}
      </Sidebar.TextPart>

      <Sidebar.Part area="button">
        <Button
          href={navigation.nextPartLink}
          variant="secondary"
          inverted
          fullWidth={breakpoints.xsExact || breakpoints.md}
        >
          Go to next topic
        </Button>
      </Sidebar.Part>

      <Sidebar.Part area="overview-link">
        <Sidebar.Anchor href={`/reviews/${reviewId}`}>
          See all topics and scorecard
        </Sidebar.Anchor>

        <br />
        <JumpToGuideline>
          {(onClick) => (
            <Sidebar.AnchorButton onClick={onClick}>
              Jump to guideline or topic&hellip;
            </Sidebar.AnchorButton>
          )}
        </JumpToGuideline>
      </Sidebar.Part>
    </PartsContainer>
  );
});

const PartsContainer = styled.div`
  display: grid;
  grid-template-areas:
    "assessed"
    "button"
    "overview-link";

  grid-column-gap: ${space.lg};
  grid-row-gap: ${space.md};

  @media ${mediaQueries.sm} {
    grid-template-columns: min-content 1fr;
    grid-template-areas:
      "assessed assessed"
      "button overview-link";
  }

  @media ${mediaQueries.md} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "assessed"
      "button"
      "overview-link";
  }
`;

function WithAssessedIcon({ children }: { children: React.ReactNode }) {
  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${space.sm};
        @media ${mediaQueries.sm} {
          justify-content: flex-start;
        }
        @media ${mediaQueries.md} {
          justify-content: space-between;
        }
      `}
    >
      <div css={{ marginRight: space.md }}>{children}</div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        height="20"
        width="20"
      >
        <g fill="none" fillRule="evenodd">
          <circle cx="10" cy="10" r="10" fill={colors.indigo}></circle>
          <path
            fill="white"
            stroke="white"
            strokeWidth="0.5"
            d="M8.392 12.282l-1.99-2.077a.5.5 0 10-.722.692l2.401 2.505a.5.5 0 00.764-.049l4.423-5.995a.5.5 0 00-.804-.594l-4.072 5.518z"
          ></path>
        </g>
      </svg>
    </span>
  );
}
