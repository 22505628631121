import type { CropConfig } from "@gemini/common";
import { motion } from "framer-motion";
import React from "react";
import useDimensions from "react-cool-dimensions";
import styled, { css } from "styled-components";
import { isDefined } from "ts-is-present";
import { useObjectUrl } from "~/modules/file";
import { CropLines } from "./components/crop-lines";
import { SelectButton } from "./components/select-button";
import type { CropRegionsConfig } from "./logic";

type CropScreenshotProps = {
  image: File;
  regionsConfig: CropRegionsConfig;
  onSelect: (cropConfig?: CropConfig) => void;
};

export function SelectCropRegions({
  image,
  regionsConfig,
  onSelect,
}: CropScreenshotProps) {
  const { height, observe } = useDimensions<HTMLDivElement>();
  const [top, setTop] = React.useState(regionsConfig.defaultTopOffset);
  const [bottom, setBottom] = React.useState(regionsConfig.defaultBottomOffset);
  const [topHover, setTopHover] = React.useState<number>();
  const [bottomHover, setBottomHover] = React.useState<number>();

  const url = useObjectUrl(image);
  const getY = (y: number) => (height / regionsConfig.height) * y;

  const topValue = isDefined(topHover) ? topHover : top;
  const bottomValue = isDefined(bottomHover) ? bottomHover : bottom;

  return (
    <div
      onClick={(evt) => evt.stopPropagation()}
      css={css({
        padding: "0 5rem",
        width: "100%",
        height: "100%",
        position: "relative",
        cursor: "default",
        display: "flex",
      })}
    >
      <ImageContainer>
        {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text
         */}
        <img src={url} css={css({ display: "block", height: "100%" })} />
        <SelectButton label="Upload Original" onClick={() => onSelect()} />
      </ImageContainer>

      <ImageContainer ref={observe}>
        <SelectButton
          label="Upload Cropped"
          onClick={() =>
            onSelect({
              x: 0,
              y: top,
              width: regionsConfig.width,
              height: regionsConfig.height - top - bottom,
            })
          }
        />

        {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text
         */}
        <img
          src={url}
          css={css({ display: "block", height: "100%", opacity: 0.2 })}
        />

        <CroppedImageContainer
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          initial={false}
          animate={{
            top: getY(topValue),
            height: height - getY(bottomValue) - getY(topValue),
          }}
        >
          <CroppedImage
            src={url}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            animate={{ top: getY(topValue) * -1 }}
          />
        </CroppedImageContainer>

        <CropLines
          regionsConfig={regionsConfig}
          getY={getY}
          height={height}
          top={top}
          bottom={bottom}
          topHover={topHover}
          bottomHover={bottomHover}
          onTopChange={setTop}
          onBottomChange={setBottom}
          onTopHover={setTopHover}
          onBottomHover={setBottomHover}
        />
      </ImageContainer>
    </div>
  );
}

const CroppedImageContainer = styled(motion.div)`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

const CroppedImage = styled(motion.img)`
  display: block;
  max-height: 80vh;
  width: 100%;
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  top: 10vh;
  width: min-content;
  margin: 0 auto;
  height: 80vh;
  cursor: default;
`;
