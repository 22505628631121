import {
  AH_VALUE,
  AL_VALUE,
  NA_VALUE,
  NU_VALUE,
  VH_VALUE,
  VL_VALUE,
} from "./constants";
import type {
  JudgementNudgeValue,
  JudgementValue,
  TranslationValue,
} from "./schemas";
import { getNumberFromTranslationValue } from "./translation-value";

export function getJudgementFromTranslationValue(
  value: TranslationValue
): JudgementValue {
  switch (value) {
    case "vh":
      return VH_VALUE;
    case "vl":
      return VL_VALUE;
    case "neutral":
      return NU_VALUE;
    case "al":
      return AL_VALUE;
    case "ah":
      return AH_VALUE;
    case "na":
      return NA_VALUE;
    case "ir":
      return AH_VALUE;
    default:
      throw new Error(`Unable to convert ${value} to judgement
        value`);
  }
}

export function getTranslationValueFromNumericValue(
  value: number
): Exclude<TranslationValue, "ir" | "na"> {
  switch (value) {
    case VH_VALUE:
      return "vh";
    case VL_VALUE:
      return "vl";
    case NU_VALUE:
      return "neutral";
    case AL_VALUE:
      return "al";
    case AH_VALUE:
      return "ah";
    default:
      throw new Error(`Unable to convert number ${value} to translation value`);
  }
}

export function getNumericValueFromJudgement(
  judgement: JudgementValue
): number {
  switch (judgement) {
    case VH_VALUE:
      return -2;
    case VL_VALUE:
      return -1;
    case NU_VALUE:
      return 0;
    case AL_VALUE:
      return 1;
    case AH_VALUE:
      return 2;
    case NA_VALUE:
      throw new Error(`Unable to convert NA judgement to number`);
    default:
      throw new Error(`Invalid judgement value ${judgement}`);
  }
}

export function getJudgementFromNudging(
  translationValue: TranslationValue = "neutral",
  nudgeValue: JudgementNudgeValue
): JudgementValue {
  if (nudgeValue === 0)
    return getJudgementFromTranslationValue(translationValue);

  if (translationValue === "na" || translationValue === "ir") {
    throw new Error(
      `Unable to nudge non-numeric translation value ${translationValue}`
    );
  }

  const value = getNumberFromTranslationValue(translationValue);

  /** Boost and then clip the value between -2 and 2 */
  const nudgedValue = Math.max(
    Math.min(value + nudgeValue, AH_VALUE),
    VH_VALUE
  );

  return nudgedValue as JudgementValue;
}

export type JudgementString = "vh" | "vl" | "neutral" | "al" | "ah" | "na";

export function convertJudgementToJudgementString(
  judgement: JudgementValue
): JudgementString {
  switch (judgement) {
    case "na":
      return "na";
    case -2:
      return "vh";
    case -1:
      return "vl";
    case 0:
      return "neutral";
    case 1:
      return "al";
    case 2:
      return "ah";
    default:
      throw new Error(`Invalid judgement value ${judgement}`);
  }
}

export function getJudgementStringFromTranslationValue(
  value: TranslationValue
) {
  return value === "ir" ? "ah" : value;
}
