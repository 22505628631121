import type { MotionStyle } from "framer-motion";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

export function ScaleAppear({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: MotionStyle;
}) {
  return (
    <AnimatePresence>
      {children && (
        <motion.span
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0, transition: { duration: 0.1 } }}
          style={{ display: "block", ...style }}
        >
          {children}
        </motion.span>
      )}
    </AnimatePresence>
  );
}
