import React from "react";
import styled, { css } from "styled-components";
import { Input } from "~/components";
import { colors, fontSizes, fontWeights, space } from "~/theme";

type GuidelineSearchInputProps = {
  autoFocus?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onChange?: (value?: string) => void;
  onFocus?: () => void;
  value?: string;
};

export function GuidelineSearchInput({
  autoFocus,
  disabled,
  onBlur,
  onChange,
  onFocus,
  value = "",
}: GuidelineSearchInputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div css={{ position: "relative" }}>
      <Input
        autoFocus={autoFocus}
        disabled={disabled}
        ref={inputRef}
        value={value}
        onChange={(x) => onChange && onChange(x)}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="Search for a Guideline title or number (eg product or #42)"
        cx={{ paddingRight: "7.5rem" }}
      />
      <ClearButton
        disabled={disabled || !value}
        onClick={() => {
          onChange && onChange();
          inputRef.current?.focus();
        }}
      />
    </div>
  );
}

type ClearButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

function ClearButton({ disabled, onClick }: ClearButtonProps) {
  return (
    <div
      css={css`
        position: absolute;
        right: ${space.md};
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
      `}
    >
      <StyledClearButton disabled={disabled} onClick={onClick}>
        clear
      </StyledClearButton>
    </div>
  );
}

const StyledClearButton = styled.button`
  display: inline;
  margin: 0 ${space.sm} 0 0;
  padding: 0;
  border: 0;
  background: none;
  text-decoration: none;
  color: ${colors.slateGray};
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeights.semibold};
  cursor: ${(x) => (x.disabled ? "default" : "pointer")};
  opacity: ${(x) => (x.disabled ? 0.5 : 1)};
`;
