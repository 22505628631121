import type { TranslationValue } from "@gemini/common";
import { Tag } from "antd";
import React from "react";
import { isDefined } from "ts-is-present";

type ValueTagProps = {
  value?: TranslationValue;
  style?: React.CSSProperties;
};

export const ValueTag: React.FC<ValueTagProps> = ({ value, style }) => {
  if (!isDefined(value)) return null;

  switch (value) {
    case "vh":
      return (
        <Tag color="red" style={style}>
          VH
        </Tag>
      );
    case "vl":
      return (
        <Tag color="orange" style={style}>
          VL
        </Tag>
      );

    case "neutral":
      return (
        <Tag color="blue" style={style}>
          Neutral
        </Tag>
      );

    case "al":
      return (
        <Tag color="lime" style={style}>
          AL
        </Tag>
      );

    case "ah":
      return (
        <Tag color="green" style={style}>
          AH
        </Tag>
      );

    case "na":
      return <Tag style={style}>NA</Tag>;

    case "ir":
      return (
        <Tag color="green" style={style}>
          IR
        </Tag>
      );
    default:
      throw new Error(`Unknown value ${value}`);
  }
};
