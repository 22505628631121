import { LoadingOutlined } from "@ant-design/icons";
import * as React from "react";
import { colors } from "~/theme";

type InlineLoadingIndicatorProps = {
  size?: number;
};

export class InlineLoadingIndicator extends React.Component<InlineLoadingIndicatorProps> {
  public render() {
    const { size } = this.props;
    return (
      <LoadingOutlined
        spin
        style={{
          fontSize: size ?? "inherit",
          lineHeight: "inherit",
          color: colors.indigo,
        }}
      />
    );
  }
}
