import type { ReviewStructurePart } from "@gemini/common";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { StyledBoxWithBorder } from "~/components";
import { useReviewStore } from "~/features/reviews/store";
import { colors, durations, timingFunctions } from "~/theme";
import { PartRow } from "./part-row";

type RootPartProps = {
  part: ReviewStructurePart;
  active?: boolean;
};

export function RootPart({ part, active }: RootPartProps) {
  const { review } = useReviewStore();

  return (
    <StyledRootPart
      as={Link}
      noPadding
      href={`/reviews/${review.id}/parts/${part.id}`}
    >
      <PartRow
        active={active}
        level={0}
        progress={review.data?.progress.progress_by_part[part.id]}
      >
        {part.title}
      </PartRow>
    </StyledRootPart>
  );
}

const StyledRootPart = styled(StyledBoxWithBorder)`
  padding: 17px 24px;
  text-decoration: none;
  display: block;
  transition-property: background-color;
  transition-duration: ${durations.lg};
  transition-timing-function: ${timingFunctions.out};

  &:hover {
    transition-duration: 0ms;
    background: ${colors.whiteLilac};
  }
`;
