/**
 * The assessment store only takes care of the logic related to individual
 * guideline assessments.
 *
 * Everything related to navigation or other review global logic is located in
 * the review store. This split keeps things more clean and separated.
 */
import type {
  Assessment,
  CauseOfChange,
  CropConfig,
  Guideline,
  GuidelineObservation,
  ImageUpload,
  JudgementNudgeValue,
  JudgementValue,
  PlatformCounterparts,
  Review,
  ScenarioIdsByParent,
  ScenarioMap,
} from "@gemini/common";
import {
  NU_VALUE,
  assert,
  calculateTranslationValueRecursively,
  compileMasterTextMarkdown,
  createUniqueId,
  deriveSelectedIdsMap,
  getDeliverableTextSegmentsRecursively,
  getJudgementFromNudging,
  platforms,
  updateScenarioSelectionInPlace,
} from "@gemini/common";
import { default as autoBind } from "auto-bind";
import { collection } from "firebase/firestore";
import { ref, uploadBytesResumable } from "firebase/storage";
import { difference, isEmpty, uniq } from "lodash-es";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import moment from "moment";
import { isDefined } from "ts-is-present";
import { submitAssessment as _submitAssessment } from "~/modules/api";
import { Timestamp, db, storage } from "~/modules/firebase";
import type { Document } from "~/modules/firestore";
import { ObservableDocument } from "~/modules/firestore-mobx";
import { cropImage } from "~/modules/image";
import { notify } from "~/modules/notifications";
import { uploadFiles } from "~/modules/upload-files";
import { confirmUnload } from "~/modules/window";

type SubmittedAssessmentData = Pick<
  Assessment,
  | "selected_scenario_ids"
  | "comment"
  | "judgement"
  | "is_manual_judgement"
  | "is_issue_resolved"
  | "judgement_nudge_value"
  | "images"
  | "files"
  | "submitted_at"
  | "submitted_by"
  | "internal_comment"
  | "is_marked_for_discussion"
  | "is_marked_for_suggestion"
> & {
  observation?: {
    cause_of_change: CauseOfChange;
    text?: string;
  };
};

export type AssessmentFlow =
  | "review-flow"
  | "embedded-assessment-flow"
  | "update-flow";

export class AssessmentStore {
  /**
   * @todo Looking at it now, I am not sure why we do not just reference the
   *   partStore like the partStore does with the reviewStore. Then we can grab
   *   the review from there and also assessment, guideline and observation
   *   maybe do not need to be passed to initialize. We only need the
   *   currentReference.
   */
  private review: Document<Review>;

  isUpdateFlow = false;

  counterpartReferences = {} as PlatformCounterparts;
  counterpartGuidelines = {
    desktop: new ObservableDocument<Guideline>(collection(db, "guidelines")),
    mobile: new ObservableDocument<Guideline>(collection(db, "guidelines")),
    app: new ObservableDocument<Guideline>(collection(db, "guidelines")),
  };

  /**
   * We can not initialize the documents before we know the review id because it
   * dictates the collection path. Therefore the initialization happens in the
   * constructor.
   */
  counterpartAssessments: {
    desktop: ObservableDocument<Assessment>;
    mobile: ObservableDocument<Assessment>;
    app: ObservableDocument<Assessment>;
  };

  currentGuidelineId: string | undefined;
  currentAssessmentTimestamp: number | undefined;
  currentReference: string | undefined;

  scenarios: ScenarioMap = {};
  selectedIdsMap: ScenarioIdsByParent = {};

  isManualJudgement = false;
  manualJudgement: JudgementValue = 0;
  judgementNudgeValue: JudgementNudgeValue = 0;
  comment = "";
  images: string[] = [];
  files: string[] = [];

  internalComment = "";
  isMarkedForDiscussion = false;
  isMarkedForSuggestion = false;

  imageUploads: ImageUpload[] = [];

  pendingFileUploads: string[] = [];
  filesToDelete: string[] = [];

  /**
   * Keep a separate set of variables for submitting and database sync, so that
   * the data we display on the summary page is insulated from user edits. For
   * example, navigating from summary to assess and then using the browser
   * back-to land back on summary with could lead to invalid data.
   */
  submittedAssessmentData: SubmittedAssessmentData | undefined;

  isSubmitting = false;

  /**
   * In order to not rely on the submitted assessment data from Firestore, we
   * can flag the store when the submit has been fired, so we can proceed to
   * display the local data on the summary page.
   */
  hasAssessment = false;

  storedObservation: GuidelineObservation | undefined;
  observation?: { cause_of_change: CauseOfChange; text?: string };

  constructor({
    review,
    flow,
  }: {
    review: Document<Review>;
    flow: AssessmentFlow;
  }) {
    console.log("[assessment store] Constructor, flow:", flow);

    this.review = review;
    this.isUpdateFlow = flow === "update-flow";

    this.counterpartAssessments = {
      desktop: new ObservableDocument<Assessment>(
        collection(db, `reviews/${this.review.id}/assessments`)
      ),
      mobile: new ObservableDocument<Assessment>(
        collection(db, `reviews/${this.review.id}/assessments`)
      ),
      app: new ObservableDocument<Assessment>(
        collection(db, `reviews/${this.review.id}/assessments`)
      ),
    };

    makeAutoObservable(this, {
      /** Somehow these are not inferred correctly */
      initializeGuideline: action,
      initializeAssessment: action,
    });

    autoBind(this);
  }

  /**
   * In this store initialize is synchronous and already called as part of the
   * constructor. The fetching of counterpart data is not something we need to
   * wait for as it is just an aid to the user. They can arrive after rendering
   * the page.
   *
   * Initialize is called again in the update flow when a new guideline document
   * becomes available.
   *
   * @todo No sure why we pass in the data here as opposed to grabbing it from a
   *   referenced store. Maybe it was necessary for the "embedded" assessment
   *   flow? I think it's worth investigating and documenting. If it's not
   *   actually necessary things could be simplified quite a bit maybe.
   */
  initialize({
    guideline,
    assessment,
    guidelineObservation,
  }: {
    guideline: Document<Guideline>;
    assessment?: Document<Assessment>;
    guidelineObservation?: Document<GuidelineObservation>;
  }) {
    this.initializeGuideline(guideline);
    this.initializeAssessment(guideline.id, assessment, guidelineObservation);

    /**
     * This assignment needs to be outside of the initialize functions above,
     * because they both compare using guideline id
     */
    this.currentGuidelineId = guideline.id;
  }

  initializeGuideline(guideline: Document<Guideline>) {
    if (guideline.id === this.currentGuidelineId) {
      return;
    }

    this.counterpartReferences =
      guideline.data.platform_counterpart_references ?? {};
    this.scenarios = guideline.data.scenarios;

    this.fetchCounterparts();
  }

  initializeAssessment(
    guidelineId: string,
    assessment?: Document<Assessment>,
    guidelineObservation?: Document<GuidelineObservation>
  ) {
    const assessmentTimestamp = assessment?.data.submitted_at.toMillis();

    if (guidelineId !== this.currentGuidelineId) {
      /**
       * This check currently needs to be here, because initialize will be
       * called with assessment undefined moving from the assess page to summary
       * for a previously unassessed guideline (which will then reset the
       * variables, causing the "summary data not available" flash).
       */
      this.resetAssessmentVariables();
    }

    if (assessment) {
      if (this.isUpdateFlow && !this.hasAssessment) {
        /**
         * In the update flow we want to use the stored assessment as a preview
         * but not inject the values into the actual assessment parameters _if
         * we didn't already make the assessment_. Once the re-assessment is
         * submitted we move to the summary page and the submitted assessment
         * data will circle back from the database via the update store, so the
         * second time we can inject rather then inherit.
         */
        this.inheritOldAssessmentVariables(
          assessment.data,
          guidelineObservation?.data
        );
      } else {
        /**
         * Always inject assessment data if passed in. This allows us to move
         * from the assess page (after making changes) back to summary page
         * using the browser back-button. The submitted assessment data will
         * then get re-injected and we have the submitted selection when we land
         * back on the assess page again.
         */

        if (this.currentAssessmentTimestamp !== assessmentTimestamp) {
          /**
           * We don't want to overwrite local changes when the incoming
           * assessment data is unchanged, because when navigating to the page,
           * the current/previous assessment data will be passed in via props
           * before the new one comes back from the server. The inject would
           * overwrite our freshly submitted variables.
           */
          this.injectAssessmentVariables(
            assessment.data,
            guidelineObservation?.data
          );
        } else {
          /**
           * When a file upload completes, a function trigger will update the
           * assessment document without setting the timestamp. In that case
           * we'll arrive in this else-clause where we inject the new files in
           * the store and clean up the fileUploads map.
           */
          this.files = assessment.data.files || [];

          if (this.submittedAssessmentData) {
            this.submittedAssessmentData.files = this.files;
          }

          this.pendingFileUploads = this.pendingFileUploads.filter(
            (x) => !this.files.includes(x)
          );
        }
      }
    }

    this.currentAssessmentTimestamp = assessmentTimestamp;
    this.currentReference = assessment?.data.guideline_reference;
    this.storedObservation = guidelineObservation?.data;
  }

  resetAssessmentVariables() {
    this.isManualJudgement = false;
    this.manualJudgement = NU_VALUE;
    this.judgementNudgeValue = 0;
    this.comment = "";
    this.internalComment = "";
    this.isMarkedForDiscussion = false;
    this.isMarkedForSuggestion = false;
    this.images = [];
    this.imageUploads = [];
    this.files = [];
    this.pendingFileUploads = [];
    this.filesToDelete = [];
    this.selectedIdsMap = {};
    this.hasAssessment = false;

    this.observation = undefined;
    this.storedObservation = undefined;
  }

  /**
   * Inject sets all the temp variables _and_ submitted data according to the
   * assessment passed in. This way the store is in sync with what the database
   * has. When someone else submits an assessment while we are in the same
   * guideline assess pages, our copy will reflect the submitted data of the
   * other user.
   */
  injectAssessmentVariables(
    data: Assessment,
    guidelineObservation: GuidelineObservation | undefined
  ) {
    this.isManualJudgement = data.is_manual_judgement;
    this.manualJudgement = data.is_manual_judgement ? data.judgement : NU_VALUE;
    this.judgementNudgeValue = data.judgement_nudge_value;
    this.comment = data.comment || "";
    this.internalComment = data.internal_comment || "";
    this.isMarkedForDiscussion = !!data.is_marked_for_discussion;
    this.isMarkedForSuggestion = !!data.is_marked_for_suggestion;
    this.images = data.images || [];
    this.imageUploads =
      data.images?.map((path) => ({
        path,
      })) || [];

    this.files = data.files || [];

    this.submittedAssessmentData = {
      ...data,
      observation: guidelineObservation,
    };
    this.hasAssessment = true;

    /**
     * Selected ids are stored as a flat array in the database, so here we
     * convert it to a map for efficient internal use.
     */
    this.selectedIdsMap = deriveSelectedIdsMap(
      this.scenarios,
      data.selected_scenario_ids
    );

    if (guidelineObservation) {
      this.observation = {
        cause_of_change: guidelineObservation.cause_of_change,
        text: guidelineObservation.text,
      };
    }
  }

  /**
   * For re-assessing a guideline as part of the update flow we only want to
   * inherit some assessment variables. The scenario selection should be started
   * from scratch.
   */
  inheritOldAssessmentVariables(
    data: Assessment,
    guidelineObservation: GuidelineObservation | undefined
  ) {
    console.log("[assessment store] inherit old assessment variables");

    this.comment = data.comment || "";
    this.images = data.images || [];
    this.imageUploads =
      data.images?.map((path) => ({
        path,
      })) || [];

    this.files = data.files || [];
    this.pendingFileUploads = [];
    this.filesToDelete = [];

    this.internalComment = data.internal_comment || "";
    this.isMarkedForDiscussion = !!data.is_marked_for_discussion;
    this.isMarkedForSuggestion = !!data.is_marked_for_suggestion;

    if (guidelineObservation?.cause_of_change) {
      this.observation = {
        cause_of_change: guidelineObservation.cause_of_change,
        text: guidelineObservation.text,
      };
    }
  }

  get selectedIds() {
    return Object.values(this.selectedIdsMap).flat();
  }

  /** Does the current selection differ from the submitted assessment data? */
  get isSelectionDirty() {
    return (
      this.selectedIds.length !==
        (this.submittedAssessmentData?.selected_scenario_ids?.length ?? 0) ||
      difference(
        this.selectedIds,
        this.submittedAssessmentData?.selected_scenario_ids ?? []
      ).length > 0
    );
  }

  updateSelection(parentId: string, values?: string[]) {
    if (this.isManualJudgement) {
      /**
       * By ignoring the call here, we keep the selection in the tree when the
       * manual option is checked. Because otherwise disabling the scenario UI
       * would also clear the selected ids (via clear on unmount)
       *
       * If this behavior is desired remains to be seen...
       */
      return;
    }

    runInAction(() => {
      updateScenarioSelectionInPlace(this.selectedIdsMap, parentId, values);

      /** Reset any known nudge value whenever the selection changes */
      this.judgementNudgeValue = 0;
    });
  }

  get calculatedTranslationValue() {
    return calculateTranslationValueRecursively({
      allScenarios: this.scenarios,
      allSelectedScenarioIds: this.selectedIds,
      reviewContext: {
        industryId: this.review.data.industry_id,
        tagIds: this.review.data.tag_ids,
      },
    });
  }

  setJudgementNudgeValue(value: JudgementNudgeValue) {
    this.judgementNudgeValue = value;
  }

  setComment(value: string) {
    this.comment = value;
  }

  setInternalComment(value: string) {
    this.internalComment = value;
  }

  setIsMarkedForDiscussion(value: boolean) {
    this.isMarkedForDiscussion = value;
  }

  setIsMarkedForSuggestion(value: boolean) {
    this.isMarkedForSuggestion = value;
  }

  setIsManualJudgement(value: boolean) {
    this.isManualJudgement = value;

    if (value == true) {
      this.selectedIdsMap = {};
    }
  }

  setManualJudgementValue(value: JudgementValue) {
    this.manualJudgement = value;
  }

  /**
   * The final assessment.judgement value comes from the calculated
   * calculatedTranslationValue plus an optional nudge, or if it was set
   * manually it comes from manualJudgement + isManualJudgement = true.
   */
  get judgement() {
    return this.isManualJudgement
      ? this.manualJudgement
      : getJudgementFromNudging(
          this.calculatedTranslationValue?.value,
          this.judgementNudgeValue
        );
  }

  get isIssueResolved() {
    return (
      !this.isManualJudgement && this.calculatedTranslationValue.value === "ir"
    );
  }

  /**
   * `submitAssessment` will be prevented when the audit was delivered more than
   * 1 month ago. If the audit was delivered less than 1 month ago, the user
   * will be prompted to confirm the submission of the assessment.
   */
  async submitAssessment(userId: string) {
    /**
     * @todo This logic now lives both in the review store as in this store..
     *   Not sure we can access the review store from within this store?
     */
    const deliveredAtTimestamp = this.review.data.last_released_at?.toMillis();

    if (deliveredAtTimestamp) {
      const oneMonthAgo = moment().subtract(1, "months").toDate();

      /** Throw an error if the review was released more than 1 month ago */
      if (deliveredAtTimestamp < oneMonthAgo.getTime()) {
        throw new Error(
          "Error: Audit was delivered more than 1 month ago. Please contact the development team for assistance."
        );
      }

      const continueWithSubmit = window.confirm(
        `
You are about to update an assessment of a 🚨DELIVERED AUDIT🚨. Are you sure you want to continue?

Click OK to continue with submission.
Click Cancel to continue without submission.
        `.trim()
      );

      /** Block submission if the user cancels the submission. */
      if (!continueWithSubmit) {
        return Promise.resolve({ success: false });
      }
    }

    assert(this.currentGuidelineId, "Missing guideline id");
    assert(this.isImageUploadFinished, "Image upload did not finish");

    const imagesToSubmit = this.imageUploads.map((x) => x.path);
    const filesToSubmit = this.files.filter(
      (x) => !this.filesToDelete.includes(x)
    );

    /**
     * Optimistically store the assessment data as if it were the database
     * document, used for display in summary page.
     */
    this.submittedAssessmentData = {
      selected_scenario_ids: this.selectedIds,
      comment: this.comment,
      internal_comment: this.internalComment,
      is_marked_for_discussion: this.isMarkedForDiscussion,
      is_marked_for_suggestion: this.isMarkedForSuggestion,
      judgement: this.judgement,
      is_manual_judgement: this.isManualJudgement,
      /**
       * This field is normally calculated on the server. But the server does a
       * lot of double work so maybe we can simplify this later. Historically
       * the server calculated the judgement also out of "safety" reasons but it
       * seems like all logic is already pretty much in the client anyway.
       *
       * We need is_issue_resolved in order to _not_ need
       * calculatedTranslationValue in the summary page. Then the summary page
       * can get everything it needs from the submitted assessment data.
       */
      is_issue_resolved:
        !this.isManualJudgement &&
        this.calculatedTranslationValue.value === "ir",
      judgement_nudge_value: this.judgementNudgeValue,
      images: imagesToSubmit,
      files: filesToSubmit,
      submitted_at: Timestamp.fromMillis(Date.now()),
      submitted_by: userId,

      observation: this.observation,
    } satisfies SubmittedAssessmentData;

    this.isSubmitting = true;
    this.hasAssessment = true;

    const cancelConfirmUnload = confirmUnload();

    /**
     * For convenience reason we submit observation data to the same endpoint as
     * the assessment data. The server will handle the observation data
     * separately.
     */
    const isObservationDeleted =
      isDefined(this.storedObservation) && !isDefined(this.observation);

    const observation = isObservationDeleted
      ? "__delete_observation"
      : this.observation
        ? {
            cause_of_change: this.observation.cause_of_change,
            ...(this.observation.text && { text: this.observation.text }),
          }
        : undefined;

    return _submitAssessment({
      review_id: this.review.id,
      guideline_id: this.currentGuidelineId,
      manual_judgement: this.manualJudgement,
      is_manual_judgement: this.isManualJudgement,
      selected_ids: this.selectedIds,
      judgement_nudge_value: this.judgementNudgeValue,
      comment: this.comment,
      internal_comment: this.internalComment,
      is_marked_for_discussion: this.isMarkedForDiscussion,
      is_marked_for_suggestion: this.isMarkedForSuggestion,
      images: imagesToSubmit,
      files: filesToSubmit,
      is_update_flow: this.isUpdateFlow,

      /**
       * Include observation data only if it has a value. Passing undefined will
       * be converted to null by the server, which fails the Zod validation as
       * it does not accept null values.
       */
      ...(observation && { observation }),
    })
      .then(() => ({ success: true }))
      .finally(() => {
        cancelConfirmUnload();
        runInAction(() => (this.isSubmitting = false));
      });
  }

  get masterText() {
    assert(this.scenarios, "No scenario data available");

    const segments = getDeliverableTextSegmentsRecursively({
      scenarioId: "__root",
      allScenarios: this.scenarios,
      allSelectedScenarioIds: this.selectedIds,
      reviewContext: {
        industryId: this.review.data.industry_id,
        tagIds: this.review.data.tag_ids,
      },
    });

    return compileMasterTextMarkdown({
      segments,
      judgement: this.judgement,
      isManualJudgement: this.isManualJudgement,
      judgementNudgeValue: this.judgementNudgeValue,
    });
  }

  fetchCounterparts() {
    for (const platform of platforms) {
      const reference = this.counterpartReferences[platform];

      this.counterpartGuidelines[platform].attachTo(
        reference ? this.review.data.linked_guidelines[reference] : undefined
      );

      this.counterpartAssessments[platform].attachTo(reference);
    }
  }

  async addImages(images: File[], cropConfigs?: (CropConfig | undefined)[]) {
    if (cropConfigs && images.length !== cropConfigs.length) {
      throw new Error("Number of crop configs must match number of images");
    }

    /** Crop and upload files in parallel */
    const promisedOperations = images.map(async (image, index) => {
      const cropConfig = cropConfigs?.[index];
      const id = createUniqueId();
      const path = `upload-temp/${id}`;

      /**
       * The client-side cropped image is only used for display purposes, actual
       * cropping is done on the server.
       */
      const objectUrl = URL.createObjectURL(
        cropConfig ? await cropImage(image, cropConfig) : image
      );

      const uploadTask = uploadBytesResumable(ref(storage, path), image, {
        customMetadata: cropConfig
          ? { cropConfigJson: JSON.stringify(cropConfig) }
          : undefined,
      });

      const imageUpload: ImageUpload = observable({
        path,
        objectUrl,
        progress: 0,
        cancelUpload: () => {
          unsubscribe();
          uploadTask.cancel();
        },
      });

      const unsubscribe = uploadTask.on("state_changed", {
        next: (snapshot) => {
          imageUpload.progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },

        complete: () => {
          unsubscribe();
          imageUpload.objectUrl = undefined;
        },

        error: (err) => {
          unsubscribe();
          notify.error(err);

          this.imageUploads.splice(
            this.imageUploads.findIndex((x) => x.path === path),
            1
          );

          if (objectUrl) {
            URL.revokeObjectURL(objectUrl);
          }
        },
      });

      runInAction(() => {
        this.imageUploads.push(imageUpload);
      });
    });

    await Promise.all(promisedOperations);
  }

  removeImage(image: ImageUpload) {
    image.cancelUpload?.();

    if (image.objectUrl) {
      URL.revokeObjectURL(image.objectUrl);
    }

    const index = this.imageUploads.findIndex((x) => x.path === image.path);
    this.imageUploads.splice(index, 1);
  }

  reorderImages(images: ImageUpload[]) {
    this.imageUploads = images;
  }

  async addFiles(files: File[], directory: string, maxFileSizeMb: number) {
    const { currentReference } = this;

    files.forEach((file) => {
      this.filesToDelete = this.filesToDelete.filter((x) => x !== file.name);
      this.pendingFileUploads = uniq([...this.pendingFileUploads, file.name]);
    });

    await uploadFiles(files, directory, maxFileSizeMb, (file, error) => {
      runInAction(() => {
        /**
         * The store is recycled between assessments, we must prevent the async
         * uploadFiles-callback from mutating the store when it's used for
         * another assessment.
         */
        if (this.currentReference === currentReference) {
          this.pendingFileUploads = this.pendingFileUploads.filter(
            (x) => x !== file.name
          );

          if (!error) {
            this.files = uniq([...this.files, file.name]);
          }
        }
      });
    });
  }

  removeFile(fileName: string) {
    this.filesToDelete = this.filesToDelete.includes(fileName)
      ? this.filesToDelete.filter((x) => x !== fileName)
      : [...this.filesToDelete, fileName];
  }

  get isSubmitDisabled() {
    const hasNoSelection =
      this.selectedIds.length === 0 && !this.isManualJudgement;
    const hasValidationErrors = !isEmpty(
      this.calculatedTranslationValue?.validationErrors
    );

    return (
      hasNoSelection ||
      hasValidationErrors ||
      this.isSubmitting ||
      !this.isImageUploadFinished
    );
  }

  get isImageUploadFinished() {
    return (
      this.imageUploads.filter((upload) => !!upload.objectUrl).length === 0
    );
  }

  setObservation(updateValue: CauseOfChange, text?: string) {
    assert(this.review.data.review_comparison_id, "Missing comparison id");

    runInAction(() => {
      this.observation = { cause_of_change: updateValue, text };
    });
  }

  deleteObservation() {
    runInAction(() => {
      this.observation = undefined;
    });
  }
}
