import { observer } from "mobx-react-lite";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import React from "react";
import { css } from "styled-components";
import {
  BackLink,
  Button,
  Message,
  PageContainer,
  PageLoader,
  Paragraph,
  SpacedChildren,
  Title,
} from "~/components";
import { useReviewStore } from "~/features/reviews";
import { archiveReview } from "~/modules/api";
import { useIsMountedRef } from "~/modules/hooks";
import { notify } from "~/modules/notifications";
import { colors, space } from "~/theme";

export const ReviewDeletePage: NextPage = observer(() => {
  const isMountedRef = useIsMountedRef();
  const [isDeleting, setIsDeleting] = React.useState(false);
  const router = useRouter();

  const store = useReviewStore();

  if (store.isLoading) {
    return <PageLoader />;
  }

  const { review } = store;
  const reviewId = router.query.reviewId as string;

  if (!review.data) {
    return <PageContainer>No data for review {reviewId}</PageContainer>;
  }

  return (
    <PageContainer narrowContent testingName="delete-page">
      <SpacedChildren>
        <BackLink href={`/reviews/${reviewId}`}>Back</BackLink>

        <Title level={2} as="h1">
          Delete review
        </Title>

        <SpacedChildren>
          <Message variant="danger" icon="error">
            <Paragraph noMargin>
              You are about to delete ’<strong>{review.data.title}</strong>’
              review. This will remove this review and all its scorecards and
              ratings for all ‘Baymard’ Baymard Premium users. This cannot be
              undone.
            </Paragraph>
          </Message>
        </SpacedChildren>

        <SpacedChildren
          horizontal
          css={css`
            border-top: 1px solid ${colors.gray};
            display: flex;
            justify-content: flex-end;
            padding: ${space.md} 0;
          `}
        >
          <Button href={`/reviews/${reviewId}`} variant="secondary">
            No, I've changed my mind
          </Button>

          <Button
            onClick={() => handleDelete(reviewId)}
            disabled={isDeleting}
            testingName="delete-button"
          >
            Yes, delete the review
          </Button>
        </SpacedChildren>
      </SpacedChildren>
    </PageContainer>
  );

  async function handleDelete(reviewId: string) {
    setIsDeleting(true);

    try {
      await archiveReview({ reviewId });
      await router.push(`/reviews`, `/reviews`);
    } catch (err) {
      notify.error(err);

      /**
       * @todo Build async function handing in button, then we don't have to
       *   have this isMounted and isDeleting logic in the components
       */
      if (isMountedRef.current) {
        setIsDeleting(false);
      }
    }
  }
});
